/** 
* 自动生成文件，请勿修改 
* @name: 项目:质检申请模块
* @description: 质检申请模块-增删改查
* @create: 2024/10/18 15:02:19
* @swagger: 2.0
* 合计:10条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const qcApply = { 
	/** 1 summary:查询-不分页
	description:参数：QcApplyQueryVO对象，查询条件太多而单独封装的对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/qcApply/queryAll.do"), 
	/** 2 summary:查询-分页
	description:参数：(page、limit,分页参数，)、QcApplyQueryVO对象，查询条件太多而单独封装的对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/qcApply/queryPage.do"), 
	/** 3 summary:质检完成状态更改-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	complete : new APIObject.postObject("/api/qcApply/complete.do"), 
	/** 4 summary:保存/修改-批量
	description:参数：List<QcApplyVO>
	method:post
	params:需要配合Column函数使用
	*/ 
	batchSaveOrUpd : new APIObject.postObject("/api/qcApply/batchSaveOrUpd.do"), 
	/** 5 summary:审核-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	audit : new APIObject.postObject("/api/qcApply/audit.do"), 
	/** 6 summary:保存/修改-单个
	description:参数：单条数据对象QcApplyVO（新增时只需传content、departmentId、itemId、title、type几个即可）
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/qcApply/saveOrUpd.do"), 
	/** 7 summary:绑定质检负责人-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	bindQcLeader : new APIObject.postObject("/api/qcApply/bindQcLeader.do"), 
	/** 8 summary:查询-单条数据
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/qcApply/queryOne.do"), 
	/** 9 summary:删除-批量
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/qcApply/batchDelete.do"), 
	/** 10 summary:删除-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/qcApply/delete.do"), 
};
export default qcApply;