<!--
* @program: TYHOA2 
* @author: ly
* @component:CardRoute 
* @description: 
* @create: 2024-09-13 17:41
-->
<template>
    <div class="column-flex">
        <div class="row-flex">
            <div style="height: 100%;width:200px; overflow: auto;background: #fafafa;">
                <MenuCardItem v-for="(child,index) in childrenRoutes.filter(item=>item.path)" :key="index"
                              :menu="child" :enable="$route.name === child.name"
                              @click="change(child)"/>
            </div>
            <div class="route-content">
                <transition  name="fade"   mode="out-in">
                    <router-view />
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
    import MenuCardItem from "./MenuCardItem";

    export default {
        name: "CardRoute",
        inject:["addHisMenu"],
        components:{
            MenuCardItem
        },
        data() {
            return {
                childrenRoutes:[],
                title:"",
            }
        },
        methods: {
            change(menu){
                this.$router.push({name: menu.name});
                let {name,cnName} = menu;
                cnName = this.title + cnName;
                this.addHisMenu({name,cnName})
            }
        },
        created() {
            let matchedRoutes = this.$route.matched;
            let currentRoute = matchedRoutes[matchedRoutes.length - 1];
            if(currentRoute.children.length >0){
                this.childrenRoutes = currentRoute.children;
            }
            else{
                this.childrenRoutes = matchedRoutes[matchedRoutes.length - 2].children
            }
            let route = this.childrenRoutes.find(item=>item.name === this.$route.name);
            if(!route){
                let {name,cnName} = this.childrenRoutes[0];
                cnName = this.title + cnName;
                this.addHisMenu({name,cnName});
                this.$router.push({name:name});
            }
        },
    }
</script>

<style scoped>

</style>