<!--
* @program: tyh-oa
* @author: ly
* @component:BillStatisticsByProject
* @description: 按时间统计账单
* @create: 2021-06-11 11:33
-->
<template>
    <div class="column-flex">
        <div class="search-bar">
            <div style="margin: 8px;font-weight: 900;font-size: 16px"> 统计方式：</div>
            <a-radio-group style="margin-left: 10px" v-model:value="picker" @change="doSearch">
                <a-radio value="month">月份</a-radio>
                <a-radio value="year">年度</a-radio>
            </a-radio-group>
            <a-date-picker @change="doSearch" v-model:value="queryTime" :picker="picker" />
            <a-button v-if="visible" @click="downLoadExcel" type="dashed" danger>
                <template #icon>
                    <DownloadOutlined />
                </template>
                下载为excel
            </a-button>
            <span v-if="visible" style="margin-left: 10px">合计：{{sum.toFixed(2)}}</span>
        </div>
        <ProjectChart :setting="setting" v-if="visible"/>
        <div style="margin:10% 30%;font-size: 36px" v-else>
            <a-empty description="暂无数据，请选择查询时间段"/>
        </div>

    </div>
</template>

<script>
    import ProjectChart from "@/page/Finance/BillManager/BillStatistics/ProjectChart";
    import api_fundSummary from "@/assets/api/fundSummary"
    import excelJS from "exceljs";
    import {DownloadOutlined} from "@ant-design/icons-vue"

    export default {
        name: "BillStatisticsByProject",
        components: {
            ProjectChart,DownloadOutlined
        },
        computed: {
            sum() {
                let sum = 0;
                for (let i in this.resData) {
                    sum += this.resData[i].applyAmount;
                }
                return sum;
            },
            queryTimeFormat(){
                if(this.picker === "month"){
                    return this.queryTime?this.queryTime.format("YYYY-MM"):""
                }
                else{
                    return this.queryTime?this.queryTime.format("YYYY"):""
                }

            }
        },
        data() {
            return {
                visible: false,
                picker:"month",
                setting: {
                    legend: {
                        data: ['有发票金额', '无发票金额']
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: []
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value'
                        }
                    ],
                    series: [
                        {
                            name: '有发票金额',
                            type: 'bar',
                            stack: '总消耗',
                            emphasis: {
                                focus: 'series'
                            },
                            data: []
                        },
                        {
                            name: '无发票金额',
                            type: 'bar',
                            stack: '总消耗',
                            emphasis: {
                                focus: 'series'
                            },
                            data: []
                        },
                    ]
                },
                options: [
                    {seg: "month", label: "按月统计"},
                    {seg: "year", label: "按年统计"},
                    //{seg:"period",label:"按时间段统计",comp:"periodSelector"}
                ], //[{seg:"title",label:"账单标题",type:"input"}]
                currentComp: "monthSelector",
                type: "month",
                queryTime: null,
                resData: []
            }
        },
        created() {
            setTimeout(() => {
                this.doSearch();
            }, 500);
        },
        methods: {
            doSearch() {
                if(!this.queryTime){
                    return
                }
                this.setting = {
                    legend: {
                        data: ['有发票金额', '无发票金额']
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: []
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value'
                        }
                    ],
                    series: [
                        {
                            name: '有发票金额',
                            type: 'bar',
                            stack: '总消耗',
                            emphasis: {
                                focus: 'series'
                            },
                            data: []
                        },
                        {
                            name: '无发票金额',
                            type: 'bar',
                            stack: '总消耗',
                            emphasis: {
                                focus: 'series'
                            },
                            data: []
                        },
                    ]
                };
                this.visible = false;
                switch (this.picker) {
                    case "month": {
                        let data = {yearMonth: this.queryTime.format("YYYY-MM")};
                        api_fundSummary.statiByMonth.urlPost(data, res => {
                            if (res.data.flag) {
                                this.visible = true;
                                this.applySetting(res.data.data);
                            } else {
                                this.$message.error(res.data.msg);
                            }
                        });
                        break;
                    }
                    case "year": {
                        let data = {year: this.queryTime.format("YYYY")};
                        api_fundSummary.statiByYear.urlPost(data, res => {
                            if (res.data.flag) {
                                this.visible = true;
                                this.applySetting(res.data.data);
                            } else {
                                this.$message.error(res.data.msg);
                            }
                        });
                        break;
                    }
                }
            },
            applySetting(data) {
                this.resData = [];
                let sortData = data.sort((a, b) => {
                    let suma = a.haveInvoiceAmount + a.noInvoiceAmount;
                    let sumb = b.haveInvoiceAmount + b.noInvoiceAmount;
                    return suma - sumb
                });
                for (let i in sortData) {
                    let name = sortData[i].itemName ? sortData[i].itemName : sortData[i].itemId;
                    let a = sortData[i].haveInvoiceAmount;
                    let b = sortData[i].noInvoiceAmount;
                    if (name !== "非项目支出") {//删除非项目支出
                        this.setting.xAxis[0].data.push(name);
                        this.setting.series[0].data.push(a);
                        this.setting.series[1].data.push(b);
                        this.resData.push(sortData[i]);
                    }
                }
            },
            downLoadExcel() {
                let workbook = new excelJS.Workbook();
                let worksheet = workbook.addWorksheet(this.queryTimeFormat);
                worksheet.columns = [
                    {header: "项目名称", key: "itemName", width: 10},
                    {header: "报账额", key: "applyAmount", width: 10},
                ];
                for (let i in this.resData) {
                    let row = parseInt(i) + 2;
                    worksheet.getRow(row).values = this.resData[i];
                }
                workbook.xlsx.writeBuffer().then((data) => {
                    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                    const blob = new Blob([data], {type: EXCEL_TYPE});
                    let tagA = document.createElement('a');
                    tagA.href = window.URL.createObjectURL(blob);// 获取当前文件的一个内存URL
                    tagA.download = this.queryTimeFormat + "项目消耗统计.xlsx";// 下载后文件名
                    tagA.style.display = 'none';
                    document.body.appendChild(tagA);
                    tagA.click();
                    window.URL.revokeObjectURL(tagA.href);
                    document.body.removeChild(tagA);
                });
            }
        }
    }
</script>

<style scoped>

</style>
