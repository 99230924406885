<!--
* @program: TYHOA2 
* @author: ly
* @component:ProjectInvoice
* @description: 收入发票
* @create: 2024-07-25 15:20
-->
<template>
    <div class="form-title">收入发票列表
        <a-button  type="link"  @click="refresh">
            <template #icon><SyncOutlined /></template>
        </a-button>
    </div>
    <div style="display: flex;margin-left: 30px;align-items: center;">
        <a-button  v-if="!viewModel" type="dashed" @click="addData">
            <template #icon><PlusOutlined /></template>添加发票
        </a-button>
        <div style="margin-left: 10px;">已开票合计: <span class="form-value">{{sum.invoiceAmount}} 元</span></div>
        <div style="margin-left: 10px;">已收款合计: <span class="form-value">{{sum.collectAmount}} 元</span></div>
        <div style="margin-left: 10px;">已收款占比: <span class="form-value">{{sum.per}} %</span></div>
    </div>
    <LTable style="height: 100%" :columns="tableColumns" :data-source="dataSource">
        <template #action="{index,record}">
            <div class="action-column">
                <a-tooltip  title="添加收款">
                    <a-button  type="link" v-if="!viewModel"  @click="addPayed(record)">
                        <template #icon><PlusOutlined /></template>
                    </a-button>
                </a-tooltip>
                <a-button  type="link"  @click="showDetail(index,record)">
                    <template #icon><SearchOutlined /></template>
                </a-button>
                <a-button  type="link" v-if="!viewModel" @click="editDetail(index,record)">
                    <template #icon><EditOutlined /></template>
                </a-button>
                <a-popconfirm
                        v-if="deleteCheck(record) && !viewModel"
                        ok-text="是"
                        cancel-text="否"
                        class="action-button"
                        title="确定删除吗?"
                        @confirm="deleteData(index,record)">
                    <DeleteOutlined  style="color: red" class="pointer"/>
                </a-popconfirm>
            </div>
        </template>
    </LTable>

    <a-modal v-model:open="visible"
             destroy-on-close
             :title="null"
             :maskClosable="vModel"
             :footer="null">
        <SelectContract v-if="!currentRecord.id && !vModel" @change="handleChange" :contract="record.contractInfoDTOs"/>
        <a-row v-if="!vModel">
            <a-col :span="4">
                <span class="form-label">收款方</span>
            </a-col>
            <a-col :span="20">
                <span v-if="contract" class="form-value">{{contract.sigCompanyName}}</span>
                <a-select style="width: 100%" v-else v-model:value="companyId" :options="companies" />
            </a-col>
        </a-row>
        <a-row v-if="!vModel">
            <a-col :span="4">
                <span class="form-label">付款方</span>
            </a-col>
            <a-col :span="20">
                <span v-if="contract" class="form-value">{{contract.associatedName}}</span>
                <CooperationUnitsPicker :option="option" style="width: 100%" v-else v-model:value="partyId" />
            </a-col>
        </a-row>
        <a-divider />
        <LForm title="发票信息" :view-model="vModel"  :form-columns="formColumns" :form-data="currentRecord">
            <template #action="{record}">
                <a-button @click="saveData(record)"  type="primary">
                    提交
                </a-button>
            </template>
        </LForm>
    </a-modal>

    <a-modal v-model:open="visible2"
             destroy-on-close
             title="发票收款"
             :maskClosable="vModel"
             :footer="null">
        <a-row v-if="!vModel">
            <a-col :span="6">
                <span class="form-value">选择入账账户</span>
            </a-col>
            <a-col :span="18">
                <a-select :fieldNames="{key:'id', value: 'id',label:'remarks'}"
                          v-model:value="bankId" style="width: 100%" :options="bankCards">
                </a-select>
            </a-col>
        </a-row>
        <LForm :view-model="vModel" :form-columns="formColumns2" :form-data="formData2">
            <template #action="{record}">
                <a-button @click="saveData2(record)"  type="primary">
                    提交
                </a-button>
            </template>
        </LForm>
    </a-modal>
</template>

<script>
    import {DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined, SyncOutlined} from "@ant-design/icons-vue";
    import LForm from "../../../components/form/LForm";
    import LTable from "../../../components/table/LTable";
    import item_invoice_record_new from "../../TableStruct/item_invoice_record_new";
    import invoiceRecord from "../../../assets/api/invoiceRecord";
    import SelectContract from "./SelectContract";
    import item_collection_info from "../../TableStruct/item_collection_info";
    import Column from "../../../assets/tables/column";
    import ColumnType from "../../../assets/tables/column_type";
    import collectionInfo from "../../../assets/api/collectionInfo";
    import {GetDict} from "../../../assets/utils/general";
    import CooperationUnitsPicker from "../../common/CooperationUnitsPicker";
    export default {
        name: "ProjectInvoice",
        props:["record","bankCards","viewModel"],
        inject:["pProjectId"],
        components:{
            PlusOutlined,DeleteOutlined,EditOutlined,
            SyncOutlined,SearchOutlined,
            LForm,LTable,SelectContract,CooperationUnitsPicker
        },
        computed:{
            sum(){
                let invoiceAmount = 0;//已开票金额
                let collectAmount = 0;//已收款金额
                for(let item of this.dataSource){
                    invoiceAmount += item.invoiceAmount;
                    collectAmount += item.collectAmount;
                }
                let per = invoiceAmount?(collectAmount/invoiceAmount *100).toFixed(2):0;
                return {invoiceAmount,collectAmount,per}
            },
            projectId(){
                if(this.record){
                    return this.record.itemId?this.record.itemId:this.record.id
                }
                else{
                    return this.pProjectId;
                }
            }
        },
        data(){
            return{
                table:null,
                dataSource:[],
                tableColumns:null,

                loading:false,
                formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                formColumns2:[
                    new Column("收款时间","collectionTime",ColumnType.Date,true),
                    new Column("收款金额(元)","collectionAmount",ColumnType.Number,true),
                    new Column("收款备注","remarks",ColumnType.String,true)
                ], //提供Form的字段集 this.table.getFormColumns()
                currentRecord:null,//提供Form的formData
                formData2:null,//提供Form的formData
                visible:false,
                visible2:false,
                vModel:true,
                contract:null,
                bankId:null,

                partyId:undefined, //付款方ID
                option:[],
                companies:GetDict("companies"),//收款方为我公司
                companyId:undefined, //收款方ID
            }
        },
        methods:{
            init(){
                this.table = new item_invoice_record_new();
                this.tableColumns = this.table.getTableColumns();
                this.formColumns = this.table.getFormColumns();
                let data = {itemId:this.projectId};
                this.loading = true;
                invoiceRecord.queryAll.post(data,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                })
            },
            refresh(){
                this.init()
            },
            addData(){
                this.contract = null;
                this.vModel = false;
                this.visible = true;
                this.currentRecord = this.table.getFormData();
                this.currentRecord.itemId = this.projectId;
                if(this.record && this.record.sigCompany){
                    this.currentRecord.companyId = this.record.sigCompany;
                }
                this.formColumns = this.table.getFormColumns();
                this.partyId = undefined;
                this.companyId = undefined;
                this.option = [];
            },
            showDetail(index,record){
                this.contract = null;
                this.visible = true;
                this.vModel = true;
                this.currentRecord = record;
                this.formColumns = this.table.getFormColumns();
            },
            editDetail(index,record){
                this.contract = null;
                this.visible = true;
                this.vModel = false;
                this.currentRecord = record;
                this.formColumns = this.table.getFormColumns();
                this.partyId = record.partyId;
                this.companyId = record.companyId;
                this.option = [record.partyInfo];
            },
            deleteData(index,record){
                let data = {id:record.id};
                invoiceRecord.delete.urlPost(data,res=>{
                    if(res.data.flag){
                        this.dataSource.splice(index,1);
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            saveData(record){
                if(this.contract){
                    record.partyId = this.contract.associatedKey;
                    record.companyId = this.contract.sigCompany;
                }
                else{
                    record.partyId = this.partyId;
                    record.companyId = this.companyId;
                }
                invoiceRecord.saveOrUpdate.post(record,res=>{
                    if(res.data.flag){
                        this.init();
                        this.visible = false;
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });

            },
            deleteCheck(record){
                return record.collectAmount === 0;
            },
            handleChange(contract){
                this.contract = contract;
            },
            addPayed(record){
                this.vModel = false;
                this.visible2 = true;
                this.currentRecord = record;
                this.formData2 = new item_collection_info().getFormData();
            },
            saveData2(record){
                if(this.bankId){
                    record.bankId = this.bankId;
                    record.itemId = this.projectId;
                    record.invoiceId = this.currentRecord.id;
                    record.receiverId = this.currentRecord.companyId;
                    record.payerId = this.currentRecord.partyId;
                    collectionInfo.save.post(record,res=>{
                        if(res.data.flag){
                            this.init();
                            this.visible2 = false;
                            this.$message.success(res.data.msg);
                        }
                        else{
                            this.$message.error(res.data.msg);
                        }
                    });
                }
                else{
                    this.$message.error("还未选择入账账户")
                }
            },
        },
        created() {
            this.init();
        }
    }
</script>

<style scoped>

</style>