/** 
* 自动生成文件，请勿修改 
* @name: 支出:其他支出申请审批流水记录
* @description: 其他支出申请审批流水记录（四期新增（董灿提出））
* @create: 2024/10/18 15:02:19
* @swagger: 2.0
* 合计:8条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const otherPayApproval = { 
	/** 1 summary:批量删除
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/otherPayApproval/batchDelete.do"), 
	/** 2 summary:批量保存/修改
	description:参数：List<OtherPayApprovalVO>
	method:post
	params:需要配合Column函数使用
	*/ 
	batchSaveOrUpd : new APIObject.postObject("/api/otherPayApproval/batchSaveOrUpd.do"), 
	/** 3 summary:审核支出申请
	description:参数：payId:支出申请id;state:(2:通过,3:不通过)
	method:post
	params:需要配合Column函数使用
	*/ 
	approvalApply : new APIObject.postObject("/api/otherPayApproval/approvalApply.do"), 
	/** 4 summary:查询所有（可根据申请id查询该申请的审核流水状态）
	description:参数：OtherPayApprovalQueryVO
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/otherPayApproval/queryAll.do"), 
	/** 5 summary:单条数据查询
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/otherPayApproval/queryOne.do"), 
	/** 6 summary:单条删除
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/otherPayApproval/delete.do"), 
	/** 7 summary:保存/修改
	description:参数：单条数据对象PayApprovalVO
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/otherPayApproval/saveOrUpd.do"), 
	/** 8 summary:分页查询（可根据申请id查询该申请的审核流水状态）
	description:参数：OtherPayApprovalQueryVO
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/otherPayApproval/queryPage.do"), 
};
export default otherPayApproval;