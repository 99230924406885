<!--
* @program: office_automation
* @author: ly
* @component:AssessmentRoleSetting
* @description: 月度考核角色设定
* @create: 2022-02-24 14:58
-->
<template>
    <div class="column-flex">
        <div class="search-bar">
            <a-button @click="refresh"  shape="circle" type="dashed">
                <template #icon>
                    <SyncOutlined/>
                </template>
            </a-button>
            <span>修改前请先确认角色说明</span>
        </div>
        <div style="width: 100%;height: 100%;display: flex">
            <div class="full-fill">
                    <LTable :columns="argument.tableColumns" :dataSource="argument.dataSource" :view-model="true" :page-size="pageSize">
                        <template #action="{record}">
                            <div class="action-column">
                                <a-tooltip title="人员设定">
                                    <a-button  type="link" @click="addUser(record)">
                                        <template #icon>
                                            <UsergroupAddOutlined />
                                        </template>
                                    </a-button>
                                </a-tooltip>
                                <!--<a-tooltip title="移除人员">-->
                                <!--<a-button  type="link" @click="removeUser(record)">-->
                                <!--<template #icon>-->
                                <!--<UsergroupDeleteOutlined style="color: red"/>-->
                                <!--</template>-->
                                <!--</a-button>-->
                                <!--</a-tooltip>-->
                            </div>
                        </template>
                    </LTable>
            </div>
        </div>
    </div>
    <a-modal v-model:open="visible"
             destroyOnClose
             :footer="null"
             title="选择人员">
        <a-transfer
                :list-style="{height: '300px'}"
                show-search
                :data-source="currentUsers"
                :titles="titles"
                :target-keys="targetKeys"
                :selected-keys="selectedKeys"
                :render="item => item.title"
                :disabled="false"
                @change="handleChange"
                @selectChange="handleSelectChange"
        />
        <a-button style="margin: 10px"  type="primary" @click="saveData">
            提交
        </a-button>
    </a-modal>
</template>

<script>
    import AssessmentRole from "../../../TableStruct/AssessmentRole";
    import LTable from "../../../../components/table/LTable";
    import {SyncOutlined,UsergroupAddOutlined,UsergroupDeleteOutlined} from "@ant-design/icons-vue";
    import assessmentRole from "../../../../assets/api/assessmentRole";
    import userInfo from "../../../../assets/api/userInfo";
    export default {
        name: "AssessmentRoleSetting",
        components: {
            LTable,SyncOutlined,
            UsergroupAddOutlined,UsergroupDeleteOutlined
        },
        data(){
            return{
                table:null,
                viewModel:true,
                pageSize:15,
                argument:{
                    dataSource:null,
                    tableColumns:null,
                    loading:false,
                },
                formArg:{
                    formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData
                },
                visible:false,
                currentUsers:[],
                currentRecord:null,
                targetKeys:[],
                selectedKeys:[],
                titles:['移除', '保留'],
                allUsers:[],
            }
        },
        methods:{
            init(){
                this.table = new AssessmentRole();
                this.argument.tableColumns = this.table.getTableColumns();
                assessmentRole.queryAll.post({},res=>{
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        //this.$message.error(res.data.msg);
                    }
                });
            },
            refresh(){
                this.init()
            },
            addUser(record){
                this.targetKeys = [];
                this.selectedKeys = [];
                this.titles = ['选择', '保留'];
                this.currentRecord = record;
                let {userIds} = record;
                this.targetKeys = userIds?userIds.split(","):[];
                this.currentUsers = [...this.allUsers];
                this.visible = true;
            },
            removeUser(record){
                this.titles = ['移除', '保留'];
                this.visible = true;
                this.currentUsers = [];
                this.targetKeys = [];
                this.selectedKeys = [];
                this.currentRecord = record;
                let {userIds,userNames} = record;
                let ids = userIds.split(",");
                let names = userNames.split("、");
                for(let i in ids) {
                    this.currentUsers.push(
                        {
                            key: ids[i],
                            title: names[i],
                            description: names[i],
                            disabled: false
                        }
                    )
                }
            },
            saveData(){
                this.currentRecord.userIds = this.targetKeys.toString();
                assessmentRole.saveOrUpd.post(this.currentRecord,res=>{
                    if(res.data.flag){
                        this.init();
                        this.$message.success(res.data.msg);
                        this.visible = false;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            handleChange(nextTargetKeys){
                this.targetKeys = nextTargetKeys;
            },
            handleSelectChange(sourceSelectedKeys, targetSelectedKeys){
                this.selectedKeys = [...sourceSelectedKeys, ...targetSelectedKeys];
            }
        },
        created() {
            this.init();
            let data = {
                "disableState":"1"
            };
            this.allUsers = [];
            userInfo.queryAll.urlPost(data,res=>{
                if(res.data.flag){
                    let data = res.data.data.filter(item=>item.id !=="2"); //删除超级管理员账号
                    for(let i in data){
                        this.allUsers.push(
                            {
                                key: data[i].id,
                                title: data[i].name,
                                description: data[i].name,
                                disabled: false
                            }
                        )
                    }
                }
            })
        }
    }
</script>

<style scoped>

</style>
