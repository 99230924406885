<!--
* @program: TYHOA2 
* @author: ly
* @component:OutComePayPDF 
* @description: 支付打印模板
* @create: 2024-09-10 16:19
银行转账，现金，微信，平台
-->
<template>
    <a-row>
        <div class="form-label" style="color:red">请确认收款人信息是否正确，若有错误，请使用【合作单位及人员】功能进行修改</div>
    </a-row>
    <a-row>
        <a-col class="form-label" :span="4">
            付款方式
        </a-col>
        <a-col :span="18" class="form-value" >
            <a-segmented  v-model:value="path" :options="payPaths" />
        </a-col>
    </a-row>
    <a-row>
        <a-col  class="form-label" :span="6">
            是否已提供结算
        </a-col>
        <a-col  :span="6" class="form-value" >
            <a-radio-group v-model:value="noticeMark" >
                <a-radio value="是">是</a-radio>
                <a-radio value="否">否</a-radio>
            </a-radio-group>
        </a-col>
    </a-row>
    <a-row style="display: flex;align-items: center">
        <a-col  class="form-label" :span="4">
            合同总金额
        </a-col>
        <a-col  :span="4" class="form-value" >
            <a-input-number :min="0" :precision="2" style="width:100%" v-model:value="money3"/>
        </a-col>
        <a-col  class="form-label" :span="4">
            已结算金额
        </a-col>
        <a-col  :span="4" class="form-value" >
            <a-input-number :min="0" :precision="2" style="width:100%" v-model:value="money1"/>
        </a-col>
        <a-col  class="form-label" :span="4">
            已付款金额
        </a-col>
        <a-col  :span="4" class="form-value" >
            <a-input-number :min="0" :precision="2" style="width:100%" v-model:value="money2"/>
        </a-col>
    </a-row>
    <a-row>
        <a-col  class="form-label" :span="2">
            备注
        </a-col>
        <a-col  :span="22" class="form-value" >
            <a-textarea :auto-size="{ minRows: 1, maxRows: 6 }" v-model:value="remarks"/>
        </a-col>
    </a-row>
    <a-divider/>
    <div id="bill" style="font-family: '黑体'">
        <div style="text-align: center;font-size: 20px;font-weight: bold">支出凭单</div>
        <a-row>
            <a-col :span="12" style="text-align:left">付款单位名称：{{record.payCompanyName}}</a-col>
            <a-col :span="6">付款日期：{{payTime}}</a-col>
            <a-col :span="6" style="text-align:right">附：单据___张</a-col>
        </a-row>
        <a-row>
            <a-col class="bill-border" :span="3">部门</a-col>
            <a-col class="bill-border" :span="9">{{record.applyDepartName}}</a-col>
            <a-col class="bill-border-last" :span="12">付款方式：{{path}}</a-col>
        </a-row>
        <a-row>
            <a-col style="
            border-left: black 1px solid;
            border-top: black 1px solid;" :span="3">支出项目</a-col>
            <a-col class="bill-border" :span="9">{{title1}}</a-col>
            <a-col class="bill-border" :span="1">收</a-col>
            <a-col class="bill-border" :span="3">全称</a-col>
            <a-col class="bill-border-last" :span="8">{{record.outSourceName}}</a-col>
        </a-row>
        <a-row>
            <a-col style="border-left:black 1px solid;" :span="3"> </a-col>
            <a-col style="border-left:black 1px solid;" :span="9">{{title2}}</a-col>
            <a-col style="border-left:black 1px solid;" :span="1">款</a-col>
            <a-col class="bill-border" :span="3">账号</a-col>
            <a-col class="bill-border-last" :span="8">{{bankNo}}</a-col>
        </a-row>
        <a-row>
            <a-col class="bill-border" :span="6">是否已提供结算</a-col>
            <a-col class="bill-border" :span="6">{{noticeMark}}</a-col>
            <a-col style="border-left:black 1px solid;" :span="1">人</a-col>
            <a-col class="bill-border" :span="3">开户银行</a-col>
            <a-col class="bill-border-last" :span="8">{{openBank}}</a-col>
        </a-row>
        <a-row>
            <a-col class="bill-border" :span="8">申请金额(小写)：
                <span >￥{{record.payAmount.toFixed(2)}}</span>
            </a-col>
            <a-col class="bill-border-last" :span="16">申请金额(大写)：
                <span >{{cnNumber(record.payAmount)}}</span>
            </a-col>
        </a-row>
        <a-row v-if="path === '平台'">
            <a-col class="bill-border" :span="8">平台手续费(小写)：
                <span >￥{{serviceAmount.toFixed(2)}}</span>
            </a-col>
            <a-col class="bill-border-last" :span="16">平台手续费(大写)：
                <span >{{cnNumber(serviceAmount)}}</span>
            </a-col>
        </a-row>
        <a-row>
            <a-col class="bill-border" :span="8">核准金额(小写)：
                <span >￥{{(serviceAmount +record.payAmount).toFixed(2)}}</span>
            </a-col>
            <a-col class="bill-border-last" :span="16">核准金额(大写)：
                <span >{{cnNumber(record.payAmount + serviceAmount)}}</span>
            </a-col>
        </a-row>
        <a-row>
            <a-col class="bill-border" :span="8">合同总金额：￥<span v-if="money3">{{money3.toFixed(2)}}</span></a-col>
            <a-col class="bill-border" :span="8">已结算金额：￥<span v-if="money1">{{money1.toFixed(2)}}</span></a-col>
            <a-col class="bill-border-last"  :span="8">已付款金额：￥<span v-if="money2">{{money2.toFixed(2)}}</span></a-col>
        </a-row>
        <a-row>
            <a-col class="bill-border" style="border-right:black 1px solid;border-bottom:black 1px solid;" :span="24">
                <pre style="font-family: '黑体';white-space: pre-wrap;">备注：{{remarks}}</pre>
            </a-col>
        </a-row>
        <a-row style="margin-top: 20px">
            <a-col :span="6">单位负责人：</a-col>
            <a-col :span="6">主管领导：</a-col>
            <a-col :span="6">项目负责人：</a-col>
            <a-col :span="6">经办人：</a-col>
        </a-row>
        <a-row style="margin-top: 20px">
            <a-col :span="6">财务主管：</a-col>
            <a-col :span="6">财务审核：</a-col>
            <a-col :span="6">生产办：</a-col>
            <a-col :span="6">出纳：</a-col>
        </a-row>
    </div>
    <div style="border-top: #c8c8c8 1px dashed;padding:10px">
        <a-button type="primary" @click="downloadPDF">
            下载
        </a-button>
    </div>
</template>

<script>
    import html2pdf from "html2pdf.js"
    import dayjs from "dayjs"
    import {NumberToChineseCurrency} from "../../../../assets/utils/general";
    export default {
        name: "OutComePayPDF",
        props:["record"],
        emits:["update:record"],
        computed:{
            title1(){
                if(this.record && this.record.itemName){
                    return this.record.itemName.substr(0,18);
                }
                return ""

            },
            title2(){
                if(this.record && this.record.itemName){
                    return this.record.itemName.substr(18);
                }
                return ""
            },
            payTime(){
                if(this.record && this.record.payTime){
                    return dayjs(this.record.payTime).format("YYYY-MM-DD");
                }
                return ""
            },
            bankNo(){
                if(this.record && this.record.partyInfo){
                    return this.record.partyInfo.bankNo
                }
                return ""
            },
            openBank(){
                if(this.record && this.record.partyInfo){
                    return this.record.partyInfo.openBank
                }
                return ""
            },
            serviceAmount(){
                if(this.record && this.record.serviceAmount){
                    return this.record.serviceAmount
                }
                return 0
            }
        },
        data() {
            return {
                fileName:"",
                payPaths:[
                    {label:"银行转账",value:"银行转账"},
                    {label:"现金",value:"现金"},
                    {label:"微信",value:"微信"},
                    {label:"电汇",value:"电汇"},
                    {label:"转账",value:"转账"},
                    {label:"支票",value:"支票"},
                    {label:"平台",value:"平台"},
                ],
                path:"银行转账",
                noticeMark:"是",
                money1:0,
                money2:0,
                money3:0,
                remarks:""
            }
        },
        methods: {
            downloadPDF(){
                let element = document.getElementById('bill');
                let opt = {
                    margin:       0.2,
                    filename:     this.fileName,
                    image:        { type: 'jpeg', quality: 0.98 },
                    html2canvas:  { scale: 2 },
                    jsPDF:        { unit: 'in', format: 'a4', orientation: 'portrait' }
                };
                // New Promise-based usage:
                html2pdf().set(opt).from(element).save();
            },
            cnNumber(num){
                return NumberToChineseCurrency(num)
            }
        },
        created() {
            this.fileName = this.record?.itemName + "支付凭单.pdf";
            this.remarks = this.record?.payReason
            if(this.record && this.record.serviceAmount){
                this.path = "平台";
                this.payPaths.map(item=>{
                    item.disabled = item.label !== "平台";
                    return item
                })
            }
            else {
                this.path = "银行转账",
                this.payPaths.map(item=>{
                    item.disabled = item.label === "平台";
                    return item
                })
            }
            if(this.record && this.record.contractInfo){
                this.money3 = this.record.contractInfo.amount;
            }
        }
    }

</script>

<style scoped>
    .bill-border{
        border-left: black 1px solid;
        border-top: black 1px solid;
    }
    .bill-border-last{
        border-left: black 1px solid;
        border-top: black 1px solid;
        border-right:black 1px solid;
    }
    .ant-col{
        padding: 5px 10px;
        text-align: left;
    }
    @page {
        size: A4; /* 设置纸张为 A4 大小 */
        margin: 1in;
    }
</style>