/** 
* 自动生成文件，请勿修改 
* @name: 财务:现金流量表会计科目-费用类别对应关系模块
* @description: 现金流量表会计科目-费用类别对应关系管理==================现金流量表导出使用
* @create: 2024/10/18 15:02:19
* @swagger: 2.0
* 合计:8条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const cashSubCategRel = { 
	/** 1 summary:保存/修改-单条
	description:参数：单条数据对象subjectCategRelationVO
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/cashSubCategRel/saveOrUpd.do"), 
	/** 2 summary:查询-单条
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/cashSubCategRel/queryOne.do"), 
	/** 3 summary:删除-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/cashSubCategRel/delete.do"), 
	/** 4 summary:查询-分页
	description:参数：CashSubCategRelQueryVO queryVO
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/cashSubCategRel/queryPage.do"), 
	/** 5 summary:查询-全部不分页
	description:参数：CashSubCategRelQueryVO queryVO
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/cashSubCategRel/queryAll.do"), 
	/** 6 summary:删除-批量
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/cashSubCategRel/batchDelete.do"), 
	/** 7 summary:绑定多个费用类别
	description:参数：全部替换该科目绑定的该类型该操作下的费用类别
	method:post
	params:需要配合Column函数使用
	*/ 
	bindCategorys : new APIObject.postObject("/api/cashSubCategRel/bindCategorys.do"), 
	/** 8 summary:保存/修改-批量
	description:参数：List<CashSubCategRelListVO>
	method:post
	params:需要配合Column函数使用
	*/ 
	batchSaveOrUpd : new APIObject.postObject("/api/cashSubCategRel/batchSaveOrUpd.do"), 
};
export default cashSubCategRel;