<!--
* @program: TYHOA2 
* @author: ly
* @component:ProjectRelateFiles 
* @description: 项目相关文件
* @create: 2024-07-24 13:53
-->
<template>
    <div style="display: flex;margin: 0 8px">
        <a-button type="dashed" v-if="!viewModel" @click="addData">
            <template #icon><PlusOutlined /></template>
            添加文件
        </a-button>
        <a-button  type="dashed"  @click="init">
            <template #icon><SyncOutlined /></template>
        </a-button>
    </div>
    <a-spin :spinning="loading" tip="正在查询数据库...">
        <div style="width: 100%;overflow: auto;display: flex;flex-wrap: wrap;" v-if="dataSource.length>0">
            <a-card hoverable v-for="(item,idx) in dataSource" :key="idx" style="width: 400px;margin:5px">
                <template #title>
                    <span class="card-title">
                        {{item.docName}}
                    </span>
                </template>
                <template #extra>
                    <a-popover placement="bottom" trigger="hover" >
                        <template #content>
                            <div v-if="!viewModel" class="card-edit">
                                <span @click="editDetail(item)">修改</span>
                            </div>
                            <div v-if="!viewModel" class="card-edit">
                                <a-popconfirm
                                        @confirm="deleteData(idx,item)"
                                        cancel-text="否"
                                        class="action-button"
                                        ok-text="是"
                                        title="确定删除吗?">
                                    <span>删除</span>
                                </a-popconfirm>
                            </div>
<!--                            <div v-if="item.approveState ==='1'">已通过审核无法修改</div>-->
                        </template>
                        <a-button style="padding: 0 5px" type="link">
                            <UnorderedListOutlined style="font-size: 22px;"/>
                        </a-button>
                    </a-popover>
                </template>
                <LForm :form-columns="formColumns.filter(item=>item.dataIndex !== 'docName')" :form-data="item" :view-model="true" />
            </a-card>
        </div>
        <a-result status="404" title="暂无文件" v-else/>
    </a-spin>
    <a-modal
            width="600px"
            :open="modalVisible"
            :footer="null" title="文件详情"
            destroyOnClose
            :maskClosable="vModel"
            @cancel="modalVisible=false">
        <a-row>
            <a-col :span="6">
                <span class="form-label">文件名称</span>
            </a-col>
            <a-col :span="18">
                <a-select
                        style="width: 100%"
                        v-model:value="docName"
                        placeholder="文件种类选择">
                    <a-select-option v-for="name in fileNames" :key="name" :value="name">
                        {{ name }}
                    </a-select-option>
                </a-select>
            </a-col>
        </a-row>
        <LForm :viewModel="vModel" :form-columns="formColumns" :form-data="formData" >
            <template #action="{record}">
                <a-button  type="primary"  @click="saveData(record)">
                    提交
                </a-button>
            </template>
        </LForm>
    </a-modal>
</template>

<script>
    import {PlusOutlined, SyncOutlined,UnorderedListOutlined} from "@ant-design/icons-vue";
    import LForm from "../../../components/form/LForm";
    import Column from "../../../assets/tables/column";
    import ColumnType from "../../../assets/tables/column_type";
    import ItemDoc from "../../../assets/api/ItemDoc";

    /**
     *
     "describe": "string",
     "docFile": "string",
     "docName": "string",
     "id": "string",
     "itemId": "string",
     "noticeMark": "string",
     "remarks": "string"
     */
    export default {
        name: "ProjectRelateFiles",
        props:["viewModel"],
        inject:["pProjectId"],
        components:{
            PlusOutlined, SyncOutlined,UnorderedListOutlined,
            LForm
        },
        data(){
            return{
                modalVisible:false,
                loading:false,
                formData:null,//提供Form的formData
                formColumns:[
                    new Column("文件名称","docName",ColumnType.String,false),
                    new Column("文件描述","describe",ColumnType.String,true),
                    new Column("扫描附件","docFile",ColumnType.PreViewFile,true),
                    new Column("审核状态","approveState",ColumnType.Enum,false)
                        .setDictEnum("item_progress_apply.approveState"),
                ],
                dataSource:[],
                queryOptions:{},
                vModel:false,
                noticeMark:"1", //0 需要审核 1 不需要审核
                fileNames:[
                    "投标方案","中标通知书",
                    "项目设计书","项目实施方案",
                    "项目验收报告","验收会议专家签字",
                    "成果资料移交清单",
                    "第三方质检报告","质量承诺函",
                    "其他"
                ],
                docName:"中标通知书"
            }
        },
        methods:{
            init(){
                this.dataSource = [];
                this.queryOptions={
                    "itemId": this.pProjectId,
                };
                this.loading = true;
                ItemDoc.queryAll.post(this.queryOptions,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                    else{
                        this.dataSource = []
                    }
                })
            },
            showDetail(record){
                this.formData = record;
                this.modalVisible = true;
                this.vModel = true;
            },
            editDetail(record){
                this.formData = record;
                this.modalVisible = true;
                this.vModel = false;
            },
            deleteData(index,record){
                let data={id:record.id};
                ItemDoc.delete.urlPost(data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.dataSource.splice(index,1);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            saveData(record){
                record.itemId = this.pProjectId;
                record.docName = this.docName;
                if(!record.id){
                    record.noticeMark = this.noticeMark;
                }
                ItemDoc.saveOrUpd.post(record,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.modalVisible = false;
                        this.init()
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            addData(){
                this.formData = {
                    docName:"2dsff",
                    describe:"",
                    docFile:"",
                };
                this.modalVisible = true;
                this.vModel = false;
            },
            handleChange(){
                this.formData.docName = this.docName;
            }
        },
        created() {
            this.init();
        }
    }
</script>

<style scoped>

</style>