<!--
* @program: TYHOA2 
* @author: ly
* @component:ContractItem 
* @description: 
* @create: 2024-07-23 20:32
甲方合同	1	blue
外协合同	2	purple
中标通知书	3	blue
三方合同	4	yellow
三方外协合同	5	blue
个人支付说明	6	CadetBlue
无需合同情况说明	7	red
-->
<template>
    <a-row v-if="!viewModel">
        <a-col :span="4">
            <div class="form-label">合同类别选择</div>
        </a-col>
        <a-col :span="8">
            <a-select @change="handleChange"
                      placeholder="合同类型"
                      v-model:value="type" style="width: 100%;margin-left:10px"
                      :options="contractType">
                <template #option="{label,color}">
                    <a-tag :color="color">{{label}}</a-tag>
                </template>
            </a-select>
        </a-col>
    </a-row>
    <div v-if="record.id">
        <MForm title="签订信息" :row-size="2" :label-span="4" :viewModel="viewModel" @save="saveData"
               :form-columns="formColumns.filter(item => item.group === 1)" :form-data="formData" />
        <MForm title="审核及签收情况" :row-size="2" :label-span="4" :viewModel="viewModel" @save="saveData"
               :form-columns="formColumns.filter(item => item.group === 2)" :form-data="formData" />
        <MForm title="其他" :row-size="2" :label-span="4" :viewModel="viewModel" @save="saveData"
               :form-columns="formColumns.filter(item => item.group === 3)" :form-data="formData" />
    </div>
    <div v-else>
        <LForm v-if="formColumns.length >0" :row-size="2" :label-span="4" :viewModel="viewModel"
               :form-columns="formColumns" :form-data="formData" >
            <template #action="{record}">
                <a-button @click="saveData2(record)"  type="primary">
                    提交
                </a-button>
            </template>
        </LForm>
    </div>

</template>

<script>
    import {GetDict,uuid} from "../../../assets/utils/general";
    import item_contract_info from "../../TableStruct/item_contract_info";
    import Column from "../../../assets/tables/column";
    import ColumnType from "../../../assets/tables/column_type";
    import partyInfo from "../../../assets/api/partyInfo";
    import api_sys_user_info from "../../../assets/api/userInfo";
    import MForm from "../../../components/form/MForm";
    import contractInfo from "../../../assets/api/contractInfo";
    import LForm from "../../../components/form/LForm";

    export default {
        name: "ContractItem",
        props:["record","viewModel","projectId"],
        emits:["change"],
        components:{
            MForm,LForm
        },
        watch:{
            record(){
                this.init();
            }
        },
        data() {
            return {
                type:"1",
                contractType:GetDict("contractType"),
                formData:{},
                formColumns:[],
            }
        },
        methods: {
            init() {
                this.formData = {...this.record};
                this.type = this.record.type;
                this.handleChange()
            },
            saveData(record,viewModel) {
                if(this.type === "7"){//无需合同收款说明
                    record.name = "无需合同收款说明";
                    record.number = "系统编号：" + uuid(10,16);
                    record.signState = "1";
                }
                if(this.type === "8"){//无需合同付款说明
                    record.name = "无需合同情况说明";
                    record.number = "系统编号："+uuid(10,16);
                    record.signState = "1";
                }
                record.type = this.type;
                record.itemId = this.projectId;
                contractInfo.saveOrUpd.post(record, res => {
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        this.$emit("change",record);
                        viewModel(true);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
            },
            saveData2(record) {
                if(this.type === "7"){//无需合同收款说明
                    record.name = "无需合同收款说明";
                    record.number = "系统编号：" + uuid(10,16);
                    record.signState = "1";
                }
                if(this.type === "8"){//无需合同付款说明
                    record.name = "无需合同情况说明";
                    record.number = "系统编号："+uuid(10,16);
                    record.signState = "1";
                }
                record.type = this.type;
                record.itemId = this.projectId;
                contractInfo.saveOrUpd.post(record, res => {
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        this.$emit("change",record);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
            },
            handleChange(){
                switch (this.type) {
                    case "1":{//甲方合同
                        this.formColumns = new item_contract_info().getFormColumns();
                        break;
                    }
                    case "2":{//外协合同
                        this.formColumns = new item_contract_info().getFormColumns();
                        break;
                    }
                    case "4":{//三方合同
                        this.formColumns = new item_contract_info().getFormColumns();
                        break;
                    }
                    case "5":{//三方外协合同
                        this.formColumns = new item_contract_info().getFormColumns();
                        break;
                    }
                    case "6":{//个人支付说明
                        this.formColumns = [
                            new Column("合同名","name",ColumnType.String,true).setGroup(1),
                            new Column("合同编号","number",ColumnType.String,true).setGroup(1),
                            new Column("签署方","associatedKey",ColumnType.IdReplace,true).setGroup(1)
                                .setIdReplaceObject("associatedName",{api:partyInfo.queryAll,tag:"queryText"}),
                            new Column("合同金额","amount",ColumnType.Number,true).setGroup(1),
                            new Column("签订公司","sigCompany",ColumnType.String,true).setGroup(1)
                                .setDictEnum("companies"),
                            new Column("签订时间","sigDate",ColumnType.Date,true).setGroup(1),
                            new Column("签订负责人","sigUser",ColumnType.IdReplace,true).setGroup(1)
                                .setIdReplaceObject("sigUserName",{api:api_sys_user_info.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}}),
                            new Column("盖章情况","sealFlag",ColumnType.Enum,true).setGroup(2)
                                .setDictEnum("sealFlag"), //文件盖章情况：0：未盖章；1：已盖章
                            new Column("合同说明","describe",ColumnType.String,true).setGroup(3),
                            new Column("扫描附件","fileUrl",ColumnType.PreViewFile,true).setGroup(3),
                        ];
                        break;
                    }
                    case "7":{//无需合同收款说明
                        this.formColumns = [
                            new Column("时间","sigDate",ColumnType.Date,true).setGroup(1),
                            new Column("签署方","associatedKey",ColumnType.IdReplace,true).setGroup(1)
                                .setIdReplaceObject("associatedName",{api:partyInfo.queryAll,tag:"queryText"}),
                            new Column("合同金额","amount",ColumnType.Number,true).setGroup(1),
                            new Column("签订公司","sigCompany",ColumnType.String,true).setGroup(1)
                                .setDictEnum("companies"),
                            new Column("签订负责人","sigUser",ColumnType.IdReplace,true).setGroup(1)
                                .setIdReplaceObject("sigUserName",{api:api_sys_user_info.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}}),
                            new Column("盖章情况","sealFlag",ColumnType.Enum,true).setGroup(2)
                                .setDictEnum("sealFlag"), //文件盖章情况：0：未盖章；1：已盖章
                            new Column("合同说明","describe",ColumnType.String,true).setGroup(3),
                            new Column("扫描附件","fileUrl",ColumnType.PreViewFile,true).setGroup(3),
                        ];
                        break;
                    }
                    case "8":{//无需合同付款说明
                        this.formColumns = [
                            new Column("时间","sigDate",ColumnType.Date,true).setGroup(1),
                            new Column("签署方","associatedKey",ColumnType.IdReplace,true).setGroup(1)
                                .setIdReplaceObject("associatedName",{api:partyInfo.queryAll,tag:"queryText"}),
                            new Column("合同金额","amount",ColumnType.Number,true).setGroup(1),
                            new Column("签订公司","sigCompany",ColumnType.String,true).setGroup(1)
                                .setDictEnum("companies"),
                            new Column("签订负责人","sigUser",ColumnType.IdReplace,true).setGroup(1)
                                .setIdReplaceObject("sigUserName",{api:api_sys_user_info.queryAll,tag:"queryText",withData:{disableState:"1",loginState:"0"}}),
                            new Column("盖章情况","sealFlag",ColumnType.Enum,true).setGroup(2)
                                .setDictEnum("sealFlag"), //文件盖章情况：0：未盖章；1：已盖章
                            new Column("合同说明","describe",ColumnType.String,true).setGroup(3),
                            new Column("扫描附件","fileUrl",ColumnType.PreViewFile,true).setGroup(3),
                        ];
                        break;
                    }
                    default:{
                        return new item_contract_info().getFormColumns();
                    }
                }
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>