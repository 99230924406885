/** 
* 自动生成文件，请勿修改 
* @name: 资产:固定资产类别模块
* @description: 固定资产类别======================增删改查
* @create: 2024/10/18 15:02:19
* @swagger: 2.0
* 合计:5条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const fixedCategory = { 
	/** 1 summary:保存/修改-单条
	description:参数：单条数据对象FixedCategoryVO
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/fixedCategory/saveOrUpd.do"), 
	/** 2 summary:查询-单条数据
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/fixedCategory/queryOne.do"), 
	/** 3 summary:查询-所有
	description:参数：categoryName:类别名称,模糊查询
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/fixedCategory/queryAll.do"), 
	/** 4 summary:删除-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/fixedCategory/delete.do"), 
	/** 5 summary:删除-批量
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/fixedCategory/batchDelete.do"), 
};
export default fixedCategory;