<!--
* @program: office_automation 
* @author: ly
* @component:StepsAdd 
* @description: 
* @create: 2022-06-01 09:26
-->
<template>
    <div>
        用户：
        <span style="margin-right:5px" v-for="(user,index) in users" :key="index">{{user.name?user.name:user.userName}}</span>
    </div>
    <div>
        <div >
            <a-button  type="dashed"  @click="handleAdd">
                <template #icon><PlusOutlined /></template>
            </a-button>
            <a-popconfirm
                    ok-text="是"
                    cancel-text="否"
                    class="action-button"
                    title="确定保存吗？"
                    @confirm="handleSave">
                <a-button  type="dashed">
                    <template #icon><CheckOutlined style="color: green" /></template>
                </a-button>
            </a-popconfirm>
        </div>
        <a-table bordered rowKey="id"
                 :pagination="{hideOnSinglePage: true}"
                 :data-source="dataSource"
                 :columns="columns">
            <template #bodyCell="{column,record,index}">
                    <div v-if="column.type === 'Index'">{{index + 1}}</div>
                    <a-select
                            v-if="column.type === 'String'"
                            v-model:value="record[column.dataIndex]"
                            show-search
                            placeholder="请输入审批人名字"
                            :default-active-first-option="false"
                            :show-arrow="false"
                            :filter-option="false"
                            :not-found-content="null"
                            @search="handleSearch"
                            style="width: 100%"
                    >
                        <a-select-option v-for="option in options" :value="option.id" :key="option.id">
                            {{option.name}}
                        </a-select-option>
                    </a-select>
                    <a-popconfirm
                            v-if="column.type === 'actions'"
                            ok-text="是"
                            cancel-text="否"
                            class="action-button"
                            title="确定删除吗?"
                            @confirm="deleteData(index)">
                        <DeleteOutlined  style="color: red" class="pointer"/>
                    </a-popconfirm>
            </template>
        </a-table>
    </div>
</template>

<script>


    import {CheckOutlined, DeleteOutlined, PlusOutlined} from "@ant-design/icons-vue";
    import Column from "../../../../assets/tables/column";
    import ColumnType from "../../../../assets/tables/column_type";
    import {uuid} from "../../../../assets/utils/general";
    import api_sys_user_info from "../../../../assets/api/userInfo";
    import processAudit from "../../../../assets/api/processAudit";

    export default {
        name: "StepsAdd",
        props: ["users", "stepType"],
        emits: ["finish"],
        components: {
            DeleteOutlined, PlusOutlined, CheckOutlined
        },
        data() {
            return {
                dataSource: [],
                columns: [
                    new Column("序号", "id", ColumnType.Index, false).setTableView(80),
                    new Column("审批人", "userId", ColumnType.String, true).setTableView(80),
                    new Column("操作", "actions", "actions", false).setTableView(80)
                ],
                options: []
            }
        },
        methods: {
            handleAdd() {
                this.dataSource.push({
                    id: uuid(4, 10)
                });
            },
            deleteData(index) {
                this.dataSource.splice(index, 1);
            },
            handleSearch(key) {
                let data = {
                    queryText: key,
                    disableState: "1", loginState: "0"
                };
                api_sys_user_info.queryAll.urlPost(data, res => {
                    if (res.data.flag) {
                        this.options = res.data.data;
                    }
                })
            },
            handleSave() {
                let userApprovalStep = "";
                for (let i in this.dataSource) {
                    userApprovalStep += this.dataSource[i].userId + ",";
                }
                userApprovalStep = userApprovalStep.substr(0, userApprovalStep.length - 1);
                debugger
                let args = [];
                for (let i in this.users) {
                    if (Object.prototype.hasOwnProperty.call(this.users[i], "userId")) {
                        let arg = new Steps(this.users[i].userId, userApprovalStep, this.dataSource.length);
                        args.push(arg);
                    } else {
                        let arg = new Steps(this.users[i].id, userApprovalStep, this.dataSource.length);
                        args.push(arg);
                    }
                }
                //let data = {processAuditListVO:args};
                processAudit.batchSaveOrUpd.post( {"processAuditVOList": args}, res => {
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        this.$emit("finish");
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            }
        }
    }
    function Steps(userId,userApprovalSteps,count) { //userApprovalSteps:审核人id组字符串，用 , 分割
        this.userId = userId;
        this.userApprovalStep = userApprovalSteps;
        this.totalStepCount = count;
    }
</script>

<style scoped>

</style>