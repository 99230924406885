<!--
* @program: TYHOA2 
* @author: ly
* @component:FinishWork 
* @description: 
* @create: 2024-08-15 11:21
-->
<template>
    <div class="column-flex" style="overflow: auto">
        <a-tree
                :height="height"
                defaultExpandAll
                :tree-data="source"
                :field-names="fieldNames">
            <template #title="{data}">
                <div style="display:flex;">
                    <div v-if="!data.processTitle" >{{data.taskTitle?data.id+"."+data.taskTitle:data.id+"."+data.itemName}}</div>
                    <a-tag @click="finishWork(data)" v-if="data.processTitle" :color="data.state?'blue':'red'">{{data.processTitle}}{{data.state?'':"（未完成）"}}</a-tag>
                </div>
            </template>
        </a-tree>
    </div>

</template>

<script>
    export default {
        name: "FinishWork",
        props:["treeData"],
        emits:["finish"],
        computed:{
            source(){
                let taskList = [];
                let count = 1;
                for(let item of this.treeData){
                    let {taskTitle,taskId,itemName,processType} = item;
                    let arr = taskList.filter(item=>item.itemName === itemName);
                    if(arr.length >0){
                        arr[0].children.push(item);
                    }
                    else{
                        taskList.push({id:count,selectable:false,taskId,processType,taskTitle:taskTitle?taskTitle:"",itemName:itemName,children:[item]})
                        count++
                    }
                }
                return taskList
            }
        },
        data() {
            return {
                fieldNames:{
                    children:'children',
                    label:'taskTitle',
                    value: 'id'
                },
                height:100
            }
        },
        methods:{
            finishWork(processEmployee){
                if(!processEmployee.state){
                    this.$emit("finish",processEmployee);
                }
            }
        },
        mounted() {
            this.height = this.$el.parentElement.offsetHeight;
        }
    }
</script>

<style scoped>

</style>