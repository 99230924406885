<!--
* @program: TYHOA2 
* @author: ly
* @component:BillStatistics 
* @description: 账单统计
* @create: 2024-10-17 20:07
-->
<template>
    <div class="column-flex" >
        <div class="row-flex">
            <div style="height: 100%;overflow: auto;width: 200px;background: #fafafa;">
                <a-spin :spinning="loading">
                    <MenuCardItem v-for="menu in menus" :key="menu.comp" :menu="menu" :enable="currentComp === menu.comp" @click="change(menu.comp)"/>
                </a-spin>
            </div>
            <div style="height: 100%;overflow: auto;min-width: 600px;width: calc(100% - 200px);background: #fafafa;box-shadow: 0px 8px 16px 1px rgba(37,84,165,0.15);">
                <transition  name="fade" >
                    <component :is="currentComp" />
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
    import MenuCardItem from "../../Other/OAMenuDefaultPage/MenuCardItem";
    import Welcome from "../../Other/Welcome";
    import BillStatisticsByCategory from "./BillStatistics/BillStatisticsByCategory";
    import BillStatisticsByDepartment from "./BillStatistics/BillStatisticsByDepartment";
    import BillStatisticsByProject from "./BillStatistics/BillStatisticsByProject";
    import BillStatisticsByCategoryId from "./BillStatistics/BillStatisticsByCategoryId";

    export default {
        name: "BillStatistics",
        components:{
            MenuCardItem,Welcome,BillStatisticsByCategory,BillStatisticsByDepartment,
            BillStatisticsByProject,BillStatisticsByCategoryId
        },
        data() {
            return {
                currentComp:"BillStatisticsByCategory",
                menus:[
                    {cnName:'概况统计',comp:"BillStatisticsByCategory"},
                    {cnName:'按部门统计',comp:"BillStatisticsByDepartment"},
                    {cnName:'按项目统计',comp:"BillStatisticsByProject"},
                    {cnName:'指定类别统计',comp:"BillStatisticsByCategoryId"},
                ],
                loading:false
            }
        },
        methods: {
            change(name){
                this.currentComp = name;
            }
        }
    }
</script>

<style scoped>

</style>