/** 
* 自动生成文件，请勿修改 
* @name: 报销:报账单审核记录流水模块
* @description: 报销-报账单审核记录流水模块-增删改查
* @create: 2024/10/18 15:02:19
* @swagger: 2.0
* 合计:9条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const recordApproval = { 
	/** 1 summary:报账单审核记录流水-保存/修改
	description:参数：单条数据对象RecordApprovalVO
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/recordApproval/saveOrUpd.do"), 
	/** 2 summary:批量替换审核流水
	description:说明：一次选择一个账单重新组装好审核流水后替换掉原来的审核流水（原来的会被删除），参数中的审核步骤approvalStep从1开始算
	method:post
	params:需要配合Column函数使用
	*/ 
	batchReplace : new APIObject.postObject("/api/recordApproval/batchReplace.do"), 
	/** 3 summary:报账单审核记录流水-批量保存/修改
	description:参数：List<RecordApprovalVO>
	method:post
	params:需要配合Column函数使用
	*/ 
	batchSaveOrUpd : new APIObject.postObject("/api/recordApproval/batchSaveOrUpd.do"), 
	/** 4 summary:报账单审核记录流水-分页查询
	description:参数：pageBean分页对象(传page、limit即可)、recordId(报账单id,可有可无);approvalUser(审核人id,可有可无);state(审核状态（0,1,2,3）,可有可无)
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/recordApproval/queryPage.do"), 
	/** 5 summary:报账单审核记录流水-批量删除
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/recordApproval/batchDelete.do"), 
	/** 6 summary:报账单审核记录流水-单条删除
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/recordApproval/delete.do"), 
	/** 7 summary:报账单-审核报账单流水
	description:参数：recordId:报账单id;userId:用户id;state:(2:通过,3:不通过)
	method:post
	params:需要配合Column函数使用
	*/ 
	updApprovalFlow : new APIObject.postObject("/api/recordApproval/updApprovalFlow.do"), 
	/** 8 summary:报账单审核记录流水-单条数据查询
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/recordApproval/queryOne.do"), 
	/** 9 summary:报账单审核记录流水-查询所有
	description:参数：recordId(报账单id,可有可无);approvalUser(审核人id,可有可无);state(审核状态（0,1,2,3）,可有可无)
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/recordApproval/queryAll.do"), 
};
export default recordApproval;