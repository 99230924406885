<!--
* @program: TYHOA2 
* @author: ly
* @component:ProjectPaymentApply 
* @description: 开票信息填写表
* @create: 2024-10-08 15:43
-->
<template>
    <div class="row-flex">
        <div id="bill" style="font-family: '黑体';width:60%">
            <div style="text-align: center;font-size: 20px;font-weight: bold">开票信息填写表</div>
            <a-row >
                <a-col :span="12" style="text-align:left">开票部门：{{myDepartment}}</a-col>
                <a-col :span="6">开票人：{{myName}}</a-col>
                <a-col :span="6">审核人：</a-col>
            </a-row>
            <a-row >
                <a-col class="bill-border" :span="18">项目名称：{{task.itemName}}</a-col>
                <a-col class="bill-border-last" :span="6">合同额：{{contract.amount}}</a-col>
            </a-row>
            <a-row >
                <a-col class="bill-border" :span="12">开票单位</a-col>
                <a-col class="bill-border-last" :span="12">{{sigCompanyName}}</a-col>
            </a-row>
            <a-row>
                <a-col class="bill-border" :span="12">发票类别（普票/专票/电子普票）</a-col>
                <a-col class="bill-border-last" :span="12">{{applyType}}</a-col>
            </a-row>
            <a-row>
                <a-col class="bill-border" :span="12">甲方单位名称</a-col>
                <a-col class="bill-border-last" :span="12">{{associatedName}}</a-col>
            </a-row>
            <a-row>
                <a-col class="bill-border" :span="12">纳税人识别号（统一社会信用代码）</a-col>
                <a-col class="bill-border-last" :span="12">{{associate.nubr}}</a-col>
            </a-row>
            <a-row>
                <a-col class="bill-border" :span="12">货物或应税劳务、服务名称（技术服务费/软件销售/检验费）</a-col>
                <a-col class="bill-border-last" :span="12">{{moneyType}}</a-col>
            </a-row>
            <a-row>
                <a-col class="bill-border" :span="12">开票金额（单位：元）</a-col>
                <a-col class="bill-border-last" :span="12">{{money}}</a-col>
            </a-row>
            <a-row>
                <a-col class="bill-border" :span="12">需要在发票上备注的信息</a-col>
                <a-col class="bill-border-last" :span="12">{{remarks}}</a-col>
            </a-row>
            <a-row>
                <a-col class="bill-border" :span="12">需特殊备注内容</a-col>
                <a-col class="bill-border-last" :span="12">{{remarks2}}</a-col>
            </a-row>
            <a-row>
                <a-col class="bill-border bill-border-last" :span="24">如需开具增值税专用发票，还需填写以下内容</a-col>
            </a-row>
            <a-row>
                <a-col class="bill-border" :span="12">地址、电话（专票必须填写）</a-col>
                <a-col class="bill-border-last" :span="12">{{address}}</a-col>
            </a-row>
            <a-row style="border-bottom:black 1px solid;">
                <a-col class="bill-border"  :span="12">开户行及账号（专票必须填写）</a-col>
                <a-col class="bill-border-last" :span="12">{{bank}}</a-col>
            </a-row>
            <a-divider />
            <a-button @click="downLoadExcel" type="primary">
                下载为excel
            </a-button>
        </div>
        <div style="width:30%">
            <div style="text-align: center;font-size: 20px;font-weight: bold">开票信息填写表 </div>
            <a-row style="height: 32px"><a-col :span="24" style="text-align:left">修改右侧数值，可以调整左侧表格内的值</a-col></a-row>
            <a-row style="height: 32px">
                <a-col :span="4" >
                    合同选择
                </a-col>
                <a-col :span="20" >
                    <ContractPicker ref="ContractPicker" style="margin:0 10px" :item-id="task.itemId" @change="handleChange"/>
                </a-col>
            </a-row>
            <a-row style="height: 32px">
                <a-col :span="24">
                    <a-select :fieldNames="{ label: 'label', value: 'label'}"
                              @change="selectConform"
                              v-model:value="sigCompanyName" style="width: 100%;margin:0 10px"
                              :options="sigCompanies">
                    </a-select>
                </a-col>
            </a-row>
            <a-row style="height: 32px">
                <a-col :span="24">
                    <a-select style="width: 100%;margin:0 10px" :options="options" v-model:value="applyType" />
                </a-col>
            </a-row>
            <a-row style="height: 32px">
                <a-col :span="24">
                    <a-select
                            show-search
                            v-model:value="associatedName"
                            placeholder="输入关键字查询(最大显示8条信息)"
                            style="width: 100%;margin:0 10px"
                            :filter-option="false"
                            :not-found-content="loading ? undefined : null"
                            :options="searchData"
                            :fieldNames="{ label: 'name', value: 'name'}"
                            @change="selectConform"
                            @search="searching">
                        <template v-if="loading" #notFoundContent>
                            <a-spin size="small" />
                        </template>
                    </a-select>
                </a-col>
            </a-row>
            <a-row style="height: 32px">
                <a-col :span="24">
                    <a-input style="margin:0 10px" v-model:value="associate.nubr"/>
                </a-col>
            </a-row>
            <a-row style="height: 32px">
                <a-col :span="24">
                    <a-select style="width: 100%;margin:0 10px" :options="options2" v-model:value="moneyType" />
                </a-col>
            </a-row>
            <a-row style="height: 32px">
                <a-col :span="24">
                    <a-input-number style="margin:0 10px;width:300px" v-model:value="money"/>
                </a-col>
            </a-row>
            <a-row style="height: 32px">
                <a-col :span="24">
                    <a-input style="margin:0 10px" v-model:value="remarks"/>
                </a-col>
            </a-row>
            <a-row style="height: 32px">
                <a-col :span="24">
                    <a-input style="margin:0 10px" v-model:value="remarks2"/>
                </a-col>
            </a-row>
            <a-row style="height: 32px">
                <a-col :span="24" style="margin:0 10px">
                    注意：税号，银行账号，地址，联系电话等信息需要提前录入系统才能够读取
                </a-col>
            </a-row>
            <a-row style="height: 32px">
                <a-col :span="24">
                    <a-input style="margin:0 10px" v-model:value="address"/>
                </a-col>
            </a-row>
            <a-row style="height: 32px">
                <a-col :span="24">
                    <a-input style="margin:0 10px" v-model:value="bank"/>
                </a-col>
            </a-row>
        </div>
    </div>
</template>

<script>
    import ContractPicker from "../../common/ContractPicker";
    import partyInfo from "../../../assets/api/partyInfo";
    import {GetDict} from "../../../assets/utils/general";
    import excelJS from "exceljs";
    export default {
        name: "ProjectPaymentApply",
        props:["task","departmentId"],
        components:{
            ContractPicker
        },
        data() {
            return {
                myName:null,
                myDepartment:undefined,
                contract:{},
                sigCompanyName:null,
                sigCompanies:GetDict("companies"),
                options:[
                    {value:"普票",label:"普票"},
                    {value:"专票",label:"专票"},
                    {value:"电子普票",label:"电子普票"},
                ],
                applyType:null,
                associate:{nubr:"未录入"},
                associatedName:null,
                searchData:[],
                loading:false,
                options2:[//技术服务费/软件销售/检验费
                    {value:"技术服务费",label:"技术服务费"},
                    {value:"软件销售",label:"软件销售"},
                    {value:"检验费",label:"检验费"},
                ],
                moneyType:null,
                money:0,
                remarks:"",
                remarks2:"规格型号：单位：数量：单价：",
                address:null,
                bank:null,
            }
        },
        methods: {
            init() {
                this.$getItem("__myName").then(str => {
                    this.myName = str;
                });
                this.$getItem("__departments").then(str=>{
                    let myDepartments = JSON.parse(str);
                    this.myDepartment = myDepartments.find(item=>item.id === this.departmentId).name
                })
            },
            handleChange(contract){
                this.contract = contract;
                this.sigCompanyName = contract.sigCompanyName;
                this.associatedName = contract.associatedName;
                this.money = this.contract.amount;
                partyInfo.queryOne.urlPost({id:contract.associatedKey},res=>{
                    if(res.data.flag){
                        this.associate = res.data.data;
                        this.bank = `开户银行：${this.associate.openBank},账号:${this.associate.bankNo}`
                        this.address = `单位地址：${this.associate.address},联系电话:${this.associate.phone}`
                    }
                    else{
                        this.associate = {nubr:"未录入"};
                    }
                })
            },
            searching(value){
                if(value){
                    this.loading = true;
                    let formParam = {
                        page:1,
                        limit:8,
                    };
                    let urlParam = {
                        queryText:value
                    };
                    partyInfo.queryPage.bothPost({formParam,urlParam},res=>{
                        this.loading = false;
                        if(res.data.flag){
                            this.searchData = res.data.data;
                        }
                        else{
                            this.searchData = [];
                        }
                    })
                }
            },
            selectConform(value){
                this.$refs.ContractPicker.selectId = undefined;
                let selectData = this.searchData.find(item=>item.name === value);
                if(selectData){
                    this.associate = selectData;
                    this.bank = `开户银行：${this.associate.openBank},账号:${this.associate.bankNo}`
                    this.address = `单位地址：${this.associate.address},联系电话:${this.associate.phone}`
                }
            },
            downLoadExcel(){
                let workbook = new excelJS.Workbook();
                workbook.creator = this.$config.cnName;
                workbook.created = new Date();
                let fileName = `${this.task.itemName}《开票信息填写表》`;
                let worksheet =  workbook.addWorksheet('开票信息填写表');
                worksheet.columns = [
                    { header: 'A', key: 'A', width: 20 },
                    { header: 'B', key: 'B', width: 20 },
                    { header: 'C', key: 'C', width: 24 },
                    { header: 'D', key: 'D', width: 24 },
                ];
                worksheet.mergeCells('A1', 'D1');
                worksheet.mergeCells('A2', 'B2');

                worksheet.mergeCells('A3', 'C3');
                worksheet.mergeCells('A4', 'B4');worksheet.mergeCells('C4', 'D4');
                worksheet.mergeCells('A5', 'B5');worksheet.mergeCells('C5', 'D5');
                worksheet.mergeCells('A6', 'B6');worksheet.mergeCells('C6', 'D6');
                worksheet.mergeCells('A7', 'B7');worksheet.mergeCells('C7', 'D7');
                worksheet.mergeCells('A8', 'B8');worksheet.mergeCells('C8', 'D8');
                worksheet.mergeCells('A9', 'B9');worksheet.mergeCells('C9', 'D9');
                worksheet.mergeCells('A10', 'B10');worksheet.mergeCells('C10', 'D10');
                worksheet.mergeCells('A11', 'B11');worksheet.mergeCells('C11', 'D11');
                worksheet.mergeCells('A12', 'D12');
                worksheet.mergeCells('A13', 'B13');worksheet.mergeCells('C13', 'D13');
                worksheet.mergeCells('A14', 'B14');worksheet.mergeCells('C14', 'D14');

                for(let i = 3;i<15;i++){
                    worksheet.getRow(i).height = 36;
                    worksheet.getRow(i).getCell(1).border = {top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'}};
                    worksheet.getRow(i).getCell(2).border = {top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'}};
                    worksheet.getRow(i).getCell(3).border = {top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'}};
                    worksheet.getRow(i).getCell(4).border = {top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'}};
                    worksheet.getRow(i).getCell(1).alignment =  { wrapText: true ,vertical: 'middle', horizontal: 'left'};
                    worksheet.getRow(i).getCell(2).alignment =  { wrapText: true ,vertical: 'middle', horizontal: 'left'};
                    worksheet.getRow(i).getCell(3).alignment =  { wrapText: true ,vertical: 'middle', horizontal: 'left'};
                    worksheet.getRow(i).getCell(4).alignment =  { wrapText: true ,vertical: 'middle', horizontal: 'left'};
                    worksheet.getRow(i).getCell(1).font = {size: 13};
                    worksheet.getRow(i).getCell(2).font = {size: 13};
                    worksheet.getRow(i).getCell(3).font = {size: 13};
                    worksheet.getRow(i).getCell(4).font = {size: 13};
                }
                worksheet.getRow(1).getCell(1).value = "开票信息填写表";
                worksheet.getRow(1).getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
                worksheet.getRow(1).getCell(1).font = {name: '黑体', size: 20, bold: true};

                worksheet.getRow(2).height = 24;
                worksheet.getRow(2).getCell(1).value = "开票部门："+this.myDepartment;
                worksheet.getRow(2).getCell(1).alignment =  { wrapText: true ,vertical: 'middle', horizontal: 'left'};
                worksheet.getRow(2).getCell(3).value = "开票人："+this.myName;
                worksheet.getRow(2).getCell(3).alignment =  { wrapText: true ,vertical: 'middle', horizontal: 'left'};
                worksheet.getRow(2).getCell(4).value = "审核人：";
                worksheet.getRow(2).getCell(4).alignment =  { wrapText: true ,vertical: 'middle', horizontal: 'left'};

                worksheet.getRow(3).getCell(1).value = `项目名称：${this.task.itemName}`;
                worksheet.getRow(3).getCell(4).value = `合同额：${this.contract.amount}`;

                worksheet.getRow(4).getCell(1).value = `开票单位`;
                worksheet.getRow(4).getCell(3).value = this.sigCompanyName;

                worksheet.getRow(5).getCell(1).value = `发票类别（普票/专票/电子普票）`;
                worksheet.getRow(5).getCell(3).value = this.applyType;

                worksheet.getRow(6).getCell(1).value = `甲方单位名称`;
                worksheet.getRow(6).getCell(3).value = this.associatedName;

                worksheet.getRow(7).getCell(1).value = `纳税人识别号（统一社会信用代码）`;
                worksheet.getRow(7).getCell(3).value = this.associate.nubr;

                worksheet.getRow(8).getCell(1).value = `货物或应税劳务、服务名称（技术服务费/软件销售/检验费）`;
                worksheet.getRow(8).getCell(3).value = this.moneyType;

                worksheet.getRow(9).getCell(1).value = `开票金额（单位：元）`;
                worksheet.getRow(9).getCell(3).value = this.money+"";

                worksheet.getRow(10).getCell(1).value = `需要在发票上备注的信息`;
                worksheet.getRow(10).getCell(3).value = this.remarks;

                worksheet.getRow(11).getCell(1).value = `需特殊备注内容`;
                worksheet.getRow(11).getCell(3).value = this.remarks2;

                worksheet.getRow(12).getCell(1).value = `如需开具增值税专用发票，还需填写以下内容`;

                worksheet.getRow(13).getCell(1).value = `地址、电话（专票必须填写）`;
                worksheet.getRow(13).getCell(3).value = this.address;

                worksheet.getRow(14).getCell(1).value = `开户行及账号（专票必须填写）`;
                worksheet.getRow(14).getCell(3).value = this.bank;
                workbook.xlsx.writeBuffer().then((data)=>{
                    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                    const blob = new Blob([data], {type: EXCEL_TYPE});
                    let tagA = document.createElement('a');
                    tagA.href = window.URL.createObjectURL(blob) ;// 获取当前文件的一个内存URL
                    tagA.download = fileName +".xlsx" ;// 下载后文件名
                    tagA.style.display = 'none';
                    document.body.appendChild(tagA);
                    tagA.click();
                    window.URL.revokeObjectURL(tagA.href);
                    document.body.removeChild(tagA);
                });
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>
    .bill-border{
        border-left: black 1px solid;
        border-top: black 1px solid;
    }
    .bill-border-last{
        border-left: black 1px solid;
        border-top: black 1px solid;
        border-right:black 1px solid;
    }
    .ant-col{
        padding: 5px 10px;
        text-align: left;
    }
    @page {
        size: A4; /* 设置纸张为 A4 大小 */
        margin: 1in;
    }
</style>