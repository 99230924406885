<!--
* @program: TYHOA2 
* @author: ly
* @component:CardManager 
* @description: 
* @create: 2024-10-10 11:25
-->
<template>
    <a-tabs @change="change" v-model:activeKey="activeKey">
        <a-tab-pane :key="group.compId" :tab="group.compName" v-for="group in groupCards">
            <div style="display: flex">
                <div style="width: 500px;height:80vh;min-height:500px; overflow: auto;">
                    <div class="card-total" style="text-align: left">
                        <a-button @click="addData" type="link">新增账号</a-button>
                        <span class="card-total-title">余额合计</span>
                        <span class="card-total-money">￥{{getSum(group.children).toFixed(2)}}</span>
                    </div>
                    <a-card @click="showFlows(item)" :key="item.id" hoverable style="margin: 5px" v-for="item in group.children">
                        <template #title>
                            <span class="card-title">
                            {{item.bankName}}
                            </span>
                        </template>
                        <template #extra>
                            <a-popover placement="bottom" trigger="hover">
                                <template #content>
                                    <div class="card-edit">
                                        <span @click="showFlows(item)" title="查看详情" v-if="!item.visible">查看详情</span>
                                    </div>
                                    <div class="card-edit">
                                        <span @click="editData(item)">账号信息修改</span>
                                    </div>
                                    <div class="card-edit">
                                        <a-popconfirm
                                                @confirm="deleteData(item)"
                                                cancel-text="否"
                                                class="action-button"
                                                ok-text="是"
                                                title="确定删除吗?">
                                            <span>删除</span>
                                        </a-popconfirm>
                                    </div>
                                </template>
                                <a-button style="padding: 0 5px" type="link">
                                    <UnorderedListOutlined style="font-size: 22px;"/>
                                </a-button>
                            </a-popover>
                        </template>
                        <a-row class="card-ye">
                            <a-row class="ye-q">
                         <span style="font-size: 16px;font-weight: 400;color: #8A9FC4;">
                            余额
                        </span>
                                <span style="margin-left: 10px;font-size: 18px;font-weight: 400;color: #337EFF;">
                            {{item.currentBlance.toFixed(2)}}元
                        </span>
                            </a-row>
                            <a-row class="ye-q ye-t">
                                更新日期:{{new Date(item.updateTime).toLocaleDateString()}}
                            </a-row>
                        </a-row>
                        <LForm :form-columns="getFormColumns(item)" :form-data="item" :labelSpan="8"/>
                        <template #actions>
                            <a-row :gutter="6" style="width: 100%;padding: 1px 20px;display: flex;justify-content: center;align-items: center;"
                                   v-if="item.visible">
                                <a-button @click="back(item)" class="show-info"
                                          title="返回" type="link">
                                    返回
                                </a-button>
                            </a-row>
                        </template>
                    </a-card>
                </div>
                <div style="height:80vh;overflow: auto;min-width: 600px;width: calc(100vw - 520px);background: #fafafa;box-shadow: 0px 8px 16px 1px rgba(37,84,165,0.15);">
                    <a-tabs style="height: 100%;padding:10px" v-if="currentRecord.id" v-model:activeKey="activeKey2">
                        <a-tab-pane key="7" tab="流水汇总">
                            <CardFlows :record="currentRecord" :show-bill="currentRecord.remarks==='待修改'"/>
                        </a-tab-pane>
                        <!--                        <a-tab-pane key="6" tab="个人报账一览" v-if="currentRecord.remarks==='现金账户'">-->
                        <!--                            <ShowBills :record="currentRecord"/>-->
                        <!--                        </a-tab-pane>-->
                        <a-tab-pane key="1" tab="项目收入流水">
                            <ProjectInflow :record="currentRecord"/>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="项目支出流水">
                            <ProjectOutflow :record="currentRecord"/>
                        </a-tab-pane>
                        <a-tab-pane key="3" tab="其他收入">
                            <OtherInflow :record="currentRecord"/>
                        </a-tab-pane>
                        <a-tab-pane key="4" tab="其他支出/转出">
                            <OtherOutflow :record="currentRecord"/>
                        </a-tab-pane>
                        <a-tab-pane key="5" tab="其他账户转入">
                            <FromOtherBank :record="currentRecord"/>
                        </a-tab-pane>
                    </a-tabs>
                    <Welcome style="width: 100%" v-else/>
                </div>
            </div>
        </a-tab-pane>
    </a-tabs>
    <a-modal :destroyOnClose="true"
             :maskClosable="false"
             style="margin-top: 2%;" title="账号信息"
             v-model:open="visible"
             width="500px">
        <a-form :label-col="8" :wrapper-col="14">
            <a-form-item label="初始余额(元)">
                <a-input-number style="width: 100%" v-model:value="currentRecord.balance"/>
            </a-form-item>
        </a-form>
        <a-form :label-col="8" :wrapper-col="14">
            <a-form-item label="账号名">
                <a-input style="width: 100%" v-model:value="currentRecord.bankName"/>
            </a-form-item>
        </a-form>
        <a-form :label-col="8" :wrapper-col="14">
            <a-form-item label="账户别名">
                <a-input style="width: 100%" v-model:value="currentRecord.remarks"/>
            </a-form-item>
        </a-form>
        <a-form :label-col="8" :wrapper-col="14">
            <a-form-item label="账号分组">
                <a-select :options="companies"
                          placeholder="账号分组"
                          style="width: 100%"
                          v-model:value="currentRecord.compId"/>
            </a-form-item>
        </a-form>
        <template #footer>
            <a-button @click="visible =false" key="back">取消</a-button>
            <a-button @click="submit" key="back" type="primary">提交</a-button>
        </template>
    </a-modal>
    <!-- 新增 -->
    <a-modal :footer="null"
             :title="null"
             destroyOnClose
             v-model:open="visible2">
        <span>账户类型：</span>
        <a-select :options="accountTypes"
                  style="width: 120px"
                  v-model:value="accountType"/>
        <LForm :form-columns="formColumns" :form-data="formData" :view-model="false">
            <template #action="{record}">
                <a-button @click="saveData(record)" type="primary">
                    提交
                </a-button>
            </template>
        </LForm>
    </a-modal>
</template>

<script>
    import bankBalance from "../../../../assets/api/bankBalance";
    import LForm from "../../../../components/form/LForm";
    import Column from "@/assets/tables/column";
    import ColumnType from "@/assets/tables/column_type";
    import {UnorderedListOutlined} from "@ant-design/icons-vue"
    import ProjectInflow from "./ProjectInflow";
    import ProjectOutflow from "./ProjectOutflow";
    import OtherInflow from "./OtherInflow";
    import OtherOutflow from "./OtherOutflow";
    import FromOtherBank from "./FromOtherBank";
    import ShowBills from "./ShowBills";
    import CardFlows from "./CardFlows";
    import Welcome from "../../../Other/Welcome";
    import {GetDict} from "../../../../assets/utils/general";
    import item_finance_bank_balance from "../../../TableStruct/item_finance_bank_balance";

    export default {
        name: "CardManager",
        components: {
            LForm, ProjectInflow, ProjectOutflow, OtherInflow, OtherOutflow, FromOtherBank,
            ShowBills, CardFlows, Welcome,
            UnorderedListOutlined
        },
        data() {
            return {
                activeKey: 0,
                activeKey2: 0,
                allCards: [],
                type1Cols: [
                    new Column("账号名称", "bankName", ColumnType.String, true),
                    new Column("银行及支行名", "subBank", ColumnType.String, true),
                    new Column("银行卡号", "bankNum", ColumnType.String, true),
                    new Column("初始余额(元)", "balance", ColumnType.Number, true),
                    new Column("初始余额日期", "balanceDate", ColumnType.Date, true),
                    new Column("账户别名", "remarks", ColumnType.String, true),
                ],
                type2Cols: [
                    new Column("初始余额(元)", "balance", ColumnType.Number, true),
                    new Column("初始余额日期", "balanceDate", ColumnType.Date, true),
                    new Column("账户别名", "remarks", ColumnType.String, true),
                ],
                accountTypes: [
                    {label: "银行", value: "1"},
                    {label: "现金", value: "2"},
                ],
                accountType: "1",//["1","2"],["银行","现金"]
                currentRecord: {},
                showBill: false,
                visible: false,
                visible2: false,
                companies: GetDict("companies")
            }
        },
        computed: {
            groupCards() {
                let groupCards = [];
                for (let card of this.allCards) {
                    let {compId, compName} = card;
                    if (!compId) {
                        compId = "xxxxxxx";
                        compName = "其他";
                    } else {
                        card.compName = this.companies.find(item => item.value === compId)?.label;
                        compName = this.companies.find(item => item.value === compId)?.label;
                    }
                    let group = groupCards.find(item => item.compId === compId);
                    if (group) {
                        group.children.push(card)
                    } else {
                        let data = {
                            compId: compId,
                            compName: compName,
                            children: [card]
                        }
                        groupCards.push(data)
                    }
                }
                return groupCards
            },
            formColumns() {
                if (this.accountType === "1") {
                    return this.type1Cols
                } else {
                    return this.type2Cols
                }
            },
        },
        methods: {
            init() {
                this.visible = false;
                this.visible2 = false;
                this.loading = true;
                bankBalance.queryAll.post({}, res => {
                    this.loading = false;
                    if (res.data.flag) {
                        this.allCards = res.data.data;
                        this.allCards.sort((a, b) => {
                            return b.createTime - a.createTime
                        })
                    } else {
                        this.allCards = [];
                        this.$message.error(res.data.msg);
                    }
                })
            },
            change() {
                this.currentRecord = {};
            },
            getFormColumns(item) {
                if (item.accountType === "1") {
                    return this.type1Cols;
                } else {
                    return this.type2Cols;
                }
            },
            getSum(children) {
                let sum = 0;
                for (let i in children) {
                    sum += children[i].currentBlance
                }
                return sum;
            },
            showFlows(item) {
                this.currentRecord = item;
                this.activeKey2 = "7";
            },
            editData(record) {
                this.visible = true;
                this.currentRecord = {...record};
            },
            submit() {
                bankBalance.saveOrUpdate.post(this.currentRecord, res => {
                    if (res.data.flag) {
                        this.visible = false;
                        this.init()
                        this.$message.success(res.data.msg);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },
            deleteData(item) {
                let data = {
                    id: item.id
                };
                bankBalance.delete.urlPost(data, res => {
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        this.init()
                    } else {
                        this.$message.error(res.data.msg)
                    }
                })
            },
            addData() {
                this.visible2 = true;
                this.formData = new item_finance_bank_balance().getFormData();
            },
            saveData(record) {
                record.compId = this.activeKey;
                if (record.id) {
                    bankBalance.saveOrUpdate.post(record, res => {
                        if (res.data.flag) {
                            this.init();
                            this.$message.success(res.data.msg);

                        } else {
                            this.$message.error(res.data.msg);
                        }
                    })
                } else {
                    if (this.accountType === "2") {
                        record.bankName = "现金账户";
                        record.subBank = "现金账户";
                        record.bankNum = "0000000000"
                    }
                    record.accountType = this.accountType;
                    bankBalance.saveOrUpdate.post(record, res => {
                        if (res.data.flag) {
                            this.init();
                            this.$message.success(res.data.msg);
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    })
                }
            },
        },
        created() {
            this.init();
            this.companies.push({label: "已废弃", value: "abandoned"})
            this.activeKey = "5ce1d392-cf46-43a0-8097-fc1d37f57784";
        }
    }
</script>

<style scoped>

</style>