<!--
* @program: TYHOA2 
* @author: ly
* @component:QCHistory 
* @description: 质检记录一览
* @create: 2024-09-29 20:31
-->
<template>
    <div class="column-flex" style="position: relative;overflow: hidden">
        <div class="search-bar">
            <a-button @click="init"  type="dashed">
                <template #icon>
                    <SyncOutlined />
                </template>
            </a-button>
            <a-input  placeholder="项目名称" style="width: 100px" v-model:value="filter.itemName" />
            <a-input  placeholder="申请部门" style="width: 150px" v-model:value="filter.departmentName" />
            <a-input  placeholder="申请人" style="width: 150px" v-model:value="filter.applyUserName" />
            <a-input  placeholder="质检组长" style="width: 150px" v-model:value="filter.qcLeaderName" />
            <a-radio-group style="margin-left:15px;border-left: #022d5c solid;padding-left:10px" @change="init" v-model:value="defaultQuery.state">
                <a-radio value="0">未完成</a-radio>
                <a-radio value="1">已完成</a-radio>
            </a-radio-group>
        </div>
        <div class="row-flex">
            <LTable :loading="loading" :data-source="source" :columns="tableColumns">
                <template #action="{record}">
                    <div class = "action-column">
                        <a-button @click="showDetail(record)"
                                  class="action-button" type="link">
                            <SearchOutlined class="pointer"/>
                        </a-button>
                        <a-tooltip v-if="record.state === '0'" title="指定负责人">
                            <a-button  class="action-button" type="link" @click="setLeader(record)">
                                <UserAddOutlined  class="pointer"/>
                            </a-button>
                        </a-tooltip>
                    </div>
                </template>
            </LTable>
        </div>
        <a-drawer :closable="false"
                  :get-container="false"
                  :style="{ position: 'absolute' }"
                  destroy-on-close
                  height="90%"
                  placement="bottom"
                  v-model:open="visible">
            <a-tabs style="height: 100%" tabPosition="left" v-model:activeKey="activeKey">
                <a-tab-pane style="height: 100%;overflow: auto" key="1" tab="项目信息">
                    <ProjectInfoView />
                </a-tab-pane>
                <a-tab-pane style="height: 100%;overflow: auto" key="2" tab="二检申请">
                    <LForm title="二检申请" :form-columns="formColumns" :form-data="currentRecord" :view-model="true"/>
                </a-tab-pane>
                <a-tab-pane  style="height: 100%;overflow: auto" key="3" tab="质检工序安排">
                    <QCTaskProcess :departmentId="defaultQuery.departmentId" :apply="currentRecord" :view-model="vModel"/>
                </a-tab-pane>
                <a-tab-pane  style="height: 100%;overflow: auto" key="4" tab="质检报告">
                    <QCReport @finish="currentRecord.state ='1'" :apply="currentRecord"  :view-model="vModel"/>
                </a-tab-pane>
            </a-tabs>
        </a-drawer>
    </div>
    <a-modal v-model:open="visible2"
             destroy-on-close
             title="指定项目负责人"
             :footer="null">
        <UserPickerS v-model:value="qcLeaderUser" />
        <a-divider />
        <a-button @click="saveLeader"  type="primary">
            提交
        </a-button>
    </a-modal>
</template>

<script>
    import LTable from "../../../components/table/LTable";
    import LForm from "../../../components/form/LForm";
    import UserPickerS from "../../common/UserPickerS";
    import {CheckOutlined, CloseOutlined, SearchOutlined, SyncOutlined,UserAddOutlined} from "@ant-design/icons-vue";
    import {ArrayFilter} from "../../../assets/utils/general";
    import item_qc_apply from "../../TableStruct/item_qc_apply";
    import Column from "../../../assets/tables/column";
    import ColumnType from "../../../assets/tables/column_type";
    import qcApply from "../../../assets/api/qcApply";
    import QCTaskProcess from "../../PersonalCenter/WorkCenter/QCWorkTask/QCTaskProcess";
    import ProjectInfoView from "../ProjectManager/ProjectInfoView";
    import QCReport from "../../PersonalCenter/WorkCenter/QCWorkTask/QCReport";
    import {computed} from "vue";

    export default {
        name: "QCHistory",
        components:{
            SyncOutlined,SearchOutlined,UserAddOutlined,
            CheckOutlined,CloseOutlined,
            QCTaskProcess,ProjectInfoView,QCReport,
            LTable,LForm,UserPickerS,
        },
        provide() {
            return {
                pTaskId: computed(() => this.currentRecord.id),
                pProjectId:computed(() => this.currentRecord.itemId),
            }
        },
        computed:{
            source(){
                return ArrayFilter(this.dataSource,this.filter);
            },
        },
        data() {
            return {
                dataSource: [],
                tableColumns: [
                    new Column("项目","itemName",ColumnType.String).setTableView(300),
                    new Column("申请说明","content",ColumnType.String).setTableView(150),
                    new Column("质检内容","proContent",ColumnType.String).setTableView(150),
                    new Column("质检完成期限","planCommitDate",ColumnType.Date).setTableView(100),
                    new Column("申请部门","departmentName",ColumnType.String).setTableView(100),
                    new Column("申请人","applyUserName",ColumnType.String).setTableView(80),
                    new Column("质检组长","qcLeaderName",ColumnType.String).setTableView(80),
                    new Column("质检组员","qcMember",ColumnType.Array).setTableView(80),
                    new Column("操作","actions","actions",false).setTableView(60)
                ],
                formColumns: new item_qc_apply().getFormColumns(),
                qcOne:{},
                formColumns2:[
                    new Column("一检时间","qcDate",ColumnType.Date,true),
                    new Column("一检描述","qcDescrib",ColumnType.String,true),
                    new Column("一检文件","qcFile",ColumnType.PreViewFile,true),
                    new Column("一检人员","qcUserNames",ColumnType.String,false),
                    new Column("评分","assessment",ColumnType.Enum,true)
                        .setDictEnum("assessment"),
                ],
                loading: false,
                defaultQuery: {
                    state:"0",
                    auditState:"1",
                },
                currentRecord:{},
                filter:{},
                visible:false,
                visible2:false,
                auditNote:null,
                qcLeaderUser:undefined,
                option:[],
                activeKey:"1"
            }
        },
        methods: {
            init() {
                this.loading = true;
                qcApply.queryAll.post(this.defaultQuery,res=>{
                    this.loading = false;
                    if (res.data.flag) {
                        this.dataSource = res.data.data.sort((a,b)=>{
                            return b.createTime - a.createTime
                        });
                    } else {
                        this.dataSource = [];
                    }
                });
            },
            showDetail(record) {
                this.visible = true;
                this.currentRecord = record;
            },
            setLeader(record){
                this.visible2 = true;
                this.currentRecord = record;
            },
            saveLeader(){
                if(!this.qcLeaderUser){
                    this.$message.error("请选择质检负责人");
                    return
                }
                let data = {
                    id:this.currentRecord.id,
                    qcLeaderUser:this.qcLeaderUser
                };
                qcApply.bindQcLeader.urlPost(data,res=>{
                    if(res.data.flag){
                        this.currentRecord.qcLeaderName = "已替换";
                        this.visible2 = false;
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>