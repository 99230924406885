import Table from "@/assets/tables/table";
import Column from "@/assets/tables/column";
import ColumnType from "@/assets/tables/column_type";

/**
 * @program: office_automation
 * @author: ly
 * @description: item_other_pay
 * @create: 2022-02-18 14:36
 * //支出类型（1：卡间相互间转账；2：取现；3：存入，4：对外支出）
 * receiveBankName
 :
 null
 receiveBankNum
 :
 **/

export default class item_other_pay extends Table{
    constructor() {
        super();
        this.name = "item_other_pay";
        this.CNName = "其他支付";
        this.columnArray = [
            new Column("支付类型","type",ColumnType.Enum)
                .setDictEnum("item_other_pay.type"),
            new Column("费用类别","categoryId",ColumnType.Category,true).setTableView(100),
            new Column("项目名称","itemName",ColumnType.String).setTableView(200),
            new Column("金额","payAmount",ColumnType.Number,true).setDefaultValue(0).setTableView(80),
            new Column("日期","payDate",ColumnType.Date,true).setTableView(80),
            new Column("支付账户","payBankName",ColumnType.String).setTableView(100),//payBankNum+payBankName
            new Column("接收账户","receiveBankName",ColumnType.String).setTableView(100),
            new Column("说明","remarks",ColumnType.String,true).setTableView(300),
            new Column("审核状态","approvalState",ColumnType.Enum).setEnum(
                {value:"0",label: "待审核",color:"red"},
                {value:"1",label: "已审核",color:"green"},
            ).setTableView(80),
        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(60)];

    }
}
