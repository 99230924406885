/** 
* 自动生成文件，请勿修改 
* @name: 考核:考勤管理-月度企业微信导出的考勤统计
* @description: 月度企业微信导出的考勤统计(三期新增)
* @create: 2024/10/18 15:02:19
* @swagger: 2.0
* 合计:8条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const attendance = { 
	/** 1 summary:导出-导出考勤模板
	description:参数：无
	method:get
	params:
	*/ 
	exportExcel : new APIObject.getObject("/api/attendance/exportExcel.do"), 
	/** 2 summary:删除-批量
	description:参数：数据的主键id,逗号分隔：1,2,3,4,5.......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/attendance/batchDelete.do"), 
	/** 3 summary:导入-企业微信考勤模板
	description:参数：单个文件对象MultipartFile（excel文件）
	method:post
	params:需要配合Column函数使用
	*/ 
	importExcel : new APIObject.postObject("/api/attendance/importExcel.do"), 
	/** 4 summary:删除-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/attendance/delete.do"), 
	/** 5 summary:根据id查询
	description:参数：id(主键id)
	method:post
	params:需要配合Column函数使用
	*/ 
	queryById : new APIObject.postObject("/api/attendance/queryById.do"), 
	/** 6 summary:查询-分页
	description:参数：AttendanceQueryVO attendanceQueryVO单位封装对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/attendance/queryPage.do"), 
	/** 7 summary:查询-不分页（全部）
	description:参数：AttendanceQueryVO attendanceQueryVO单位封装对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/attendance/queryAll.do"), 
	/** 8 summary:新增/修改-单条
	description:参数：AttendanceVO attendanceVO单位对象封装
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpdate : new APIObject.postObject("/api/attendance/saveOrUpdate.do"), 
};
export default attendance;