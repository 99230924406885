/** 
* 自动生成文件，请勿修改 
* @name: 资产:设备采买申请
* @description: 设备采买申请-增删改查
* @create: 2024/10/18 15:02:19
* @swagger: 2.0
* 合计:10条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const assetBuyApply = { 
	/** 1 summary:办公室审核-单条
	description:参数：主键id，审核状态，审核备注
	method:post
	params:需要配合Column函数使用
	*/ 
	officeApprov : new APIObject.postObject("/api/assetBuyApply/officeApprov.do"), 
	/** 2 summary:查询-单条
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/assetBuyApply/queryOne.do"), 
	/** 3 summary:新增/修改-单条
	description:注释：设备采买申请
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/assetBuyApply/saveOrUpd.do"), 
	/** 4 summary:设备入库-将采买的设备同步到设备表中-批量
	description:参数：List<DeviceForApplyVO>
	method:post
	params:需要配合Column函数使用
	*/ 
	batchSaveToDevice : new APIObject.postObject("/api/assetBuyApply/batchSaveToDevice.do"), 
	/** 5 summary:查询-所有数据
	description:参数：assetBuyApplyQueryVO,参数过多而封装的对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/assetBuyApply/queryAll.do"), 
	/** 6 summary:采买状态更改-单条
	description:参数：主键id，采买状态：0未采买，1已采买
	method:post
	params:需要配合Column函数使用
	*/ 
	buyStateModify : new APIObject.postObject("/api/assetBuyApply/buyStateModify.do"), 
	/** 7 summary:总经办审核-单条
	description:参数：主键id，审核状态，审核备注
	method:post
	params:需要配合Column函数使用
	*/ 
	bossApprov : new APIObject.postObject("/api/assetBuyApply/bossApprov.do"), 
	/** 8 summary:设备入库-将采买的设备同步到设备表中-单条
	description:参数：单条数据对象DeviceVO
	method:post
	params:需要配合Column函数使用
	*/ 
	saveToDevice : new APIObject.postObject("/api/assetBuyApply/saveToDevice.do"), 
	/** 9 summary:查询-分页
	description:参数：assetBuyApplyQueryVO,参数过多而封装的对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/assetBuyApply/queryPage.do"), 
	/** 10 summary:删除-单条
	description:参数：主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/assetBuyApply/delete.do"), 
};
export default assetBuyApply;