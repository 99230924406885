<!--
* @program: TYHOA2 
* @author: ly
* @component:OutSourcePayApplyAuditStatus 
* @description: 
* @create: 2024-07-26 19:48
-->
<template>
    <div v-if="steps.length > 1" class="center-flex" style="display: flex;overflow-x: auto">
        <a-steps size="small" >
            <a-step v-for="({approvalUserName,updateTime,remarks,status},key) in steps"
                    :status = "status"
                    :key="key">
                <template #title>
                    <div style="display: flex;justify-content: space-around;height: 24px;align-items: center;">
                        <div>{{approvalUserName}}</div>
                        <a-popover v-if="remarks" trigger="hover" placement="top">
                            <template #content>
                                {{remarks}}
                            </template>
                            <a-button  type="link" >审核意见</a-button>
                        </a-popover>
                    </div>
                </template>
                <template #subTitle>
                    {{new Date(updateTime).toLocaleDateString()}}
                </template>
            </a-step>
        </a-steps>
    </div>
    <div v-else class="form-label">
        还未提交审核
    </div>
</template>

<script>
    import payApproval from "../../../assets/api/payApproval";
    export default {
        name: "OutSourcePayApplyAuditStatus",
        props:["record"],
        data() {
            return {
                steps:[{approvalUserName:"提交审核",state:"0"}],
            }
        },
        methods: {
            init() {
                if(this.record.id){
                    let data = {
                        "applyId": this.record.id
                    };
                    payApproval.queryAll.post(data,res=>{
                        if(res.data.flag && res.data.data.length>0){
                            this.steps = this.steps.concat(res.data.data);
                            for(let i in this.steps){
                                switch (this.steps[i].state) {
                                    case "0":{
                                        this.steps[i].status = "wait" ; //wait process finish error
                                        break;
                                    }
                                    case "1":{
                                        this.steps[i].status = "process" ; //wait process finish error
                                        this.current = parseInt(i);
                                        this.steps[0].status = "finish";
                                        break;
                                    }
                                    case "2":{
                                        this.steps[i].status = "finish" ; //wait process finish error
                                        this.current = parseInt(i);
                                        this.steps[0].status = "finish";
                                        break;
                                    }
                                    case "3":{
                                        this.steps[i].status = "error" ; //wait process finish error
                                        this.current = parseInt(i);
                                        this.steps[0].status = "finish";
                                        break;
                                    }
                                }
                            }
                        }
                    })
                }
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>