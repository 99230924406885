<!--
* @program: TYHOA2 
* @author: ly
* @component:MonthArrange 
* @description: 月度绩效分配
* @create: 2024-08-06 16:07
-->
<template>
    <div class="column-flex">
        <div class="search-bar">
            <a-button @click="init"   type="dashed">
                <template #icon><SyncOutlined/></template>
            </a-button>
            <a-tooltip title="单人绩效分配">
                <a-button @click="addData"  type="dashed">
                    <template #icon><PlusOutlined/></template>
                </a-button>
            </a-tooltip>
            <a-tooltip title="根据任务负责人的录入的比例分配">
                <a-button @click="addPlan"  type="dashed">
                    按比例分配
                </a-button>
            </a-tooltip>
            <a-button v-if="task.pid === '0'" @click="applyMoney"  type="dashed">
                绩效申请
            </a-button>
            <a-input v-model:value="filter.yearMonth" style="width:150px" placeholder="年月" />
            <a-input v-model:value="filter.userName" style="width:150px" placeholder="姓名" />
        </div>
        <div style="width: 100%;height: 100%;display: flex">
            <div class="main-content30">
                <a-collapse accordion v-model:activeKey="activeKey">
                    <a-collapse-panel  key="0" >
                        <template #header>
                            <div v-if="task.pid === '0'" class="form-value">绩效下发情况</div>
                            <div v-else class="form-value">子任务绩效由部门绩效池出</div>
                        </template>
                        <div v-if="task.pid === '0'">
                            <div v-if="pool.length>0">
                                <div class="form-title" >已下发合计：￥{{allSum.all}}
                                    【月度￥{{allSum.monthAmount}} 年度￥{{allSum.yearAmount}}】
                                </div>
                                <div style="color: #858585;text-align: left;margin:10px 20px" v-for="(item,index) in pool" :key="item.id">
                                    <PoolItem :index="index" :item="item"/>
                                </div>
                            </div>
                            <div v-else class="form-title" >尚未下发绩效</div>
                            <div class="form-title">已分配合计：￥{{completeMoneyAll.toFixed(2)}}</div>
                            <div class="form-title">待分配合计：￥{{(allSum.all - completeMoneyAll).toFixed(2)}}</div>
                            <div class="form-label">
                                <span>月度绩效：￥{{monthAmount.toFixed(2)}}</span>
                                <span style="margin-left: 10px">年度绩效：￥{{yearAmount.toFixed(2)}}</span>
                            </div>
                        </div>
                        <div v-else>
                            <div class="card-total-money">
                                本子任务绩效由本部门绩效池支出
                            </div>
                            <div>
                                【部门绩效池】功能内可以支付其他部门绩效或者接受其他部门的绩效支付
                            </div>
                        </div>
                    </a-collapse-panel>
                    <a-collapse-panel v-if="unSetter.length>0"  key="2" >
                        <template #header>
                            <div class="form-value">绩效申请情况</div>
                        </template>
                        <div style="color: #858585;text-align: left;margin:10px 20px" v-for="(item,index) in unSetter" :key="index">
                            <PoolItem :index="index" :item="item" show-audit="true" >
                                <a-popconfirm
                                        v-if="item.approvState==='0' || !item.approvState"
                                        ok-text="是"
                                        cancel-text="否"
                                        class="action-button"
                                        title="删除申请吗?"
                                        @confirm="deleteData2(item)">
                                    <a-button type="link" danger>
                                        删除
                                    </a-button>
                                </a-popconfirm>
                            </PoolItem>
                        </div>
                    </a-collapse-panel>
                    <a-collapse-panel  key="1" >
                        <template #header>
                            <div class="form-value">项目月报</div>
                        </template>
                        <LTable :show-index="false" :loading="loading" :columns="columns2" :data-source="monthReport">
                            <template #action="{record}">
                                <div class="action-column">
                                    <a-tooltip title="绩效分配详情">
                                        <a-button type="link" @click="monthMoney(record)">
                                            <SearchOutlined class="pointer"/>
                                        </a-button>
                                    </a-tooltip>
                                </div>
                            </template>
                        </LTable>
                    </a-collapse-panel>
                </a-collapse>
            </div>
            <div class="main-content70">
                <LTable :title="'分配详情 合计：￥'+completeMoneyFilter.toFixed(2)" :columns="columns" :data-source="source">
                    <template #action="{record}">
                        <div class="action-column">
                            <a-button  class="action-button" type="link" @click="editDetail(record)">
                                <EditFilled class="pointer"/>
                            </a-button>
                            <a-popconfirm
                                    ok-text="是"
                                    cancel-text="否"
                                    class="action-button"
                                    title="确定删除吗?"
                                    @confirm="deleteData(record)">
                                <DeleteOutlined  style="color: red" class="pointer"/>
                            </a-popconfirm>
                        </div>
                    </template>
                </LTable>
            </div>
        </div>
    </div>
    <a-modal v-model:open="visible"
             destroy-on-close
             title="月度绩效分配"
             :width="500"
             :footer="null">
        <a-row>
            用于添加不由项目负责人分配工作量比例的人员绩效
        </a-row>
        <a-row>
            <a-col :span="8">
                <div class="form-label" style="margin-right: 10px">选择人员</div>
            </a-col>
            <a-col :span="12">
                <div class="form-value"><DepartmentUserPicker :department-id="departmentId" v-model:value="user"/></div>
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="8">
                <div class="form-label" style="margin-right: 10px">年月</div>
            </a-col>
            <a-col :span="12">
                <div class="form-value">
                    <a-date-picker v-model:value="yearMonth" picker="month" />
                </div>
            </a-col>
        </a-row>
        <LForm :labelSpan="8" :form-data="formData" :form-columns="columns" :view-model="vModel">
            <template #action="{record}">
                <a-button  type="primary"  @click="saveData(record)">
                    提交
                </a-button>
            </template>
        </LForm>
    </a-modal>
    <a-modal v-model:open="visible2"
             destroy-on-close
             title="月度绩效分配"
             :width="800"
             :footer="null">
        <MonthlyArrangePlan v-if="task.pid === '0'" :money="allSum.monthAmount" :subTaskRatio="subTaskRatio"  @change="monthChange" :users="users"  @finish="savePlan" :monthIncrease="monthIncrease"/>
        <SubMonthlyArrangePlan v-else   @change="monthChange" :users="users"  @finish="savePlan" :monthIncrease="monthIncrease"/>
    </a-modal>
    <ModalPoolApply ref="apply" @update="init" />
</template>

<script>
    import depAchievePool from "../../../assets/api/depAchievePool";
    import taskMonthUser from "../../../assets/api/taskMonthUser";
    import LTable from "../../../components/table/LTable";
    import Column from "@/assets/tables/column";
    import ColumnType from "@/assets/tables/column_type"
    import {EditFilled,DeleteOutlined,SearchOutlined,
        PlusOutlined,SyncOutlined} from "@ant-design/icons-vue"
    import {ArrayFilter} from "../../../assets/utils/general";
    import LForm from "../../../components/form/LForm";
    import DepartmentUserPicker from "../../common/DepartmentUserPicker";
    import dayjs from "dayjs"
    import MonthlyArrangePlan from "./MonthlyArrangePlan";
    import SubMonthlyArrangePlan from "./SubMonthlyArrangePlan";
    import taskMonthRatio from "../../../assets/api/taskMonthRatio";
    import ModalPoolApply from "../Pool/ModalPoolApply";
    import PoolItem from "../Pool/PoolItem";
    export default {
        name: "MonthArrange",
        props:["viewModel","task","departmentId"],
        components:{
            LTable,LForm,DepartmentUserPicker,MonthlyArrangePlan,SubMonthlyArrangePlan,
            ModalPoolApply,PoolItem,
            EditFilled,DeleteOutlined,PlusOutlined,SyncOutlined,SearchOutlined
        },
        computed:{
            allSum(){
                let sum = {
                    monthAmount:0,
                    yearAmount:0,
                };
                for(let item of this.pool){
                    sum.monthAmount += item.monthAmount;
                    sum.yearAmount += item.yearAmount;
                }
                sum.monthAmount = parseFloat(sum.monthAmount.toFixed(2));
                sum.yearAmount = parseFloat(sum.yearAmount.toFixed(2));
                sum.all = parseFloat((sum.monthAmount + sum.yearAmount).toFixed(2));
                return sum;
            },
            subTaskRatio(){//子任务绩效占比
                let ratio = 0;
                if(this.task && this.task.children && this.task.children.length>0){
                    for(let child of this.task.children){
                        ratio += child.ratio
                    }
                }
                return ratio *100/100
            },
            completeMoneyAll(){
                let sum = 0;
                for(let item of this.dataSource){
                    sum += item.completeMoney;
                }
                return sum *100/100;
            },
            completeMoneyFilter(){
                let sum = 0;
                for(let item of this.source){
                    sum += item.completeMoney;
                }
                return sum *100/100;
            },
            monthAmount(){//月度剩余待分配
                if(this.allSum.monthAmount - this.completeMoneyAll > 0){
                    return this.allSum.monthAmount - this.completeMoneyAll
                }
                else{
                    return 0
                }
            },
            yearAmount(){
                if(this.allSum.monthAmount - this.completeMoneyAll <0){
                    return this.allSum.yearAmount + (this.allSum.monthAmount - this.completeMoneyAll)
                }
                else{
                    return this.allSum.yearAmount
                }
            },
            source(){
                return ArrayFilter(this.dataSource,this.filter);
            },
        },
        data() {
            return {
                pool:[],
                columns:[
                    new Column("年月","yearMonth",ColumnType.Month,false).setTableView(80),
                    new Column("用户名","userName",ColumnType.String,false).setTableView(100),
                    new Column("比例(%)","completeRatio",ColumnType.Number,false).setTableView(100),
                    new Column("作业绩效","completeMoney",ColumnType.Number,true).setTableView(100),
                    // new Column("操作","actions","actions").setTableView(100)
                ],
                columns2:[
                    new Column("年月","month",ColumnType.Month,false).setTableView(80),
                    new Column("总进度(%)","ratio",ColumnType.String,false).setTableView(80),
                    new Column("本月增量(%)","increase",ColumnType.String,false).setTableView(80),
                    new Column("填报人","remarks",ColumnType.String,false).setTableView(80),
                    new Column("绩效","actions","actions").setTableView(60)
                ],
                dataSource:[],
                filter:{},
                visible:false,
                visible2:false,
                formData:{},
                user:null,
                users:[],
                yearMonth:null,
                vModel:true,
                activeKey:"1",
                monthReport:[],
                monthIncrease:0,//月增量
                loading:false,
                unSetter:[]
            }
        },
        methods: {
            init() {
                //绩效下发情况 若为子任务 则无下发记录
                if(this.task.pid === '0'){
                    depAchievePool.queryAll.urlPost({taskId:this.task.id},res=>{
                        if(res.data.flag){
                            this.pool = res.data.data.filter(item=>item.approvState === "1");
                            this.unSetter = res.data.data.filter(item=>item.approvState !== "1" );
                        }
                        else{
                            this.pool = [];
                        }
                    })
                    this.activeKey = "0"
                }
                //绩效分配情况
                taskMonthUser.queryAll.post({taskId:this.task.id,deptId:this.departmentId},res=>{//得到每个人已经分配的绩效
                    if(res.data.flag){
                        this.dataSource = res.data.data.sort((a,b)=>{
                            return  b.createTime - a.createTime
                        });
                    }
                    else{
                        this.dataSource = [];
                    }
                });
                //月进度增量计算
                this.monthReport = [];
                this.loading = true;
                let data = {
                    taskId: this.task.id
                };
                taskMonthRatio.queryAll.urlPost(data, res => {
                    if (res.data.flag) {
                        this.monthReport = res.data.data.sort((a, b) => {
                            return dayjs(b.month) - dayjs(a.month)
                        });
                        for (let i = 0; i < this.monthReport.length; i++) {
                            if(i < this.monthReport.length - 1){
                                this.monthReport[i].formerRatio = this.monthReport[i+1].ratio
                            }
                            else{
                                this.monthReport[i].formerRatio = 0
                            }
                        }
                    }
                    this.loading = false;
                })
            },
            addData(){
                this.visible = true;
                this.formData = {};
                this.vModel = false;
            },
            addPlan(){
                this.visible2 = true;
                this.users = [];
                this.monthIncrease = 0;
            },
            editDetail(record){
                this.vModel = false;
                this.visible = true;
                this.formData = record;
                this.user = record.userId;
                this.yearMonth = dayjs(record.yearMonth);

            },
            deleteData(record){
                let data = {id:record.id};
                taskMonthUser.delete.urlPost(data,res=>{
                    if(res.data.flag){
                        this.dataSource = this.dataSource.filter(item=>item.id !== record.id);
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            deleteData2(record){
                let data = {id:record.id};
                depAchievePool.delete.urlPost(data,res=>{
                    if(res.data.flag){
                        this.unSetter = this.unSetter.filter(item=>item.id !== record.id);
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            saveData(record){
                record.yearMonth = this.yearMonth.format("YYYY-MM");
                record.taskId = this.task.id;
                record.userId = this.user;
                record.deptId = this.departmentId;
                record.completeRatio = 0;
                taskMonthUser.saveOrUpd.post(record,res=>{
                    if(res.data.flag){
                        this.visible = false;
                        this.init();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            monthChange(yearMonth){
                this.users = this.dataSource.filter(item => item.yearMonth === yearMonth);
                let report = this.monthReport.find(item =>item.month === yearMonth);
                if(report){
                    this.monthIncrease = report.ratio - report.formerRatio;
                }
                else{
                    this.monthIncrease = 0;
                }
            },
            savePlan(plan){
                let params = {
                    taskMonthUserVOS: plan
                };
                taskMonthUser.batchSaveOrUpd.post(params,res=>{
                    if (res.data.flag) {
                        this.visible2 = false;
                        this.init()
                        this.$message.success(res.data.msg);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },
            monthMoney(record){
                this.filter.yearMonth = record.month;
            },
            applyMoney(){
                let data = {
                    "departId": this.task.departmentId,
                    "taskId": this.task.id,
                };
                this.$refs.apply.open(data);
            }
        },
        created() {
            this.init();
        }
    }
</script>

<style scoped>

</style>