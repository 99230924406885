<!--
* @program: office_automation 
* @author: ly
* @component:AssessmentRecordHistory 
* @description: 
* @create: 2022-03-01 16:56
-->
<template>
    <LTable v-if="source.length>0" title="评分记录" :loading="loading" :download="false"
            :data-source="source" :columns="tableColumns" />
    <h3 v-else>还未进行评分</h3>
</template>

<script>
    import userScore from "../../../../assets/api/userScore";
    import ColumnType from "@/assets/tables/column_type";
    import Column from "@/assets/tables/column";
    import LTable from "../../../../components/table/LTable";
    export default {
        name: "AssessmentRecordHistory",
        props:["record","showAll"],
        components:{
            LTable
        },
        computed:{
            source(){
                if(this.showAll){
                    return this.dataSource
                }
                else{
                    let source = this.dataSource.filter(item=>{return item.createUser === this.userId});
                    if(source.length === 1){
                        this.$emit("init",source[0]);
                    }
                    return source
                }
            }
        },
        data(){
            return{
                dataSource:[],
                tableColumns:[
                    new Column("评分人","judgeUserName",ColumnType.String,false).setTableView(80),
                    new Column("评分","obtainScore",ColumnType.String,false).setTableView(80),
                    new Column("评语","remarks",ColumnType.String,false).setTableView(150),
                ],
                loading:false,
                userId:null
            }
        },
        methods:{
            init(){
                let data = {
                    "assessmentId": this.record.id
                };
                this.loading = true;
                userScore.queryAll.post(data,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                    else {
                        this.dataSource = [];
                    }

                })
            }
        },
        created() {
            this.$getItem("__myID").then(str => {
                this.userId = str;
                this.init()
            })
        }
    }
</script>

<style scoped>

</style>