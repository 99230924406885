<!--
* @program: TYHOA2 
* @author: ly
* @component:SelectContract
* @description: 
* @create: 2024-07-25 16:40
-->
<template>
    <div class="form-title" v-if="source.length > 0">
        <div v-if="contractId">已绑定合同</div>
        <div v-else>请选择合同</div>
        <a-button :disabled="contractId" v-if="value" type="link"  @click="clear">
            清除
        </a-button>
    </div>
    <div v-else class="form-title">
        暂无需要支付的合同
    </div>
    <a-radio-group :disabled="contractId" v-if="source.length > 0" @change="handleChange" v-model:value="value">
        <a-radio v-for="item in source" :key="item.id" :value="item.id">{{item.name}}({{item.amount}}元)</a-radio>
    </a-radio-group>
</template>

<script>
    import contractInfo from "../../../assets/api/contractInfo";
    export default {
        name: "SelectContract",
        emits:["change"],
        inject:["pProjectId"],
        props:{
            contract: {
                required: true,
                default: []
            },
            contractId:{
                required: false
            }
        },
        computed:{
            source(){
                return this.lContract.filter(it=>it.type !== '1' && it.type !=='4')
            }
        },
        data() {
            return {
                value:null,
                lContract:[]
            }
        },
        methods: {
            handleChange() {
                this.$emit("change",this.lContract.find(item=>item.id === this.value))
            },
            clear(){
                this.value = null;
                this.$emit("change",null)
            }
        },
        created() {
            if(this.contract && this.contract.length>0){
                this.lContract = [...this.contract]
            }
            else{
                if(this.pProjectId){
                    contractInfo.queryAll.post({itemId:this.pProjectId},res=>{
                        if(res.data.flag){
                            this.lContract = res.data.data;
                            if(this.contractId){
                                this.value = this.contractId;
                                this.$emit("change",this.lContract.find(item=>item.id === this.value));
                            }
                        }
                        else{
                            this.lContract = [];
                        }
                    })
                }

            }
            if(this.contractId){
                this.value = this.contractId;
                this.$emit("change",this.lContract.find(item=>item.id === this.value));
            }

        }
    }
</script>

<style scoped>

</style>