/** 
* 自动生成文件，请勿修改 
* @name: 耗材:耗材采买申请
* @description: 耗材采买申请-增删改查
* @create: 2024/10/18 15:02:19
* @swagger: 2.0
* 合计:8条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const materialApply = { 
	/** 1 summary:删除-单条
	description:参数：主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/materialApply/delete.do"), 
	/** 2 summary:查询-单条
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/materialApply/queryOne.do"), 
	/** 3 summary:部门审核-单条
	description:参数：主键id，审核状态，审核备注
	method:post
	params:需要配合Column函数使用
	*/ 
	deptApprov : new APIObject.postObject("/api/materialApply/deptApprov.do"), 
	/** 4 summary:查询-所有数据
	description:参数：materialApplyQueryVO,参数过多而封装的对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/materialApply/queryAll.do"), 
	/** 5 summary:办公室审核-单条
	description:参数：主键id，审核状态，审核备注
	method:post
	params:需要配合Column函数使用
	*/ 
	officeApprov : new APIObject.postObject("/api/materialApply/officeApprov.do"), 
	/** 6 summary:查询-分页
	description:参数：materialApplyQueryVO,参数过多而封装的对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/materialApply/queryPage.do"), 
	/** 7 summary:新增/修改-单条
	description:注释：耗材采买申请
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/materialApply/saveOrUpd.do"), 
	/** 8 summary:下发状态更改-单条
	description:参数：主键id，下发状态：0未下发；1已下发
	method:post
	params:需要配合Column函数使用
	*/ 
	stateModify : new APIObject.postObject("/api/materialApply/stateModify.do"), 
};
export default materialApply;