<!--
* @program: office_automation 
* @author: ly
* @component:MonthlyAssessmentViewer 
* @description: 月度考核信息展示控件  按人查询使用
* @create: 2022-02-28 09:55
-->
<template>
    <div class="column-flex" style="overflow: auto">
        <div class="form-value" style="margin:10px" v-if="assessmentData">考核类型【{{assessmentName}}】总分：{{fullPoints}} 得分：{{sumPoints.toFixed(2)}}
            <a-button v-if="userType === 'judge'" @click="listJudge"  type="primary">
                批量评分
            </a-button>
        </div>
        <div v-if="assessmentData">
            <div v-for="(item,index) in tabList"  :key="index" :tab="item.label">
                <LTable :download="false" :show-index="false" :title="item.label" :data-source="item.dataSource" :columns="tableColumns">
                    <template #action="{record}">
                        <div v-if="!viewModel"  class="action-column">
                            <div v-if="userType === 'user' && record.judgeEnd === true">
                                <a-popconfirm
                                        v-if="record.agreeFlag === '0' "
                                        ok-text="是"
                                        cancel-text="否"
                                        class="action-button"
                                        title="不同意本项考核结果吗?"
                                        @confirm="disagree(record)">
                                    <a-tooltip title="不同意本项考核结果">
                                        <a-button danger  type="link">
                                            <template #icon><CloseOutlined /></template>
                                        </a-button>
                                    </a-tooltip>
                                </a-popconfirm>
                            </div>
                            <a-tooltip v-if="record.agreeFlag !== '1' && record.editFlag === true && userType === 'judge'" title="打分">
                                <a-button @click="judge(record)"  type="link">
                                    打分
                                </a-button>
                            </a-tooltip>
                        </div>
                    </template>
                </LTable>
            </div>
        </div>
        <Welcome v-else />
    </div>
    <a-modal v-model:open="visible"
             destroy-on-close
             :footer="null"
             title="评分">
        <AssessmentRecordHistory :record="currentRecord" @init="dataHandle"/>
        <div class="form-title">满分为：{{fullScore}}</div>
        <span>得分：</span><a-input-number :max="fullScore" v-model:value="score"
                                        style="width: 188px; margin-bottom: 8px;"
                                        @pressEnter="saveData"/>
        <br />
        <span>评语：</span><a-input placeholder="请输入评语"
                                 v-model:value="remarks" style="width: 188px; margin-bottom: 8px;"/>
        <br />
        <a-button @click="saveData"  type="primary">
            提交
        </a-button>
    </a-modal>
    <a-modal v-model:open="visible2"
             destroy-on-close
             width="800px"
             :footer="null"
             title="批量评分">
        <div class="form-value" style="color: red">注意：本功能只能在第一次打分时使用，分数修改请使用单项评分功能</div>
        <div v-for="(item,index) in batchAssessment" :key="item.id">
            <a-row style="border-bottom:1px dashed #becee8">
                <a-col :span="14">
                    <div class="form-value" style="margin-right: 10px">{{index+1}}.{{item.smallIndex}}</div>
                </a-col>
                <a-col :span="4">
                    <div class="form-value" style="margin-right: 10px">满分：{{item.standardScore}}</div>
                </a-col>
                <a-col :span="6">
                    <div class="form-value" style="display: flex">
                        <div style="min-width:60px">
                            得分：
                        </div>
                        <a-input-number style="width: 100%" :max="item.standardScore"  placeholder="得分"  v-model:value="item.obtainScore" />
                    </div>
                </a-col>
                <a-col :span="24">
                    <div class="form-value" style="display: flex">
                        <div style="min-width:60px">
                            评语：
                        </div>
                        <a-textarea :auto-size="{ minRows: 2, maxRows: 6 }" v-model:value="item.remarks"/>
                    </div>
                </a-col>
            </a-row>
        </div>
        <a-divider />
        <a-popconfirm
                ok-text="是"
                cancel-text="否"
                title="确定分数无误吗?"
                @confirm="saveData2">
            <a-button type="primary">
                提交
            </a-button>
        </a-popconfirm>
    </a-modal>
</template>

<script>
    import ColumnType from "@/assets/tables/column_type";
    import Column from "@/assets/tables/column";
    import assessmentUser from "../../../../assets/api/assessmentUser";
    import userScore from "../../../../assets/api/userScore";
    import {CheckOutlined,CloseOutlined} from "@ant-design/icons-vue"
    import AssessmentRecordHistory from "./AssessmentRecordHistory";
    import LTable from "../../../../components/table/LTable";
    import Welcome from "../../../Other/Welcome";

    export default {
        name: "MonthlyAssessmentViewer",
        props:["assessmentData","viewModel","userType","assessmentName"],//userType user查看分数的人  judge 对分项进行打分的人
        components:{
            CloseOutlined,CheckOutlined,AssessmentRecordHistory,LTable,Welcome
        },
        computed:{
            sumPoints(){
                let num = 0;
                for(let i in this.assessmentData){
                    num += this.assessmentData[i].obtainScore
                }
                return num;
            },
            fullPoints(){
                let num = 0;
                for(let i in this.assessmentData){
                    num += this.assessmentData[i].standardScore
                }
                return num;
            }
        },
        watch:{
            assessmentData(){
                this.init()
            }
        },
        data(){
            return{
                activeKey:"0",
                tabList:[],
                tableColumns:[],
                visible:false,
                visible2:false,
                fullScore:null,
                score:null,
                currentRecord:null,
                remarks:null,
                historyRecord:null,
                batchAssessment:[]
            }
        },
        methods:{
            init(){
                this.tabList = [];
                this.batchAssessment = [];
                if(this.assessmentData && this.assessmentData.length>0){
                    for(let record of this.assessmentData){
                        let tabs = this.tabList.filter(item =>item.label === record.bigIndex);
                        if(tabs.length === 0){
                            this.tabList.push(
                                {
                                    label:record.bigIndex,
                                    dataSource:[record]
                                }
                            )
                        }
                        else{
                            tabs[0].dataSource.push(record)
                        }
                        if(record.agreeFlag !== '1' && record.editFlag === true && record.judgeEnd ===false){
                            this.batchAssessment.push({...record})
                        }
                    }
                }
            },
            agree(record){
                let data = {
                    ids:record.id,
                    agreeFlag:"1"
                };
                assessmentUser.agree.urlPost(data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        record.agreeFlag = "1";
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            disagree(record){
                let data = {
                    ids:record.id,
                    agreeFlag:"2"
                };
                assessmentUser.agree.urlPost(data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        record.agreeFlag = "2";
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            judge(record){
                this.score = record.standardScore?record.standardScore*0.8*100/100:0;
                this.remarks = null;
                this.fullScore = record.standardScore?record.standardScore:999;
                this.visible = true;
                this.currentRecord = record;
                this.historyRecord = null;
            },
            listJudge(){
                this.visible2 = true;
            },
            saveData(){
                let data = {
                    "actualFinish":this.score,
                    "assessmentId": this.currentRecord.id,
                    "obtainScore": this.score,
                    "remarks": this.remarks
                };
                if(this.historyRecord){
                    data.id = this.historyRecord.id;
                }
                userScore.saveOrUpd.post(data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.visible = false;
                        this.currentRecord.obtainScore = this.score;
                        this.currentRecord.judgeEnd = true;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            dataHandle(historyRecord){//评分历史数据
                this.historyRecord = historyRecord;
            },
            saveData2(){
                let userScoreVOList = []
                for(let item of this.batchAssessment){
                    userScoreVOList.push({
                        "actualFinish":item.obtainScore,
                        "assessmentId": item.id,
                        "obtainScore": item.obtainScore,
                        "remarks": item.remarks
                    })
                }
                userScore.batchSaveOrUpd.post({userScoreVOList},res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.visible2 = false;
                        for(let item of this.batchAssessment){
                            userScoreVOList.push({
                                "actualFinish":item.obtainScore,
                                "assessmentId": item.id,
                                "obtainScore": item.obtainScore,
                                "remarks": item.remarks
                            })
                        }
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            }
        },
        created() {
            this.init();
            if(this.userType === "user"){
                this.tableColumns = [
                    new Column("类别","smallIndex",ColumnType.String).setTableView(100),
                    new Column("打分角色","judgeRole",ColumnType.IdReplace,false).setTableView(100)
                        .setIdReplaceObject("judgeRoleName",{}),
                    new Column("打分人","judgeUserNames",ColumnType.String).setTableView(80),
                    new Column("评分规则","describe",ColumnType.String).setTableView(200),
                    new Column("指标","indexDescribe",ColumnType.String).setTableView(100),
                    new Column("实际完成","actualFinish",ColumnType.String).setTableView(100),
                    new Column("满分","standardScore",ColumnType.Number).setTableView(60),
                    new Column("得分","obtainScore",ColumnType.Number,true).setTableView(60),
                    new Column("评分","judgeEnd",ColumnType.Boolean).setTableView(60)
                        .setBoolTags(["未完成","已完成"]),
                    new Column("同意","agreeFlag",ColumnType.Enum).setTableView(80)
                        .setDictEnum("agreeFlag"),
                    new Column("评语","remarks",ColumnType.String).setTableView(100),
                    new Column("操作","actions","actions").setTableView(60)
                ]
            }
            else if(this.userType === "judge"){
                this.tableColumns = [
                    new Column("类别","smallIndex",ColumnType.String).setTableView(100),
                    new Column("打分角色","judgeRole",ColumnType.IdReplace,false).setTableView(100)
                        .setIdReplaceObject("judgeRoleName",{}),
                    new Column("打分人","judgeUserNames",ColumnType.String).setTableView(80),
                    new Column("评分规则","describe",ColumnType.String).setTableView(200),
                    new Column("满分","standardScore",ColumnType.Number).setTableView(60),
                    new Column("得分","obtainScore",ColumnType.Number,true).setTableView(60),
                    new Column("评分","judgeEnd",ColumnType.Boolean).setTableView(60)
                        .setBoolTags(["未完成","已完成"]),
                    new Column("同意","agreeFlag",ColumnType.Enum).setTableView(80)
                        .setDictEnum("agreeFlag"),
                    new Column("评语","remarks",ColumnType.String).setTableView(100),
                    new Column("操作","actions","actions").setTableView(60)
                ]
            }
        }
    }
</script>

<style scoped>

</style>