<!--
* @program: TYH_office_automation
* @author: ly
* @component:BillStatisticsByDepartment
* @description: 按部门和时间统计消耗
* @create: 2021-08-12 15:52
-->
<template>
    <div class="column-flex">
        <div class="search-bar">
            <div style="margin: 8px;font-weight: 900;font-size: 16px"> 部门：</div>
            <a-select allow-clear v-model:value="departmentId" style="width: 200px"
                      placeholder="选择部门进行查询"
                      @change="doSearch">
                <a-select-option v-for="item in departments"  :key="item.id">
                    {{item.fullName}}
                </a-select-option>
            </a-select>
            <div style="margin: 8px;font-weight: 900;font-size: 16px"> 统计时间：</div>
            <a-range-picker @change="doSearch"  v-model:value="rangeMonth" picker="month" />
        </div>
        <div style="height: 45%;width: 100%">
            <div ref="chart_item" style="height:100%;width: 100%"/>
        </div>
        <div style="margin: 8px;font-weight: 900;font-size: 16px">合计（￥）：{{allSum.toFixed(2)}} 有发票：{{sum1.toFixed(2)}} 无发票：{{sum2.toFixed(2)}}</div>
        <div style="height: 40%;">
            <LTable :columns="argument.tableColumns" :data-source="argument.dataSource"
                    :download="true"
                    :view-model="true">
                <template #action="{record}">
                    <div class="action-column">
                        <a-button @click="showDetail(record)" type="link">详情</a-button>
                    </div>
                </template>
            </LTable>
        </div>
    </div>

    <a-modal :footer="null"
             :title="dep_title"
             :width="800"
             destroyOnClose
             v-model:open="visible">
        <LTable :columns="argument2.tableColumns" :data-source="argument2.dataSource"
                :download="false"
                :page-size="6"
                :view-model="true">
            <template #action="{record}">
                <a-button @click="showDetail_user(record)" type="link">
                    <template #icon>
                        <SearchOutlined title="查看"/>
                    </template>
                </a-button>
            </template>
        </LTable>

        <div v-if="flag_user">
            <a-divider orientation="left">月份：{{flag_title}}
                <a-divider style="height:32px;border-left: #022d5c solid;" type="vertical"/>
                合计金额：{{flag_applyAmount}}
            </a-divider>

            <LTable :columns="argument3.tableColumns" :data-source="argument3.dataSource"
                    :download="false"
                    :pagination="false"
                    :view-model="true">
            </LTable>
        </div>
        <span style="font-size:20px">部门报账总金额（￥）：{{sumMoney.toFixed(2)}}</span>
    </a-modal>
</template>

<script>
    import {markRaw} from "vue";
    import * as echarts from "echarts";
    import api_fundSummary from "@/assets/api/fundSummary";
    import Column from "@/assets/tables/column";
    import ColumnType from "@/assets/tables/column_type";
    import {SearchOutlined} from "@ant-design/icons-vue"
    import LTable from "@/components/table/LTable";
    import {getNextMonth} from "@/assets/utils/dataUtil";

    export default {
        name: "BillStatisticsByDepartment",
        components: {
             LTable, SearchOutlined
        },
        computed: {
            sumMoney() {
                let sum = 0;
                for (let i in this.argument2.dataSource) {
                    sum += this.argument2.dataSource[i].applyAmount;
                }
                return sum;
            },
            allSum() {
                let sum = 0;
                for (let i in this.argument.dataSource) {
                    sum += this.argument.dataSource[i].applyAmount;
                }
                return sum;
            },
            sum1() {
                let sum = 0;
                for (let i in this.argument.dataSource) {
                    sum += this.argument.dataSource[i].haveInvoiceAmount;
                }
                return sum;
            },
            sum2() {
                let sum = 0;
                for (let i in this.argument.dataSource) {
                    sum += this.argument.dataSource[i].noInvoiceAmount;
                }
                return sum;
            },
        },
        data() {
            return {
                visible: false,
                departments:[],
                departmentId:undefined,
                rangeMonth:[],
                argument: {
                    dataSource: null,
                    tableColumns: [
                        new Column("部门名称", "departName", ColumnType.String, true).setTableView(200),
                        /* new Column("时间","dateStr",ColumnType.String,true).setTableView(80),*/
                        new Column("有发票报销(元)", "haveInvoiceAmount", ColumnType.Number, true).setTableView(80),
                        new Column("无发票报销(元)", "noInvoiceAmount", ColumnType.Number, true).setTableView(80),
                        new Column("合计(元)", "applyAmount", ColumnType.Number, true).setTableView(80),
                        new Column("操作", "actions", "actions", false).setTableView(80)
                    ],

                    loading: false
                },
                argument2: {
                    dataSource: null,
                    tableColumns: [
                        new Column("月份", "monthStr", ColumnType.String, true).setTableView(120),
                        new Column("有发票报销(元)", "haveInvoiceAmount", ColumnType.Number, true).setTableView(150),
                        new Column("无发票报销(元)", "noInvoiceAmount", ColumnType.Number, true).setTableView(150),
                        new Column("合计(元)", "applyAmount", ColumnType.Number, true).setTableView(150),
                        new Column("操作", "actions", "actions", false).setTableView(60)
                    ],

                    loading: false
                },
                dep_title: "",
                argument3: {
                    dataSource: null,
                    tableColumns: [
                        new Column("姓名", "userName", ColumnType.String, true).setTableView(150),
                        new Column("有发票报销(元)", "haveInvoiceAmount", ColumnType.Number, true).setTableView(150),
                        new Column("无发票报销(元)", "noInvoiceAmount", ColumnType.Number, true).setTableView(150),
                        new Column("合计(元)", "applyAmount", ColumnType.Number, true).setTableView(150),
                    ],

                    loading: false
                },
                flag_user: false,
                flag_title: "",
                flag_applyAmount: 0,
                chartsItem: null,

                date_interval: [],// 查询时间区间数组
            }
        },
        methods: {
            doSearch() {
                if(this.rangeMonth && this.rangeMonth.length>0){
                    let data = {
                        startMonth:this.rangeMonth[0].format("YYYY-MM"),
                        endMonth:this.rangeMonth[1].format("YYYY-MM")
                    };
                    if(this.departmentId){
                        data.departId = this.departmentId
                    }
                    api_fundSummary.statiMonthDep.urlPost(data, res => {
                        this.argument.loading = false;
                        if (res.data.flag) {
                            this.argument.dataSource = res.data.data;
                        } else {
                            this.argument.dataSource = [];
                            this.$message.error(res.data.msg);
                        }
                        this.cypher_date();
                        this.fz_eachar();
                    });
                }
            },
            showDetail(record) {
                this.dep_title = "报账详情：" + record.departName;
                this.flag_user = false;
                let monthSumList = record.monthSumList;
                if (monthSumList.length > 0) {
                    this.visible = true;
                    this.argument2.dataSource = [...record.monthSumList];
                } else {
                    this.$message.error("无数据");
                }
            },
            showDetail_user(record) {
                this.flag_title = record.monthStr;
                this.flag_applyAmount = record.applyAmount;
                let userSumList = record.userSumList;
                if (userSumList.length > 0) {
                    this.flag_user = true;
                    this.argument3.dataSource = [...record.userSumList];
                } else {
                    this.$message.error("无数据");
                }
            },
            remove(index) {
                this.argument2.dataSource.splice(index, 1);
            },
            /** 根据开始时间、结束时间拆分成时间数组 **/
            cypher_date() {
                this.date_interval = [];
                if (this.rangeMonth && this.rangeMonth.length > 0) {
                    let startMonth = this.rangeMonth[0].format("YYYY-MM");
                    let endMonth = this.rangeMonth[1].format("YYYY-MM");
                    this.date_interval.push(startMonth);
                    let next = 1;
                    for (let i = 0; i < next; i++) {
                        // 获取下个月
                        let nextMonth = getNextMonth(this.date_interval[i]);
                        if (nextMonth <= endMonth) {
                            this.date_interval.push(nextMonth);
                            next++;
                        }
                    }
                } else {
                    this.date_interval = [];
                }
            },
            /*** 统计分析 start ***/
            // 封装
            fz_eachar() {
                let data = this.argument.dataSource;
                let series_data = [];
                if (data.length > 0 && this.date_interval.length > 0) {
                    let date_interval = this.date_interval;
                    data.forEach((item_i) => {// 处理部门
                        let serie = {
                            name: item_i.departName,
                            type: 'line',
                            emphasis: {
                                focus: 'series'
                            },
                            label: {
                                show: true,
                            },
                            data: []
                        }
                        let serie_data = [];
                        date_interval.forEach((item_j) => {

                            let monthSumList = item_i.monthSumList;
                            // 处理查询月份数据
                            if (monthSumList != null && monthSumList.length > 0) {
                                let flag = false;
                                let applyAmount = 0; // 月总金额
                                monthSumList.forEach((item_k) => {
                                    let monthStr = item_k.monthStr;
                                    if (item_j === monthStr) {
                                        flag = true;
                                        applyAmount = item_k.applyAmount;
                                    }
                                })
                                if (flag) {
                                    serie_data.push(applyAmount);
                                } else {
                                    serie_data.push(0);
                                }
                            }
                        })
                        serie.data = serie_data;
                        series_data.push(serie);
                    })
                } else {// 无数据
                    series_data = [];
                }
                console.log(series_data)
                this.eachar_item(series_data);
            },
            eachar_item(data) {
                if (this.chartsItem) {
                    this.chartsItem.clear();
                }
                let option = null;
                if (data.length <= 0) {
                    option = {
                        title: {
                            extStyle: {
                                color: "grey",
                                fontSize: 20

                            },
                            textStyle: {
                                color: "#FFD700",
                            },

                            text: "暂无数据",

                            left: "center",

                            top: "center"

                        },
                    }
                } else {
                    option = {
                        title: {
                            text: "部门报账统计",
                            x: 'center',
                        },
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'shadow'
                            },
                            formatter: (params) => {
                                var result = params[0].axisValueLabel + '<br>'
                                params.forEach(function (item) {
                                    if (item.value) {
                                        result += item.marker + ' ' + item.seriesName + ' : ' + item.value + '</br>'
                                    }
                                })
                                // 保留数据
                                this.chartsCurrentData = params[0]
                                // 返回mousemove提示信息
                                return result
                            }
                        },
                        /* toolbox: {
                           show: true,
                           feature: {
                             magicType: { show: true, type: ['line', 'bar'] },
                             saveAsImage: { show: true },
                           }
                         },*/
                        legend: {
                            y: 'bottom',
                            x: 'center',
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '13%',
                            containLabel: true
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: this.date_interval,
                                name: '时间',
                                axisLine: {//x轴线的颜色以及宽度
                                    show: true,
                                    lineStyle: {
                                        color: "blue",
                                        width: 0,
                                        type: "solid",
                                    },

                                },
                                axisLabel: {
                                    textStyle: {
                                        color: 'blue',
                                        fontSize: 20,

                                    }
                                },
                                nameTextStyle: {
                                    color: '#A9A9A9',
                                    padding: [100, 0, 0, 0],		//表示[上,右,下,左]的边距
                                },
                                triggerEvent: true,
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                name: '(金额)',
                                nameTextStyle: {
                                    color: '#A9A9A9',
                                    padding: [100, 0, 0, 15],		//表示[上,右,下,左]的边距
                                }
                            }
                        ],
                        series: data
                    };
                }

                this.chartsItem = markRaw(echarts.init(this.$refs.chart_item))//获取dom元素;
                this.chartsItem.setOption(option);
            },
            /*** 统计分析 end ***/
        },
        async created() {
            this.departments = JSON.parse(await this.$getItem("__departments"));
            this.departmentId = this.departments[0].id
        }
    }
</script>

<style scoped>

</style>
