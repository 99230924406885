<!--
* @program: TYHOA2 
* @author: ly
* @component:QCApply 
* @description: 
* @create: 2024-09-27 10:01

-->
<template>
    <div class="column-flex" style="position: relative;overflow: hidden">
        <div class="search-bar">
            <a-button @click="init"  type="dashed">
                <template #icon>
                    <SyncOutlined />
                </template>
            </a-button>
            <a-input  placeholder="项目名称" style="width: 100px" v-model:value="filter.itemName" />
            <a-input  placeholder="申请部门" style="width: 150px" v-model:value="filter.departmentName" />
            <a-input  placeholder="申请人" style="width: 150px" v-model:value="filter.applyUserName" />
            <a-radio-group style="margin-left:15px;border-left: #022d5c solid;padding-left:10px" @change="init" v-model:value="defaultQuery.auditState">
                <a-radio value="0">待审核</a-radio>
                <a-radio value="1">已通过</a-radio>
                <a-radio value="2">未通过</a-radio>
            </a-radio-group>
        </div>
        <div class="row-flex">
            <LTable :loading="loading" :data-source="source" :columns="tableColumns">
                <template #action="{record}">
                    <div class = "action-column">
                        <a-button @click="showDetail(record)"
                                  class="action-button" type="link">
                            <SearchOutlined class="pointer"/>
                        </a-button>
                        <a-button @click="audit(record)" class="action-button" v-if="record.auditState !== '1'" type="link">
                            审核
                        </a-button>
                    </div>
                </template>
            </LTable>
        </div>
    </div>
    <a-modal v-model:open="visible"
             :footer="null"
             width="800px"
             title="项目质检">
        <LForm title="一检信息" :labelSpan="4" v-if="qcOne.qcDate" :form-columns="formColumns2" :form-data="qcOne" :view-model="true" />
        <a-result  title="还未上传一检信息" v-else/>
        <LForm title="二检申请" :label-span="4" :form-columns="formColumns" :form-data="currentRecord" :view-model="true"/>
    </a-modal>

    <a-modal v-model:open="visible2"
             :footer="null"
             width="800px"
             title="质检审核">
        <LForm title="一检信息" :labelSpan="4" v-if="qcOne.qcDate" :form-columns="formColumns2" :form-data="qcOne" :view-model="true" />
        <a-result  title="还未上传一检信息" v-else/>

        <a-row>
            <a-col class="form-label" style="text-align: right;padding-right: 15px;" :span="4">
                <div>质检负责人</div>
            </a-col>
            <a-col class="form-value" style="text-align: right;padding-right: 15px;" :span="10">
                <UserPickerS v-model:value="qcLeaderUser" />
            </a-col>
        </a-row>
        <a-row>
            <a-col class="form-label" style="text-align: right;padding-right: 15px;" :span="4">
                <div>审核意见</div>
            </a-col>
            <a-col class="form-value" style="text-align: right;padding-right: 15px;" :span="20">
                <a-textarea placeholder="审核意见" :auto-size="{ minRows: 2, maxRows: 6 }" v-model:value="auditNote"/>
            </a-col>
        </a-row>
        <a-divider />
        <a-button @click="accept" style="margin: 10px" type="primary">
            通过
        </a-button>
        <a-button @click="refuse" style="margin: 10px" type="primary" danger>
            不通过
        </a-button>
    </a-modal>
</template>

<script>
    import LTable from "../../../components/table/LTable";
    import LForm from "../../../components/form/LForm";
    import {SearchOutlined, SyncOutlined,
        CheckOutlined,CloseOutlined
    } from "@ant-design/icons-vue";
    import {ArrayFilter} from "../../../assets/utils/general";
    import qcApply from "../../../assets/api/qcApply";
    import item_qc_apply from "../../TableStruct/item_qc_apply";
    import Column from "../../../assets/tables/column";
    import ColumnType from "../../../assets/tables/column_type";
    import itemTask from "../../../assets/api/itemTask";
    import UserPickerS from "../../common/UserPickerS";
    import itemInfo from "../../../assets/api/itemInfo";
    export default {
        name: "QCApply",
        components:{
            LTable,LForm,UserPickerS,
            SyncOutlined,SearchOutlined,
            CheckOutlined,CloseOutlined
        },
        computed:{
            source(){
                return ArrayFilter(this.dataSource,this.filter);
            },
        },
        data() {
            return {
                dataSource: [],
                tableColumns: new item_qc_apply().getTableColumns(),
                formColumns: new item_qc_apply().getFormColumns(),
                qcOne:{},
                formColumns2:[
                    new Column("一检时间","qcDate",ColumnType.Date,true),
                    new Column("一检描述","qcDescrib",ColumnType.String,true),
                    new Column("一检文件","qcFile",ColumnType.PreViewFile,true),
                    new Column("一检人员","qcUserNames",ColumnType.String,false),
                    new Column("评分","assessment",ColumnType.Enum,true)
                        .setDictEnum("assessment"),
                ],
                loading: false,
                defaultQuery: {
                    auditState:"0",
                },
                currentRecord:null,
                filter:{},
                visible:false,
                visible2:false,
                auditNote:null,
                qcLeaderUser:undefined,
                option:[]
            }
        },
        methods: {
            init() {
                this.loading = true;
                qcApply.queryAll.post(this.defaultQuery,res=>{
                    this.loading = false;
                    if (res.data.flag) {
                        this.dataSource = res.data.data.sort((a,b)=>{
                            return b.createTime - a.createTime
                        });
                    } else {
                        this.dataSource = [];
                    }
                });
            },
            showDetail(record) {
                this.visible = true;
                this.currentRecord = record;
                this.qcOne = {};
                itemTask.queryOne.urlPost({id:record.originId},res=>{
                    if(res.data.flag){
                        this.qcOne = res.data.data;
                    }
                })
            },
            audit(record){
                this.visible2 = true;
                this.currentRecord = record;
                this.qcOne = {};
                this.qcLeaderUser = undefined;
                this.auditNote = "符合申请二检要求";
                itemTask.queryOne.urlPost({id:record.originId},res=>{
                    if(res.data.flag){
                        this.qcOne = res.data.data;
                    }
                })
            },
            accept(){
                if(!this.qcLeaderUser){
                    this.$message.error("请选择质检负责人");
                    return
                }
                let data = {
                    id:this.currentRecord.id,
                    auditState:"1",
                    auditNote:this.auditNote,
                    qcLeaderUser:this.qcLeaderUser
                };
                qcApply.audit.urlPost(data,res=>{
                    if(res.data.flag){
                        this.visible2 = false;
                        this.$message.success(res.data.msg);
                        this.dataSource = this.dataSource.filter(item=>item.id !== this.currentRecord.id);
                        itemInfo.updByFiled.post({id:this.currentRecord.itemId,qualityState:"2"})
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            refuse(){
                let data = {
                    id:this.currentRecord.id,
                    auditState:"2",
                    auditNote:this.auditNote,
                    qcLeaderUser:this.qcLeaderUser
                };
                qcApply.audit.urlPost(data,res=>{
                    if(res.data.flag){
                        this.visible2 = false;
                        this.$message.success(res.data.msg);
                        this.dataSource = this.dataSource.filter(item=>item.id !== this.currentRecord.id);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>