/** 
* 自动生成文件，请勿修改 
* @name: 文件操作模块
* @description: 文件操作-上传/下载
* @create: 2024/10/18 15:02:19
* @swagger: 2.0
* 合计:4条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const file = { 
	/** 1 summary:文件操作模块-文件下载/查看
	description:参数：fileUrl，文件路径
	method:post
	params:需要配合Column函数使用
	*/ 
	download : new APIObject.postObject("/api/file/download.do"), 
	/** 2 summary:文件操作模块-多个文件上传
	description:参数：多个文件对象MultipartFile[]数组
	method:post
	params:需要配合Column函数使用
	*/ 
	uploads : new APIObject.postObject("/api/file/uploads.do"), 
	/** 3 summary:文件操作模块-文件删除(可批量，可单个)
	description:参数：fileUrl，多个文件路径，逗号分隔
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDeleteFile : new APIObject.postObject("/api/file/batchDeleteFile.do"), 
	/** 4 summary:文件操作模块-单个文件上传
	description:参数：单个文件对象MultipartFile
	method:post
	params:需要配合Column函数使用
	*/ 
	upload : new APIObject.postObject("/api/file/upload.do"), 
};
export default file;