<!--
* @program: TYHOA2 
* @author: ly
* @component:ProjectFlows 
* @description: 所有项目收入记录
* @create: 2024-09-24 15:44
-->
<template>
    <div class="column-flex">
        <div class="search-bar">
            <a-button @click="init" type="dashed">
                <template #icon>
                    <SyncOutlined/>
                </template>
            </a-button>
            <a-input placeholder="项目名称" style="width: 200px" v-model:value="filter.itemName" />
            <a-input placeholder="付款方" style="width: 200px" v-model:value="filter.payerName" />
            <a-input placeholder="收款方" style="width: 200px" v-model:value="filter.receiverName" />
            <a-range-picker v-model:value="rangeMonth" picker="month" />
            <a-button @click="getSumMoney"  type="dashed">
                <template #icon><BarChartOutlined/></template>
                统计
            </a-button>
            <a-popover v-if="sumMoney.length > 0" trigger="hover" placement="bottom">
                <template #content>
                    <a-list size="small" style="margin: 0" bordered :data-source="sumMoney">
                        <template #renderItem="{item}">
                            <a-list-item>{{item.month}}【{{item.value.toFixed(2)}}元】</a-list-item>
                        </template>
                    </a-list>
                </template>
                <a-button  type="link" >
                    总计：{{sum.toFixed(2)}}元
                </a-button>
            </a-popover>
        </div>
        <LTable :columns="tableColumns" :data-source="source" :loading="loading">

        </LTable>
    </div>
</template>

<script>
    import collectionInfo from "../../../../assets/api/collectionInfo";
    import LTable from "../../../../components/table/LTable";
    import {SearchOutlined, SyncOutlined,BarChartOutlined} from "@ant-design/icons-vue";
    import Column from "@/assets/tables/column";
    import ColumnType from "@/assets/tables/column_type"
    import dayjs from "dayjs";
    import {ArrayFilter} from "../../../../assets/utils/general";
    export default {
        name: "ProjectFlows",
        components:{
            LTable,SearchOutlined,SyncOutlined,BarChartOutlined
        },
        computed:{
            source(){
                let source = [];
                if(this.rangeMonth){
                    source = this.dataSource.filter(item=>{
                        let payDate = dayjs(item.collectionTime);
                        const startOfMonth = this.rangeMonth[0].startOf('month');
                        const endOfMonth = this.rangeMonth[1].endOf('month');
                        return payDate.isAfter(startOfMonth) && payDate.isBefore(endOfMonth);
                    });
                    return ArrayFilter(source,this.filter);
                }
                else{
                    return ArrayFilter(this.dataSource,this.filter);
                }
            },
            sum(){
                let money =0;
                for(let i in this.sumMoney){
                    money += this.sumMoney[i].value;
                }
                return money;
            }
        },
        data() {
            return {
                tableColumns:[],
                dataSource:[],
                loading:false,
                filter:{},
                rangeMonth:undefined,
                sumMoney:[]
            }
        },
        methods: {
            init() {
                this.tableColumns = [
                    new Column("项目名称","itemName",ColumnType.String).setTableView(200),
                    new Column("收款时间","collectionTime",ColumnType.Date).setTableView(80),
                    new Column("收款金额(元)","collectionAmount",ColumnType.Number).setTableView(100),
                    new Column("所属发票","invoiceNumber",ColumnType.String).setTableView(100),
                    new Column("付款方", "payerName", ColumnType.String).setTableView(150),
                    new Column("收款方", "receiverId", ColumnType.Enum).setTableView(150)
                        .setDictEnum("companies"),
                    new Column("收款备注","remarks",ColumnType.String).setTableView(150),
                ];
                this.loading = true;
                this.dataSource = [];
                collectionInfo.queryAll.urlPost({},res=>{
                    this.loading = false;
                    if (res.data.flag) {
                        this.dataSource = res.data.data.sort((a,b)=>{
                            return b.collectionTime - a.collectionTime
                        });
                    }
                })
            },
            getSumMoney(){
                this.sumMoney = [];
                for(let item of this.source){
                    let month = dayjs(item.collectionTime).format("YYYY-MM");
                    let arr = this.sumMoney.filter(item=>item.month === month);
                    if(arr.length >0){
                        arr[0].value += item.collectionAmount;//
                    }
                    else{
                        this.sumMoney.push({month:month,value:item.collectionAmount})
                    }
                }
            },
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>