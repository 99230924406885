<!--
* @program: office_automation
* @author: ly
* @component:ProjectInflow
* @description: 项目收入
* @create: 2022-01-25 11:05
-->
<template>
    <div class="column-flex">
        <div class="form-value">
            <a-button @click="init" type="dashed">
                <template #icon><SyncOutlined/></template>
            </a-button>
            <span > 收入合计：{{summary.toFixed(2)}} 元</span>
            <a-popover trigger="hover" placement="top">
                <template #content>
                    <span class="form-value">收入合计：{{(summary*0.914).toFixed(4)}} 手续费：{{(summary*0.086).toFixed(4)}}</span>
                </template>
                <a-button  type="link" >计算手续费（8.6%）</a-button>
            </a-popover>
            <a-divider style="height:32px;border-left: #022d5c solid;" type="vertical"/>
            <a-range-picker style="width: 200px" v-model:value="rangeMonth" picker="month" />
            <a-input  placeholder="项目名称" style="width: 150px" v-model:value="filter.itemName" />
            <a-input  placeholder="付款方" style="width: 150px" v-model:value="filter.payerName" />
            <a-input  placeholder="收款方" style="width: 150px" v-model:value="filter.receiverName" />
        </div>
        <div style="height:600px;overflow:auto">
            <LTable :columns="tableColumns" :data-source="source">
                <template #action="{record}">
                    <div class="action-column">
                        <a-button @click="showDetail(record)" type="link">
                            <template #icon>
                                <SearchOutlined/>
                            </template>
                        </a-button>
                    </div>
                </template>
            </LTable>
        </div>
    </div>
</template>

<script>
    import dayjs from "dayjs";
    import LTable from "@/components/table/LTable";
    import Column from "@/assets/tables/column";
    import ColumnType from "@/assets/tables/column_type";
    import {SearchOutlined,SyncOutlined} from "@ant-design/icons-vue"
    import collectionInfo from "../../../../assets/api/collectionInfo";
    import {ArrayFilter} from "../../../../assets/utils/general";

    export default {
        name: "ProjectInflow",
        props: ["record", "value"],
        components: {
            LTable,
            SearchOutlined,SyncOutlined
        },
        watch: {
            record() {
                this.init()
            }
        },
        computed:{
            source(){
                let source = [];
                if(this.rangeMonth){
                    source = this.dataSource.filter(item=>{
                        let payDate = dayjs(item.collectionTime);
                        const startOfMonth = this.rangeMonth[0].startOf('month');
                        const endOfMonth = this.rangeMonth[1].endOf('month');
                        return payDate.isAfter(startOfMonth) && payDate.isBefore(endOfMonth);
                    });
                    return ArrayFilter(source,this.filter);
                }
                else{
                    return ArrayFilter(this.dataSource,this.filter);
                }
            },
            summary(){
                let sum = 0;
                for(let item of this.source){
                    let date = dayjs(item.collectionTime);
                    if (date.isAfter(dayjs(this.record.balanceDate))) {
                        sum += item.collectionAmount;
                    }
                }
                return sum
            },
        },
        data() {
            return {
                dataSource: [],
                tableColumns: [
                    new Column("项目名称", "itemName", ColumnType.String).setTableView(200),
                    new Column("收款时间", "collectionTime", ColumnType.Date).setTableView(80),
                    new Column("收款金额(元)", "collectionAmount", ColumnType.Number).setTableView(100),
                    new Column("付款方", "payerName", ColumnType.String).setTableView(150),
                    new Column("收款方", "receiverId", ColumnType.Enum).setTableView(150)
                        .setDictEnum("companies"),
                    new Column("收款备注","remarks",ColumnType.String).setTableView(100),
                    new Column("发票绑定", "invoiceId",ColumnType.Boolean).setTableView(100).setBoolTags(["未绑定","已绑定"]),
                ],
                loading: false,
                rangeMonth:undefined,
                filter:{}
            }
        },
        methods: {
            init() {
                this.dataSource = [];
                let data = {
                    bankId: this.record.id
                };
                this.loading = true;
                collectionInfo.queryAllByObj.post(data, res => {
                    this.loading = false;
                    if (res.data.flag) {
                        this.dataSource = res.data.data.sort((a,b)=>{
                            return b.createTime - a.createTime
                        });
                        this.dataSource.map(item=>{
                            item.sjhkje = item.collectionAmount * 0.914;
                            return item;
                        })
                    }
                })
            },
            refresh() {
                this.init()
            },
            showDetail(record) {
                this.visible = true;
                this.formData = record;
            }
        },
        created() {
            this.init();
        }
    }
</script>

<style scoped>

</style>
