import Table from "./table";
import Column from "./column";
import ColumnType from "./column_type";

/**
 * @program: TYH_office_automation
 * @author: ly
 * @description: device_apply
 private String  deviceId;                     //设备id
 private String  userId;                       //申请用户id   如果是转借，借给谁，这里就是谁的id,相当于他发起了申领申请
 private String  auditState;                   //审核状态：0：未审核；1：通过；2：不通过
 private String  auditUser;                    //审核人id
 private Date    auditDate;                    //审核时间
 private String  purposeDescri;                //用途描述，申请该设备来干什么，简要说明（如果是转借，说明为什么借给他）
 private String  applyType;                    //申领类型：1：主动申领；2：转借  如果我将设备转借给别人，就得在该表新增一条申请
 private String  applyId;
 * @create: 2021-06-22 14:35
 **/
export default class device_apply  extends Table{
    constructor() {
        super();
        this.name = "device_apply";
        this.CNName = "设备表";
        this.columnArray = [
            new Column("设备名称","deviceName",ColumnType.String).setTableView(200),
            new Column("设备编码类型","deviceCodeName",ColumnType.Enum,true).setDictEnum("deviceCodeName"),
            new Column("设备编码","deviceCode",ColumnType.String,true).setTableView(100),
            new Column("申请项目","itemName",ColumnType.String).setTableView(150),
            new Column("申请人","userId",ColumnType.IdReplace,true).setTableView(80)
                .setIdReplaceObject("userName",{}),
            new Column("审核状态","auditState",ColumnType.Enum,true).setTableView(80)
                .setDictEnum("auditState"),
            new Column("申领类型","applyType",ColumnType.Enum).setDefaultValue("1")
                .setDictEnum("device_apply.applyType"),
            new Column("申请理由","purposeDescri",ColumnType.String,true).setTableView(300),
            // new Column("审核人","auditUser",ColumnType.String,true).setTableView(80)
            //     .setIdReplaceObject("auditUserName",{}),
            // new Column("审核时间","auditDate",ColumnType.Date,true).setTableView(100),
            // new Column("审核批复","remarks",ColumnType.String,true).setTableView(),
            //new Column("设备状态","state",ColumnType.String,true),
        ];
        this.actions = [new Column("操作","actions","actions").setTableView(80)];
    }
}