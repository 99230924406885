/** 
* 自动生成文件，请勿修改 
* @name: 资产:我的设备模块
* @description: 我的设备模块-根据用户id查询其借用过的所有设备
* @create: 2024/10/18 15:02:19
* @swagger: 2.0
* 合计:2条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const myDevice = { 
	/** 1 summary:查询-分页
	description:参数：userId:用户id,state:状态（0:使用中;1:已归还;）,queryText:设备名称、编码模糊根据用户id（userId）查询用户申请过的所有设备记录（包含使用中的、已经归还的等）用于登录用户查看自己借过的设备，及管理员查看任何一个人借过的设备
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/myDevice/queryPage.do"), 
	/** 2 summary:查询-所有数据
	description:参数：userId:用户id,state:状态（0:使用中;1:已归还;）,queryText:设备名称、编码模糊根据用户id（userId）查询用户申请过的所有设备记录（包含使用中的、已经归还的等）用于登录用户查看自己借过的设备，及管理员查看任何一个人借过的设备
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/myDevice/queryAll.do"), 
};
export default myDevice;