<!--
* @program: TYH_office_automation 
* @author: ly
* @component:typeSelector 
* @description: 
* @create: 2021-07-07 15:33
-->
<template>
    <div >
        <div v-if="viewModel">
            <NoWrapString @click="onClick" :text="presentValue"/>
        </div>
        <div v-else>
            <a-select
                    @blur="blurTrigger"
                    v-if="editable"
                    v-model:value="record[column.dataIndex]"
                    @select="handleSelect"
                    @change="columnTrigger"
                    :field-names="fieldNames"
                    :options="options">
            </a-select>
            <NoWrapString v-else @click="onClick" :text="presentValue"/>
        </div>
    </div>
</template>

<script>
    import typeBase from "./typeBase";

    export default {
        name: "typeSelector",
        mixins:[typeBase],
        computed:{
            presentValue(){
                return this.record[this.column.selectorObject.replaceSegment]?this.record[this.column.selectorObject.replaceSegment]:"无"
            },
            options(){
                return this.column.selectorObject.options
            }
        },
        data(){
            return{
                fieldNames:{label:"remarks", value:"id"}
            }
        },
        methods:{
            handleSelect(value, option){
                this.record[this.column.selectorObject.replaceSegment] = option[this.fieldNames.label];
            }
        }
    }
</script>

<style scoped>

</style>