/** 
* 自动生成文件，请勿修改 
* @name: 项目:项目设计文档审核记录管理=============新增
* @description: 项目设计文档审核记录管理===新增
* @create: 2024/10/18 15:02:19
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const ItemDoc = { 
	/** 1 summary:保存/修改-单条
	description:参数：单条数据对象ItemDocVO
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/ItemDoc/saveOrUpd.do"), 
	/** 2 summary:删除-批量
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/ItemDoc/batchDelete.do"), 
	/** 3 summary:审核-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	audit : new APIObject.postObject("/api/ItemDoc/audit.do"), 
	/** 4 summary:查询-分页
	description:参数：(page、limit,分页参数，int型)、ItemDocQueryVO对象，
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/ItemDoc/queryPage.do"), 
	/** 5 summary:删除-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/ItemDoc/delete.do"), 
	/** 6 summary:查询-所有数据
	description:参数：ItemDocQueryVO对象，
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/ItemDoc/queryAll.do"), 
	/** 7 summary:查询-单条数据
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/ItemDoc/queryOne.do"), 
};
export default ItemDoc;