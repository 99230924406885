<!--
* @description：财务科目管理、科目对应类别管理
* @author:rzl
* @date:2022/7/27 15:46
-->
<template>
  <div class="column-flex" style="background-color:#f0f0f0">
      <div class="ant-row">
        <div class="ant-col-6"
             style="background: #FFFFFF;border-radius: 12px 12px 12px 12px;opacity: 1;border: 1px solid #E1E6EE;">
          <suject-type-tree ref="typeTree"  @event="eventHandle" />
        </div>
        <div class="ant-col-17" style="box-shadow: 0px 0px 5px #888888;height: 800px;margin-left:40px;margin-top: 6px;">
          <div v-if="flag == '1'">
            <a-divider orientation="left">
              <span style="color: #e5b556;font-size: 22px;">{{sujectName}} </span>
            </a-divider>
            <a-tabs v-model:activeKey="sujectTypeValue" @change="sujectTypeChange">
                <a-tab-pane v-for="(info) in sujectTypeOption"  :key="info.id" :tab="info.name" :disabled="info.disabled"></a-tab-pane>
            </a-tabs>
            <a-row :gutter="24" style="height: 660px;padding: 5px 15px;">
<!--                <div  v-if="!treeFlag" class="loading">
                    <a-space>
                        <a-spin size="large" tip="数据加载中..."/>
                    </a-space>
                </div>-->
                <a-col :span="6" >
                    <div style="background: #F5F8FC;height: 660px;">
                        <div style="margin-top: 6px;font-size: 16px;font-weight: bold;color: #001A47;">费用类别</div>
                        <a-divider style="margin: 15px 0;"></a-divider>
                        <div style="height:600px;overflow: auto">
                            <a-tree
                                    v-if="treeFlag"
                                    :tree-data="treeData"
                                    v-model:checkedKeys="checkedKeys"
                                    :expandedKeys="expandedKeys"
                                    :fieldNames="{children:'children', key:'id', value: 'id', title: 'label'}"
                                    @check="handleCheckMenu"
                                    @expand='handleExpand'
                                    :checkStrictly="checkStrictly"
                                    autoExpandParent="true"
                                    style="text-align: left;width: 100%;background: #F5F8FC;"
                            >
                                <template #title="node">
                                    <div style="display: flex">
                                        <a-row style="width: 220px;">
                                            <a-col :span="18">
                                                <span  style="padding: 2px 0;">{{ node.label }}</span>
                                            </a-col>
                                            <a-col  :span="3">
                                                <a-tooltip   title="已经关联的费用类别（增加项）">
                                                    <img style="cursor: pointer;" :src="url_add" @click="tc_hide_expand">
                                                </a-tooltip>
                                            </a-col>
                                            <a-col :span="3">
                                                <a-tooltip   title="已经关联的费用类别（减少项）">
                                                    <img style="cursor: pointer;" :src="url_minus" @click="tc_hide_expand">
                                                </a-tooltip>
                                            </a-col>
                                        </a-row>
                                    </div>
                                </template>
                            </a-tree>
                        </div>
                    </div>

                </a-col>
                <a-col :span="9" >
                    <div style="background: #F5F8FC;height: 660px;">
                        <div style="margin-top: 6px;font-size: 16px;font-weight: bold;color: #001A47;">已经关联的费用类别（增加项）</div>
                        <a-divider style="margin: 15px 0;"></a-divider>
                        <div>
                            <a-row>
                                <a-col :span="2"></a-col>
                                <a-col :span="11" class="zj-title">费用类别大类</a-col>
                                <a-col :span="11" class="zj-title">费用类别名称</a-col>
                            </a-row>
                            <div style="overflow: auto">
                                <a-row v-for="(item,index) in argument_add.dataSource" :key="index">
                                    <a-col :span="2">
                                        <a-tooltip   title="移除">
                                            <img style="cursor: pointer;" :src="url_minus" @click="remove_add(item)">
                                        </a-tooltip>
                                    </a-col>
                                    <a-col :span="11" class="zj-lable">{{item.type}}</a-col>
                                    <a-col :span="11" class="zj-lable">{{item.categoryName}}</a-col>
                                </a-row>
                            </div>
                        </div>
                    </div>
                </a-col>
                <a-col :span="9" >
                    <div style="background: #F5F8FC;height: 660px;">
                        <div style="margin-top: 6px;font-size: 16px;font-weight: bold;color: #001A47;">已经关联的费用类别（减少项）</div>
                        <a-divider style="margin: 15px 0;"></a-divider>
                        <div>
                            <a-row>
                                <a-col :span="2"></a-col>
                                <a-col :span="11" class="zj-title">费用类别大类</a-col>
                                <a-col :span="11" class="zj-title">费用类别名称</a-col>
                            </a-row>
                            <div style="overflow: auto">
                                <a-row v-for="(item,index) in argument_reduce.dataSource" :key="index">
                                    <a-col :span="2">
                                        <a-tooltip   title="移除">
                                            <img style="cursor: pointer;" :src="url_minus" @click="submit_Remove">
                                        </a-tooltip>
                                    </a-col>
                                    <a-col :span="11" class="zj-lable">{{item.type}}</a-col>
                                    <a-col :span="11" class="zj-lable">{{item.categoryName}}</a-col>
                                </a-row>
                            </div>

                        </div>
                    </div>
                </a-col>
                  <!--              <div class="ant-col-9" style="box-shadow: 0px 0px 5px #888888;margin-left: 15px;height: 650px;">
                                  <div class="ant-row" style="overflow: auto;height: 550px">
                                    <div  v-if="!treeFlag" class="loading">
                                      <a-space>
                                        <a-spin size="large" tip="数据加载中..."/>
                                      </a-space>
                                    </div>
                                      <a-tree
                                          v-if="treeFlag"
                                          checkable
                                          :tree-data="treeData"
                                          :blockNode="true"
                                          v-model:checkedKeys="checkedKeys"
                                          :expandedKeys="expandedKeys"
                                          :fieldNames="{children:'children', key:'id', value: 'id', title: 'label'}"
                                          @check="handleCheckMenu"
                                          @expand='handleExpand'
                                          :checkStrictly="checkStrictly"
                                          autoExpandParent="true"
                                      />
                                  </div>

                                  <a-divider></a-divider>
                                  <div class="ant-row" style="margin: 10px 0 0 150px;">
                                    <a-button type="primary" @click="submit">提交关联</a-button>
                                  </div>
                                </div>-->
                  <!--              <div class="ant-col-14" style="margin-left: 15px;height: 650px;">
                                  <div class="ant-row" style="box-shadow: 0px 0px 5px #888888;height: 320px;margin-left:20px">
                                    <div class="ant-col-24" >
                                      <a-divider orientation="left" style="color: #ff7875;font-size: 16px;margin: 5px 0;"> 已经关联的费用类别&nbsp;&nbsp;<PlusCircleTwoTone  title="加"/></a-divider>
                                        &lt;!&ndash; table start &ndash;&gt;
                                        <LTable ref="pstable" :page-size="pageParam.limit"
                                                :data-source="argument_add.dataSource" :columns="argument_add.tableColumns">

                                        </LTable>
                                        &lt;!&ndash; table end &ndash;&gt;
                                    </div>
                                  </div>

                                  <div class="ant-row" style="box-shadow: 0px 0px 5px #888888;height: 320px;margin-left:20px;margin-top: 10px;">
                                    <div class="ant-col-24" >
                                      <a-divider orientation="left" style="color: #faad14;font-size: 16px;margin: 5px 0;"> 已经关联的费用类别 &nbsp;&nbsp;<MinusCircleTwoTone  title="减"/></a-divider>
                                      &lt;!&ndash; table start &ndash;&gt;
                                      <LTable ref="pstable" :page-size="pageParam.limit"
                                              :data-source="argument_reduce.dataSource" :columns="argument_reduce.tableColumns">

                                      </LTable>
                                      &lt;!&ndash; table end &ndash;&gt;
                                    </div>
                                  </div>


                                </div>-->
          </a-row>
          </div>
          <div v-else-if="flag == '2'">
            <a-divider orientation="left" style="margin: 5px 0">
              <span style="color: #096dd9;;font-size: 22px;">设置资产负债表期初余额 </span>
            </a-divider>

            <a-divider style="border-color: #7cb305" dashed />
            <a-tabs v-model:activeKey="activeKey" @change="check">
              <a-tab-pane key="3" tab="资产" >

                <!-- table start -->
                <LTable ref="pstable" :pagination="false"
                        :data-source="argument_zc.dataSource" :columns="argument_zc.tableColumns" >
                  <template v-slot:action="{record}">
                    <div style="justify-content: center;;display: flex;">
                      <a-input v-if="check_balance(record)" v-model:value="record.balance"  />
                      <span v-else>
                        {{record.balance}}
                      </span>
                    </div>
                  </template>

                </LTable>
                <!-- table end -->

              </a-tab-pane>
              <a-tab-pane key="4"  tab="负债">
                <!-- table start -->
                <LTable ref="pstable" :pagination="false"
                        :data-source="argument_fz.dataSource" :columns="argument_fz.tableColumns">

                  <template v-slot:action="{record}">
                    <div style="justify-content: center;;display: flex;">
                      <a-input v-if="check_balance(record)" v-model:value="record.balance"  />
                      <span v-else>
                        {{record.balance}}
                      </span>
                    </div>
                  </template>

                </LTable>
                <!-- table end -->
              </a-tab-pane>
              <a-tab-pane key="5" tab="权益">
                <!-- table start -->
                <LTable ref="pstable" :pagination="false"
                        :data-source="argument_qy.dataSource" :columns="argument_qy.tableColumns">

                  <template v-slot:action="{record}">
                    <div style="justify-content: center;;display: flex;">
                      <a-input v-if="check_balance(record)" v-model:value="record.balance"  />
                      <span v-else>
                        {{record.balance}}
                      </span>
                    </div>
                  </template>

                </LTable>
                <!-- table end -->
              </a-tab-pane>

              <template #tabBarExtraContent >
                <a-month-picker   @change="check" placeholder="请选择" style="width: 200px;margin-right: 20px;"
                                  v-model:value="year" :format="dateFormat" />

                <a-button type="primary" @click="submit_zcfz" style="margin-right: 20px;">保存</a-button>


                <a-popover title="试算平衡"  placement="left" trigger="click">
                  <template #content>
                    <div class="ant-row" style="position: absolute;width: 200px;">
                      <div class="ant-col-8" :style="zcAmount_style">{{zcAmount}}</div>
                      <div class="ant-col-8" :style="differAmount_style">{{differAmount}}</div>
                      <div class="ant-col-8" :style="fzqyAmount_style">{{fzqyAmount}}</div>
                    </div>
                    <div class="ant-row" style="margin-top: 20px;">
                      <a-image
                          :width="200"
                          :src="balanceImage"
                      />
                    </div>

                  </template>
                  <a-button type="primary" danger @click="calculate_balance" style="margin-right: 20px;">试算平衡</a-button>
                </a-popover>


              </template>


            </a-tabs>
          </div>
          <welcome v-else />
        </div>
      </div>
  </div>
</template>

<script>
import Welcome from "@/page/Other/Welcome";
/*import dayjs from "dayjs";*/
import { cloneDeep } from 'lodash-es';

import sujectTypeTree from "@/page/Finance/FinancialManager/financeSujectMange/point/sujectTypeTree";
import cashSubCategRel from "@/assets/api/cashSubCategRel";
import category from "@/assets/api/category";
import sujectRelated_item from "@/page/Finance/FinancialManager/financeSujectMange/config/sujectRelated_item";
import LTable from "@/components/table/LTable";
import Column from "@/assets/tables/column";
import ColumnType from "@/assets/tables/column_type";
import cashSubject from "@/assets/api/cashSubject";
import fixedCategory from "@/assets/api/fixedCategory";
import assetsBalance from "@/assets/api/assetsBalance";

export default {
  name: "financeSuject",
  components:{
    sujectTypeTree,LTable,Welcome
  },
  data(){
    return{
      flag:"0",// 0:空页面；1：关联费用类别页面；2：设置期初余额页面
      subjectId:null,
      sujectName:null,
      resource:'',
      operate:null,// 1:加；0：减

      sujectTypeValue:'1',
      sujectTypeOption:[],
      /** 现金流量、利润***/
      sujectTypeOption1:[
        {id: '1',name: '项目收款',disabled:false},
        {id: '2',name: '其他收入',disabled:false},
        {id: '3',name: '其他支出',disabled:false},
        {id: '4',name: '外协支付',disabled:false},
        {id: '5',name: '员工报账',disabled:false},
        {id: '6',name: '财务科目',disabled:false},
      ],
      /*** 资产负债表 ***/
      sujectTypeOption2:[
        {id: '1',name: '项目收款',disabled:false},
        {id: '2',name: '其他收入',disabled:false},
        {id: '3',name: '其他支出',disabled:false},
        {id: '4',name: '外协支付',disabled:false},
        {id: '5',name: '员工报账',disabled:false},
        {id: '6',name: '财务科目',disabled:false},
        {id: '7',name: '合同',disabled:false},
        {id: '8',name: '销项发票',disabled:false},
        {id: '9',name: '进项发票',disabled:false},
        {id: '10',name: '固定资产',disabled:false},
      ],

      /** 关联费用类别 **/
      treeData:[],
      checkedKeys:[],
      expandedKeys:[],// 展开选中的父节点
      itemCollectionData:[
        {label:"所有项目收款",id : "1",},
        {label:"有合同项目收款",id : "2",},
        {label:"无合同项目收款",id : "3",},
      ],
      itemPayData:[
        {label:"所有外协付款",id : "1",},
        {label:"有合同外协付款",id : "2",},
        {label:"无合同外协付款",id : "3",},
      ],
      // 合同
      htData:[
        {label:"甲方合同金额",id : "1",},
        {label:"外协合同金额",id : "2",},
      ],
      // 销项发票
      xxfp:[
        {label:"发票总金额",id : "1",},
        {label:"发票已收款额",id : "2",},
        {label:"发票未收款额",id : "3",},
      ],
      // 进项发票
      jxfp:[
        {label:"发票总金额",id : "1",},
        {label:"发票已付款额",id : "2",},
        {label:"发票未付款额",id : "3",},
      ],
      costData:[],// 费用类别
      submit_checkedKey:[],// 提交关联费用类别，去除第一级
      sujectData:[],// 科目
      treeFlag:false,
      fixedAssetData:[],// 固定资产

      checkStrictly:true,// checkable 状态下节点选择完全受控（父子节点选中状态不再关联）

      /** 已经关联的费用类别 ***/
      pageParam:{
        page: 1,
        limit: 10,
      },
      /** 现金流量表**/
      argument_add:{
        dataSource:[],
        tableColumns:new sujectRelated_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
      },
      argument_reduce:{
        dataSource:[],
        tableColumns:new sujectRelated_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
      },

      /***** 资产负债表期初余额 start ******/
      activeKey:'3',
      tableColumns:[],
      year:null,
      dateFormat: 'YYYY',
      /** 资产 **/
      argument_zc:{
        dataSource:[],
        tableColumns:null,
        loading:false,
      },

      /** 负债 **/
      argument_fz:{
        dataSource:[],
        tableColumns:null,
        loading:false,
      },

      /** 权益 **/
      argument_qy:{
        dataSource:[],
        tableColumns:null,
        loading:false,
      },

      // 编辑
      editingKey: '',
      editableData:{},

      zcAmount:0,//资产期初总额
      fzqyAmount:0,//负债+权益 ：期初总额
      differAmount:0, //差额：大减去小
      balanceImage:require('./images/资产负债_试算平衡.png'),// 试算平衡
      zcAmount_style:"text-align: center",
      differAmount_style:"text-align: center;margin-top: 50px;",
      fzqyAmount_style:"text-align: center",
      popover_visible:false,
      /***** 资产负债表期初余额 end  ******/

        url_add:require('@/assets/image/bussess/增加.png'),
        url_add2:require('@/assets/image/bussess/增加2.png'),
        url_minus:require('@/assets/image/bussess/删减.png'),
        url_minus2:require('@/assets/image/bussess/删减2.png'),

    }
  },
  mounted() {
    // 科目树
    this.$refs.typeTree.setTreeData();
    var date = new Date();
    this.year = date.getFullYear().toString();
    this.init();
    this.argument_add.tableColumns[0].tableView = this.argument_add.tableColumns[0].dataIndex == "_index" ? false : this.argument_add.tableColumns[0].tableView;
    this.argument_reduce.tableColumns[0].tableView = this.argument_reduce.tableColumns[0].dataIndex == "_index" ? false : this.argument_reduce.tableColumns[0].tableView;
  },
  methods:{
    init(){
      this.tableColumns = [
        new Column("主键", "id", ColumnType.String, false).setVisible(false),
        new Column("级别", "level", ColumnType.String, true),
          new Column("科目名称", "subjectName", ColumnType.String, true),
        /*new Column("科目名称", "subjectName", ColumnType.String2, true).setTableView(150).setFixed("left")
            .setCustomCell((record,index) => {
              let subjectName = record.subjectName;
              if(subjectName == "流动资产：" || subjectName == "非流动资产：" || subjectName == "资产总计" ||
                  subjectName == "流动负债：" ||subjectName == "非流动负债：" ||subjectName == "负债合计"

                  ||subjectName == "所有者权益（或股东权益)："||subjectName == "所有者权益（或股东权益)合计"||subjectName == "负债和所有者权益(或股东权益)总计"){
                return {
                  style: {
                    //可以定义样式
                    color: '#faad14',
                    'text-align': 'left',
                    'font-size': '18px',
                  },
                };
              }else{
                return {
                  style: {
                    //可以定义样式
                    'padding-left': '30px',
                    'font-size': '14px',
                  },
                };
              }
            }),*/
        new Column("期初数据", "balance", ColumnType.Number, true),
        new Column("期初数据", "actions", "actions", false).setTableView(100)
      ];
      this.argument_zc.tableColumns = this.tableColumns;
      this.argument_fz.tableColumns = this.tableColumns;
      this.argument_qy.tableColumns = this.tableColumns;
      this.checkedKeys = [];
      this.expandedKeys = [];
      this.treeData = [];
      /** 查询费用类别 **/
      category.queryOneLevel.post(null,res=>{
        if(res.data.flag){
          let data = res.data.data
          /**结果封装成树**/
          this.costData = this.convertToTreedatas(data);
        }
        else{
          this.$message.error(res.data.msg);
        }
      })

      /** 查询固定资产类别 **/
      fixedCategory.queryAll.post(null,res=>{
        if(res.data.flag){
          let data = res.data.data
          /**结果封装成树**/
          this.fixedAssetData = this.convertToTreedatas(data);
        }
        else{
          this.$message.error(res.data.msg);
        }
      })
    },
    eventHandle({type,arg}){
      switch (type){
        case "refresh":{
          this.subjectId = null;
          this.sujectName = null;
          this.flag = "0";
          this.treeData = [];
          this.checkedKeys = [];
          this.expandedKeys = [];
          break;
        }
        case "relevancy":{
          this.sujectTypeOption = [];
          let type = this.$refs.typeTree.get_sujectType();
          if(type == '1' || type == '2'){
            this.sujectTypeOption = this.sujectTypeOption1;
          }else {
            this.sujectTypeOption = this.sujectTypeOption2;
          }

          this.subjectId = arg.id;
          this.sujectName = arg.label;
          this.flag = "1";
          this.operate = "1";
          this.init_relevancy();
          break;
        }
        case "periodBalance":{
          this.flag = "2";
          this.check();
          break;
        }
      }
    },
    /**
     * 查询科目关联情况：
     * 如果关联有类别，禁用sujectTypeOption：1、2、3、4、5；
     * 如果关联科目：禁用sujectTypeOption：6；
     * 如果都没有关联，不禁用
     * **/
    init_relevancy(){
      this.argument_reduce.dataSource =[];
      this.argument_add.dataSource =[];
      let parms ={
        subjectId:this.subjectId,
      };
      cashSubCategRel.queryAll.post(parms,res=>{
        if(res.data.flag){
          let data = res.data.data;
          if(data != null && data.length > 0){
            let info = data[0];
            // 判断是否禁用费用类别大类
            if(info.type == '1' ||info.type == '2' ||info.type == '3'
                ||info.type == '4' ||info.type == '5' ||info.type == '7'
                ||info.type == '8' ||info.type == '9'||info.type == '10'){
              this.sujectType_disabled("2");
              this.sujectTypeValue = "1";
            }else if(info.type == '6'){
              this.sujectType_disabled("1");
              this.sujectTypeValue = "6";
            }
            // 已关联的费用类别拆分
            for(let i=0;i<data.length;i++){
              let i_info = data[i];
              let operate = i_info.operate;
              if(operate == '0'){// 减
                this.argument_reduce.dataSource.push(i_info);
              }else {// 加
                this.argument_add.dataSource.push(i_info);
              }
            }
          }
        }else{
          this.sujectTypeValue = "1";
          this.sujectType_disabled("0");
        }
        this.sujectTypeChange();
      });
    },
    /**
     * 判断sujectTypeOption类别是否禁用
     * 0:都不禁用
     * 1，禁用sujectTypeOption：1、2、3、4、5、7、8、9；
     * 2：禁用sujectTypeOption：6；
     * **/
    sujectType_disabled(type){
      let dataList = this.sujectTypeOption;
      if(dataList.length > 0){
        dataList.forEach((item)=>{
          if(type == '0'){
            item.disabled = false;
          }else if(type == '1'){
            if(item.name == '财务科目'){
              item.disabled = false;
            }else{
              item.disabled = true;
            }
          }else if(type == '2'){
            if(item.name == '财务科目'){
              item.disabled = true;
            }else{
              item.disabled = false;
            }
          }
        })
      }
    },
    /**
     * 大类别单选事件
     */
    sujectTypeChange(){
      this.treeFlag = false;
      this.treeData = [];
      this.checkedKeys = [];
      this.expandedKeys = [];
      let type = this.sujectTypeValue;
      switch (type){
        case "1":{
          this.treeData = this.itemCollectionData;
          this.checkStrictly = false;
          break;
        }
        case "2":{
          this.treeData = this.costData;
          this.checkStrictly = false;
          break;
        }
        case "3":{
          this.treeData = this.costData;
          this.checkStrictly = false;
          break;
        }
        case "4":{
          this.treeData = this.itemPayData;
          this.checkStrictly = false;
          break;
        }
        case "5":{
          this.treeData = this.costData;
          this.checkStrictly = false;
          break;
        }
        case "6":{
          this.treeData = this.$refs.typeTree.getTreeData();
          this.checkStrictly = true;
          break;
        }
        case "7":{
          this.treeData = this.htData;
          this.checkStrictly = false;
          break;
        }
        case "8":{
          this.treeData = this.xxfp;
          this.checkStrictly = false;
          break;
        }
        case "9":{
          this.treeData = this.jxfp;
          this.checkStrictly = false;
          break;
        }
        case "10":{
          this.treeData = this.fixedAssetData;
          this.checkStrictly = false;
          break;
        }
      }
      this.CashSubCategRel();
    },
    /** 查询已经关联的费用类别 **/
    CashSubCategRel(){
      let parms ={
        subjectId:this.subjectId,
        type:this.sujectTypeValue,
        operate:this.operate,
      };
      cashSubCategRel.queryAll.post(parms,res=>{
        if(res.data.flag){
          let data = res.data.data;
          if(data != null && data.length > 0 ){
            for(let i=0;i<data.length;i++){
              let id = data[i].categoryId;
              this.checkedKeys.push(id);

              // this.CashSubCategRel_data(id,tree_data);
            }
          }
        }
        //this.$forceUpdate()
        // 延时渲染树
        setTimeout(() => {
          this.treeFlag = true;
          this.expandedKeys = this.checkedKeys;
        }, 200)

      });
    },
    CashSubCategRel_data(id,tree_data){
      if(tree_data != null && tree_data.length > 0){
        for(let j=0;j<tree_data.length;j++){
          let children = tree_data[j].children;
          //判断是否存在子节点，如果存在则递归
          if(children != null && children.length > 0){
            this.CashSubCategRel_data(id,children);
          }else{
            let key = tree_data[j].key;
            if( id === key){
              this.checkedKeys.push(id);
              return;
            }
          }
        }
      }
    },
    handleCheckMenu(checkedKeys){
      let type = this.sujectTypeValue;
      let checked = [];
      if(type == '6'){
        checked = checkedKeys.checked;
      }else{
        checked = checkedKeys;
      }
      this.checkedKeys = checked;
    },
    //展开，
    handleExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
    },
      remove_add(arg){
          this.argument_add.dataSource = this.argument_add.dataSource.filter((item) => {
              return item.id !== arg.id
          })
          let ids_l = [];
          this.argument_add.dataSource.forEach((item)=>{
              if(item.id != arg.id){
                  ids_l.push(item.id);
              }
          })
          let ids = ids_l != null && ids_l.length > 0 ?  ids_l.join(",") : '';
          let data = {
              subjectId:this.subjectId,
              type:this.sujectTypeValue,
              operate:'0',
              categoryIds:ids,
          }
          cashSubCategRel.bindCategorys.urlPost(data,res=>{
              if(res.data.flag){
                  this.$message.success(res.data.msg);
                  this.init_relevancy();
              }
              else{
                  this.$message.error(res.data.msg);
              }
          });
      },
    submit(){
      this.submit_checkedKey = [];
      let checked_key = this.checkedKeys;
     /* if(checked_key == null || checked_key.length == 0){
        this.$message.error("请选择要授权的费用类别");
        return;
      }*/
      if(this.checkStrictly){
        this.submit_checkedKey = checked_key;
      }else{
        if(checked_key != null || checked_key.length > 0){
          for(let i=0;i<checked_key.length;i++){
            let key = checked_key[i];
            let tree_data = this.treeData;
            this.submit_key(key,tree_data);
          }
        }
      }

      let data = {
        subjectId:this.subjectId,
        type:this.sujectTypeValue,
        operate:this.operate,
        categoryIds:this.submit_checkedKey,
      }
      cashSubCategRel.bindCategorys.urlPost(data,res=>{
        if(res.data.flag){
          this.$message.success(res.data.msg);
          this.init_relevancy();
        }
        else{
          this.$message.error(res.data.msg);
        }
      });
    },
    submit_key(key,tree_data){
      if(tree_data != null && tree_data.length > 0){
        for(let j=0;j<tree_data.length;j++){
          let id = tree_data[j].id;
          let children = tree_data[j].children;
          if( key === id){
            //判断是否存在子节点，如果存在则递归
            if(children == null || children.length == 0){
              this.submit_checkedKey.push(key);
            }
            return;
          }else if(children != null && children.length > 0){
            this.submit_key(key,children);
          }
        }
      }
    },

    /****** 资产负债表 start *******/
    check() {
      this.argument_zc.dataSource = [];
      this.argument_fz.dataSource = [];
      this.argument_qy.dataSource = [];
      // this.year = this.year != null ? dayjs(this.year).format(this.dateFormat) : null;
        this.year = this.year != null ? this.year : null;
      if(this.year != null){
        let parms = {
          year:this.year,
          type:this.activeKey,
        };
        cashSubject.queryAllBalance.urlPost(parms,res=>{
          if(res.data.flag){
            let data = res.data.data;
            if(data.length > 0){
              let list = [];
              data.forEach((item)=>{
                  list.push(item);
                  let children = item.childrens;
                  if(children.length > 0){
                    children.forEach((item_chil)=>{
                      list.push(item_chil);
                    })
                  }
              })
              if(this.activeKey == '3'){
                this.argument_zc.dataSource = list;
                let zcDataList = this.argument_zc.dataSource;
                zcDataList.forEach((item)=>{
                  this.editableData[item.id] = cloneDeep(item);
                })
              }else if(this.activeKey == '4'){
                this.argument_fz.dataSource = list;
              }else{
                this.argument_qy.dataSource = list;
              }
            }
          }
          else{
            this.$message.error(res.data.msg)
          }
        })
      }

    },
    /** 保存资产负债余额设置 **/
    submit_zcfz(){
      let type = this.activeKey;
      let balanceVOs = [];
      let dataList = [];
      switch (type){
        case "3":{
          dataList = this.argument_zc.dataSource;
          break;
        }
        case "4":{
          dataList = this.argument_fz.dataSource;
          break;
        }
        case "5":{
          dataList = this.argument_qy.dataSource;
          break;
        }
      }

      if(dataList != null && dataList.length > 0){
        dataList.forEach((item)=>{

          let balance = item.balance;
          if(item.level != '1' && (item.totalSign == null || item.totalSign != '1')){
            if(balance != null){
              balanceVOs.push({
                balance:balance,
                subjectId:item.id,
                year:this.year,
              })
            }
          }
        })
      }
      let parms ={
        balanceVOs:balanceVOs
      };
      assetsBalance.batchSave.post(parms,res=>{
        if(res.data.flag){
          this.$message.success(res.data.msg);
          this.check();
        }
        else{
          this.$message.error(res.data.msg);
        }
      });

    },
    /** 判断是否需要手动设置期初余额 **/
    check_balance(record){
      let flag = false;
      if(record.level == '1'){
        flag = false;
      }else {
        if(record.totalSign == '1'){
          flag = false;
        }else{
          flag = true;
        }
      }
      return flag;

    },
    /** 试算平衡 ***/
    calculate_balance(){
      let parms = {
        year:this.year
      };
      assetsBalance.calBalance.urlPost(parms,res=>{
        if(res.data.flag){
          this.zcAmount = res.data.data.zcAmount;
          this.fzqyAmount = res.data.data.fzqyAmount;
          this.differAmount = res.data.data.differAmount;
          if(this.zcAmount == this.fzqyAmount){
              this.balanceImage = require('./images/资产负债_试算平衡.png');
              this.zcAmount_style = "text-align: center";
              this.differAmount_style = "text-align: center;margin-top: 50px;";
              this.fzqyAmount_style = "text-align: center";
          }else if(this.zcAmount > this.fzqyAmount){
            this.balanceImage = require('./images/资产负债_试算平衡2.png');
            this.zcAmount_style = "text-align: center";
            this.differAmount_style = "text-align: center;margin-top: 60px;";
            this.fzqyAmount_style = "text-align: center;margin-top: 25px;";
          }else {
            this.balanceImage = require('./images/资产负债_试算平衡3.png');
            this.zcAmount_style = "text-align: center;margin-top: 35px;";
            this.differAmount_style = "text-align: center;margin-top: 70px;";
            this.fzqyAmount_style = "text-align: center;";
            this.differAmount = "-"+this.differAmount
          }
        }
        else{
          this.$message.error(res.data.msg);
        }
      });
    },
    /****** 资产负债表 end *******/

    /**
     * 多级
     * 树选择框,构造转换，转换后端数据为树形选择需要的数据
     * @param data  后端数据
     * @returns {[]}  返回结构
     */
    convertToTreedatas(data){
      var returnData = [];
      //遍历数据
      for(var i = 0; i < data.length; i++){
        var tempObj = {
          label : data[i].categoryName,
          id : data[i].id,
        };

        //判断是否存在子节点，如果存在则递归
        if(data[i].children != null && data[i].children.length > 0){
          tempObj.children = this.convertToTreedatas(data[i].children);
        }
        //push到数据数组中
        returnData.push(tempObj);
      }
      return returnData;
    },
    handleChange(visible){
      if(!visible){
        this.sujectTypeChange();
      }
    },
    /**** 期初余额编辑 start ***/
    edit(){
      //this.editableData[key] = cloneDeep(this.argument_zc.dataSource.filter(item => item.id === key)[0]);
      this.editableData = this.argument_zc.dataSource;
    },
     save(key){
     /* Object.assign(this.argument_zc.dataSource.filter(item => id === item.id)[0], this.editableData[key]);*/
      delete this.editableData[key];
    },
    cancel(key){
      delete this.editableData[key];
    },
    /**** 期初余额编辑 end ***/


  },

}
</script>

<style scoped>
.loading{
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display:flex;
  justify-content:center;
  align-items:center;
}
.zj-title{
    font-size: 16px;
    font-weight: bold;
    color: #6A7992;
    text-align: center;
}
.zj-lable{
    font-size: 16px;
    font-weight: 400;
    color: #6A7992;
    text-align: center;
}
/deep/.ant-tabs-nav-wrap{
    left: 10px;
}
</style>
