<!--
* @program: office_automation
* @author: ly
* @component:FromOtherBank
* @description: 其他账户转入的情况
* @create: 2022-02-22 10:02
-->
<template>
    <div class="column-flex">
        <div style="display: flex;align-items: center">
            <a-button @click="init" type="dashed">
                <template #icon><SyncOutlined/></template>
            </a-button>
            <span class="form-value">合计：{{summary.toFixed(2)}}元</span>
            <a-divider style="height:32px;border-left: #022d5c solid;" type="vertical"/>
            <a-range-picker style="width: 200px" v-model:value="rangeMonth" picker="month" />
            <a-input  placeholder="支付账号" style="width: 150px" v-model:value="filter.payBankName" />
            <a-input  placeholder="收款账号" style="width: 150px" v-model:value="filter.receiveBankName" />
        </div>
        <div style="height:600px;overflow:auto">
            <LTable :columns="tableColumns" :data-source="source" />
        </div>
    </div>
</template>

<script>
    import otherPay from "@/assets/api/otherPay";
    import Column from "@/assets/tables/column";
    import ColumnType from "@/assets/tables/column_type";
    import LTable from "@/components/table/LTable";
    import {SyncOutlined} from "@ant-design/icons-vue";
    import dayjs from "dayjs";
    import {ArrayFilter} from "../../../../assets/utils/general";
    export default {
        name: "FromOtherBank",
        props:["record","value"],
        components: {
            LTable,SyncOutlined
        },
        computed:{
            source(){
                let source = [];
                if(this.rangeMonth){
                    source = this.dataSource.filter(item=>{
                        let payDate = dayjs(item.payTime);
                        const startOfMonth = this.rangeMonth[0].startOf('month');
                        const endOfMonth = this.rangeMonth[1].endOf('month');
                        return payDate.isAfter(startOfMonth) && payDate.isBefore(endOfMonth);
                    });
                    return ArrayFilter(source,this.filter);
                }
                else{
                    return ArrayFilter(this.dataSource,this.filter);
                }
            },
            summary(){
                let sum=0;
                for(let i in this.source){
                    let date = this.source[i].payDate;
                    if(dayjs(date).isBefore(dayjs(this.record.balanceDate))){
                        continue
                    }
                    sum += this.source[i].payAmount;
                }
                return sum;
            }
        },
        watch:{
            record(){
                this.init()
            }
        },
        data(){
            return{
                dataSource:null,
                tableColumns:null,
                loading:false,
                rangeMonth:undefined,
                filter:{}
            }
        },
        methods:{
            init(){
                this.visible = false;
                this.loading = true;
                this.dataSource = [];
                let data = {
                    "approvalState": "1",
                    "receiveBankId": this.record.id,
                };
                otherPay.queryAll.post(data,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                        this.$emit("update:value",{value:this.summary,loading:false});
                    }
                    else{
                        this.$emit("update:value",{value:0,loading:false});
                    }
                })
            },
        },
        created() {
            this.tableColumns = [
                new Column("支付类型","type",ColumnType.Enum).setTableView(100)
                    .setDictEnum("item_other_pay.type"),
                new Column("费用类别","categoryId",ColumnType.Category,true).setTableView(100),
                new Column("支付金额","payAmount",ColumnType.Number,true).setDefaultValue(0).setTableView(100),
                new Column("支付日期","payDate",ColumnType.Date,true).setTableView(100),
                new Column("支付账户","payBankName",ColumnType.String).setTableView(100),
                new Column("收款账户","receiveBankName",ColumnType.String,false).setTableView(100),
                new Column("支付说明","remarks",ColumnType.String,false).setTableView(200)
            ];
            this.init();
        }
    }
</script>

<style scoped>

</style>
