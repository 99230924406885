<!--
* @program: office_automation2.0 
* @author: ly
* @component:LForm 
* @description: 基础Form
* @create: 2022-11-07 10:39
-->
<template>
    <div style="margin: 5px">
        <div class="form-title" v-if="title">{{title}}</div>
        <a-form :model="lfd" layout="horizontal" size="small">
            <a-row :key="index" v-for="(group,index) in groupCols">
                <a-col :key="idx" :span="column.formColSpan" v-for="(column,idx) in group">
                    <a-form-item
                                 :labelCol="column.labelCol"
                                 :name="column.dataIndex"
                                 :rules="viewModel?[]:column.rules"
                                 :wrapperCol="column.wrapperCol"
                                 style="display: flex;align-items: flex-start;">
                        <template #label>
                            <span class="form-label">{{column.title}}</span>
                        </template>
                        <DataPresent class="form-value"  :column="column" :record="lfd" :view-model="viewModel"
                                     @trigger="handleTrigger" @update="handleUpdate" />
                    </a-form-item>
                </a-col>
            </a-row>
        </a-form>
        <slot :columns="cols" :record="lfd" name="action" v-if="!viewModel"/>
    </div>
</template>

<script>
    import DataPresent from "../dataPresentType/DataPresent";
    import {objectsAreEqual} from "../../assets/utils/general";
    /**
     * Ant Design Vue 将整个设计建议区域按照 24 等分的原则进行划分。
     * 划分之后的信息区块我们称之为『盒子』。建议横向排列的盒子数量最多四个，最少一个。
     * @type {number}
     */
    const GridSize = 24;
    export default {
        name: "LForm",
        provide: {
            holder: "form",
        },
        components: {
            DataPresent
        },
        props: {
            formColumns: {
                required: true
            }, //提供Form的字段集 this.table.getFormColumns()
            formData: {
                required: false,
                default: {}
            },//提供Form的formData
            viewModel: {
                //外部是否允许编辑的开关，控件的编辑状态由viewModel直接控制
                required: false,
                default: true
            },
            title: {
                required: false,
                default: null
            },
            /**
             * 每行显示的col数
             * rowSize 与 column内的formColSpan冲突
             */
            rowSize: {
                required: false,
                default: 1
            },
            /**
             * label所占24盒子的份数
             */
            labelSpan: {
                required: false,
                default: 6
            }
        },
        emits: ["update:formData","change"],
        computed: {
            cols() {
                if (this.formColumns === null) {
                    return [];
                }
                if (!this.viewModel) {
                    return this.formColumns.filter(col => {
                        return col.editable === true && col.visible;
                    });
                } else {
                    return this.formColumns.filter(col => col.visible);
                }
            },
            groupCols() {
                let groups = [];
                if (this.rowSize > 1) {
                    for (let col of this.cols) {
                        col.formColSpan = GridSize / this.rowSize;
                        col.labelCol = {span: this.labelSpan};
                        col.wrapperCol = {span: GridSize - this.labelSpan};
                        let lastElement = groups[groups.length - 1];
                        if (lastElement && lastElement.length < this.rowSize) {
                            lastElement.push(col)
                        } else {
                            groups.push([col]);
                        }
                    }
                }
                else {
                    let maxSize = 1;
                    for (let col of this.cols) {
                        let lastElement = groups[groups.length - 1];
                        if (lastElement) {
                            let formColSpan = 0;
                            for (let item of lastElement) {
                                formColSpan += item.formColSpan;
                            }
                            if (formColSpan + col.formColSpan <= GridSize) {
                                lastElement.push(col)
                            } else {
                                maxSize = lastElement.length > maxSize ? lastElement.length : maxSize;
                                groups.push([col]);
                            }
                        } else {
                            groups.push([col]);
                        }
                    }
                }
                //判断是否为一行一个col的类型,每行col数中最大的mutiRowSize
                let mutiRowSize = 1;
                for (let group of groups) {
                    mutiRowSize = group.length > mutiRowSize ? group.length : mutiRowSize;
                }
                for (let group of groups) {
                    for (let item of group) {
                        if(mutiRowSize > 1){
                            item.labelCol = {span: this.labelSpan * mutiRowSize};
                            item.wrapperCol = {span: GridSize - this.labelSpan * mutiRowSize};
                        }
                        else{
                            item.labelCol = {span: this.labelSpan};
                            item.wrapperCol = {span: 24 - this.labelSpan};
                        }
                    }
                }
                return groups
            }
        },
        watch: {
            formData() {
                this.init()
            },
            formColumns(){
                this.init()
            }
        },
        data() {
            return {
                lfd: null,
            }
        },
        methods: {
            init() {
                this.lfd = {...this.formData};
            },
            handleUpdate() {
                if(!objectsAreEqual(this.formData,this.lfd)){
                    this.$emit("update:formData", this.lfd);
                    this.$emit("change", this.lfd);
                }
            },
            handleTrigger(action) {
                if (action) {
                    action(this.lfd, this.formColumns);
                }
            },
            handleClick(record,column){
                this.$emit("click",record,column)
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>