<!--
* @program: TYHOA2 
* @author: ly
* @component:QCWorkTask 
* @description: 
* @create: 2024-09-27 11:08
-->
<template>
    <div class="column-flex" style="position: relative;overflow: hidden">
        <div class="search-bar">
            <a-button @click="refresh" type="dashed">
                <template #icon>
                    <SyncOutlined/>
                </template>
            </a-button>
            <div style="margin: 10px;color: black">部门:</div>
            <a-select v-model:value="defaultQuery.departmentId"
                      style="width: 200px"
                      @change="init">
                <a-select-option v-for="department in myDepartments" :key="department.id">
                    {{ department.name }}
                </a-select-option>
            </a-select>
            <a-segmented @change="doSearch" v-model:value="state" :options="states" />
            <div style="margin:0 5px">名称：</div>
            <a-input @change="doSearch" placeholder="项目名称" style="width: 150px" v-model:value="filter.itemName" />
        </div>
        <div style="width: 100%;height: 100%;">
            <LTable :columns="taskColumns" :data-source="dataSource" :loading="loading">
                <template #expandedRow="{children}">
                    <a-row>
                        <a-col :span="8">
                            <div class="form-label" style="margin-right: 10px">已分配子任务，分配部门：</div>
                        </a-col>
                        <a-col :span="16">
                            <div class="form-value" >
                                <span style="margin-left:10px " v-for="child in children" :key="child.id">{{child.departmentName}}</span>
                            </div>
                        </a-col>
                    </a-row>
                </template>
                <template #action="{record}">
                    <div class="action-column">
                        <a-button @click="showDetail(record)" class="action-button" type="link">
                            <SearchOutlined class="pointer"/>
                        </a-button>
                        <a-tooltip v-if="!record.state" title="提交任务完成">
                            <a-button  class="action-button" type="link" @click="finishTask(record)">
                                <CheckOutlined  class="pointer"/>
                            </a-button>
                        </a-tooltip>
                        <a-tooltip v-if="!record.state" title="任务延期申请">
                            <a-button  class="action-button" type="link" @click="delayApply(record)">
                                <FallOutlined  style="color: red" class="pointer"/>
                            </a-button>
                        </a-tooltip>
                    </div>
                </template>
            </LTable>
        </div>
        <a-drawer :closable="false"
                  :get-container="false"
                  :style="{ position: 'absolute' }"
                  @close="visible = false"
                  destroy-on-close
                  height="90%"
                  placement="bottom"
                  v-model:open="visible">
            <a-tabs style="height: 100%" tabPosition="left" v-model:activeKey="activeKey">
                <a-tab-pane style="height: 100%;overflow: auto" key="1" tab="项目信息">
                    <ProjectInfoView />
                </a-tab-pane>
                <a-tab-pane style="height: 100%;overflow: auto" key="2" tab="二检申请">
                    <LForm title="二检申请" :form-columns="formColumns" :form-data="currentRecord" :view-model="true"/>
                </a-tab-pane>
                <a-tab-pane  style="height: 100%;overflow: auto" key="3" tab="质检工序安排">
                    <QCTaskProcess :departmentId="defaultQuery.departmentId" :apply="currentRecord" :view-model="vModel"/>
                </a-tab-pane>
                <a-tab-pane  style="height: 100%;overflow: auto" key="4" tab="质检报告">
                    <QCReport @finish="currentRecord.state ='1'" :apply="currentRecord"  :view-model="vModel"/>
                </a-tab-pane>
            </a-tabs>
        </a-drawer>
    </div>
</template>

<script>
    import {CheckOutlined, FallOutlined, SearchOutlined, SyncOutlined} from "@ant-design/icons-vue";
    import LTable from "../../../../components/table/LTable";
    import ProjectInfoView from "../../../WorkAdministration/ProjectManager/ProjectInfoView";
    import {computed} from "vue";
    import dayjs from "dayjs";
    import {ArrayFilter} from "../../../../assets/utils/general";
    import Column from "@/assets/tables/column";
    import ColumnType from "@/assets/tables/column_type"
    import qcApply from "../../../../assets/api/qcApply";
    import QCTaskProcess from "./QCTaskProcess";
    import LForm from "../../../../components/form/LForm";
    import item_qc_apply from "../../../TableStruct/item_qc_apply";
    import QCReport from "./QCReport";
    export default {
        name: "QCWorkTask",
        components: {
            SearchOutlined, SyncOutlined,
            CheckOutlined,FallOutlined,
            QCTaskProcess,LForm,LTable,ProjectInfoView,QCReport
        },
        provide() {
            return {
                pTaskId: computed(() => this.currentRecord.id),
                pProjectId:computed(() => this.currentRecord.itemId),
            }
        },
        data() {
            return {
                taskList: [],//
                taskColumns: [
                    new Column("项目","itemName",ColumnType.String,false).setTableView(300),
                    new Column("申请说明","content",ColumnType.String,true).setTableView(150),
                    new Column("质检内容","proContent",ColumnType.String,true).setTableView(150),
                    new Column("质检完成期限","planCommitDate",ColumnType.Date,true).setTableView(100),
                    new Column("申请部门","departmentName",ColumnType.String,false).setTableView(100),
                    new Column("申请人","applyUserName",ColumnType.String,false).setTableView(80),
                    new Column("剩余时间", "deadLine", ColumnType.ColorText).setTableView(80),
                    new Column("完成状态","state",ColumnType.String)
                        .setDictEnum("state"),
                    new Column("备注", "remarks", ColumnType.String).setTableView(80),
                    new Column("操作", "actions", "actions", false).setTableView(100)
                ],
                formColumns:new item_qc_apply().getFormColumns(),
                searchData: null,
                currentRecord: {},
                loading: false,
                defaultQuery: {
                    qcLeaderUser:null,
                    auditState:"1",
                },
                filter:{},
                condition:"1",
                visible: false,
                visible2: false,
                visible3: false,
                visible4: false,
                dataSource: [],
                columns: [],
                activeKey:"1",
                vModel:true,
                state:"0",
                states:[
                    {value:"0",label:"未完成"},
                    {value:"1",label:"已完成"},
                    {value:"2",label:"全部"},
                ],
                taskDate:null,
                user:null,
                myDepartments:[]
            }
        },
        methods: {
            init() {
                this.taskList = [];
                this.loading = true;
                let today = dayjs();
                qcApply.queryAll.post(this.defaultQuery,res=>{
                    this.loading = false;
                    if (res.data.flag) {
                        this.taskList = res.data.data.sort((a, b) => {
                            return b.createTime- a.createTime;
                        });
                        this.taskList = this.taskList.map(item=>{
                            if(item.planCommitDate && item.state === '0'){
                                let endDate = dayjs(item.planCommitDate)
                                let num = endDate.diff(today, 'day');
                                if(num>0){
                                    item.deadLine = {text:`剩余 ${num} 天`,color:"#00338b"}
                                }
                                else{
                                    item.deadLine = {text:`已超时 ${0-num} 天`,color:"#c64634"}
                                }
                            }
                            return item
                        });
                        this.doSearch()
                    }
                })
            },
            doSearch() {
                let list = []
                switch (this.state) {
                    case "0":{
                        this.vModel = false;
                        list = this.taskList.filter(item => item.state === "0");
                        break;
                    }
                    case "1":{
                        this.vModel = true;
                        list = this.taskList.filter(item => item.state === "1");
                        break;
                    }
                    case "2":{
                        this.vModel = true;
                        list = this.taskList;
                        break
                    }
                }
                this.dataSource = ArrayFilter(list,this.filter);
            },
            refresh() {
                this.init();
            },
            showDetail(record) {
                this.currentRecord = record;
                this.visible = true;
            },
            finishTask(record){
                this.currentRecord = record;
                this.visible2 = true;
            },
            delayApply(record){
                this.currentRecord = record;
                this.visible3 = true;
            },
            saveData(){
                /** 以当前时间为完成时间
                 let time = localStorage.getItem("time");
                 record.completeDate = parseInt(time);
                 */
                this.currentRecord.completeDate = this.taskDate.valueOf();
                qcApply.saveOrUpd.post(this.currentRecord,res=>{
                    if(res.data.flag){
                        this.visible2 = false;
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            }
        },
        async created() {
            this.defaultQuery.qcLeaderUser = await this.$getItem("__myID");
            this.myDepartments = JSON.parse(await this.$getItem("__myDepartments"));
            this.defaultQuery.departmentId = this.myDepartments[0].id;
            this.init();
        }
    }
</script>

<style scoped>

</style>