<!--
* @program: TYHOA2 
* @author: ly
* @component:UserMonthMoney 
* @description: 
* @create: 2024-08-30 17:23
-->
<template>
    <a-spin :spinning="spinning">
        <a-calendar v-model:value="calendarValue" v-if="param.id"  mode="year"  @select="showList">
            <template #monthCellRender="{ current: value }">
                <a-badge v-if="getMonthMoney(value)" status="success" :text="getMonthMoney(value)" />
            </template>
        </a-calendar>
    </a-spin>
    <a-modal v-model:open="visible"
              width="1000px"
              :footer="null"
              title="绩效分配详情">
        <LTable :data-source="dataSource" :columns="tableColumns"/>
    </a-modal>
</template>

<script>
    import taskMonthUser from "../../../../assets/api/taskMonthUser";
    import LTable from "../../../../components/table/LTable";
    import Column from "../../../../assets/tables/column";
    import ColumnType from "../../../../assets/tables/column_type";
    export default {
        name: "UserMonthMoney",
        props:["param"],
        components:{
            LTable
        },
        watch:{
            param(){
                this.init()
            }
        },
        data() {
            return {
                calendarValue:null,
                dateFormat: "YYYY-MM-DD",
                monthFormat: "YYYY-MM",

                dataSource:null,
                tableColumns:null,
                loading:false,

                allRecords:[],
                visible:false,
                spinning:false,
            }
        },
        methods: {
            init() {
                let data = {
                    userId:this.param.id
                };
                this.spinning = true;
                taskMonthUser.queryAll.post(data,res=>{
                    this.spinning = false;
                    if(res.data.flag){
                        this.allRecords = res.data.data
                    }
                    else{
                        this.allRecords = [];
                    }
                })
            },
            getMonthMoney(value){
                let sum = 0;
                let m = value.format(this.monthFormat);
                for(let i in this.allRecords){
                    if(m === this.allRecords[i].yearMonth){
                        sum += this.allRecords[i].completeMoney;
                    }
                }
                return sum;
            },
            showList(date){
                this.dataSource = [];
                let m = date.format(this.monthFormat);
                for(let i in this.allRecords){
                    if(m === this.allRecords[i].yearMonth){
                        this.dataSource.push(this.allRecords[i])
                    }
                }
                if(this.dataSource.length >0){
                    this.visible = true;
                }
                else{
                    this.$message.error("没有绩效分配记录")
                }
            }
        },
        created() {
            this.init();
            this.tableColumns = [
                new Column("年月","yearMonth",ColumnType.Month).setTableView(80),
                new Column("任务名称","taskTitle",ColumnType.String).setTableView(300),
                new Column("用户名","userName",ColumnType.String).setTableView(100),
                new Column("作业绩效","completeMoney",ColumnType.Number).setTableView(100),
                new Column("说明","remarks",ColumnType.String).setTableView(200),
            ];
        }
    }
</script>

<style scoped>

</style>