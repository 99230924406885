<!--
* @program: TYHOA2 
* @author: ly
* @component:ProjectInfo 
* @description: 
* @create: 2023-09-14 15:32
-->
<template>
    <div class="column-flex">
        <div style="border: 1px solid rgb(235, 237, 240);height: 52px">
            <div style="display: flex;align-items: center;font-size:20px;padding: 10px">
                <div>《{{mFormData.name}}》</div>
                <div v-if="!mFormData.dxhte">&nbsp;&nbsp; 预计金额：{{NumberToChineseCurrency(mFormData.itemBudget)}}({{mFormData.itemBudget}})</div>
                <div v-if="mFormData.dxhte">&nbsp;&nbsp; 等效合同额：{{NumberToChineseCurrency(mFormData.dxhte)}}({{mFormData.dxhte}})</div>
                <div v-else>&nbsp;&nbsp; 还未添加等效合同额</div>
            </div>
        </div>
        <div style="height: calc(100% - 55px);overflow: auto ">
            <LForm title="基础信息"
                   :labelSpan="3" :row-size="3" :form-data="mFormData" :form-columns="formColumns" :view-model="true"/>
            <LForm title="合同相关"
                   :labelSpan="3" :row-size="3" :form-data="mFormData" :form-columns="formColumns2" :view-model="true"/>
            <LForm v-if="mFormData.id" title="归档状态"
                   :labelSpan="3" :row-size="3" :form-data="mFormData" :form-columns="formColumns1" :view-model="true"/>
            <LForm v-if="mFormData.id" title="项目创建和审核"
                   :labelSpan="3" :row-size="3" :form-data="mFormData" :form-columns="formColumns3" :view-model="true"/>
            <LForm v-if="mFormData.id" title="项目完结信息"
                   :labelSpan="3" :row-size="2" :form-data="mFormData" :form-columns="formColumns4" :view-model="true"/>
        </div>
    </div>
</template>

<script>
    import item_info from "../../TableStruct/item_info";
    import api_item_info from "../../../assets/api/itemInfo";
    import LForm from "../../../components/form/LForm";
    import {GetDict,NumberToChineseCurrency} from "../../../assets/utils/general";
    import {ApplyContract} from "../../../assets/utils/general";

    export default {
        name: "projectInfoView",
        props:["record"],
        components: {
            LForm
        },
        inject:["pProjectId"],
        computed:{
            projectId(){
                if(this.record){
                    return this.record.itemId?this.record.itemId:this.record.id
                }
                else{
                    return this.pProjectId;
                }
            }
        },
        watch: {
            record() {
                this.init()
            }
        },
        data() {
            return {
                mFormData: {},
                table: new item_info(),
                formColumns: [],
                formColumns1: [],
                formColumns2: [],
                formColumns3: [],
                formColumns4: [],
                formColumns5: [],
                changeType: false,
                spinning: false,
                contractInfoDTO: null,
                dxhte: 0,
                dxhteEdit: false,
                itemType:GetDict("itemType"),
                NumberToChineseCurrency:NumberToChineseCurrency
            }
        },
        methods: {
            init() {
                if(this.projectId) {
                    this.changeType = false;
                    let data = {
                        id: this.projectId
                    };
                    this.spinning = true;
                    api_item_info.queryOne.urlPost(data, res => {
                        this.spinning = false;
                        if (res.data.flag) {
                            this.mFormData = new ApplyContract(res.data.data);
                            if (this.mFormData.contractInfoDTO) {
                                this.contractInfoDTO = this.mFormData.contractInfoDTO;
                            }
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    })
                }
            }
        },
        created() {
            this.formColumns = this.table.getFormColumns().filter(item => item.group === 0);
            this.formColumns1 = this.table.getFormColumns().filter(item => item.group === 1);
            this.formColumns2 = this.table.getFormColumns().filter(item => item.group === 2);
            this.formColumns3 = this.table.getFormColumns().filter(item => item.group === 3);
            this.formColumns4 = this.table.getFormColumns().filter(item => item.group === 4);
            this.formColumns5 = this.table.getFormColumns().filter(item => item.group === 5);
            this.init();
        }
    }
</script>

<style scoped>

</style>