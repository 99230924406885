<!--
* @program: oa2.0
* @author: ly
* @component:UserInfoManager
* @description: 用户管理
* @create: 2023-08-25 09:51
-->
<template>
    <div class="column-flex">
        <div class="search-bar">
            <a-button @click="refresh"   type="dashed">
                <template #icon><SyncOutlined/></template>
            </a-button>
            <a-button @click="addUser"   type="dashed">
                <template #icon><PlusOutlined/></template>
            </a-button>
            <a-input v-model:value="filter.name" style="width:150px" placeholder="请输入姓名" />
            <span style="margin-left: 10px">筛选条件：</span>
            <a-radio-group @change="doSearch"  v-model:value="params.disableState">
                <a-radio value="1">在职</a-radio>
                <a-radio value="0">离职</a-radio>
            </a-radio-group>
            <a-select v-model:value="params.departmentId" style="width: 250px"
                      allowClear
                      placeholder="通过部门筛选"

                      @change="departmentSelect">
                <a-select-option v-for="item in departments"  :key="item.id">
                    {{item.fullName}}
                </a-select-option>
            </a-select>
            <a-select v-model:value="params.loginState" style="width: 150px"
                      allowClear
                      placeholder="正式/实习生/试用期"
                      :options="loginStates"
                      @change="doSearch">
            </a-select>
        </div>
        <div style="width: 100%;height: 100%;display: flex">
            <div class="main-content30">
                <LTable :loading="loading" :columns="tableColumns" :data-source="source">
                    <template #action="{index,record}">
                        <div class = "action-column">
                            <a-button @click="showDetail(record)" class="action-button" type="link">
                                <SearchOutlined class="pointer"/>
                            </a-button>
                            <a-popconfirm
                                    v-if="record.disableState ==='1'"
                                    ok-text="是"
                                    cancel-text="否"
                                    class="action-button"
                                    title="重置密码为：123456?"
                                    @confirm="resetPassword(record)">
                                <a-tooltip title="重置密码">
                                    <WarningOutlined style="color: #ff40f0" class="pointer"/>
                                </a-tooltip>
                            </a-popconfirm>
                            <a-tooltip v-if="record.disableState ==='1'" title="离职设定">
                                <a-button  @click="resign(record)" class="action-button" type="link">
                                    <CloseCircleOutlined style="color: #ff760a" class="pointer"/>
                                </a-button>
                            </a-tooltip>
                            <a-popover v-if="record.disableState ==='1'" title="入职状态设定" trigger="click">
                                <template #content>
                                    <a-button v-if="record.loginState ==='1' || record.loginState ==='2'" class="action-button" style="color: blue" type="link" @click="loginStateSet(record,'0')">
                                        正式员工
                                    </a-button>
                                    <a-button v-if="record.loginState ==='0' || record.loginState ==='1'" class="action-button" style="color: purple" type="link" @click="loginStateSet(record,'2')">
                                        实习生
                                    </a-button>
                                    <a-button v-if="record.loginState ==='0' || record.loginState ==='2'" class="action-button" style="color: green" type="link" @click="loginStateSet(record,'1')">
                                        试用期
                                    </a-button>
                                </template>
                                <a-tooltip title="状态设定">
                                    <a-button class="action-button" type="link"><VerticalAlignMiddleOutlined /></a-button>
                                </a-tooltip>
                            </a-popover>
                            <a-popconfirm
                                    ok-text="是"
                                    cancel-text="否"
                                    class="action-button"
                                    title="确定删除吗?"
                                    @confirm="deleteData(index,record)">
                                <a-tooltip title="删除">
                                    <DeleteOutlined  style="color: red" class="pointer"/>
                                </a-tooltip>
                            </a-popconfirm>
                        </div>
                    </template>
                </LTable>
            </div>
            <div class="main-content70">
                <ExtraInfoEditor v-if="currentRecord" :param="currentRecord" @update="handleUpdate"/>
            </div>
        </div>
    </div>
    <a-modal v-model:open="visible"
             :footer="null"
             title="离职原因">
        <div>离职后将不能登录本系统</div>
        <a-row style="margin:10px">
            <a-col :span="6">
                <div class="form-label" style="margin-right: 10px">离职时间</div>
            </a-col>
            <a-col :span="18">
                <a-date-picker style="width: 100%;" v-model:value="resignDate"  placeholder="请选择时间"/>
            </a-col>
        </a-row>
        <a-row style="margin:10px">
            <a-col :span="6">
                <div class="form-label" style="margin-right: 10px">离职原因</div>
            </a-col>
            <a-col :span="18">
                <a-textarea :auto-size="{ minRows: 2, maxRows: 6 }" v-model:value="reason"/>
            </a-col>
        </a-row>
        <a-button @click="resignSave" type="primary">
            提交
        </a-button>
    </a-modal>
    <ModalAddNewUser ref="addUser"/>
</template>

<script>
    import LTable from "../../../components/table/LTable";
    import {SearchOutlined,SyncOutlined,
        PlusOutlined,WarningOutlined,CloseCircleOutlined,
        DeleteOutlined,VerticalAlignMiddleOutlined
    } from "@ant-design/icons-vue"
    import sys_user_info from "../UserExtraManager/sys_user_info"
    import userDepartRelation from "../../../assets/api/userDepartRelation";
    import userInfo from "../../../assets/api/userInfo";
    import ExtraInfoEditor from "./ExtraInfoEditor";
    import {ArrayFilter, GetDict} from "../../../assets/utils/general";
    import ModalAddNewUser from "./ModalAddNewUser";

    export default {
        name: "UserInfoManager",
        components:{
            LTable,SearchOutlined,SyncOutlined,PlusOutlined,
            WarningOutlined,CloseCircleOutlined,DeleteOutlined,
            VerticalAlignMiddleOutlined,ExtraInfoEditor,ModalAddNewUser
        },
        data() {
            return {
                loginStates:GetDict("loginState"),
                filter:{},
                params:{
                    disableState:"1", //默认只查询在职人员
                    loginState:undefined, //
                    departmentId:undefined
                },
                dataSource:null,
                tableColumns:new sys_user_info().getTableColumns(),
                loading:false,
                departments:[],
                currentRecord:null,
                visible:false,
                resignDate:undefined,
                reason:null
            }
        },
        computed:{
            queryParams(){
                let obj = {...this.params};
                const keys = Object.keys(obj);
                for (const key of keys) {
                    if(!obj[key]){
                        delete obj[key]
                    }
                }
                return obj;
            },
            source(){
                return ArrayFilter(this.dataSource,this.filter);
            }
        },
        methods: {
            async init() {
                this.departments = JSON.parse(await this.$getItem("__departments"));
                this.doSearch();
            },
            doSearch(){
                this.loading = true;
                userInfo.queryAll.urlPost(this.queryParams,res=>{
                    this.loading = false;
                    if(res.data.flag && res.data.data.length>0){
                        //this.dataSource = res.data.data.filter(item=>item.id !=="2");
                        this.dataSource = res.data.data;
                    }
                    else{
                        this.dataSource = [];
                        this.$message.error(res.data.msg);
                    }
                })
            },
            refresh(){
                this.params = {
                    disableState:"1", //默认只查询在职人员
                    loginState:undefined, //
                    departmentId:undefined
                };
                this.doSearch()
            },
            departmentSelect(){
                this.loading = true;
                userDepartRelation.queryAll.urlPost(this.queryParams,res=>{
                    this.loading = false;
                    if(res.data.flag && res.data.data.length>0){
                        for(let item of res.data.data){
                            item.id = item.userId;
                            item.name = item.userName;
                        }
                        this.dataSource = res.data.data.filter(item=>item.id !=="2");
                    }
                    else{
                        this.dataSource = [];
                        this.$message.error(res.data.msg);
                    }
                });
            },
            showDetail(record){
                this.currentRecord = record;
            },
            handleUpdate(data){
                const keys = Object.keys(data);
                for (const key of keys) {
                    this.currentRecord[key] = data[key];
                }
            },
            resetPassword(record){
                let data = {
                    id:record.id
                };
                userInfo.passWordInitial.urlPost(data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg)
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            deleteData(index,record){
                let data = {
                    id:record.id
                };
                userInfo.delete.urlPost(data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.dataSource.splice(index,1);
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                });
            },
            resign(record){
                this.resignDate = undefined;
                this.reason = null;
                this.currentRecord = record;
                this.visible = true;
            },
            resignSave(){
                if(!this.resignDate){
                    this.$message.error("尚未录入离职时间");
                    return
                }
                if(!this.reason){
                    this.$message.error("尚未录入离职原因");
                    return
                }
                let date = this.resignDate.format("YYYY-MM-DD");
                this.currentRecord.remarks = `于${date}离职，离职原因：${this.reason}`;
                this.currentRecord.loginAccount = this.currentRecord.phone;
                this.currentRecord.disableState = "0";
                userInfo.saveOrUpd.post(this.currentRecord,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.dataSource = this.dataSource.filter(item=>item.id !== this.currentRecord.id)
                        this.visible = false;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            resign2(index,record){
                let data = {
                    id:record.id,
                    state:"1"
                };
                userInfo.stateModify.urlPost(data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.dataSource.splice(index,1);
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                })
            },
            loginStateSet(record,state){
                let data = {
                    id:record.id,
                    loginState:state
                };
                userInfo.leaveOffice.urlPost(data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        record.loginState = state;
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                })

            },
            addUser(){
                this.$refs.addUser.open();
            }
        },
        created() {
            this.init();
        }
    }
</script>

<style scoped>

</style>
