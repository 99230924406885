/** 
* 自动生成文件，请勿修改 
* @name: 考核:员工月度考核表-参与评分角色管理
* @description: 员工月度考核表-参与评分角色管理（三期新增）
* @create: 2024/10/18 15:02:19
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const assessmentRole = { 
	/** 1 summary:删除-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/assessmentRole/delete.do"), 
	/** 2 summary:查询-全部不分页
	description:参数：queryVO对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/assessmentRole/queryAll.do"), 
	/** 3 summary:查询-分页
	description:参数：queryVO对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/assessmentRole/queryPage.do"), 
	/** 4 summary:查询-单条(根据主键)
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/assessmentRole/queryOne.do"), 
	/** 5 summary:保存/修改-单条
	description:参数：单条数据对象AssessmentRoleVO
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/assessmentRole/saveOrUpd.do"), 
	/** 6 summary:保存/修改-批量
	description:参数：List<AssessmentRoleVO> 
	method:post
	params:需要配合Column函数使用
	*/ 
	batchSaveOrUpd : new APIObject.postObject("/api/assessmentRole/batchSaveOrUpd.do"), 
	/** 7 summary:删除-批量
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/assessmentRole/batchDelete.do"), 
};
export default assessmentRole;