<!--
* @program: TYHOA2 
* @author: ly
* @component:ExtraInfoEditor
* @description:
param:为userInfo的record
* @create: 2023-08-28 16:49
-->
<template>
    <div class="column-flex" style="overflow: auto">
        <MForm title="账号信息" :row-size="3" :labelSpan="3" @save="saveData1"
               :form-data="formData" :form-columns="cols" :view-model="viewModel">
        </MForm>
        <a-row v-if="formData.disableState === '0'" >
            <a-col :span="3">
                <div class="form-label" >离职说明：</div>
            </a-col>
            <a-col :span="21">
                <div class="form-value">{{formData.remarks}}</div>
            </a-col>
        </a-row>
        <a-row style="display:flex;align-items: center">
            <a-col :span="3">
                <div class="form-label" >系统角色：</div>
            </a-col>
            <a-col :span="21">
                <div class="form-value">
                    <a-button v-if="!viewModel" @click="addRole" type="link">
                        <template #icon><PlusOutlined/></template>
                    </a-button>
                    <a-tag v-for="(relation,index) in userRoles" :key="index" color="blue" :closable="!viewModel"
                           @close="removeRoleRelation(relation)">
                        {{relation.roleName}}
                    </a-tag>
                </div>
            </a-col>
        </a-row>
        <a-spin v-if="formData2.id" :spinning="loading">
            <MForm v-if="!loading" title="基础信息" @save="saveData2"
                   :labelSpan="3" :row-size="3" v-model:form-data="formData2" :form-columns="formColumns.filter(item=>item.group === 0)" :view-model="viewModel">
            </MForm>
            <MForm v-if="!loading" title="学历信息" @save="saveData2"
                   :labelSpan="3" :row-size="3" v-model:form-data="formData2" :form-columns="formColumns.filter(item=>item.group === 1)" :view-model="viewModel">
            </MForm>
            <MForm v-if="!loading" title="任职信息" @save="saveData2"
                   :labelSpan="3" :row-size="3" v-model:form-data="formData2" :form-columns="formColumns.filter(item=>item.group === 2)" :view-model="viewModel">
            </MForm>
            <MForm v-if="!loading" title="其他"  @save="saveData2"
                   :labelSpan="3" v-model:form-data="formData2" :form-columns="formColumns.filter(item=>item.group === 3)" :view-model="viewModel">
            </MForm>
        </a-spin>
        <a-result v-if="!formData2.id && !addNew"  status="404" title="还未上传花名册信息" >
            <template #extra>
                <a-button @click="addNew = true" type="primary">新增</a-button>
            </template>
        </a-result>
        <LForm v-if="addNew" title="新增信息"
               :labelSpan="3" :row-size="3" v-model:form-data="formData2" :form-columns="formColumns" :view-model="viewModel">
            <template #action="{record}">
                <a-button  type="primary"  @click="saveData3(record)">
                    提交
                </a-button>
            </template>
        </LForm>
    </div>

    <a-modal title="添加角色"
             v-model:open="visible"
             :footer="null"
             :maskClosable="true"
             destroyOnClose>
        <a-select show-search
                  placeholder="选择角色"
                  :filter-option="filterOption"
                  v-model:value="role" style="width: 300px"
                  :options="roles"
                  :fieldNames="fieldNames">
            <template #option = "{name,deptName}">
                <a-tag :color="deptName?'blue':''">{{deptName?deptName:"公用"}}</a-tag>{{name}}
            </template>
        </a-select>
        <a-button  type="dashed"  @click="roleSet">
            保存
        </a-button>
    </a-modal>
</template>

<script>
    import MForm from "../../../components/form/MForm";
    import sys_user_info from "../UserExtraManager/sys_user_info";
    import sys_user_info_extra from "../UserExtraManager/sys_user_info_extra";
    import userExtra from "../../../assets/api/userExtra";
    import ReadAuth from "../../../components/ReadAuth";
    import userInfo from "../../../assets/api/userInfo";
    import api_sys_user_role_relation from "../../../assets/api/userRoleRelation";
    import {PlusOutlined} from "@ant-design/icons-vue"
    import role from "../../../assets/api/role";
    import LForm from "../../../components/form/LForm";
    export default {
        name: "ExtraInfoEditor",
        props:["param"],
        emits:["update","update:param"],
        mixins:[ReadAuth],
        components:{
            MForm,PlusOutlined,LForm
        },
        watch:{
            param(){
                this.init()
            }
        },
        data() {
            return {
                formData:{},
                cols:new sys_user_info().getFormColumns(),
                formData2: {},
                formColumns: new sys_user_info_extra().getFormColumns(),
                loading:false,
                viewModel:false,
                userRoles:[],
                fieldNames:{
                    label: "name",
                    value: "id",
                },
                roles:[],//可选角色列表
                role:undefined,//所选择设定的角色id
                visible:false,
                addNew:false
            }
        },
        methods: {
            init() {
                this.addNew = false;
                let data = {
                    userId:this.param.id
                };
                this.loading = true;
                userExtra.queryAll.post(data,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.formData2 = res.data.data[0];
                    }
                    else{
                        this.formData2 = new sys_user_info_extra().getFormData();
                    }
                })
                userInfo.queryOne.urlPost({id:this.param.id},res=>{
                    if(res.data.flag){
                        this.formData = res.data.data;
                    }
                    else{
                        this.formData = {};
                    }
                })
                this.showRole()
            },
            saveData1(record,viewModel){
                userInfo.saveOrUpd.post(record,res=>{
                    if(res.data.flag){
                        this.$emit("update",record);
                        this.$emit("update:param",record);
                        this.$message.success(res.data.msg);
                        viewModel(true);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            saveData2(record,viewModel){
                record.userId = this.formData.id;
                userExtra.saveOrUpdate.post(record,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.formData2 = record;
                        viewModel(true);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            saveData3(record){
                record.userId = this.formData.id;
                userExtra.saveOrUpdate.post(record,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.formData2 = record;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            showRole(){
                this.userRoles=[];
                api_sys_user_role_relation.queryAll.urlPost({userId:this.param.id},res=>{
                    if(res.data.flag){
                        this.userRoles = res.data.data;
                    }
                });
            },
            removeRoleRelation(relation){
                let data = {id:relation.id};
                api_sys_user_role_relation.delete.urlPost(data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            addRole(){
                this.visible = true;
            },
            filterOption(inputValue, option){
                if(option.name && option.deptName){
                    return option.name.includes(inputValue) || option.deptName.includes(inputValue)
                }
                else if(option.name){
                    return option.name.includes(inputValue)
                }
                else {
                    return false
                }
            },
            roleSet(){
                let data = {roleId:this.role,userId:this.param.id};
                api_sys_user_role_relation.saveOrUpd.post(data,res=>{
                    if(res.data.flag){
                        this.visible = false;
                        this.$message.success(res.data.msg);
                        this.userRoles = [];
                        api_sys_user_role_relation.queryAll.urlPost({userId:this.param.id},res=>{
                            if(res.data.flag){
                                this.userRoles = res.data.data;
                            }
                            else{
                                this.$message.error(res.data.msg);
                            }
                        });
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            }
        },
        async created() {
            this.init();
            role.queryAll.post({}, res => {
                if (res.data.flag) {
                    this.roles = res.data.data.filter(role => role.name !== "超级管理员")
                }
                else{
                    this.roles = [];
                }
            });
        }
    }
</script>

<style scoped>

</style>