<!--
* @program: TYHOA2 
* @author: ly
* @component:TaskQC 
* @description: 任务质检
* @create: 2024-09-24 17:23
-->
<template>
    <div class="row-flex">
        <a-tabs style="margin:10px"  v-model:activeKey="activeKey">
            <a-tab-pane key="1" tab="一检信息">
                <a-card style="width: 600px">
                    <template #title>
                    <span class="card-title">
                        一检信息
                    </span>
                    </template>
                    <template #extra>
                        <a-popover placement="bottom" trigger="hover" >
                            <template #content>
                                <div v-if="!viewModel" class="card-edit">
                                    <span @click="editDetail">编辑</span>
                                </div>
                            </template>
                            <a-button style="padding: 0 5px" type="link">
                                <UnorderedListOutlined style="font-size: 22px;"/>
                            </a-button>
                        </a-popover>
                    </template>
                    <LForm v-if="formData.qcDate" :form-columns="formColumns" :form-data="formData" :view-model="true" />
                    <a-result  title="还未上传一检信息" v-else/>
                </a-card>
            </a-tab-pane>
            <a-tab-pane key="2" tab="二检申请" >
                <div class="form-title">二检申请
                    <a-button v-if="formData.qcDate" style="margin-left: 10px" @click="addApply" type="dashed" size="small">
                        <template #icon><PlusOutlined /></template>
                    </a-button>
                    <a-button v-if="formData.qcDate" style="margin-left: 10px" @click="init" type="dashed" size="small">
                        <template #icon><SyncOutlined /></template>
                    </a-button>
                </div>
                <LTable v-if="formData.qcDate"  :download="false" :data-source="applyList" :columns="tableColumns">
                    <template #action="{record}">
                        <div class = "action-column">
                            <a-button @click="showApply(record)"
                                      class="action-button" type="link">
                                <SearchOutlined class="pointer"/>
                            </a-button>
                            <a-popconfirm
                                    v-if="record.auditState !== '1'"
                                    @confirm="deleteData(record)"
                                    cancel-text="否"
                                    class="action-button"
                                    ok-text="是"
                                    title="是否删除审核？">
                                <a-tooltip title="删除审核">
                                    <a-button class="action-button" style="color: orange" type="link">
                                        <DeleteOutlined class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                            </a-popconfirm>
                        </div>
                    </template>
                </LTable>
                <a-result  title="未完成一检，不能发起二检申请" v-else/>
            </a-tab-pane>
            <a-tab-pane key="3" tab="二检报告">
                <QCReport :view-model="true" :apply="currentApply"/>
            </a-tab-pane>
            <a-tab-pane key="4" tab="成果质量证明文件">
                <QCFiles :view-model="false" />
            </a-tab-pane>
        </a-tabs>
    </div>
    <a-modal
            width="600px"
            v-model:open="visible"
            :footer="null" title="一检上报"
            destroyOnClose
            :maskClosable="vModel">
        <a-row>
            <a-col class="form-label" style="text-align: right;padding-right: 15px;" :span="6">
                <div>一检人员</div>
            </a-col>
            <a-col class="form-label" style="text-align: right;padding-right: 15px;" :span="18">
                <UserPicker v-model:value="users" />
            </a-col>
        </a-row>
        <LForm :viewModel="vModel" :form-columns="formColumns" :form-data="lFormData" >
            <template #action="{record}">
                <a-button  type="primary"  @click="saveData(record)">
                    提交
                </a-button>
            </template>
        </LForm>
    </a-modal>

    <a-modal
            width="600px"
            v-model:open="visible2"
            :footer="null" title="二检申请"
            destroyOnClose
            :maskClosable="vModel">
        <LForm :viewModel="vModel" :form-columns="formColumns2" :form-data="currentApply" >
            <template #action="{record}">
                <a-button  type="primary"  @click="saveApply(record)">
                    提交
                </a-button>
            </template>
        </LForm>
    </a-modal>
</template>

<script>
    import {PlusOutlined, SyncOutlined, UnorderedListOutlined,SearchOutlined,
        DeleteOutlined} from "@ant-design/icons-vue";
    import LForm from "../../../../components/form/LForm";
    import Column from "../../../../assets/tables/column";
    import ColumnType from "../../../../assets/tables/column_type";
    import itemTask from "../../../../assets/api/itemTask";
    import UserPicker from "../../../common/UserPicker";
    import qcApply from "../../../../assets/api/qcApply";
    import dayjs from "dayjs"
    import LTable from "../../../../components/table/LTable";
    import item_qc_apply from "../../../TableStruct/item_qc_apply";
    import itemInfo from "../../../../assets/api/itemInfo";
    import QCReport from "../../../PersonalCenter/WorkCenter/QCWorkTask/QCReport";
    import QCFiles from "./QCFiles";
    export default {
        name: "TaskQC",
        props:["viewModel","task"],
        inject:["pProjectId"],
        emits:["update"],
        components:{
            PlusOutlined, SyncOutlined,UnorderedListOutlined,DeleteOutlined,
            SearchOutlined,
            LForm,UserPicker,LTable,QCReport,QCFiles
        },
        data(){
            return{
                visible:false,
                visible2:false,
                loading:false,
                formData:{},//提供Form的formData
                lFormData:{},//提供Form的formData
                formColumns:[
                    new Column("一检时间","qcDate",ColumnType.Date,true),
                    new Column("一检描述","qcDescrib",ColumnType.String,true),
                    new Column("一检文件","qcFile",ColumnType.PreViewFile,true),
                    new Column("一检人员","qcUserNames",ColumnType.String,false),
                    new Column("评分","assessment",ColumnType.Enum,true)
                        .setDictEnum("assessment"),
                ],
                tableColumns:[
                    new Column("标题","title",ColumnType.String,true).setTableView(200),
                    new Column("审核","auditState",ColumnType.String,false).setTableView(80)
                        .setDictEnum("auditState"),
                    new Column("质检组长","qcLeaderName",ColumnType.String).setTableView(100),
                    new Column("质检组员","qcMember",ColumnType.String).setTableView(150),
                    new Column("操作","actions","actions",false).setTableView(80)
                ],
                formColumns2:new item_qc_apply().getFormColumns(),
                dataSource:[],
                vModel:false,
                users:[],
                applyList:[],
                currentApply:{},
                departmentId:undefined,
                activeKey:"1"
            }
        },
        methods:{
            init(){
                this.users = [];
                if(this.task){
                    let {qcDate,qcDescrib,qcFile,qcUsers,assessment,qcUserNames} = this.task;
                    this.formData = {qcDate,qcDescrib,qcFile,qcUsers,assessment, qcUserNames};
                    if(qcUserNames){
                        this.formData.qcUserNames = qcUserNames.substr(1,qcUserNames.length-2);
                        let ids = qcUsers.split(",");
                        let names = this.formData.qcUserNames.split(",");
                        for(let i in ids){
                            this.users.push({id:ids[i],name:names[i]});
                        }
                    }
                    let data = {
                        itemId:this.task.itemId
                    };
                    qcApply.queryAll.post(data,res=>{
                        if(res.data.flag){
                            this.applyList = res.data.data;
                            this.currentApply = this.applyList[0];
                        }
                        else{
                            this.applyList = [];
                        }
                    })
                }
            },
            editDetail(){
                this.visible = true;
                this.vModel = false;
                if(!this.formData.qcDate){
                    this.lFormData = {
                        qcDate: undefined,
                        qcDescrib:this.task.itemName + "已完成一检，并上传文件附件",
                        qcFile: "",
                        qcUsers:"",
                        assessment:"1",
                    };
                }
                else{
                    this.lFormData = {...this.formData};
                }
            },
            saveData(record){
                let users = [];
                let userNames = [];
                for(let user of this.users){
                    users.push(user.id)
                    userNames.push(user.name)
                }
                record.id = this.task.id;
                record.qcUsers = users.toString();
                record.qcUserNames = userNames.toString();
                record.qcDate = dayjs(record.qcDate).format("YYYY-MM-DD")
                itemTask.qcFileUpload.urlPost(record,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.formData = {...record};
                        this.$emit("update",record);
                        this.visible = false;
                        itemInfo.updByFiled.post({id:this.pProjectId,qualityState:"1"})
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            addApply(){
                this.visible2 = true;
                this.currentApply = {
                    title:this.task.itemName + "《二检申请》",
                    content:"已完成一检，可以申请二检",
                    proContent:"需要在质检完成期限之前完成质检，需质检成果有：",
                };
                this.vModel = false;
            },
            deleteData(record){
                qcApply.delete.urlPost({id:record.id},res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.init()
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            showApply(record){
                this.visible2 = true;
                this.vModel = true;
                this.currentApply = record;
            },
            saveApply(record){
                record.itemId = this.pProjectId;
                record.type = "2";
                record.departmentId = this.departmentId;
                record.originId = this.task.id;
                qcApply.saveOrUpd.post(record,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.init()
                        this.visible2 = false;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            }
        },
        async created() {
            this.init();
            let myDepartments = JSON.parse(await this.$getItem("__myDepartments"));
            this.departmentId = myDepartments[0].id;
        }
    }
</script>

<style scoped>

</style>