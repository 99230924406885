<!--
* @program: TYHOA2 
* @author: ly
* @component:DepartmentAbandonedProject 
* @description: 部门虚拟项目管理
* @create: 2024-09-23 19:46
-->
<template>
    <div class="column-flex" style="position: relative;overflow: hidden">
        <div class="search-bar">
            <a-button @click="init" type="dashed">
                <template #icon>
                    <SyncOutlined/>
                </template>
            </a-button>
            <div style="margin: 10px;color: black">部门:</div>
            <a-select v-model:value="defaultQuery.departmentId"
                      style="width: 200px"
                      @change="init"
            >
                <a-select-option v-for="department in myDepartments" :key="department.id">
                    {{ department.name }}
                </a-select-option>
            </a-select>
            <div style="margin:0 5px">名称：</div>
            <a-input  placeholder="项目名称" style="width: 150px" v-model:value="filter.name" />
            <div style="margin:0 5px">任务负责人：</div>
            <a-input  placeholder="任务负责人" style="width: 150px" v-model:value="filter.taskLeaderName" />
        </div>
        <div class="column-flex">
            <a-tabs v-if="ATaskList.length > 0" v-model:activeKey="activeKey2" @change="handleChange">
                <a-tab-pane v-for="(item,index) in ATaskList"  :key="index" >
                    <template #tab>
                        <div style="margin: 0 20px;display: flex;align-items:center">
                            {{item.itemName}}
                            <a-button v-if="!item.state" @click="addChildren(index)" type="link" style="margin-left: 5px">
                                <template #icon><PlusOutlined/></template>
                            </a-button>
                        </div>
                    </template>
                    <LTable :fixed="true" :data-source="source" :loading="loading" :columns="tableColumns">
                        <template #action="{record}">
                            <div class="action-column">
                                <a-button  @click="showDetail(record)" class="action-button" type="link">
                                    <SearchOutlined class="pointer" title="查看"/>
                                </a-button>
                                <a-popconfirm
                                        @confirm="remove(record)"
                                        cancel-text="否"
                                        class="action-button"
                                        ok-text="是"
                                        title="确定从虚拟项目中移除吗?">
                                    <RollbackOutlined class="pointer" style="color: red" />
                                </a-popconfirm>
                            </div>
                        </template>
                    </LTable>
                </a-tab-pane>
            </a-tabs>
            <a-result status="404" title="还没有创建虚拟项目" v-else/>
        </div>
        <a-drawer :closable="false"
                  :get-container="false"
                  :style="{ position: 'absolute' }"
                  @close="visible = false"
                  destroy-on-close
                  height="90%"
                  placement="bottom"
                  v-model:open="visible">
            <a-tabs style="height: 100%" tabPosition="left" v-model:activeKey="activeKey">
                <a-tab-pane style="height: 100%;overflow: auto" key="1" tab="项目信息">
                    <ProjectInfoView  :record="currentRecord"/>
                </a-tab-pane>
                <a-tab-pane style="height: 100%;overflow: auto" key="6" tab="项目合同"><ProjectContractInfo :record="currentRecord" :view-model="true"/></a-tab-pane>
                <a-tab-pane style="height: 100%;overflow: auto" key="5" tab="项目消耗"><ProjectUseUp :record="currentRecord"/></a-tab-pane>
                <a-tab-pane style="height: 100%;overflow: auto" key="7" tab="项目收款"><ProjectIncomeView :record="currentRecord"/></a-tab-pane>
                <a-tab-pane style="height: 100%;overflow: auto" key="8" tab="外协支付"><ProjectOutcome :record="currentRecord"/></a-tab-pane>
                <a-tab-pane  style="height: 100%;overflow: auto" key="4" tab="月度绩效分配">
                    <MonthlyArrangeAbandoned :task="currentRecord.itemTaskDTO" :departmentId="defaultQuery.departmentId" :view-model="false"/>
                </a-tab-pane>
            </a-tabs>
        </a-drawer>
    </div>
    <a-modal v-model:open="visible3"
             destroyOnClose
             width="600px"
             :footer="null"
             title="添加虚拟子项目">
        <a-row style="margin-top: 20px">
            <a-col class="form-label"  :span="6">
                <div>项目</div>
            </a-col>
            <a-col class="form-value"  :span="16">
                <a-select v-model:value="subId"
                          show-search
                          :options="BTaskList"
                          :fieldNames="fieldNames"
                          :filter-option="filterOption"
                          style="width: 100%">
                </a-select>
            </a-col>
        </a-row>
        <a-divider />
        <div class="form-value" style="text-align: center" >将选择的项目添加到{{currentTask.itemName}}中</div>
        <div class="form-value" style="text-align: center" >成为虚拟子项目后该项目以及项目任务将会隐藏</div>
        <a-divider />
        <a-popconfirm
                @confirm="saveMount"
                cancel-text="否"
                ok-text="是"
                title="确定添加虚拟子项目吗？">
            <a-button danger>
                添加
            </a-button>
        </a-popconfirm>
    </a-modal>
</template>

<script>
    import {
        PlusOutlined, RollbackOutlined, SearchOutlined,
        SyncOutlined,
    } from "@ant-design/icons-vue";
    import LTable from "../../../../components/table/LTable";
    import ProjectInfoView from "../../ProjectManager/ProjectInfoView";
    import ProjectTaskInfo from "../../ProjectTask/ProjectTaskInfo";
    import ProjectContractInfo from "../../ProjectContract/ProjectContractInfo";
    import ProjectIncomeView from "../../ProjectIncome/ProjectIncomeView";
    import ProjectOutcome from "../../ProjectOutcome/ProjectOutcome";
    import {computed} from "vue";
    import itemTask from "../../../../assets/api/itemTask";
    import {ArrayFilter} from "../../../../assets/utils/general";
    import item_info_abandoned from "../../../TableStruct/item_info_abandoned";
    import itemInfo from "../../../../assets/api/itemInfo";
    import ProjectUseUp from "../../ProjectManager/ProjectUseUp";
    import MonthlyArrangeAbandoned from "../MonthlyArrangeAbandoned";


    export default {
        name: "DepartmentAbandonedProject",
        components: {
            SyncOutlined,
            PlusOutlined,
            SearchOutlined, RollbackOutlined,
            ProjectUseUp,LTable,ProjectInfoView,ProjectTaskInfo,
            ProjectContractInfo,ProjectIncomeView,ProjectOutcome,
            MonthlyArrangeAbandoned
        },
        provide() {
            return {
                pProjectId:computed(() => this.currentRecord.id),
            }
        },
        computed: {
            source() {
                return ArrayFilter(this.abandonList, this.filter)
            },
            ATaskList(){
                return this.taskList.filter(item=>item.itemType === "9");
            },
            BTaskList(){
                return this.taskList.filter(item=>item.itemType !== "9");
            }
        },
        data() {
            return {
                taskList: [],//所有任务列表
                tableColumns: new item_info_abandoned().getTableColumns(),
                currentTask: {},
                currentRecord: {},
                loading: false,
                defaultQuery: {
                    departmentId: null,
                },
                filter:{},
                visible: false,
                visible2: false,
                visible3: false,
                visible4: false,
                abandonList: [],
                myDepartments: [],
                activeKey:"1",
                activeKey2:0,
                subId:undefined,
                fieldNames:{label:"itemName",value:"itemId"}
            }
        },
        methods: {
            init() {
                itemTask.queryAll.urlPost(this.defaultQuery,res=>{
                    if (res.data.flag) {
                        this.taskList = res.data.data.sort((a, b) => {
                            return b.createTime - a.createTime;
                        });
                        if(this.ATaskList.length > 0){
                            this.currentTask = this.ATaskList[0];
                            this.activeKey2 = 0;
                            this.handleChange(0);
                        }
                    }
                    else{
                        this.taskList = [];
                    }
                })
            },
            showDetail(record) {
                this.currentRecord = record;
                this.visible = true;
            },
            handleChange(key){
                this.currentTask = this.ATaskList[key];
                this.loading = true;
                itemInfo.queryByPid.get({pid:this.currentTask.itemId}, res => {
                    if (res.data.flag) {
                        this.abandonList = res.data.data.sort((a, b) => {
                            return b.startTime - a.startTime;
                        });
                    } else {
                        this.abandonList = [];
                    }
                    this.loading = false;
                });
            },
            addChildren(index){
                this.currentTask = this.ATaskList[index];
                this.visible3 = true;
                this.subId = undefined;
            },
            filterOption(input, option){
                return option.itemName.indexOf(input) >=0;
            },
            saveMount(){
                let data = {
                    id:this.subId,
                    pid:this.currentTask.itemId
                };
                itemInfo.itemMount.urlPost(data,res=>{
                    if (res.data.flag) {
                        this.taskList = this.taskList.filter(item =>item.itemId !== this.subId);
                        this.visible3 = false;
                        this.$message.success(res.data.msg);
                        this.loading = true;
                        itemInfo.queryByPid.get({pid:this.currentTask.itemId}, res => {
                            if (res.data.flag) {
                                this.abandonList = res.data.data.sort((a, b) => {
                                    return b.startTime - a.startTime;
                                });
                            } else {
                                this.abandonList = [];
                            }
                            this.loading = false;
                        });
                    } else {
                        this.$message.error(res.data.msg)
                    }
                })
            },
            remove(record){
                let data = {
                    id:record.id,
                    pid:""
                };
                itemInfo.itemMount.urlPost(data,res=>{
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        this.abandonList = this.abandonList.filter(item=>item.id !== record.id);
                    } else {
                        this.$message.error(res.data.msg)
                    }
                })
            }
        },
        async created() {
            this.myDepartments = JSON.parse(await this.$getItem("__myDepartments"));
            this.defaultQuery.departmentId = this.myDepartments[0].id;
            this.init();
        }
    }
</script>

<style scoped>

</style>