/** 
* 自动生成文件，请勿修改 
* @name: 项目:合同管理模块
* @description: 项目-合同管理模块-增删改查
* @create: 2024/10/18 15:02:19
* @swagger: 2.0
* 合计:12条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const contractInfo = { 
	/** 1 summary:更改-收款完结状态
	description:参数：单条数据的主键id,paymentEndState
	method:post
	params:需要配合Column函数使用
	*/ 
	payment : new APIObject.postObject("/api/contractInfo/payment.do"), 
	/** 2 summary:外协合同数量统计：年-月、个数、金额、已支付、未支付等）
	description:参数：年度或月度（以签订日期算）、外协id
	method:post
	params:需要配合Column函数使用
	*/ 
	statOutCount : new APIObject.postObject("/api/contractInfo/statOutCount.do"), 
	/** 3 summary:合同数量统计：年、月、个数、金额等）
	description:参数：年度（以签订日期算）、合同类别（1:甲方合同；2：外协合同；3：中标通知书；4：三方合同；5三方外协合同）
	method:post
	params:需要配合Column函数使用
	*/ 
	statCount : new APIObject.postObject("/api/contractInfo/statCount.do"), 
	/** 4 summary:保存/修改-单个合同
	description:参数：单条数据对象ContractInfoVO
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/contractInfo/saveOrUpd.do"), 
	/** 5 summary:删除-批量
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/contractInfo/batchDelete.do"), 
	/** 6 summary:查询-所有数据
	description:参数：ContractInfoQueryVO对象，项目属性中一些查询字段单独封装的对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/contractInfo/queryAll.do"), 
	/** 7 summary:合同查询：按签订年度（月度）、项目id、收款状态（已收款、未收款（未全部收款））
	description:参数：yearMonth：可以是‘2022/2022-01’等样式；itemId:项目id；colleState:收款状态（0：未收款（含部分收款）；1：已全部收款）
	method:post
	params:需要配合Column函数使用
	*/ 
	queryByItem : new APIObject.postObject("/api/contractInfo/queryByItem.do"), 
	/** 8 summary:查询-单条数据
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/contractInfo/queryOne.do"), 
	/** 9 summary:合同统计：合同总金额、应收款金额、未收款金额
	description:参数：itemType：项目类型（0：自研项目:1：甲方项目；2：劳务协作项目；3：三方项目）
	method:post
	params:需要配合Column函数使用
	*/ 
	statistics : new APIObject.postObject("/api/contractInfo/statistics.do"), 
	/** 10 summary:查询-分页
	description:参数：(page、limit,分页参数，int型)、ContractInfoQueryVO对象，合同属性中一些查询字段单独封装的对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/contractInfo/queryPage.do"), 
	/** 11 summary:保存/修改-批量
	description:参数：List<ContractInfoVO>
	method:post
	params:需要配合Column函数使用
	*/ 
	batchSaveOrUpd : new APIObject.postObject("/api/contractInfo/batchSaveOrUpd.do"), 
	/** 12 summary:删除-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/contractInfo/delete.do"), 
};
export default contractInfo;