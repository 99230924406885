<!--
* @program: TYHOA2 
* @author: ly
* @component:ProjectOutcomeRecord 
* @description: 项目外协支付申请
* @create: 2024-07-26 14:15
-->
<template>
    <div class="form-title">支出列表
        <a-button  type="link"  @click="refresh">
            <template #icon><SyncOutlined /></template>
        </a-button>
        <div style="margin:0 5px">合同名：</div>
        <a-input placeholder="合同名称" style="width: 150px" v-model:value="contractName" />
    </div>
    <div style="display: flex;margin-left: 30px;align-items: center;">
<!--        <a-button type="dashed" @click="addPay">-->
<!--            <template #icon><PlusOutlined /></template>添加付款(无发票)-->
<!--        </a-button>-->
        <div style="margin-left: 10px;">已付款: <span class="form-value">{{summary.payed.toFixed(2)}} 元</span></div>
        <div style="margin-left: 10px;">未付款: <span class="form-value">{{summary.paying.toFixed(2)}} 元</span></div>
    </div>
    <LTable style="height: 100%" :columns="tableColumns" :data-source="source">
        <template #action="{record}">
            <div class="action-column">
                <a-button  type="link"  @click="showDetail(record)">
                    <template #icon><SearchOutlined /></template>
                </a-button>
                <a-button  type="link"  @click="showAudit(record)">
                    <template #icon><LinkOutlined /></template>
                </a-button>
                <a-button  v-if="checkAction('edit',record)" type="link"  @click="editDetail(record)">
                    <template #icon><EditOutlined /></template>
                </a-button>
                <a-popconfirm
                        @confirm="cancelSubmit(record)"
                        cancel-text="否"
                        class="action-button"
                        ok-text="是"
                        title="是否撤销审核？"
                        v-if="checkAction('cancel',record)">
                    <a-tooltip title="撤销审核">
                        <a-button class="action-button" style="color: orange" type="link">
                            <RollbackOutlined class="pointer"/>
                        </a-button>
                    </a-tooltip>
                </a-popconfirm>
                <a-popconfirm
                        @confirm="submit(record)"
                        cancel-text="否"
                        class="action-button"
                        ok-text="提交"
                        title="提交审核？"
                        v-if="checkAction('submit',record)">
                    <a-tooltip title="提交审核">
                        <a-button class="action-button" style="color: green" type="link">
                            <ArrowUpOutlined class="pointer"/>
                        </a-button>
                    </a-tooltip>
                </a-popconfirm>
                <a-popconfirm
                        v-if="checkAction('delete',record)"
                        ok-text="是"
                        cancel-text="否"
                        class="action-button"
                        title="确定删除吗?"
                        @confirm="deleteData(record)">
                    <DeleteOutlined  style="color: red" class="pointer"/>
                </a-popconfirm>
            </div>
        </template>
    </LTable>
    <a-modal v-model:open="visible"
             destroy-on-close
             title="支付记录"
             :maskClosable="vModel"
             :footer="null">
        <a-row>
            <a-col :span="6">
                <span class="form-label">支出账户</span>
            </a-col>
            <a-col :span="18">
                <a-select v-if="!vModel" :fieldNames="{key:'id', value: 'id',label:'remarks'}"
                          v-model:value="bankId" style="width: 100%" :options="bankCards">
                </a-select>
                <div class="form-value" v-if="bankId">{{bankCards.find(item=>item.id === bankId).remarks}}</div>
            </a-col>
        </a-row>
        <LForm :view-model="vModel" :form-columns="formColumns" :form-data="formData">
            <template #action="{record}">
                <a-button @click="saveData(record)"  type="primary">
                    提交
                </a-button>
            </template>
        </LForm>
    </a-modal>
    <a-modal v-model:open="visible2"
             destroy-on-close
             width="1000px"
             title="审核进度"
             maskClosable
             :footer="null">
        <OutSourcePayApplyAuditStatus :record="formData" />
    </a-modal>

    <a-modal v-model:open="visible3"
             destroy-on-close
             title="添加付款申请"
             :maskClosable="vModel"
             :footer="null">
        <SelectContractType2  @change="handleChange" :contract="record?.contractInfoDTOs"/>
        <a-divider />
        <a-row v-if="!vModel">
            <!--            <a-col :span="6">-->
            <!--                <span class="form-value">支出账户</span>-->
            <!--            </a-col>-->
            <!--            <a-col :span="18">-->
            <!--                <a-select :fieldNames="{key:'id', value: 'id',label:'remarks'}"-->
            <!--                          allowClear-->
            <!--                          placeholder="若未知，请不要选择"-->
            <!--                          v-model:value="bankId" style="width: 100%" :options="bankCards">-->
            <!--                </a-select>-->
            <!--            </a-col>-->
            [支出账户]在通过审核之后，在【账户收付款管理】【项目外协支付】确认付款时选择
        </a-row>
        <LForm :view-model="vModel" :form-columns="formColumns" :form-data="formData">
            <template #action="{record}">
                <a-button @click="saveData2(record)"  type="primary">
                    提交
                </a-button>
            </template>
        </LForm>
    </a-modal>
</template>

<script>
    import {DeleteOutlined, EditOutlined,
        SearchOutlined, SyncOutlined,RollbackOutlined,ArrowUpOutlined,LinkOutlined,
        PlusOutlined
    } from "@ant-design/icons-vue";
    import LForm from "../../../components/form/LForm";
    import LTable from "../../../components/table/LTable";
    import item_outsourc_pay_apply from "../../TableStruct/item_outsourc_pay_apply";
    import payApply from "../../../assets/api/payApply";
    import OutSourcePayApplyAuditStatus from "./OutSourcePayApplyAuditStatus";
    import SelectContractType2 from "./SelectContractType2";
    import item_invoice_record_payoff from "../../TableStruct/item_invoice_record_payoff";
    import CooperationUnitsPicker from "../../common/CooperationUnitsPicker";
    import {GetDict} from "../../../assets/utils/general";
    export default {
        name: "ProjectOutcomeRecord",
        props:["record","bankCards"],
        inject:["pProjectId"],
        components:{
            DeleteOutlined,EditOutlined,RollbackOutlined,ArrowUpOutlined,LinkOutlined,
            SyncOutlined,SearchOutlined,PlusOutlined,
            LForm,LTable,OutSourcePayApplyAuditStatus,SelectContractType2,
            CooperationUnitsPicker
        },
        computed:{
            summary(){
                let payed = 0;
                let paying = 0;
                for(let item of this.source){
                    if(item.payState === "1"){
                        payed += item.payAmount;
                    }
                    else{
                        paying += item.payAmount;
                    }
                }
                return {payed,paying};
            },
            source(){
                if(this.contractName){
                    return this.dataSource.filter(item=>{
                        if(item.contractName){
                            return item.contractName.includes(this.contractName)
                        }
                        else{
                            return false
                        }
                    })
                }
                else{
                    return this.dataSource
                }
            },
            projectId(){
                if(this.record){
                    return this.record.id
                }
                else{
                    return this.pProjectId
                }
            }
        },
        data(){
            return{
                table:new item_outsourc_pay_apply(),
                dataSource:[],
                tableColumns:null,
                loading:false,
                formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                formData:null,//提供Form的formData
                visible:false,
                visible2:false,
                visible3:false,
                vModel:true,
                fieldNames:{key:'id', value: 'id',label:"remarks"},
                bankId:undefined,
                contractName:null,
                contract:{},
                departmentId:null,
                partyId:undefined, //付款方ID
                option:[],
                companies:GetDict("companies"),//外协合同，付款方为我公司
                companyId:undefined, //收款方ID
            }
        },
        methods:{
            init(){
                this.tableColumns = this.table.getTableColumns();
                this.formColumns = this.table.getFormColumns();
                let data = {itemId:this.projectId};
                this.loading = true;
                payApply.queryAll.post(data,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                })

            },
            refresh(){
                this.init()
            },
            addPay(){
                this.vModel = false;
                this.visible3 = true;
                this.contract = {};
                this.formData = new item_invoice_record_payoff().getFormData();

                this.partyId = undefined;
                this.companyId = undefined;
                this.option = [];
            },
            showDetail(record){
                this.visible = true;
                this.vModel = true;
                this.formData = record;
                this.bankId = record.bankId;
                this.formColumns = this.table.getFormColumns();
            },
            showAudit(record){
                this.formData = record;
                this.visible2 = true;
            },
            editDetail(record){
                this.visible = true;
                this.vModel = false;
                this.formData = record;
                this.bankId = record.bankId;
                this.formColumns = this.table.getFormColumns();
            },
            deleteData(record){
                let data = {id:record.id};
                payApply.delete.urlPost(data,res=>{
                    if(res.data.flag){
                        this.dataSource = this.dataSource.filter(item=>item.id !== record.id)
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            saveData(record){
                record.bankId = this.bankId;
                record.itemId = this.projectId;
                payApply.saveOrUpd.post(record,res=>{
                    if(res.data.flag){
                        this.init();
                        this.visible = false;
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            saveData2(record){
                if(!this.contract.id){
                    this.$message.error("还未选择合同");
                    return
                }
                record.bankId = this.bankId;
                record.itemId = this.projectId;
                record.contractId = this.contract.id;
                record.outSourceId = this.contract.associatedKey;
                record.payCompanyId = this.contract.sigCompany;
                record.applyDepart = this.departmentId;
                payApply.saveOrUpd.post(record,res=>{
                    if(res.data.flag){
                        this.init();
                        this.visible3 = false;
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            deleteCheck(record){
                let time = parseInt(localStorage.getItem("time"));
                return new Date(record.createTime).toLocaleDateString() === new Date(time).toLocaleDateString();
            },
            checkAction(action, record) {
                /**
                 * submitState 提交状态 0：未提交；1：已提交，未提交的可删改
                 * approvalState 是否全部审核通过：0：未完成；1：已完成
                 * payState 支付状态：0：未支付；1：已支付
                 */
                switch (action) {
                    case "edit": {
                        return record.submitState === "0" && record.payState === "0" ;
                    }
                    case "submit": {
                        return record.submitState === "0" && record.payState === "0" ;
                    }
                    case "delete": {
                        return record.submitState === "0" && record.payState === "0" ;
                    }
                    case "cancel": {
                        return record.submitState === "1" && record.approvalState === "0";
                    }
                    case "download": {
                        return record.submitState === "1" && record.approvalState === "1";
                    }
                    case "auditDone":{
                        return record.approvalState === "1" && record.payState === "0" ;
                    }
                }
            },
            submit(record){
                let data = {
                    id: record.id
                };
                payApply.submit.urlPost(data,res=>{
                    if(res.data.flag){
                        this.init();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            cancelSubmit(record){
                let data = {
                    id: record.id
                };
                payApply.cancelSubmit.urlPost(data,res=>{
                    if(res.data.flag){
                        this.init();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            handleChange(contract){
                this.contract = contract;
                this.formData.outSourceId = this.contract?.associatedKey;
                this.formData.outSourceName = this.contract?.associatedName;
                this.formData.payCompanyId = this.contract?.sigCompany;
            },
        },
        async created() {
            this.init();
            let myDepartments = JSON.parse(await this.$getItem("__myDepartments"));
            this.departmentId = myDepartments[0].id
        }
    }
</script>

<style scoped>

</style>