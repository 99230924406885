<!--
* @program: TYHOA2 
* @author: ly
* @component:UserPicker 
* @description: return [{id,name}]
* @create: 2023-09-21 17:39
-->
<template>
    <div style="width:100%;">
        <div v-if="selectedUsers.length>0" style="display: flex;flex-wrap: wrap;align-items: center;border: 1px dashed #c8c8c8;">
            <div v-for="(user,index) in selectedUsers.slice(0, 6)" :key="user.id">
                <a-tag v-if="index < 5" color="blue" style="cursor: pointer;margin:5px;height: 20px">
                    <template #icon>
                        <CloseOutlined @click="remove(index)" style="color: #630000;"/>
                    </template>
                    <span>{{user.name}}</span>
                </a-tag>
                <a-popover :overlayStyle="{'max-width': '400px'}" v-else trigger="hover" placement="bottom" >
                    <template #content>
                        <a-tag v-for="(user,index) in selectedUsers.slice(6, selectedUsers.length)" :key="user.id" color="blue" style="cursor: pointer;margin:5px;height: 20px">
                            <template #icon>
                                <CloseOutlined @click="remove(index)" style="color: #630000;"/>
                            </template>
                            <span>{{user.name}}</span>
                        </a-tag>
                    </template>
                    <a-tag color="blue" style="cursor: pointer;margin:5px;height: 20px">
                        <span>...</span>
                    </a-tag>
                </a-popover>
            </div>
        </div>
        <a-radio-group v-model:value="type" @change="changeHandler">
            <a-radio value="1">按部门</a-radio>
            <a-radio value="2">按姓名</a-radio>
        </a-radio-group>
    </div>
    <div v-if="type === '1'" style="display: flex;height: 200px;">
        <a-space direction="vertical" style="overflow:auto;width:30%;min-width: 150px;">
            <a-spin :spinning="loading">
                <div v-for="department in departments" :key="department.id">
                    <a-tag @click="selectDep(department)"
                           style="cursor: pointer;margin:5px;"
                           :color="currentDepart === department?'blue':''">
                        <span>{{department.name}}</span>
                    </a-tag>
                </div>
            </a-spin>
        </a-space>
        <div style="display: flex;flex-wrap: wrap;align-content: flex-start;width:70%;height: 100%;">
            <a-tag @click="selectUser(user)" v-for="(user) in departMembers" :key="user.id" :color="getColor(user)"
                   style="cursor: pointer;margin:5px;height: 20px">
                <span style="color:#630000" v-if="currentDepart.leaderUser === user.id">负责人:{{user.name}}</span>
                <span v-else>{{user.name}}</span>
            </a-tag>
        </div>
    </div>
    <div v-if="type === '2'" style="width:100%;height: 200px;overflow: auto">
        <a-input v-model:value="keyValue" style="width:150px" placeholder="姓名" />
        <div style="display: flex;flex-wrap: wrap;align-content: flex-start;">
            <a-tag @click="selectUser(user)" v-for="(user) in users" :key="user.id" :color="getColor(user)"
                   style="cursor: pointer;margin:5px;height: 20px">
                <span >{{user.name}}</span>
            </a-tag>
        </div>
    </div>
</template>

<script>
    import userDepartRelation from "../../assets/api/userDepartRelation";
    import {CloseOutlined} from "@ant-design/icons-vue"
    import userInfo from "../../assets/api/userInfo";
    export default {
        name: "UserPicker",
        components:{
            CloseOutlined
        },
        props:{
            value:{
                required:true,
                default:[]
            }
        },
        emits:["update:value"],
        computed:{
            users(){
                if(this.keyValue){
                    return this.allUsers.filter(item=>{
                        return item?.name.includes(this.keyValue)
                    })
                }
                else{
                    return this.allUsers
                }
            }
        },
        data() {
            return {
                selectedUsers:[],
                departments:[],
                currentDepart:null,
                departMembers:[],
                loading:false,
                type:"1",
                allUsers:[],
                keyValue:null
            }
        },
        watch:{
            value(){
                this.init()
            }
        },
        methods: {
            init() {
                if(this.value){
                    this.selectedUsers = [...this.value];
                }
            },
            changeHandler(){
                if(this.type === "2" && this.allUsers.length === 0){
                    this.getAllUser();
                }
            },
            getColor(user){
                let include = this.selectedUsers.find(item =>{
                    return item.id === user.id
                });
                if(include){
                    return "blue"
                }
                else{
                    return ""
                }
            },
            selectDep(department){
                this.currentDepart = department;
                let data = {
                    departmentId:this.currentDepart.id,
                    disableState:"1",
                    haveLevel:"0"
                };
                this.departMembers = [];
                this.loading = true;
                userDepartRelation.queryAll.urlPost(data,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        for(let user of res.data.data){
                            let {userId,userName} = user;
                            if(userName !=="超级管理员"){
                                this.departMembers.push({id:userId,name:userName});
                            }
                        }
                    }
                })
            },
            selectUser(user){
                let include = this.selectedUsers.find(item =>{
                    return item.id === user.id
                });
                if(include){
                    this.selectedUsers = this.selectedUsers.filter(item =>{
                        return item.id !== user.id
                    })
                }
                else{
                    this.selectedUsers.push(user);
                }
                this.$emit("update:value",this.selectedUsers)
            },
            remove(index){
                this.selectedUsers.splice(index,1);
            },
            getAllUser(){
                userInfo.queryAll.urlPost({disableState:"1"},res=>{
                    if(res.data.flag){
                        res.data.data = res.data.data.filter(item=>item.name !=="超级管理员"); //删除超级管理员账号
                        for(let i in res.data.data){
                            this.allUsers.push({id:res.data.data[i].id,name:res.data.data[i].name});
                            this.allUsers.sort((a, b) => a.name.localeCompare(b.name, 'zh-Hans-CN'))
                        }
                    }
                })
            }
        },
        created() {
            this.$getItem("__departments").then(str=>{
                this.departments = JSON.parse(str);
            });
            this.init()
        }
    }
</script>

<style scoped>

</style>