<!--
* @program: TYHOA2 
* @author: ly
* @component:QCFiles 
* @description: 
* @create: 2024-10-10 19:49
-->
<template>
    <div class="column-flex" >
        <div style="display: flex;margin: 0 8px">
            <a-button  type="dashed"  @click="init">
                <template #icon><SyncOutlined /></template>
            </a-button>
            <a-button type="dashed" v-if="!viewModel" @click="addData">
                <template #icon><PlusOutlined /></template>
                添加《成果质量证明文件》
            </a-button>
        </div>
        <a-divider />
        <a-spin :spinning="loading" tip="正在查询数据库...">
            <div style="width: 100%;overflow: auto;display: flex;flex-wrap: wrap;" v-if="dataSource.length>0">
                <a-card hoverable v-for="(item,idx) in dataSource" :key="idx" style="width: 400px;margin:5px">
                    <template #title>
                    <span class="card-title">
                        {{item.docName}}
                    </span>
                    </template>
                    <template #extra>
                        <a-popover placement="bottom" trigger="hover" >
                            <template #content>
                                <div v-if="!viewModel" class="card-edit">
                                    <span @click="editDetail(item)">修改</span>
                                </div>
                                <div v-if="!viewModel" class="card-edit">
                                    <a-popconfirm
                                            @confirm="deleteData(idx,item)"
                                            cancel-text="否"
                                            class="action-button"
                                            ok-text="是"
                                            title="确定删除吗?">
                                        <span>删除</span>
                                    </a-popconfirm>
                                </div>
<!--                                <div v-if="item.approveState ==='1'">已通过审核无法修改</div>-->
                            </template>
                            <a-button style="padding: 0 5px" type="link">
                                <UnorderedListOutlined style="font-size: 22px;"/>
                            </a-button>
                        </a-popover>
                    </template>
                    <LForm :form-columns="cols2.filter(it=>it.dataIndex !== 'docName')" :form-data="item" :view-model="true" />
                </a-card>
            </div>
            <a-result status="404" title="暂无成果质量证明文件" v-else/>
        </a-spin>
    </div>
    <a-modal
            width="600px"
            :open="modalVisible"
            :footer="null" title="文件详情"
            destroyOnClose
            :maskClosable="vModel"
            @cancel="modalVisible=false">
        <a-radio-group  style="margin-left:10px" v-model:value="docName">
            <a-radio v-for="name in fileNames" :key="name" :value="name">{{name}}</a-radio>
        </a-radio-group>
        <LForm :viewModel="vModel" :form-columns="cols2" :form-data="formData" >
            <template #action="{record}">
                <a-button  type="primary"  @click="saveFile(record)">
                    提交
                </a-button>
            </template>
        </LForm>
    </a-modal>
</template>

<script>

    import {PlusOutlined, SyncOutlined, UnorderedListOutlined} from "@ant-design/icons-vue";
    import LForm from "../../../../components/form/LForm";
    import Column from "../../../../assets/tables/column";
    import ColumnType from "../../../../assets/tables/column_type";
    import api_item_info from "../../../../assets/api/itemInfo";
    import ItemDoc from "../../../../assets/api/ItemDoc";

    export default {
        name: "QCFiles",
        inject:["pProjectId"],
        props:["viewModel"],
        components: {
            PlusOutlined, SyncOutlined,UnorderedListOutlined,
            LForm
        },
        data() {
            return {
                mFormData:{},
                modalVisible:false,
                loading:false,
                formData:null,//提供Form的formData
                cols2:[
                    new Column("文件名称","docName",ColumnType.String),
                    new Column("文件描述","describe",ColumnType.String,true),
                    new Column("扫描附件","docFile",ColumnType.PreViewFile,true),
                    new Column("审核状态","approveState",ColumnType.Enum,false)
                        .setDictEnum("item_progress_apply.approveState"),
                ],
                dataSource:[],
                vModel:false,
                noticeMark:"0",
                fileNames:[
                    "项目验收报告","第三方质检报告","质量承诺函","其他成果质量证明文件"
                ],
                docName:"验收报告",

            }
        },
        methods: {
            init() {
                if (this.pProjectId) {
                    api_item_info.queryOne.urlPost({id: this.pProjectId}, res => {
                        if (res.data.flag) {
                            this.mFormData = res.data.data;
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    });
                    this.dataSource = [];
                    this.loading = true;
                    ItemDoc.queryAll.post({"itemId": this.pProjectId,},res=>{
                        this.loading = false;
                        if(res.data.flag){
                            this.dataSource = res.data.data.filter(item=>this.fileNames.indexOf(item.docName)!== -1);
                        }
                        else{
                            this.dataSource = []
                        }
                    })
                }
            },
            editDetail(record){
                this.formData = record;
                this.modalVisible = true;
                this.vModel = false;
            },
            deleteData(index,record){
                let data={id:record.id};
                ItemDoc.delete.urlPost(data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.dataSource.splice(index,1);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            saveFile(record){
                record.itemId = this.pProjectId;
                if(!record.id){
                    record.noticeMark = this.noticeMark;
                    record.docName = this.docName;
                }
                ItemDoc.saveOrUpd.post(record,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.modalVisible = false;
                        this.init()
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            addData(){
                this.formData = {
                    docName:"",
                    describe:"",
                    docFile:"",
                };
                this.modalVisible = true;
                this.vModel = false;
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>