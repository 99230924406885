<!--
* @program: TYHOA2 
* @author: ly
* @component:Welcome 
* @description: 
* @create: 2023-08-28 16:42
-->
<template>
    <div class="pre-main non-select">
        <div style="font-size: 24px;display: flex;align-items: center;justify-content: center;">
            欢迎使用{{$config.cnName}}<sub style="margin-left: 10px">{{$config.version}}</sub>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Welcome",
    }
</script>

<style scoped>
    .pre-main{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        background: url("../../assets/image/preMain/BG.png");
    }
</style>