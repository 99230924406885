<!--
* @program: TYHOA2 
* @author: ly
* @component:QCReport 
* @description: 
* @create: 2024-09-29 17:30
-->
<template>
    <div class="column-flex" style="position: relative;overflow: hidden">
        <div class="search-bar">
            <a-tooltip title="刷新">
                <a-button  type="dashed"  @click="init">
                    <template #icon><SyncOutlined /></template>
                </a-button>
            </a-tooltip>
            <a-button  v-if="!viewModel" type="dashed"  @click="addReport">
                <template #icon><PlusOutlined /></template>
                添加报告
            </a-button>
            <a-button v-if="!viewModel && apply.state === '0' && dataSource.length>0"  type="dashed"  @click="finishTask">
                提交质检完成
            </a-button>
        </div>
        <div v-if="dataSource.length>0">
            <a-card v-for="item in dataSource" style="width:400px" :key="item.id">
                <template #title>
                    <span class="card-title">
                        质检报告
                    </span>
                </template>
                <template #extra>
                    <a-popover v-if="!viewModel" placement="bottom" trigger="hover" >
                        <template #content>
                            <div class="card-edit">
                                <span @click="editDetail(item)">修改</span>
                            </div>
                            <div class="card-edit">
                                <a-popconfirm
                                        @confirm="deleteData(item)"
                                        cancel-text="否"
                                        class="action-button"
                                        ok-text="是"
                                        title="确定删除吗?">
                                    <span>删除</span>
                                </a-popconfirm>
                            </div>
                        </template>
                        <a-button style="padding: 0 5px" type="link">
                            <UnorderedListOutlined style="font-size: 22px;"/>
                        </a-button>
                    </a-popover>
                </template>
                <VForm :form-columns="tableColumns" :form-data="item" :view-model="true"/>
            </a-card>
        </div>
        <a-result status="404" title="质检部门还未上传二检报告" v-else/>
    </div>
    <a-modal
            :footer="null"
            :maskClosable="vModel"
            v-model:open="visible"
            destroyOnClose
            :title="null"
            width="600px">
        <VForm :form-columns="tableColumns" :form-data="currentRecord" :view-model="vModel">
            <template #action="{record}">
                <a-button @click="saveData(record)"  type="primary">
                    提交
                </a-button>
            </template>
        </VForm>
    </a-modal>
    <a-modal v-model:open="visible2"
             destroy-on-close
             title="提交任务完成情况"
             :footer="null">
        <a-row style="margin: 10px">
            <a-col :span="8">
                <div class="form-label" style="margin-right: 10px">完成时间</div>
            </a-col>
            <a-col :span="16">
                <div class="form-value" >
                    <a-date-picker style="width: 200px" v-model:value="taskDate" />
                </div>
            </a-col>
        </a-row>
        <a-row style="margin: 10px">
            <a-col :span="8">
                <div class="form-label" style="margin-right: 10px">完成描述</div>
            </a-col>
            <a-col :span="16">
                <div class="form-value" >
                    <a-input style="width: 100%" v-model:value="describe" />
                </div>
            </a-col>
        </a-row>
        <a-button @click="saveData2"  type="primary">
            提交
        </a-button>
    </a-modal>
</template>

<script>
    import qcReportApply from "../../../../assets/api/qcReportApply";
    import {
        PlusOutlined,
        SyncOutlined,
        UnorderedListOutlined
    } from "@ant-design/icons-vue";
    import Column from "@/assets/tables/column";
    import ColumnType from "@/assets/tables/column_type"
    import VForm from "../../../../components/form/VForm";
    import qcApply from "../../../../assets/api/qcApply";
    import itemInfo from "../../../../assets/api/itemInfo";
    export default {
        name: "QCReport",
        inject:["pProjectId"],
        props:["apply","viewModel"],
        emits:["finish"],
        components:{
            PlusOutlined,SyncOutlined,UnorderedListOutlined,
            VForm
        },
        data() {
            return {
                dataSource:[],
                defaultQuery:{
                    itemId:this.pProjectId,
                    applyId:this.apply.id
                },
                tableColumns: [
                    new Column("质检总结","qcSummary",ColumnType.String,true).setRequired(),
                    new Column("质检评价","assessment",ColumnType.String,true).setRequired().setDictEnum("assessment"),
                    new Column("质检文件","enclosureUrl",ColumnType.PreViewFile,true).setRequired(),
                    //new Column("下次质检时间","qcNextDate",ColumnType.Date,true),
                ],
                vModel:true,
                visible:false,
                visible2:false,
                currentRecord:{},
                taskDate:undefined,
                describe:undefined
            }
        },
        methods: {
            init() {
                this.dataSource = [];
                qcReportApply.queryAll.urlPost({itemId:this.pProjectId},res=>{
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                })
            },
            addReport(){
                this.currentRecord = {};
                this.visible = true;
                this.vModel = false;
            },
            showDetail(item){
                this.currentRecord = item;
                this.visible = true;
                this.vModel = true;
            },
            editDetail(item){
                this.currentRecord = item;
                this.visible = true;
                this.vModel = false;
            },
            deleteData(item){
                let data = {id:item.id};
                qcReportApply.delete.urlPost(data,res=>{
                    if(res.data.flag){
                        this.dataSource = this.dataSource.filter(item =>item.id !== item.id)
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            saveData(record){
                record.applyId = this.apply.id;
                qcReportApply.saveOrUpd.post(record,res=>{
                    if(res.data.flag){
                        this.init()
                        this.visible = false;
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            finishTask(){
                this.taskDate = undefined;
                this.describe = undefined;
                this.visible2 = true;
            },
            saveData2(){
                let data = {
                    id:this.apply.id,
                    state:"1"
                };
                qcApply.complete.urlPost(data,res=>{
                    if(res.data.flag){
                        this.visible2 = false;
                        this.$emit("finish",this.apply.id);
                        this.$message.success(res.data.msg);
                        itemInfo.updByFiled.post({id:this.apply.itemId,qualityState:"3"})
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>