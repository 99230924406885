<!--
* @program: TYHOA2 
* @author: ly
* @component:DepartmentMaterialBuy 
* @description: 部门耗材采买
* @create: 2024-10-16 13:53
-->
<template>
    <div class="column-flex">
        <div class="search-bar">
            <a-button @click="init"  type="dashed">
                <template #icon>
                    <SyncOutlined/>
                </template>
            </a-button>
            <a-button @click="addData"  type="dashed">
                <template #icon>
                    <PlusOutlined/>
                </template>
            </a-button>
            <a-select v-model:value="defaultQuery.applyDept"
                      style="width: 200px"
                      @change="init">
                <a-select-option v-for="department in myDepartments" :key="department.id">
                    {{ department.name }}
                </a-select-option>
            </a-select>
            <div style="margin: 10px;color: black">部门审核:</div>
            <a-radio-group  style="margin-left:15px;" @change="handleChange" v-model:value="defaultQuery.deptState">
                <a-radio  value="0">未审核</a-radio>
                <a-radio  value="1">已通过</a-radio>
                <a-radio  value="2">未通过</a-radio>
            </a-radio-group>
            <div style="margin: 10px;color: black">下发状态:</div>
            <a-radio-group  style="margin-left:15px;" @change="handleChange2" v-model:value="defaultQuery.state">
                <a-radio  value="0">未下发</a-radio>
                <a-radio  value="1">已下发</a-radio>
            </a-radio-group>
            <a-divider type="vertical" style="height:32px;border-left: #022d5c solid;" />
            <a-input  placeholder="耗材名" style="width: 150px" v-model:value="filter.name" />
        </div>
        <div class="row-flex">
            <LTable :columns="tableColumns" :dataSource="source" :loading="loading">
                <template #action="{record}">
                    <div class="action-column">
                        <a-tooltip title="查看审核情况">
                            <a-button @click="showStep(record)" type="link">
                                <template #icon>
                                    <SearchOutlined/>
                                </template>
                            </a-button>
                        </a-tooltip>
                        <a-button v-if="record.deptState !=='1' " class="action-button" type="link" @click="editDetail(record)">
                            <EditFilled class="pointer"/>
                        </a-button>
                        <a-popconfirm
                                v-if="record.deptState !=='1' "
                                ok-text="是"
                                cancel-text="否"
                                class="action-button"
                                title="确定删除吗?"
                                @confirm="deleteData(record)">
                            <DeleteOutlined  style="color: red" class="pointer"/>
                        </a-popconfirm>
                        <a-tooltip title="审核" v-if="record.deptState !=='1'">
                            <a-button @click="auditRow(record)" class="action-button" type="link">
                                审核
                            </a-button>
                        </a-tooltip>
                    </div>
                </template>
            </LTable>
        </div>
    </div>
    <a-modal :footer="null"
             title="审核"
             destroyOnClose
             v-model:open="visible">
        <a-textarea :auto-size="{ minRows: 2, maxRows: 6 }" v-model:value="reason"/>
        <a-divider />
        <a-button @click="saveAudit('1')"  style="margin: 20px" type="primary">
            通过
        </a-button>
        <a-button @click="saveAudit('2')"  style="margin: 20px" type="primary" danger>
            不通过
        </a-button>
    </a-modal>
    <a-modal :footer="null"
             title="审核"
             width="600px"
             destroyOnClose
             v-model:open="visible2">
        <div class="form-title">部门审核</div>
        <div>
            <a-row style="margin:10px;border-bottom: #c8c8c8 1px solid;">
                <a-col :span="6">
                    <span class="form-label">状态</span>
                </a-col>
                <a-col :span="6">
                <span class="form-value">
                    {{currentRecord.deptState==="0"?"待审核":(currentRecord.deptState==="1"?"已通过":"未通过")}}
                </span>
                </a-col>
            </a-row>
            <a-row v-if="currentRecord.deptState !== '0'" style="margin:10px;border-bottom: #c8c8c8 1px solid;">
                <a-col :span="6">
                    <span class="form-label">审核人</span>
                </a-col>
                <a-col :span="6">
                <span class="form-value">
                    {{currentRecord.deptUserName}}
                </span>
                </a-col>
                <a-col :span="6">
                    <span class="form-label">时间</span>
                </a-col>
                <a-col :span="6">
                <span class="form-value">
                    {{new Date(currentRecord.deptTime).toLocaleDateString()}}
                </span>
                </a-col>
            </a-row>
            <a-row v-if="currentRecord.deptState !== '0'" style="margin:10px;border-bottom: #c8c8c8 1px solid;">
                <a-col :span="6">
                    <span class="form-label">审核意见</span>
                </a-col>
                <a-col :span="18">
                    <span class="form-value">{{currentRecord.deptRemark}}</span>
                </a-col>
            </a-row>
        </div>
        <div class="form-title">办公室审核</div>
        <div>
            <a-row style="margin:10px;border-bottom: #c8c8c8 1px solid;">
                <a-col :span="6">
                    <span class="form-label">状态</span>
                </a-col>
                <a-col :span="6">
                <span class="form-value">
                    {{currentRecord.officeState==="0"?"待审核":(currentRecord.officeState==="1"?"已通过":"未通过")}}
                </span>
                </a-col>
            </a-row>
            <a-row v-if="currentRecord.officeState !== '0'" style="margin:10px;border-bottom: #c8c8c8 1px solid;">
                <a-col :span="6">
                    <span class="form-label">审核人</span>
                </a-col>
                <a-col :span="6">
                <span class="form-value">
                    {{currentRecord.officeUserName}}
                </span>
                </a-col>
                <a-col :span="6">
                    <span class="form-label">时间</span>
                </a-col>
                <a-col :span="6">
                <span class="form-value">
                    {{new Date(currentRecord.officeTime).toLocaleDateString()}}
                </span>
                </a-col>
            </a-row>
            <a-row v-if="currentRecord.officeState !== '0'" style="margin:10px;border-bottom: #c8c8c8 1px solid;">
                <a-col :span="6">
                    <span class="form-label">审核意见</span>
                </a-col>
                <a-col :span="18">
                    <span class="form-value">{{currentRecord.officeRemark}}</span>
                </a-col>
            </a-row>
        </div>
    </a-modal>

    <a-modal :footer="null"
             title="申请详情"
             width="800px"
             destroyOnClose
             v-model:open="visible3">
        <LForm :labelSpan="6" :view-model="false" :form-data="currentRecord" :form-columns="tableColumns">
            <template #action="{record}">
                <a-button @click="saveData(record)"  type="primary">
                    提交
                </a-button>
            </template>
        </LForm>
    </a-modal>
</template>

<script>
    import LTable from "../../../components/table/LTable";
    import {SearchOutlined, SyncOutlined, PlusOutlined, EditFilled, DeleteOutlined} from "@ant-design/icons-vue";
    import {ArrayFilter} from "../../../assets/utils/general";
    import Column from "../../../assets/tables/column";
    import ColumnType from "../../../assets/tables/column_type";
    import materialApply from "../../../assets/api/materialApply";
    import LForm from "../../../components/form/LForm";
    export default {
        name: "DepartmentMaterialBuy",
        components: {
            LTable,LForm,
            SearchOutlined,
            SyncOutlined,PlusOutlined,EditFilled,DeleteOutlined
        },
        computed:{
            source(){
                return ArrayFilter(this.dataSource,this.filter);
            }
        },
        data() {
            return {
                searchData: null,
                dataSource: [],
                tableColumns: [
                    new Column("耗材名","name",ColumnType.String,true).setTableView(150),
                    new Column("规格", "standards",ColumnType.String,true).setTableView(150),
                    new Column("数量","count",ColumnType.Number,true).setTableView(100),
                    new Column("单位","unit",ColumnType.String,true).setTableView(100),
                    //new Column("总价格（元）","amount",ColumnType.Number,false).setTableView(120),
                    new Column("申请时间", "applyMonth",ColumnType.Month).setTableView(100),//payBankNum+payBankName
                    new Column("申请人","applyUserName",ColumnType.String).setTableView(100),//payBankNum+payBankName
                    new Column("申请部门","applyDeptName",ColumnType.String),
                    new Column("申请说明","remarks",ColumnType.String,true).setTableView(150),
                    new Column("部门审核","deptState",ColumnType.String).setTableView(100)
                        .setDictEnum("auditState"),
                    new Column("办公室","officeState",ColumnType.String).setTableView(100)
                        .setDictEnum("auditState"),
                    new Column("下发","state",ColumnType.String).setTableView(100)
                        .setEnum({value:"0",label:"未下发",color:"red"},{value:"1",label:"已下发",color:"blue"}),
                    new Column("操作","actions","actions",false).setTableView(120)
                ],
                loading: false,
                defaultQuery: {
                    applyDept:"",
                    deptState:"0",
                    state:"0"
                },
                filter:{},
                visible: false,
                visible2: false,
                visible3: false,
                currentRecord: null,
                reason: null,
                myDepartments:[]
            }
        },
        methods: {
            init() {
                this.loading = true;
                materialApply.queryAll.post(this.defaultQuery, res => {
                    if (res.data.flag) {
                        this.dataSource = res.data.data;
                    } else {
                        this.dataSource = [];
                    }
                    this.loading = false;
                });
            },
            handleChange(){
                if(this.defaultQuery.deptState === "0"){
                    this.defaultQuery.state = "0"
                }
                this.init()
            },
            handleChange2(){
                if(this.defaultQuery.state === "1"){
                    this.defaultQuery.deptState = "1";
                }
                this.init()
            },
            auditRow(record) {
                this.visible = true;
                this.reason = "满足申请条件";
                this.currentRecord = record;
            },
            saveAudit(state) {
                let data = {
                    "id": this.currentRecord.id,
                    "deptState": state,
                    "deptRemark": this.reason
                };
                materialApply.deptApprov.urlPost(data, res => {
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        this.init();
                        this.visible = false;
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
            },
            showStep(record) {
                this.visible2 = true;
                this.currentRecord = record;
            },
            addData(){
                this.visible3 = true;
                this.currentRecord = {};
            },
            editDetail(record){
                this.visible3 = true;
                this.currentRecord = record;
            },
            deleteData(record){
                let data ={id:record.id};
                materialApply.delete.urlPost(data,res=>{
                    if(res.data.flag){
                        this.init();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            saveData(record){
                materialApply.saveOrUpd.post(record, res => {
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        this.init();
                        this.visible3 = false;
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
            }
        },
        async created() {
            this.myDepartments = JSON.parse(await this.$getItem("__myDepartments"));
            this.defaultQuery.applyDept = this.myDepartments[0].id;
            this.init();
        }
    }
</script>

<style scoped>

</style>