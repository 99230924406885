<!--
* @program: TYHOA2 
* @author: ly
* @component:BillStatisticsByCategoryId 
* @description: 按费用类别统计
* @create: 2024-10-30 15:48
-->
<template>
    <div class="column-flex">
        <div class="search-bar">
            <div style="margin: 8px;font-weight: 900;font-size: 16px"> 统计时间：</div>
            <a-radio-group style="margin-left: 10px" v-model:value="picker" >
                <a-radio value="month">月份</a-radio>
                <a-radio value="year">年度</a-radio>
            </a-radio-group>
            <a-date-picker  v-model:value="queryTime" :picker="picker" />
            <a-tree-select
                    show-search
                    v-model:value="categoryId"
                    style="width: 250px"
                    placeholder="输入查询/选择子分类"
                    :field-names="fieldNames"
                    :treeData="treeData"
                    :treeNodeFilterProp="'categoryName'"
            />
            <a-button @click="doSearch" >
                <template #icon>
                    <SearchOutlined/>
                </template>
                查询
            </a-button>
        </div>
        <div style="width: 100%;height: 100%;display: flex">
            <div class="main-content30">
                <LTable :title="title"
                        :loading="loading" :data-source="dataSource" :columns="cols" >
                    <template #action="{record}">
                        <div class="action-column">
                            <a-button @click="showDetail(record)" type="link">详情</a-button>
                        </div>
                    </template>
                </LTable>
            </div>
            <div class="main-content70">
                <LTable :title="title2" :loading="loading2"
                        :data-source="source" :columns="cols2" >

                </LTable>
            </div>
        </div>
    </div>
</template>

<script>
    import category from "../../../../assets/api/category";
    import fundSummary from "../../../../assets/api/fundSummary";
    import {SearchOutlined} from "@ant-design/icons-vue"
    import Column from "@/assets/tables/column";
    import ColumnType from "@/assets/tables/column_type";
    import LTable from "../../../../components/table/LTable";
    import dayjs from "dayjs"
    import isBetween from 'dayjs/plugin/isBetween';
    import recordFlow from "../../../../assets/api/recordFlow";
    import userDepartRelation from "../../../../assets/api/userDepartRelation";
    export default {
        name: "BillStatisticsByCategoryId",
        components:{
            SearchOutlined,
            LTable
        },
        computed:{
            source() {
                if(this.queryTime){
                    return this.flows.filter(item => {
                        let flowTime = dayjs(item.flowTime);
                        const startOf = this.queryTime.startOf(this.picker);
                        const endOf = this.queryTime.endOf(this.picker);
                        return flowTime.isBetween(startOf, endOf, null, this.picker);
                    })
                }
                else {
                    return this.flows
                }
            },
            title(){
                let sum = 0;
                for(let item of this.dataSource){
                    sum += item.applyAmount
                }
                return `报销合计：${sum.toFixed(2)}元`;
            },
            title2(){
                let sum = 0;
                for(let item of this.source){
                    sum += item.applyAmount
                }
                return `报销合计：${sum.toFixed(2)}元`;
            }
        },
        data() {
            return {
                categoryId:undefined,
                fieldNames :{
                    label: 'categoryName',
                    key:"id",
                    value:"id",
                },
                treeData:[],
                queryTime:undefined,
                picker:"month",
                dataSource:[],
                cols:[
                    new Column("时间段", "dateStr", ColumnType.String).setTableView(100),
                    // new Column("有发票合计", "haveInvoiceAmount", ColumnType.Number).setTableView(100),
                    // new Column("无发票合计", "noInvoiceAmount", ColumnType.Number).setTableView(100),
                    new Column("报销总额", "applyAmount", ColumnType.Number).setTableView(100)
                ],
                cols2:[
                    new Column("支出时间", "flowTime", ColumnType.Date, true).setTableView(80),
                    new Column("项目支付", "itemName", ColumnType.String, true).setTableView(80),
                    new Column("报销金额(元)", "applyAmount", ColumnType.Number, true).setTableView(80),
                    new Column("报账人", "createUserName", ColumnType.String).setTableView(80),
                    new Column("部门", "departmentName", ColumnType.String).setTableView(80),
                    new Column("备注", "remarks", ColumnType.String, true).setTableView(150)
                ],
                loading:false,
                loading2:false,
                flows:[],
                flowsId:undefined,
                currentRecord:{},
                userRelation:[],
            }
        },
        methods: {
            doSearch(){
                if(!this.categoryId){
                    this.$message.error("请选择费用类别");
                    return
                }
                this.loading = true;
                let data = {
                    categoryId:this.categoryId
                };
                if(this.queryTime){
                    data.yearMonth = this.picker === "month"?this.queryTime.format("YYYY-MM"):this.queryTime.format("YYYY")
                }
                fundSummary.statistic.urlPost(data,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                        this.dataSource.sort((a, b) => {
                            let ad = dayjs(a.dateStr);
                            let bd = dayjs(b.dateStr);
                            return ad.valueOf() - bd.valueOf()
                        });
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
                if(this.flowsId !== this.categoryId){
                    this.flowsId = this.categoryId;
                    let data2 = {
                        categoryId: this.flowsId,
                        entryState: "1"
                    };
                    this.flows = [];
                    this.loading2 = true;
                    recordFlow.queryAll.urlPost(data2, res => {
                        this.loading2 = false;
                        if (res.data.flag) {
                            this.flows = res.data.data.sort((a,b)=>{
                                return a.flowTime - b.flowTime
                            });

                            this.flows.map(item=>{
                                let user = this.userRelation.find(it=>it.userId === item.createUser);
                                if(user) {
                                    item.createUserName = user.userName;
                                    item.departmentName = user.departmentName
                                }
                                else{
                                    item.createUserName = "已离职";
                                }
                                return item
                            })
                        }
                    })
                }
            },
            showDetail(record){
                this.currentRecord = record;
            }
        },
        created() {
            dayjs.extend(isBetween);
            category.queryOneLevel.post({},res=>{
                if(res.data.flag){
                    this.treeData = res.data.data;
                }
                else{
                    this.$message.error(res.data.msg);
                }
            })
            userDepartRelation.queryAll.post({},res=>{
                if(res.data.flag){
                    this.userRelation = res.data.data;
                }
                else{
                    this.$message.error(res.data.msg);
                }
            })
        }
    }
</script>

<style scoped>

</style>