<!--
* @program: office_automation
* @author: ly
* @component:OtherInflow
* @description: 其他收入（除项目收入之外）
* @create: 2022-02-16 16:55
-->
<template>
    <div class="column-flex">
        <div class="form-value">
            <a-button @click="init" type="dashed">
                <template #icon><SyncOutlined/></template>
            </a-button>
            <span > 收入合计：{{summary.toFixed(2)}} 元</span>
            <a-divider style="height:32px;border-left: #022d5c solid;" type="vertical"/>
            <a-range-picker style="width: 200px" v-model:value="rangeMonth" picker="month" />
            <a-input  placeholder="项目名称" style="width: 150px" v-model:value="filter.itemName" />
        </div>
        <div style="height:600px;overflow:auto">
            <LTable :columns="tableColumns" :data-source="source" />
        </div>
    </div>
</template>

<script>
import dayjs from "dayjs";
import LTable from "@/components/table/LTable";
import income from "@/assets/api/income";
import {SyncOutlined} from "@ant-design/icons-vue";
import {ArrayFilter} from "../../../../assets/utils/general";
import Column from "../../../../assets/tables/column";
import ColumnType from "../../../../assets/tables/column_type";
export default {
    name: "OtherInflow",
    props:["record","value"],
    components: {
        LTable,SyncOutlined
    },
    computed:{
        source(){
            let source = [];
            if(this.rangeMonth){
                source = this.dataSource.filter(item=>{
                    let payDate = dayjs(item.collectDate);
                    const startOfMonth = this.rangeMonth[0].startOf('month');
                    const endOfMonth = this.rangeMonth[1].endOf('month');
                    return payDate.isAfter(startOfMonth) && payDate.isBefore(endOfMonth);
                });
                return ArrayFilter(source,this.filter);
            }
            else{
                return ArrayFilter(this.dataSource,this.filter);
            }
        },
        summary(){
            let sum = 0;
            for(let item of this.source){
                let date = dayjs(item.collectDate);
                if (date.isAfter(dayjs(this.record.balanceDate))) {
                    sum += item.collectAmount;
                }
            }
            return sum
        }
    },
    watch:{
        record(){
            this.init()
        }
    },
    data(){
        return{
            dataSource:null,
            tableColumns:[
                new Column("收入类别","categoryId",ColumnType.Category,false).setTableView(80),
                new Column("收入日期","collectDate",ColumnType.Date,true).setTableView(80),
                new Column("收款方式","collectType",ColumnType.Enum,true).setTableView(80)
                    .setEnum(
                        {value:"1",label: "现金",color:"green"},
                        {value:"2",label: "对公打款",color:"green"},
                    ),
                new Column("金额（元）","collectAmount",ColumnType.Number,true).setTableView(80),
                new Column("所属项目","itemName",ColumnType.String).setTableView(100),
                new Column("说明","remarks",ColumnType.String,true).setTableView(200),
            ],
            loading:false,
            rangeMonth:undefined,
            filter:{}
        }
    },
    methods:{
        init(){
            this.loading = true;
            this.dataSource = [];
            let data = {
                bankId:this.record.id
            };
            income.queryAll.post(data,res=>{
                this.loading = false;
                if(res.data.flag){
                    this.dataSource = res.data.data.sort((a,b)=>{
                        return b.createTime - a.createTime
                    });
                    this.$emit("update:value",{value:this.summary,loading:false});
                }
                else{
                    this.$emit("update:value",{value:0,loading:false});
                }
            })
        },
        refresh(){
            this.init()
        },
    },
    created() {
        this.init();
    }
}
</script>

<style scoped>

</style>
