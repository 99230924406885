/** 
* 自动生成文件，请勿修改 
* @name: 系统:用户额外信息管理（人事需要）
* @description: 用户额外信息管理（人事需要）（三期新增）
* @create: 2024/10/18 15:02:19
* @swagger: 2.0
* 合计:6条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const userExtra = { 
	/** 1 summary:查询-分页
	description:参数：UserExtraQueryVO UserExtraQueryVO用户信息封装对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/userExtra/queryPage.do"), 
	/** 2 summary:查询-不分页（全部）
	description:参数：UserExtraQueryVO userExtraQueryVO用户信息封装对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/userExtra/queryAll.do"), 
	/** 3 summary:删除-批量
	description:参数：数据的主键id,逗号分隔：1,2,3,4,5.......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/userExtra/batchDelete.do"), 
	/** 4 summary:新增/修改-单条
	description:参数：UserExtraVO UserExtraVO用户信息对象封装
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpdate : new APIObject.postObject("/api/userExtra/saveOrUpdate.do"), 
	/** 5 summary:删除-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/userExtra/delete.do"), 
	/** 6 summary:根据id查询
	description:参数：id(主键id)
	method:post
	params:需要配合Column函数使用
	*/ 
	queryById : new APIObject.postObject("/api/userExtra/queryById.do"), 
};
export default userExtra;