/**
 * @program: oa2.0
 * @author: ly
 * @description: EquipmentComp
 * @create: 2023-08-25 09:33
 * meta:{checkAuth:"notNeed"}
 **/


let EquipmentComp = [
    {path: '', component: ()=>import("@/page/Other/OAMenuDefaultPage/MenuList"), name:"EquipmentComp",children:()=>{return EquipmentComp}},
    {path: 'AssetsManager', name:"AssetsManager",cnName:"设备管理",component: ()=>import("@/page/Equipment/AssetsManager/AssetsManager")},
    {path: 'FixedAssetsManager', name:"FixedAssetsManager",cnName:"固定资产管理", component: ()=>import("@/page/Equipment/FixedAssetsManager/FixedAssetsManager")},
    {path: 'AssetsSetting', name:"AssetsSetting",cnName:"资产相关设定", component: ()=>import("@/page/Equipment/AssetsSetting/AssetsSetting")},
    {path: 'AssetsAuditCenter',name:"AssetsAuditCenter",cnName:"设备审核",
        component: ()=>import("@/page/Equipment/AssetsAuditCenter/AssetsAuditCenter"),
        children:[
            {path: 'AssetsApplyAudit', name:"AssetsApplyAudit",cnName:"设备申请审核",
                component: ()=>import("@/page/Equipment/AssetsAuditCenter/AssetsApplyAudit")
            },
            {path: 'AssetsReturnAudit', name:"AssetsReturnAudit",cnName:"设备归还审核",
                component: ()=>import("@/page/Equipment/AssetsAuditCenter/AssetsReturnAudit")
            },
            {path: 'MaterialApplyAudit', name:"MaterialApplyAudit",cnName:"耗材采买审核",
                component: ()=>import("@/page/Equipment/AssetsAuditCenter/MaterialApplyAudit"),
                meta:{checkAuth:"parentAuth"}
            },
            {path: 'AssetsBuyAudit', name:"AssetsBuyAudit",cnName:"设备采买审核",
                component: ()=>import("@/page/Equipment/AssetsAuditCenter/AssetsBuyAudit"),
                meta:{checkAuth:"parentAuth"}
            },
            {path: 'AssetsImport', name:"AssetsImport",cnName:"采买设备入库",
                component: ()=>import("@/page/Equipment/AssetsAuditCenter/AssetsImport"),
                meta:{checkAuth:"parentAuth"}
            },
        ]
    },
];


export default EquipmentComp