import Table from "@/assets/tables/table";
import Column from "@/assets/tables/column";
import ColumnType from "@/assets/tables/column_type";
//import api_item_outsourc_info from "@/assets/api/partyInfo";

/**
 * @program: office_automation
 * @author: ly
 * @description: item_outsourc_pay_apply
 * @create: 2021-12-20 16:00
 *
 private String itemId;                      //项目id
 @NotNull(message = "外协单位/人员不能为空！")
 private String outSourceId;                 //外协单位/人员id
 private String contractId;                  //合同id(可能存在一个项目与同一家外协签订了不止一份合同)，也可能该项目没有签订外协合同
 @NotNull(message = "申请支付原由描述不能为空！")
 private String payReason;                   //申请支付原由描述
 @NotNull(message = "申请支付金额不能为空！")
 private BigDecimal payAmount;              //申请支付金额
 @NotNull(message = "支付公司不能为空！")
 private String payCompanyId;               //支付公司id（我们的5个公司的id,在甲方表中筛选）
 @NotNull(message = "申请支付部门不能为空！")
 private String applyDepart;                //申请支付部门id
 private String submitState;                //提交状态 0：未提交；1：已提交，未提交的可删改
 **/




export default class item_outsourc_pay_apply extends Table{
    constructor() {
        super();
        this.name = "item_outsourc_pay_apply";
        this.CNName = "外协支付申请";
        this.columnArray = [
            new Column("付款合同","contractName",ColumnType.String,false).setTableView(200),
            new Column("付款合同编号","contractNum",ColumnType.String,false),
            new Column("付款发票","invoiceId",ColumnType.Boolean,false).setBoolTags(["未绑定付款发票","已绑定付款发票"]),
            // new Column("收款单位/个人","outSourceId",ColumnType.IdReplace,true)
            //     .setIdReplaceObject("outSourceName",{api:api_item_outsourc_info.queryAll,tag:"queryText"}),
            new Column("收款单位/个人", "outSourceName", ColumnType.String, false),
            new Column("申请支付金额","payAmount",ColumnType.Number,true).setDefaultValue("0").setTableView(130),
            new Column("支付状态","payState",ColumnType.Enum,false).setTableView(90)
                .setDictEnum("payState"),
            new Column("申请部门", "applyDepartName", ColumnType.String, false),
            //new Column("支付备注","remarks",ColumnType.String,true).setTableView(100),
            new Column("付款公司", "payCompanyId", ColumnType.Enum)
                .setDictEnum("companies"),
            new Column("支付时间","payTime",ColumnType.Date),
            new Column("支付描述","payReason",ColumnType.String,true),

        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(120).setFixed("right")   ];

    }
}
