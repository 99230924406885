<!--
* @program: TYHOA2 
* @author: ly
* @component:InvoiceIncome 
* @description: 
* @create: 2024-09-30 09:54
-->
<template>
    <div class="column-flex" style="position: relative;overflow: hidden">
        <div class="search-bar">
            <a-button @click="init"   type="dashed">
                <template #icon><SyncOutlined/></template>
            </a-button>
            <a-input v-model:value="filter.itemName" style="width:200px" placeholder="项目名称" />
            <a-input v-model:value="filter.partyName" style="width:200px" placeholder="付款方" />
            <a-input v-model:value="filter.companyName" style="width:200px" placeholder="收款方" />
            <a-input-number v-model:value="filter.invoiceAmount" style="width:150px" placeholder="开票金额" />
        </div>
        <div class="row-flex">
            <LTable :loading="loading" :data-source="source" :columns="inCols" >
                <template #action="{record}">
                    <div class = "action-column">
                        <a-button :loading="record.loading" @click="showDetail(record)"
                                  class="action-button" type="link">
                            <SearchOutlined class="pointer"/>
                        </a-button>
                        <a-button @click="editDetail(record)"
                                  class="action-button" type="link">
                            <EditFilled class="pointer"/>
                        </a-button>
                    </div>
                </template>
            </LTable>
        </div>
        <a-drawer v-model:open="visible2"
                  placement="bottom"
                  height="90%"
                  :closable="false"
                  :get-container="false"
                  :style="{ position: 'absolute' }"
                  destroy-on-close>
            <div class="search-bar">
                <a-button @click="refresh"  type="dashed">
                    <template #icon><SyncOutlined/></template>
                </a-button>
                <a-button @click="addPayed"  type="dashed">
                    <template #icon><PlusOutlined/></template>
                    添加收款记录
                </a-button>
            </div>
            <LTable  :data-source="source2" :columns="flowCols" >
                <template #action="{record}">
                    <div class = "action-column">
                        <a-button @click="editFlow(record)"
                                  class="action-button" type="link">
                            <EditFilled class="pointer"/>
                        </a-button>
                        <a-popconfirm
                                ok-text="是"
                                cancel-text="否"
                                class="action-button"
                                title="确定删除吗?"
                                @confirm="deleteFlow(record)">
                            <DeleteOutlined  style="color: red" class="pointer"/>
                        </a-popconfirm>
                    </div>
                </template>
            </LTable>
        </a-drawer>
    </div>
    <a-modal v-model:open="visible"
             destroy-on-close
             :title="null"
             :footer="null">
        <LForm title="发票信息" :view-model="false"  :form-columns="inCols" :form-data="currentRecord">
            <template #action="{record}">
                <a-button @click="saveData(record)"  type="primary">
                    提交
                </a-button>
            </template>
        </LForm>
    </a-modal>
    <a-modal v-model:open="visible3"
             destroy-on-close
             title="收款"
             :footer="null">
        <a-row>
            <a-col :span="6">
                <span class="form-value">选择入账账户</span>
            </a-col>
            <a-col :span="18">
                <a-select :fieldNames="fieldNames" v-model:value="bankId" style="width: 100%" :options="bankCards"/>
            </a-col>
        </a-row>
        <LForm :view-model="false" :form-columns="flowCols" :form-data="formData">
            <template #action="{record}">
                <a-button @click="saveFlow(record)"  type="primary">
                    提交
                </a-button>
            </template>
        </LForm>
    </a-modal>
</template>

<script>
    import LTable from "../../../../components/table/LTable";
    import {DeleteOutlined, EditFilled, PlusOutlined, SearchOutlined, SyncOutlined} from "@ant-design/icons-vue";
    import LForm from "../../../../components/form/LForm";
    import invoiceRecord from "../../../../assets/api/invoiceRecord";
    import Column from "../../../../assets/tables/column";
    import ColumnType from "../../../../assets/tables/column_type";
    import collectionInfo from "../../../../assets/api/collectionInfo";
    import {ArrayFilter} from "../../../../assets/utils/general";
    export default {
        name: "InvoiceIncome",
        props: ["bankCards"],
        components:{
            LTable,LForm,
            EditFilled,DeleteOutlined,PlusOutlined,SearchOutlined,SyncOutlined
        },
        computed: {
            source() {
                return ArrayFilter(this.source1, this.filter);
            }
        },
        data() {
            return {
                filter:{},
                inCols:[
                    new Column("项目", "itemName", ColumnType.String,).setGroup(1).setTableView(250),
                    new Column("开票金额", "invoiceAmount", ColumnType.Number, true).setTableView(80),
                    new Column("已收款额", "collectAmount", ColumnType.Number, false).setTableView(80).setGroup(1),
                    new Column("收款状态", "collectState", ColumnType.Enum, false).setGroup(1).setDictEnum("collectState"),
                    new Column("开票日期", "invoiceTime", ColumnType.Date, true).setTableView(80),
                    new Column("发票代码", "invoiceCode", ColumnType.String, true),
                    new Column("发票号码", "invoiceNumber", ColumnType.String, true),
                    new Column("发票类型", "invoiceType", ColumnType.Enum,true).setDefaultValue("1")
                        .setDictEnum("invoiceType"),
                    new Column("检验码", "checkCode", ColumnType.String, true),
                    new Column("付款方", "partyName", ColumnType.String).setGroup(1).setTableView(100),
                    new Column("收款方", "companyName", ColumnType.String).setTableView(100),
                    new Column("冲红", "flushRed", ColumnType.Boolean, true).setGroup(2)
                        .setBoolTags(["未冲红", "已冲红"]).setDefaultValue(false),
                    new Column("操作", "actions", "actions", false).setTableView(90)
                ],
                visible:false,
                visible2:false,
                visible3:false,
                flowCols:[
                    new Column("收款时间","collectionTime",ColumnType.Date,true).setTableView(80),
                    new Column("收款金额(元)","collectionAmount",ColumnType.Number,true).setTableView(100),
                    new Column("入账银行","bankName",ColumnType.String,false).setTableView(120),
                    new Column("入账账户","bankNum",ColumnType.String,false).setTableView(120),
                    new Column("付款方", "payerName", ColumnType.String, false).setTableView(120),
                    new Column("收款方", "receiverId", ColumnType.Enum, false).setDictEnum("companies").setTableView(120),
                    new Column("收款备注","remarks",ColumnType.String,true).setTableView(200),
                    new Column("操作", "actions", "actions", false).setTableView(90)
                ],
                loading:false,
                loading2:false,
                source1:[],
                source2:[],
                currentRecord:{},
                bankId:undefined,
                fieldNames :{key:'id', value: 'id',label:'remarks'},
                formData:{}
            }
        },
        methods: {
            init() {
                this.loading = true;
                invoiceRecord.queryAll.post({},res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.source1 = res.data.data;
                        this.source1.sort((a, b) => {
                            return b.createTime - a.createTime;
                        });
                        this.source1.map(item=>{
                            item.itemName = item.itemInfo?.name;
                            item.partyName = item.partyInfo?.name;
                            item.companyName = item.companyInfo?.name;
                            return item;
                        })
                    }
                    else{
                        this.source1 = [];
                    }
                })
            },
            showDetail(record){
                this.source2 = [];
                this.visible2 = true;
                this.currentRecord = record;
                collectionInfo.queryAll.urlPost({invoiceId:this.currentRecord.id},res=>{
                    if(res.data.flag){
                        this.source2 = res.data.data;
                    }
                })
            },
            refresh(){
                collectionInfo.queryAll.urlPost({invoiceId:this.currentRecord.id},res=>{
                    if(res.data.flag){
                        this.source2 = res.data.data;
                    }
                })
            },
            editDetail(record){
                this.currentRecord = record;
                this.visible = true;
            },
            saveData(record){
                invoiceRecord.saveOrUpdate.post(record,res=>{
                    if(res.data.flag){
                        let index = this.source1.findIndex(item=>item.id === record.id)
                        this.source1[index] = record;
                        this.visible = false;
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            addPayed(){
                this.visible3 = true;
                this.formData = {}
            },
            editFlow(record){
                this.visible3 = true;
                this.formData = record;
                this.bankId = record.bankId;
            },
            saveFlow(record){
                if(record.id){
                    record.bankId = this.bankId;
                    collectionInfo.update.post(record,res=>{
                        if(res.data.flag){
                            this.refresh();
                            this.visible3 = false;
                            this.$message.success(res.data.msg);
                        }
                        else{
                            this.$message.error(res.data.msg);
                        }
                    });
                }
                else{
                    if(this.bankId){
                        record.bankId = this.bankId;
                        record.itemId = this.currentRecord.itemInfo.id;
                        record.invoiceId = this.currentRecord.id;
                        record.receiverId = this.currentRecord.companyInfo.id;
                        record.payerId = this.currentRecord.partyInfo.id;
                        collectionInfo.save.post(record,res=>{
                            if(res.data.flag){
                                this.refresh();
                                this.visible3 = false;
                                this.$message.success(res.data.msg);
                            }
                            else{
                                this.$message.error(res.data.msg);
                            }
                        });
                    }
                    else{
                        this.$message.error("还未选择入账账户")
                    }
                }
            },
            deleteFlow(record){
                let data = {id:record.id};
                collectionInfo.delete.urlPost(data,res=>{
                    if(res.data.flag){
                        this.refresh()
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>