<!--
* @program: TYHOA2 
* @author: ly
* @component:OtherPay 
* @description: 资金账户支出
* @create: 2024-09-18 15:01
-->
<template>
    <div class="column-flex">
        <div class="search-bar">
            <a-button @click="init" type="dashed">
                <template #icon><SyncOutlined/></template>
            </a-button>
            <span style="margin-left: 10px">筛选条件：</span>
            <a-select v-model:value="payType"
                      placeholder="类别"
                      style="width: 200px"
                      :options="payTypes" />
            <a-range-picker style="width: 200px" @change="rangeChange" v-model:value="rangeMonth" picker="month" />
            <a-input  placeholder="支出账户" style="width: 200px" v-model:value="payBankName" />
            <a-button @click="getSumMoney"  type="dashed">
                <template #icon><BarChartOutlined/></template>
                统计
            </a-button>
            <a-popover v-if="sumMoney" trigger="hover" placement="bottom">
                <template #content>
                    <a-list size="small" style="margin: 0" bordered :data-source="sumMoney">
                        <template #renderItem="{item}">
                            <a-list-item>{{item.month}}【{{item.value.toFixed(2)}}元】</a-list-item>
                        </template>
                    </a-list>
                </template>
                <a-button  type="link" >
                    总计：{{sum.toFixed(2)}}元
                </a-button>
            </a-popover>
        </div>
        <LTable :columns="tableColumns" :data-source="source" :loading="loading">
            <template #action="{record}">
                <div class="action-column">
                    <a-tooltip title="查看审核流程">
                        <a-button @click="showStep(record)" type="link">
                            <template #icon>
                                <SearchOutlined/>
                            </template>
                        </a-button>
                    </a-tooltip>
                    <a-tooltip v-if="record.approvalState === '1'" title="生成支出凭单">
                        <a-button @click="generatePaper(record)" type="link">
                            <template #icon>
                                <AlignLeftOutlined/>
                            </template>
                        </a-button>
                    </a-tooltip>
                </div>
            </template>
        </LTable>
    </div>
    <a-modal :footer="null"
             :width="modalWidth"
             destroyOnClose
             title="审核状态"
             v-model:open="visible2">
        <AuditSteps :steps="steps" v-if="visible2"/>
    </a-modal>
</template>

<script>
    import LTable from "../../../../components/table/LTable";
    import AuditSteps from "../../../Equipment/AssetsAuditCenter/AuditSteps";
    import {BarChartOutlined,
        SearchOutlined, SyncOutlined,AlignLeftOutlined} from "@ant-design/icons-vue";
    import otherPay from "../../../../assets/api/otherPay";
    import item_other_pay from "../../../TableStruct/item_other_pay";
    import otherPayApproval from "../../../../assets/api/otherPayApproval";
    import dayjs from "dayjs"
    export default {
        name: "OtherPay",
        props:["bankCards"],
        components:{
            LTable,AuditSteps,
            SearchOutlined,BarChartOutlined,SyncOutlined,
            AlignLeftOutlined,
        },
        computed:{
            source(){
                if(this.payType === "5"){
                    return this.tableSource.filter(item =>{
                        if(this.payBankName){
                            return item.payBankName.indexOf(this.payBankName) !== -1
                        }
                        else{
                            return true
                        }
                    })
                }
                else{
                    return this.tableSource.filter(item=>{
                        if(this.payBankName){
                            return (item.type === this.payType) && (item.payBankName.indexOf(this.payBankName) !== -1)
                        }
                        else{
                             return item.type === this.payType
                        }
                    });
                }
            },
            sum(){
                let sum =0;
                for(let i in this.sumMoney){
                    sum += this.sumMoney[i].value;
                }
                return sum;
            }
        },
        data() {
            return {
                sumMoney:null,
                defaultQuery: {
                    approvalState: "1"
                },
                formColumns: null,
                currentRecord: null,
                tableSource: [],
                dataSource: null,
                tableColumns: null,
                viewModel: true,
                currentType:"1",
                cardAccounts:[],
                moneyAccount:[],
                rHeader:null,
                modalWidth:300,
                steps:[],
                loading:false,
                rangeMonth:null,
                visible:false,
                visible2:false,
                payTypes:[
                    {value:"1",label: "银行账户间转账",color:"cyan"},
                    {value:"2",label: "由银行卡取出到现金账户",color:"blue"},
                    {value:"3",label: "由现金账户存入银行卡",color:"green"},
                    {value:"4",label: "支出",color:"red"},
                    {value:"5",label: "全部",color:"red"},
                ],
                payType:"5",
                payBankName:""
            }
        },
        methods: {
            init() {
                this.sumMoney = null;
                this.payType = "5";
                this.rangeMonth = null;
                this.loading = true;
                otherPay.queryAll.post(this.defaultQuery, res => {
                    this.loading = false;
                    if (res.data.flag) {
                        this.dataSource = res.data.data;
                        this.dataSource = this.dataSource.sort((a,b)=>{
                            return b.payDate - a.payDate;
                        });
                        this.dataSource.map(item=>{
                            let pCard = this.bankCards.find(card=>card.id === item.payBankId);
                            let rCard = this.bankCards.find(card=>card.id === item.receiveBankId);
                            if(pCard){
                                item.payBankName = pCard.remarks
                            }
                            if(rCard){
                                item.receiveBankName = rCard.remarks;
                            }
                        });
                        this.tableSource = [...this.dataSource];
                    } else {
                        this.tableSource = [];
                    }
                });
            },
            showStep(record) {
                this.steps = [];
                let data = {
                    "payId": record.id,
                };
                otherPayApproval.queryAll.post(data, res => {
                    if (res.data.flag) {
                        this.steps = res.data.data;
                        this.modalWidth = (this.steps.length+1) * 270;
                        this.visible2 = true;
                    }
                    else{
                        this.$message.error("无审核流程，本条支付为无需审核类型")
                    }
                })
            },
            checkAction(action, record) {
                switch (action) {
                    case "edit": {
                        return record.approvalState === "1";
                    }
                    case "delete": {
                        return record.approvalState === "0";
                    }
                }
            },
            rangeChange(){
                if(this.rangeMonth){
                    this.tableSource = this.dataSource.filter(item=>{
                        let payDate = dayjs(item.payDate);
                        const startOfMonth = this.rangeMonth[0].startOf('month');
                        const endOfMonth = this.rangeMonth[1].endOf('month');
                        return payDate.isAfter(startOfMonth) && payDate.isBefore(endOfMonth);
                    })
                }
                else{
                    this.tableSource = [...this.dataSource];
                }
            },
            getSumMoney(){
                this.sumMoney = [];
                for(let item of this.source){
                    let month = dayjs(item.payDate).format("YYYY-MM");
                    let arr = this.sumMoney.filter(item=>item.month === month);
                    if(arr.length >0){
                        arr[0].value += item.payAmount;
                    }
                    else{
                        this.sumMoney.push({month:month,value:item.payAmount})
                    }
                }
            },
            generatePaper(){
                this.$message.error("暂无凭单模板")
            }
        },
        created() {
            this.tableColumns = new item_other_pay().getTableColumns();
            this.init()
        }
    }
</script>

<style scoped>

</style>