<!--
* @program: office_automation
* @author: ly
* @component:ShowBills
* @description: 展示所有报账单
* @create: 2022-04-18 17:36
-->
<template>
    <div class="center-flex">
        <span>月份：</span>
        <a-date-picker v-model:value="filterMonth" picker="month" />
        <span class="statistic-label" v-if="summary"> 合计：{{summary.toFixed(2)}} 元</span>
        <MonthMoneySum v-model:summary="summary" :start-date="record.balanceDate" :records="dataSource" date-seg="recordYearMonth" sum-seg="amount" />
    </div>
    <div style="height:600px">
    <LTable  :data-source="dataSource" :loading="loading"
                 :table-columns="tableColumns" :view-model="true" />
    </div>
</template>

<script>
import dayjs from "dayjs";
import LTable from "@/components/table/LTable";
import Column from "@/assets/tables/column";
import ColumnType from "@/assets/tables/column_type";
import record from "@/assets/api/record";

export default {
    name: "ShowBills",
    components: {
      LTable
    },
    props:["record"],
    computed:{
        dataSource(){
            let arr = this.ds.concat(this.ds2).sort((a,b)=>{
                return new Date(b.recordYearMonth).valueOf() - new Date(a.recordYearMonth).valueOf()
            });
            return arr.filter(item=>{
                if(this.filterMonth){
                    return item.recordYearMonth === this.filterMonth.format("YYYY-MM")
                }
                else{
                    return true
                }
            })
        }
    },
    data() {
        return {
            pageSize:15,
            ds:[],
            ds2:[],
            tableColumns:[
                new Column("账单归属时间","recordYearMonth",ColumnType.Month,true).setTableView(100),
                new Column("用户名","userName",ColumnType.String,false).setTableView(80),
                new Column("账单标题","title",ColumnType.String,true).setTableView(200),
                new Column("总金额（元）","amount",ColumnType.Number,false).setTableView(100),
                new Column("入账状态","entryState",ColumnType.String,false).setTableView(100)
                    .setEnum(
                        {value:"1",label: "现金",color:"green"},
                       /* ["0","1","4","2","3"],["未入账","对公入账","私人入账","拒绝入账","撤销入账"],["pink","green","purple","red","orange"]*/),
            ],
            loading:false,
            visible:false,
            defaultQuery:{
                entryState:"4", //入账状态（0：未入账；1：对公入账；2：拒绝入账；3：撤销入账 ;4：私人入账）
                approvalState:"1", //审核状态（0：未完成；1：已完成）
                submitState:"1" //提交状态（0：未提交；1：已提交）
            },
            summary:0,
            filterMonth:null,
            startMoment:null
        }
    },
    methods: {
        init() {
            this.loading = true;
            let data = {
                entryState:"4", //入账状态（0：未入账；1：对公入账；2：拒绝入账；3：撤销入账 ;4：私人入账）
                approvalState:"1", //审核状态（0：未完成；1：已完成）
                submitState:"1" //提交状态（0：未提交；1：已提交）
            };
            let data2 = {
                entryState:"1", //入账状态（0：未入账；1：对公入账；2：拒绝入账；3：撤销入账 ;4：私人入账）
                approvalState:"1", //审核状态（0：未完成；1：已完成）
                submitState:"1" //提交状态（0：未提交；1：已提交）
            };
            record.queryAll.urlPost(data,res=>{
                this.loading = false;
                if(res.data.flag){
                    this.ds = res.data.data.filter(item=>{
                        return dayjs(item.recordYearMonth).isSame(this.startMoment,'month') || dayjs(item.recordYearMonth).isAfter(this.startMoment,'month')
                    });
                }
                else{
                    this.ds = []
                }
            });
            record.queryAll.urlPost(data2,res=>{
                this.loading = false;
                if(res.data.flag){
                    this.ds2 = res.data.data.filter(item=>{
                        return dayjs(item.recordYearMonth).isSame(this.startMoment,'month') || dayjs(item.recordYearMonth).isAfter(this.startMoment,'month')
                    });
                }
                else{
                    this.ds2 = []
                }
            })
        }
    },
    created() {
        this.startMoment = dayjs(this.record.balanceDate,'YYYY-MM');
        this.init()
    }
}
</script>

<style scoped>

</style>
