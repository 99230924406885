<!--
* @program: tyh-oa 
* @author: ly
* @component:typePreViewFile  报账凭证用
* @description: 
* @create: 2021-04-19 17:48
-->
<template>
    <div v-if="viewModel" style="display: flex;text-align: center;">
        <div class="action-button" v-for="url in imageUrls" :key="url" type="link" @click="showDetail(url)">
            <FileImageTwoTone class="pointer" v-if="preViewType(url) ===2"  />
            <FilePdfOutlined class="pointer" v-else-if="preViewType(url) ===1" style="color:#b10405"/>
            <QuestionCircleOutlined class="pointer" v-else style="color:purple"/>
        </div>
    </div>
    <div v-else>
        <a-upload
                @blur="blurTrigger"
                :action="uploadUrl"
                list-type="picture-card"
                v-model:file-list="fileList"
                @preview="handlePreview"
                :accept="accept"
                @change="handleChange"
                :remove="handleRemove"
                withCredentials
        >
            <div v-if="fileList.length < 4">
                <plus-outlined />
                <div class="ant-upload-text">上传</div>
            </div>
        </a-upload>
    </div>
    <a-modal v-model:open="previewVisible"
              width="1350px"
             :footer="null"
              destroy-on-close>
        <template #title>
            <div class="center-flex">
                文件详情
                <a-button type="link"  @click="download">
                    <template #icon><DownloadOutlined /></template>
                    批量下载
                </a-button>
            </div>
        </template>
        <a-tabs tab-position="left" v-model:activeKey="activeKey">
            <a-tab-pane v-for="(url,index) in imageUrls" :key="index.toString()">
                <template #tab>
                    <div class="search-bar">
                        <a-button type="link" style="margin-right:10px"  @click="download2(url)">
                            <template #icon><DownloadOutlined /></template>
                        </a-button>
                        附件-{{index+1}}
                    </div>
                </template>
                <div style="height: 600px">
                    <a-image v-if="preViewType(url) === 2" style="height: 600px;width: 90% " :src="GetCurrentFileUrl(url)"/>
                    <embed v-else-if="preViewType(url) === 1" style="height: 600px;width: 90% " :src="GetCurrentFileUrl(url)" />
                    <div style="text-align: center" v-else>当前文件格式不支持在线浏览</div>
                </div>
            </a-tab-pane>
        </a-tabs>
    </a-modal>
</template>

<script>
    import typeBase from "./typeBase";
    import { PlusOutlined,FileImageTwoTone,FilePdfOutlined,QuestionCircleOutlined,DownloadOutlined } from '@ant-design/icons-vue';
    import {uuid,GetCurrentFileUrl} from "/src/assets/utils/general"
    import api_file from "../../assets/api/file";
    export default {
        name: "typePreViewFile",
        mixins:[typeBase],
        components:{PlusOutlined,FileImageTwoTone,FilePdfOutlined,QuestionCircleOutlined,DownloadOutlined},
        watch:{
            record(){
                this.columnTrigger();
                this.init();
            }
        },
        computed:{
            imageUrls(){
                if(this.record[this.column.dataIndex]){
                    return this.record[this.column.dataIndex].split(",");
                }
                else {
                    return []
                }
            },
        },
        data(){
            return{
                accept:"image/png,image/jpeg,application/pdf",
                fileList:[],
                previewVisible:false,
                uploadUrl:api_file.upload.url,
                preView:false, //预览的类型   1 为pdf  2为 图片文件
                GetCurrentFileUrl:GetCurrentFileUrl,
                activeKey:"0"
            }
        },
        methods:{
            handlePreview(){
                this.previewVisible = true;
            },
            handleCancel(){
                this.previewVisible=false;
            },
            handleChange({file,fileList}){
                if(file.status==="done"){//status: "done"
                    this.record[this.column.dataIndex] = this.getRealUrls(fileList);
                }
            },
            showDetail(url){
                this.previewVisible = true;
                this.preView = this.preViewType(url);
            },
            preViewType(url){
                let suffix = url.split(".").pop();
                switch (suffix) {
                    case "pdf":{
                        return 1;
                    }
                    case "jpg":{
                        return 2;
                    }
                    case "png":{
                        return 2;
                    }
                    case "gif":{
                        return 2;
                    }
                    default:{
                        return 0;
                    }
                }
            },
            async handleRemove(file){
                let data = "";
                if(Object.prototype.hasOwnProperty.call(file,"response")){
                    data = {batchFileUrl:file.response.data.fileUrl};
                }
                else{
                    data = {batchFileUrl:file.path};
                }
                await api_file.batchDeleteFile.urlPost(data,res=>{
                    if(res.data.flag){
                        //let index = this.fileList.indexOf(file);
                        //this.fileList.splice(index,1);
                        //let urls = this.getRealUrls(this.fileList);
                        this.record[this.column.dataIndex] = this.getRealUrls(this.fileList);
                        return true
                    }
                    else{
                        this.$message.error(res.data.msg);
                        return false
                    }
                });
            },
            handleClear(){
                this.fileList = [];
            },
            getRealUrls(fileList){
                let urls ="";
                for(let i in fileList){
                    if(fileList[i].response && fileList[i].response.flag){
                        let url = fileList[i].response.data.fileUrl;
                        urls += url + ",";
                    }
                    else{
                        let url = fileList[i].path;
                        urls += url + ",";
                    }
                }
                urls = urls.substr(0,urls.length-1);
                return urls;
            },
            init(){
                this.fileList = [];
                if(this.record[this.column.dataIndex]){
                    let urls = this.record[this.column.dataIndex].split(",");
                    for(let i in urls){
                        let id = uuid(3,16);
                        let img = {
                            uid: id,
                            name: id,
                            status: 'done',
                            url: GetCurrentFileUrl(urls[i]),
                            thumbUrl:GetCurrentFileUrl(urls[i]),
                            path:urls[i]
                        };
                        this.fileList.push(img);
                    }
                }
            },
            download(){
                for(let url of this.imageUrls){
                    let data = {
                        fileUrl:url
                    };
                    let name = url.split("/");
                    api_file.download.preFileDownload(data,name.pop());
                }
            },
            download2(url){
                let data = {
                    fileUrl:url
                };
                let name = url.split("/");
                api_file.download.preFileDownload(data,name.pop());
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>
    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }
    .ant-upload-list-picture-card-container{
        float: left;
        width: 80px;
        height: 80px;
        margin: 0 8px 8px 0;
    }
    .outlined-text {
        color: white; /* 字体颜色 */
        text-shadow:
                -2px -2px 0 #000,
                2px -2px 0 #000,
                -2px  2px 0 #000,
                2px  2px 0 #000; /* 描边颜色和宽度 */
    }
</style>