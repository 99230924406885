/** 
* 自动生成文件，请勿修改 
* @name: 财务:现金流量表会计科目设置模块
* @description: 财务-现金流量表会计科目设置==================现金流量表导出使用
* @create: 2024/10/18 15:02:19
* @swagger: 2.0
* 合计:14条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const cashSubject = { 
	/** 1 summary:会计科目-批量删除
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/cashSubject/batchDelete.do"), 
	/** 2 summary:会计科目-全部数据查询（只模糊一级科目名称）
	description:参数：subjectName类别名称（大类名称），模糊查询，可传可不传
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/cashSubject/queryAll.do"), 
	/** 3 summary:利润表-(导出)
	description:参数：year：年度
	method:get
	params:需要配合Column函数使用
	*/ 
	exportProfitReport : new APIObject.getObject("/api/cashSubject/exportProfitReport.do"), 
	/** 4 summary:根据月份及科目id得到收入/支出流水详情列表(展示-现金流量表和利润表使用的)
	description:参数：month：月度；subjectId:科目id
	method:get
	params:需要配合Column函数使用
	*/ 
	detailList : new APIObject.getObject("/api/cashSubject/detailList.do"), 
	/** 5 summary:会计科目-单条数据查询
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/cashSubject/queryOne.do"), 
	/** 6 summary:资产负债表-(导出)
	description:参数：yearMonth：年月度
	method:get
	params:需要配合Column函数使用
	*/ 
	exportAssetReport : new APIObject.getObject("/api/cashSubject/exportAssetReport.do"), 
	/** 7 summary:现金流量表-页面展示(计算)
	description:参数：year：年度
	method:get
	params:需要配合Column函数使用
	*/ 
	calCashReport : new APIObject.getObject("/api/cashSubject/calCashReport.do"), 
	/** 8 summary:资产负债表-页面展示(计算)
	description:参数：yearMonth：月度
	method:get
	params:需要配合Column函数使用
	*/ 
	calAssetReport : new APIObject.getObject("/api/cashSubject/calAssetReport.do"), 
	/** 9 summary:会计科目-保存/修改
	description:参数：单条数据对象SubjectVO
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/cashSubject/saveOrUpd.do"), 
	/** 10 summary:现金流量表-(导出)
	description:参数：year：年度
	method:get
	params:需要配合Column函数使用
	*/ 
	exportCashReport : new APIObject.getObject("/api/cashSubject/exportCashReport.do"), 
	/** 11 summary:利润表-页面展示(计算)
	description:参数：year：年度
	method:get
	params:需要配合Column函数使用
	*/ 
	calProfitReport : new APIObject.getObject("/api/cashSubject/calProfitReport.do"), 
	/** 12 summary:根据年月得到该年1月份 - 该月收入/支出流水详情列表(展示-资产负债表使用的)
	description:参数：yearMonth：月度；subjectId:科目id
	method:get
	params:需要配合Column函数使用
	*/ 
	detailListForZiChan : new APIObject.getObject("/api/cashSubject/detailListForZiChan.do"), 
	/** 13 summary:会计科目-单条删除
	description:参数：单条数据的主键id,若选择的是大类，其下的小类也会被删除，前端应给与二次提示
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/cashSubject/delete.do"), 
	/** 14 summary:会计科目-全部数据查询（资产负债表期初数查询）
	description:参数：year：期初年度；type：3：资产；4：负债；5：权益
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAllBalance : new APIObject.postObject("/api/cashSubject/queryAllBalance.do"), 
};
export default cashSubject;