/** 
* 自动生成文件，请勿修改 
* @name: 支付:外协支付申请审批流程设置
* @description: 外协支付申请审批流程设置（三期新增）
* @create: 2024/10/18 15:02:19
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const payAudit = { 
	/** 1 summary:单条数据查询
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/payAudit/queryOne.do"), 
	/** 2 summary:分页查询
	description:参数：PayAuditQueryVO
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/payAudit/queryPage.do"), 
	/** 3 summary:保存/修改
	description:参数：单条数据对象PayAuditVO
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/payAudit/saveOrUpd.do"), 
	/** 4 summary:批量保存/修改
	description:参数：List<PayAuditVO>,审核步骤数totalstepcount必须大于0
	method:post
	params:需要配合Column函数使用
	*/ 
	batchSaveOrUpd : new APIObject.postObject("/api/payAudit/batchSaveOrUpd.do"), 
	/** 5 summary:单条删除
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/payAudit/delete.do"), 
	/** 6 summary:所有数据查询
	description:参数：PayAuditQueryVO
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/payAudit/queryAll.do"), 
	/** 7 summary:批量删除
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/payAudit/batchDelete.do"), 
};
export default payAudit;