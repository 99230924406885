<!--
* @program: TYHOA2 
* @author: ly
* @component:QCMyReport 
* @description: 我的质检报告
* @create: 2024-09-29 16:55
-->
<template>
    <div>
        
    </div>
</template>

<script>
    export default {
        name: "QCMyReport",
        props:["processList"],
        data() {
            return {

            }
        },
        methods: {
            init() {
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>