<!--
* @program: TYHOA2 
* @author: ly
* @component:BillManager 
* @description: 
* @create: 2024-08-21 16:35
-->
<template>
    <div class="column-flex" style="position: relative;overflow: hidden">
        <div class="search-bar">
            <a-button @click="init"  type="dashed">
                <template #icon>
                    <SyncOutlined />
                </template>
            </a-button>
            <div style="margin: 10px;color: black">部门:</div>
            <a-select v-model:value="defaultQuery.departmentId"
                      style="width: 200px"
                      @change="init"
            >
                <a-select-option v-for="department in myDepartments" :key="department.id">
                    {{ department.name }}
                </a-select-option>
            </a-select>
            <a-input  placeholder="用户名" style="width: 100px" v-model:value="filter.userName" />
            <a-input  placeholder="账单标题" style="width: 150px" v-model:value="filter.title" />
            <a-input  placeholder="归属年月" style="width: 150px" v-model:value="filter.recordYearMonth" />
            <a-input  placeholder="入账人" style="width: 150px" v-model:value="filter.transferUserName" />
            <a-radio-group style="margin-left:15px;border-left: #022d5c solid;padding-left:10px" @change="init" v-model:value="defaultQuery.submitState">
                <a-radio value="0">未提交</a-radio>
                <a-radio value="1">已提交</a-radio>
            </a-radio-group>
        </div>
        <div class="row-flex">
            <div class="main-content30" style="height:100%">
                <LTable :loading="loading" :data-source="source" :columns="tableColumns">
                    <template #action="{record}">
                        <div class = "action-column">
                            <a-tooltip title="账单信息">
                                <a-button @click="editDetail(record)"
                                          class="action-button" type="link">
                                    <SearchOutlined class="pointer"/>
                                </a-button>
                            </a-tooltip>
                            <a-popconfirm
                                    @confirm="cancelSubmit(record)"
                                    cancel-text="否"
                                    class="action-button"
                                    ok-text="是"
                                    title="已审核的账单无法撤销，是否撤销审核？"
                                    v-if="record.submitState === '1'">
                                <a-tooltip title="撤销审核">
                                    <a-button @click="currentRecord = record" class="action-button" style="color: orange" type="link">
                                        <RollbackOutlined class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                            </a-popconfirm>
                            <a-popconfirm
                                    @confirm="submit(record)"
                                    cancel-text="否"
                                    class="action-button"
                                    ok-text="提交"
                                    title="提交审核？"
                                    v-if="record.submitState === '0'">
                                <a-tooltip title="提交审核">
                                    <a-button class="action-button" @click="currentRecord = record"  style="color: green" type="link">
                                        <ArrowUpOutlined class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                            </a-popconfirm>
                        </div>
                    </template>
                </LTable>
            </div>
            <div class="main-content75" style="height:100%">
                <BillFlows v-if="currentRecord" :bill="currentRecord" ref="bill"
                          @show="showBill" />
                <Welcome v-else />
            </div>
        </div>
    </div>
    <a-modal v-model:open="visible"
             :footer="null"
             title="账单详情">
        <LForm :label-span="8" :form-columns="formColumns" :form-data="currentRecord" :view-model="true"/>
    </a-modal>
</template>

<script>
    import LTable from "../../../components/table/LTable";
    import LForm from "../../../components/form/LForm";
    import {SearchOutlined,
        SyncOutlined,RollbackOutlined,ArrowUpOutlined
    } from "@ant-design/icons-vue";
    import BillFlows from "../../PersonalCenter/MyBill/BillFlows";
    import Welcome from "../../Other/Welcome";
    import {ArrayFilter} from "../../../assets/utils/general";
    import item_finance_record from "../../PersonalCenter/MyBill/item_finance_record";
    import api_item_finance_record from "../../../assets/api/record";
    import Column from "@/assets/tables/column";
    import ColumnType from "@/assets/tables/column_type";

    export default {
        name: "BillManager",
        components:{
            LTable,LForm,
            SyncOutlined,SearchOutlined,RollbackOutlined,ArrowUpOutlined,
            BillFlows,Welcome
        },
        computed:{
            source(){
                return ArrayFilter(this.dataSource,this.filter);
            },
        },
        data() {
            return {
                dataSource: [],
                tableColumns: [
                    new Column("报账人","userName",ColumnType.String).setTableView(80),
                    new Column("账单标题","title",ColumnType.String).setTableView(100),
                    new Column("归属时间","recordYearMonth",ColumnType.String).setTableView(100),
                    new Column("入账人","transferUserName",ColumnType.String).setTableView(80),
                    new Column("操作","actions","actions").setTableView(80)
                ],
                formColumns: new item_finance_record().getFormColumns(),
                loading: false,
                defaultQuery: {
                    submitState:"0",
                },
                viewModel:true,
                currentRecord:null,
                filter:{},
                visible:false,
                reason:null,
                myDepartments:[]
            }
        },
        methods: {
            init() {
                this.loading = true;
                api_item_finance_record.queryAll.urlPost(this.defaultQuery, res => {
                    this.loading = false;
                    if (res.data.flag) {
                        this.dataSource = res.data.data;
                    } else {
                        this.dataSource = [];
                    }
                });
            },
            editDetail(record) {
                this.viewModel = false;
                this.currentRecord = record;
            },
            showBill(){
                this.visible = true;
                this.viewModel = true;
            },
            submit(record) {
                record.submitState = "1";
                api_item_finance_record.saveOrUpd.post(record, res => {
                    if (res.data.flag) {
                        record.submitState = "1";
                        this.$message.success(res.data.msg);
                    } else {
                        record.submitState = "0";
                        this.$message.error(res.data.msg);
                    }
                });
            },
            cancelSubmit(record) {
                let data = {id: record.id};
                api_item_finance_record.cancelSubmit.urlPost(data, res => {
                    if (res.data.flag) {
                        record.submitState = "0";
                        this.$message.success(res.data.msg);
                    } else {
                        this.$message.error(res.data.msg)
                    }
                });
            },

        },
        created() {
            this.$getItem("__departments").then(str=>{
                this.myDepartments = JSON.parse(str);
                this.defaultQuery.departmentId = this.myDepartments[0].id
                this.init()
            })
        }
    }
</script>

<style scoped>

</style>