<!--
* @program: TYHOA2 
* @author: ly
* @component:FileUpload 
* @description: 
* @create: 2024-10-09 13:48
-->
<template>
    <a-upload
            :action="uploadUrl"
            list-type="picture-card"
            v-model:file-list="fileList"
            :accept="accept"
            @change="handleChange"
            :remove="handleRemove"
            withCredentials>
        <div v-if="fileList.length < 4">
            <plus-outlined />
            <div class="ant-upload-text">上传</div>
        </div>
    </a-upload>
</template>

<script>
    import {PlusOutlined} from "@ant-design/icons-vue";
    import api_file from "../../assets/api/file";

    export default {
        name: "FileUpload",
        components:{PlusOutlined},
        emits:["change"],
        data(){
            return{
                accept:"*",
                fileList:[],
                uploadUrl:api_file.upload.url,
            }
        },
        methods:{
            handleChange({file,fileList}){
                if(file.status==="done"){//status: "done"
                    this.$emit("change",this.getRealUrls(fileList))
                }
            },
            async handleRemove(file){
                let data = "";
                if(Object.prototype.hasOwnProperty.call(file,"response")){
                    data = {batchFileUrl:file.response.data.fileUrl};
                }
                else{
                    data = {batchFileUrl:file.path};
                }
                await api_file.batchDeleteFile.urlPost(data,res=>{
                    if(res.data.flag){
                        this.$message.error(res.data.msg);
                        return true
                    }
                    else{
                        this.$message.error(res.data.msg);
                        return false
                    }
                });
            },
            getRealUrls(fileList){
                let urls ="";
                for(let i in fileList){
                    if(fileList[i].response && fileList[i].response.flag){
                        let url = fileList[i].response.data.fileUrl;
                        urls += url + ",";
                    }
                    else{
                        let url = fileList[i].path;
                        urls += url + ",";
                    }
                }
                urls = urls.substr(0,urls.length-1);
                return urls;
            },
            init(){
                this.fileList = [];
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>