<!--
* @program: TYHOA2 
* @author: ly
* @component:CalendarDiary 
* @description: 
* @create: 2024-08-14 16:14
workContent
taskTitle
processTitle
remarks
-->
<template>
    <a-calendar mode="month" >
        <template #dateFullCellRender="{ current }">
            <div style="height:150px;cursor: default">
                <CalendarCellHead style="height:32px;cursor: pointer" :day="current"/>
                <div style="height:168px;overflow: auto">
                    <div v-for="item in getList(current)" :key="item.id">
                        <div @click="show(item)" class="diary-title no-warp-string">{{item.taskTitle?item.taskTitle:"其他工作"}}</div>
                    </div>
                </div>
            </div>
        </template>
    </a-calendar>
    <a-modal v-model:open="visible"
             destroyOnClose
             :footer="null"
             title="工作日志">
        <a-row  style="margin:10px">
            <a-col  :span="6">
                <div class="form-label" style="margin-right: 10px">项目名称</div>
            </a-col>
            <a-col  :span="18">
                <div class="form-value" >{{currentDiary.itemName}}</div>
            </a-col>
        </a-row>
        <a-row  style="margin:10px">
            <a-col  :span="6">
                <div class="form-label" style="margin-right: 10px">任务名称</div>
            </a-col>
            <a-col  :span="18">
                <div class="form-value" >{{currentDiary.taskTitle}}</div>
            </a-col>
        </a-row>
        <a-row  style="margin:10px">
            <a-col  :span="6">
                <div class="form-label" style="margin-right: 10px">工序名称</div>
            </a-col>
            <a-col :span="18">
                <div class="form-value" >{{currentDiary.processTitle}}</div>
            </a-col>
        </a-row>
        <a-row  style="margin:10px">
            <a-col  :span="6">
                <div class="form-label" style="margin-right: 10px">工作内容</div>
            </a-col>
            <a-col :span="18">
                <div class="form-value" >{{currentDiary.workContent}}</div>
            </a-col>
        </a-row>
    </a-modal>
</template>

<script>
    import CalendarCellHead from "../../../PersonalCenter/WorkCenter/WorksDiary/CalendarCellHead";
    import dayjs from "dayjs"
    import workSituation from "../../../../assets/api/workSituation";
    export default {
        name: "CalendarDiary",
        props:["param"],
        components:{
            CalendarCellHead
        },
        watch:{
            param(){
                this.init()
            }
        },
        data() {
            return {
                diary:[],
                visible:false,
                currentDiary:{}
            }
        },
        methods: {
            async init(){
                this.diary = [];
                this.loading = true;
                let res1 = await workSituation.queryAll.asyncPost({userId:this.param.id,processType:"1"});
                if(res1.flag){
                    this.diary = res1.data;
                }
                let res2 = await workSituation.queryAll.asyncPost({userId:this.param.id,processType:"2"});
                if(res2.flag){
                    this.diary.push(...res2.data);
                }
                let res3 = await workSituation.queryAll.asyncPost({userId:this.param.id,processEmployeeId:"work"});
                if(res3.flag){
                    this.diary.push(...res3.data);
                }
                this.diary.map(item=>{
                    item.itemName = item.itemName?item.itemName:"";
                    item.processTitle = item.processTitle?item.processTitle:"其他工作";
                    return item
                });
                this.loading = false;
            },
            getList(current){
                if(this.diary && this.diary.length >0){
                    return this.diary.filter(item =>{
                        return dayjs(item.workDate).format('YYYY-MM-DD') === current.format('YYYY-MM-DD')
                    })
                }
                else{
                    return []
                }
            },
            getClass(current){
                let name = "";
                if(dayjs().format('YYYY-MM-DD') === current.format('YYYY-MM-DD')){
                    name += " diary-today"
                }
                return name
            },
            show(item){
                this.currentDiary = item;
                this.visible = true;
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>