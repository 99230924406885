<!--
* @program: TYHOA2 
* @author: ly
* @component:QCFileAudit 
* @description: 项目文件审核
* @create: 2024-10-10 20:03
-->
<template>
    <div class="column-flex" style="position: relative;overflow: hidden">
        <div class="search-bar">
            <a-button @click="init" type="dashed">
                <template #icon>
                    <SyncOutlined/>
                </template>
            </a-button>
            <a-radio-group  @change="init" style="margin-left:10px" v-model:value="defaultQuery.approveState">
                <a-radio value="0">待审核</a-radio>
                <a-radio value="1">已通过</a-radio>
                <a-radio value="2">未通过</a-radio>
            </a-radio-group>
            <a-divider type="vertical" style="height:32px;border-left: #022d5c solid;" />
            <a-input placeholder="项目名称"  style="width: 150px" v-model:value="filter.itemName" />
            <a-input placeholder="文件名" style="width: 150px" v-model:value="filter.docName" />
        </div>
        <div style="width: 100%;height: 100%;">
            <LTable :columns="columns" :data-source="source" :loading="loading">
                <template #action="{record}">
                    <div class="action-column">
                        <a-button  class="action-button" type="link" @click="show(record)">
                            <SearchOutlined  class="pointer"/>
                        </a-button>
                        <a-tooltip v-if="record.approveState !== '1'" title="文件审核">
                            <a-button  class="action-button" type="link" @click="audit(record)">
                                审核
                            </a-button>
                        </a-tooltip>
                    </div>
                </template>
            </LTable>
        </div>
        <a-drawer :closable="false"
                  :get-container="false"
                  :style="{ position: 'absolute' }"
                  @close="visible = false"
                  destroy-on-close
                  height="90%"
                  placement="bottom"
                  v-model:open="visible">
            <a-tabs style="height: 100%" tabPosition="left" v-model:activeKey="activeKey">
                <a-tab-pane style="height: 100%;overflow: auto" key="1" tab="项目信息">
                    <ProjectInfoView  />
                </a-tab-pane>
            </a-tabs>
        </a-drawer>
    </div>
    <a-modal
            width="600px"
            v-model:open="visible2"
            :footer="null" title="文件审核"
            :maskClosable="false"
            destroyOnClose>
        <a-textarea v-model:value="approveRemarks" :auto-size="{ minRows: 2, maxRows: 6 }"
                    style="width:100%" placeholder="审核评价" />
        <a-divider />
        <a-popconfirm
                @confirm="accept"
                cancel-text="否"
                ok-text="是"
                title="确认通过吗?">
            <a-button style="margin-right:30px " type="primary" >
                通过
            </a-button>
        </a-popconfirm>
        <a-popconfirm
                @confirm="refuse"
                cancel-text="否"
                ok-text="是"
                title="确认不通过吗?">
            <a-button  type="primary" danger >
                不通过
            </a-button>
        </a-popconfirm>
    </a-modal>
</template>

<script>
    import {
        CheckOutlined,
        SyncOutlined,
        SearchOutlined,
    } from "@ant-design/icons-vue";
    import LTable from "../../../components/table/LTable";
    import ProjectInfoView from "../ProjectManager/ProjectInfoView";
    import {computed} from "vue";
    import {ArrayFilter} from "../../../assets/utils/general";
    import Column from "@/assets/tables/column";
    import ColumnType from "@/assets/tables/column_type"
    import ItemDoc from "../../../assets/api/ItemDoc";
    import itemInfo from "../../../assets/api/itemInfo";


    export default {
        name: "QCFileAudit",
        components: {
            SyncOutlined,CheckOutlined,SearchOutlined,
            LTable,ProjectInfoView,
        },
        provide() {
            return {
                pTaskId: computed(() => this.currentRecord.id),
                pProjectId:computed(() => this.currentRecord.itemId),
            }
        },
        computed:{
            source(){
                return ArrayFilter(this.dataSource,this.filter);
            },
        },
        data() {
            return {
                dataSource: [],//
                columns: [
                    new Column("项目名称","itemName",ColumnType.String).setTableView(250),
                    new Column("文件名称","docName",ColumnType.String).setTableView(100),
                    new Column("文件描述","describe",ColumnType.String).setTableView(250),
                    new Column("扫描件","docFile",ColumnType.PreViewFile).setTableView(80),
                    new Column("审核状态","approveState",ColumnType.Enum,false).setTableView(80)
                        .setDictEnum("item_progress_apply.approveState"),
                    new Column("操作","actions","actions",false).setTableView(80)
                ],
                currentRecord: {},
                loading: false,
                defaultQuery: {
                    "approveState": "0",
                },
                filter:{},
                visible: false,
                visible2: false,
                activeKey:"1",
                approveRemarks:null
            }
        },
        methods: {
            init() {
                this.dataSource = [];
                this.loading = true;
                ItemDoc.queryAll.post(this.defaultQuery,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                    else{
                        this.dataSource = []
                    }
                })
            },
            show(record){
                this.currentRecord = record;
                this.visible = true;
            },
            audit(record) {
                this.approveRemarks = "上传文件与文件描述无误，符合要求";
                this.currentRecord = record;
                this.visible2 = true;
            },
            accept(){
                let data = {
                    id:this.currentRecord.id,
                    approveState:"1",
                    approveRemarks:this.approveRemarks
                };
                ItemDoc.audit.urlPost(data,res=>{
                    if(res.data.flag){
                        this.visible2 = false;
                        this.dataSource = this.dataSource.filter(item =>item.id !== this.currentRecord.id);
                        this.$message.success(res.data.msg);
                        if(this.currentRecord.docName === "成果资料移交清单"){//成果资料移交清单 审核通过之后，项目完成归档
                            itemInfo.updByFiled.post({id:this.currentRecord.itemId,archiveState:"1"})
                        }
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            refuse(){
                let data = {
                    id:this.currentRecord.id,
                    approveState:"2",
                    approveRemarks:this.approveRemarks
                };
                ItemDoc.audit.urlPost(data,res=>{
                    if(res.data.flag){
                        this.visible2 = false;
                        this.init();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            }
        },
        created() {
            this.init();
        }
    }
</script>

<style scoped>

</style>