<!--
* @program: TYHOA2 
* @author: ly
* @component:ProjectPay 
* @description: 项目外协支付
* @create: 2024-09-18 15:00
-->
<template>
    <div class="column-flex">
        <div class="search-bar">
            <a-button @click="init" type="dashed">
                <template #icon><SyncOutlined/></template>
            </a-button>
            <a-radio-group style="margin-left:10px" v-model:value="defaultQuery.payState" @change="init">
                <a-radio value="0">待支付</a-radio>
                <a-radio value="1">已支付</a-radio>
            </a-radio-group>
            <a-input class="title-divider" placeholder="项目名称" style="width: 120px" v-model:value="filter.itemName" />
            <a-input placeholder="合同编号" style="width: 120px" v-model:value="filter.contractNum" />
            <a-input placeholder="收款单位" style="width: 120px" v-model:value="filter.outSourceName" />
            <a-input placeholder="付款部门" style="width: 120px" v-model:value="filter.applyDepartName" />
            <a-input placeholder="付款描述" style="width: 120px" v-model:value="filter.payReason" />
            <a-range-picker style="width: 200px" v-model:value="rangeMonth" picker="month" />
            <a-button @click="getSumMoney"  type="dashed">
                <template #icon><BarChartOutlined/></template>
                统计
            </a-button>
            <a-popover v-if="sumMoney.length > 0" trigger="hover" placement="bottom">
                <template #content>
                    <a-list size="small" style="margin: 0" bordered :data-source="sumMoney">
                        <template #renderItem="{item}">
                            <a-list-item>{{item.month}}【支付金额：{{item.value.toFixed(2)}}元，平台手续费：{{item.value2.toFixed(2)}}元】</a-list-item>
                        </template>
                    </a-list>
                </template>
                <a-button  type="link" >
                    支付金额：{{sum.money.toFixed(2)}}元，平台手续费：{{sum.serviceAmount.toFixed(2)}}元
                </a-button>
            </a-popover>
        </div>
        <LTable :columns="tableColumns" :data-source="source" :loading="loading">
            <template #action="{record}">
                <div class = "action-column">
                    <a-tooltip title="查看审核流程">
                        <a-button @click="showStep(record)" type="link">
                            <template #icon>
                                <SearchOutlined/>
                            </template>
                        </a-button>
                    </a-tooltip>
                    <a-tooltip v-if="record.payState === '1'" title="生成支出凭单">
                        <a-button @click="generatePaper(record)" type="link">
                            <template #icon>
                                <AlignLeftOutlined/>
                            </template>
                        </a-button>
                    </a-tooltip>
                    <a-tooltip v-if="checkAction('auditDone',record)"  title="完成支付">
                        <a-button @click="auditDone(record)" class="action-button" style="color: green" type="link">
                            <CheckOutlined class="pointer"/>
                        </a-button>
                    </a-tooltip>
                </div>
            </template>
        </LTable>
    </div>
    <a-modal v-model:visible="visible"
             :width="600"
             destroy-on-close
             title="确认支付完成"
             :footer="null">
        <a-row>
            <a-col :span="8">
                <span class="form-label">支付时间</span>
            </a-col>
            <a-col :span="12">
                <a-date-picker  style="width: 100%" v-model:value="payDate"  placeholder="请选择时间" />
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="8">
                <span class="form-label">支出账户</span>
            </a-col>
            <a-col :span="12">
                <a-select :disabled="vModel" :fieldNames="{key:'id', value: 'id',label:'remarks'}"
                          v-model:value="bankId" style="width: 100%" :options="bankCards">
                </a-select>
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="8">
                <span class="form-label">平台手续费</span>
            </a-col>
            <a-col :span="12">
                <a-input-number :min="0" :precision="2" style="width:100%" v-model:value="serviceAmount"/>
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="8">
                <span class="form-label">附件（支付凭单）</span>
            </a-col>
            <a-col :span="12">
                <FileUpload @change="handleChange"/>
            </a-col>
        </a-row>
        <a-divider />
        <div style="text-align:left">若录入平台手续费，支付凭单的【付款方式】自动为平台支付，若未录入，可以选择其他支付方式。</div>
        <div style="text-align:left;color:red">支付完成后，将不能录入平台手续费</div>
        <a-divider />
        <a-popconfirm
                ok-text="是"
                cancel-text="否"
                title="请确认是否录入正确?"
                @confirm="payConfirm">
            <a-button type="primary" >
                支付完成
            </a-button>
        </a-popconfirm>
    </a-modal>
    <a-modal :footer="null"
             :width="modalWidth"
             destroyOnClose
             title="审核状态"
             v-model:open="visible2">
        <AuditSteps :steps="steps" v-if="visible2"/>
    </a-modal>
    <a-modal v-model:visible="visible3"
             width="800px"
             destroy-on-close
             :title="null"
             :footer="null">
        <ProjectPayPDF :record="currentRecord"/>
    </a-modal>
</template>

<script>
    import payApply from "../../../../assets/api/payApply";
    import LTable from "../../../../components/table/LTable";
    import {SearchOutlined,CheckOutlined,SyncOutlined,AlignLeftOutlined,BarChartOutlined} from "@ant-design/icons-vue"
    import Column from "@/assets/tables/column";
    import ColumnType from "@/assets/tables/column_type"
    import payApproval from "../../../../assets/api/payApproval";
    import AuditSteps from "../../../Equipment/AssetsAuditCenter/AuditSteps";
    import dayjs from "dayjs"
    import {ArrayFilter} from "../../../../assets/utils/general";
    import ProjectPayPDF from "./ProjectPayPDF";
    import FileUpload from "../../../common/FileUpload";
    export default {
        name: "ProjectPay",
        props:["bankCards"],
        components:{
            LTable,AuditSteps,ProjectPayPDF,FileUpload,
            SearchOutlined,CheckOutlined,SyncOutlined,AlignLeftOutlined,BarChartOutlined
        },
        computed:{
            source(){
                let source = [];
                if(this.rangeMonth){
                    source = this.dataSource.filter(item=>{
                        let payDate = dayjs(item.payTime);
                        const startOfMonth = this.rangeMonth[0].startOf('month');
                        const endOfMonth = this.rangeMonth[1].endOf('month');
                        return payDate.isAfter(startOfMonth) && payDate.isBefore(endOfMonth);
                    });
                    return ArrayFilter(source,this.filter);
                }
                else{
                    return ArrayFilter(this.dataSource,this.filter);
                }
            },
            sum(){
                let money =0;
                let serviceAmount =0;
                for(let i in this.sumMoney){
                    money += this.sumMoney[i].value;
                    serviceAmount += this.sumMoney[i].value2;
                }
                return {money,serviceAmount};
            }
        },
        data() {
            return {
                rangeMonth:undefined,
                tableColumns:[],
                loading:false,
                visible:false,
                visible2:false,
                visible3:false,
                payDate:null,
                currentRecord:null,
                dataSource:[],
                steps:[],
                modalWidth:300,
                bankId:undefined,
                /**
                 * submitState 提交状态 0：未提交；1：已提交，未提交的可删改
                 * approvalState 是否全部审核通过：0：未完成；1：已完成
                 * payState 支付状态：0：未支付；1：已支付
                 */
                defaultQuery:{
                    submitState: "1",
                    approvalState: "1",
                    payState: "0"
                },
                filter:{},
                serviceAmount:0,
                sumMoney:[],
                fileUrl:"",
            }
        },
        methods: {
            init() {
                this.loading = true;
                this.dataSource = [];
                payApply.queryAll.post( this.defaultQuery, res => {
                    this.loading = false;
                    if (res.data.flag) {
                        this.dataSource = res.data.data.sort((a,b)=>{
                            return b.createTime - a.createTime
                        });
                    }
                    else{
                        this.dataSource = [];
                    }
                });
            },
            checkAction(action, record) {
                /**
                 * submitState 提交状态 0：未提交；1：已提交，未提交的可删改
                 * approvalState 是否全部审核通过：0：未完成；1：已完成
                 * payState 支付状态：0：未支付；1：已支付
                 */
                switch (action) {
                    case "edit": {
                        return record.submitState === "0" && record.payState === "0" ;
                    }
                    case "submit": {
                        return record.submitState === "0" && record.payState === "0" ;
                    }
                    case "delete": {
                        return record.submitState === "0" && record.payState === "0" ;
                    }
                    case "cancel": {
                        return record.submitState === "1" && record.approvalState === "0" ;
                    }
                    case "download": {
                        return record.submitState === "1" && record.approvalState === "1";
                    }
                    case "auditDone":{
                        return record.approvalState === "1" && record.payState === "0" ;
                    }
                }
            },
            auditDone(record){
                this.serviceAmount = 0;
                this.bankId = record.bankId;
                this.currentRecord = record;
                this.visible = true
            },
            generatePaper(record){
                this.currentRecord = record;
                this.visible3 = true
            },
            showStep(record) {
                this.steps = [];
                let data = {
                    "applyId": record.id,
                };
                payApproval.queryAll.post(data, res => {
                    if (res.data.flag) {
                        this.steps = res.data.data;
                        this.modalWidth = (this.steps.length+1) * 270;
                        this.visible2 = true;
                    }
                    else{
                        this.$message.error("无审核流程，本条支付为无需审核类型")
                    }
                })
            },
            payConfirm(){
                if(!this.payDate){
                    this.$message.error("请选择支付日期");
                    return
                }
                if(!this.bankId){
                    this.$message.error("请选择支付账号");
                    return
                }
                let data = {
                    id:this.currentRecord.id,
                    payState:"1",
                    payTime:this.payDate.format("YYYY-MM-DD"),
                    bankId:this.bankId,
                    serviceAmount:this.serviceAmount,
                    invoiceId:this.currentRecord.invoiceId,
                    remarks:this.fileUrl
                };
                payApply.payConfirm.urlPost(data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.currentRecord.payState = "1";
                        this.visible = false;
                        this.init();
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            getSumMoney(){
                this.sumMoney = [];
                for(let item of this.source){
                    let month = dayjs(item.payTime).format("YYYY-MM");
                    let arr = this.sumMoney.filter(item=>item.month === month);
                    if(arr.length >0){
                        arr[0].value += item.payAmount;//支付金额
                        arr[0].value2 += item.serviceAmount?item.serviceAmount:0;//手续费
                    }
                    else{
                        this.sumMoney.push({month:month,value:item.payAmount,value2:item.serviceAmount?item.serviceAmount:0})
                    }
                }
            },
            handleChange(urls){
                this.fileUrl = urls;
            }
        },
        created() {
            this.init();
            this.payDate = dayjs();
            this.tableColumns = [
                new Column("项目名称","itemName",ColumnType.String).setTableView(200),
                new Column("付款合同名称","contractName",ColumnType.String),
                new Column("付款合同编号","contractNum",ColumnType.String).setTableView(100),
                new Column("收款单位/个人","outSourceName",ColumnType.String).setTableView(100),
                new Column("付款时间","payTime",ColumnType.Date).setTableView(80),
                new Column("支付金额","payAmount",ColumnType.Number).setTableView(80),
                new Column("平台手续费","serviceAmount",ColumnType.Number).setTableView(80),
                new Column("付款公司", "payCompanyId", ColumnType.Enum).setTableView(100)
                    .setDictEnum("companies"),
                new Column("付款部门", "applyDepartName", ColumnType.String).setTableView(100),
                new Column("支付描述","payReason",ColumnType.String).setTableView(100),
                new Column("附件","remarks",ColumnType.PreViewFile).setTableView(100),
                new Column("操作","actions","actions").setTableView(80)
            ];
        }
    }
</script>

<style scoped>

</style>