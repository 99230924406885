<!--
* @program: office_automation
* @author: ly
* @component:ProjectOutflow
* @description: 项目外协支付
* @create: 2022-01-25 11:06
-->
<template>
    <div class="column-flex">
        <div style="display: flex;align-items: center">
            <a-button @click="init" type="dashed">
                <template #icon><SyncOutlined/></template>
            </a-button>
            <span class="form-value">合计：{{summary.sum.toFixed(2)}}元</span>
            <span >【外协支付：{{summary.money.toFixed(2)}}元，手续费：{{summary.serviceAmount.toFixed(2)}}元】</span>
            <a-divider style="height:32px;border-left: #022d5c solid;" type="vertical"/>
            <a-range-picker style="width: 200px" v-model:value="rangeMonth" picker="month" />
            <a-input  placeholder="项目名称" style="width: 150px" v-model:value="filter.itemName" />
            <a-input  placeholder="付款方" style="width: 150px" v-model:value="filter.payCompanyName" />
            <a-input  placeholder="收款方" style="width: 150px" v-model:value="filter.outSourceName" />
        </div>
        <div style="height:600px;overflow:auto">
            <LTable :columns="tableColumns" :data-source="source">
                <template #action="{record}">
                    <div class="action-column">
                        <a-button @click="showDetail(record)" type="link">
                            <template #icon>
                                <SearchOutlined/>
                            </template>
                        </a-button>
                    </div>
                </template>
            </LTable>
        </div>
    </div>
</template>

<script>
import dayjs from "dayjs";
import LTable from "@/components/table/LTable";
import payApply from "@/assets/api/payApply";
import Column from "@/assets/tables/column";
import ColumnType from "@/assets/tables/column_type";
import {SearchOutlined, SyncOutlined} from "@ant-design/icons-vue";
import {ArrayFilter} from "../../../../assets/utils/general";


export default {
    name: "ProjectOutflow",
    props:["record","value"],
    components: {
        LTable,
        SearchOutlined,SyncOutlined
    },
    watch: {
        record() {
            this.init()
        }
    },
    computed:{
        source(){
            let source = [];
            if(this.rangeMonth){
                source = this.dataSource.filter(item=>{
                    let payDate = dayjs(item.payTime);
                    const startOfMonth = this.rangeMonth[0].startOf('month');
                    const endOfMonth = this.rangeMonth[1].endOf('month');
                    return payDate.isAfter(startOfMonth) && payDate.isBefore(endOfMonth);
                });
                return ArrayFilter(source,this.filter);
            }
            else{
                return ArrayFilter(this.dataSource,this.filter);
            }
        },
        summary(){
            let money = 0;
            let serviceAmount = 0;
            for(let item of this.source){
                let date = dayjs(item.payTime);
                if (date.isAfter(dayjs(this.record.balanceDate))) {
                    money += item.payAmount;
                    serviceAmount += item.serviceAmount?item.serviceAmount:0
                }
            }

            return {money,serviceAmount,sum:money+serviceAmount}
        }
    },
    data(){
        return{
            dataSource:[],
            tableColumns:[
                new Column("项目名称","itemName",ColumnType.String).setTableView(200),
                new Column("付款时间","payTime",ColumnType.Date).setTableView(80),
                new Column("收款单位/个人","outSourceName",ColumnType.String).setTableView(100),
                new Column("支付金额","payAmount",ColumnType.Number).setTableView(80),
                new Column("平台手续费","serviceAmount",ColumnType.Number).setTableView(80),
                new Column("付款公司", "payCompanyId", ColumnType.Enum).setTableView(100)
                    .setDictEnum("companies"),
                new Column("支付描述","payReason",ColumnType.String).setTableView(100),
                new Column("附件","remarks",ColumnType.PreViewFile).setTableView(80),
            ],
            loading:false,
            rangeMonth:undefined,
            filter:{}
        }
    },
    methods:{
        init(){
            this.visible = false;
            this.loading = true;
            let data = {
                bankId: this.record.id,
                payState:"1"
            };
            payApply.queryAll.post(data,res=>{
                this.loading = false;
                if(res.data.flag){
                    this.dataSource = res.data.data.sort((a,b)=>{
                        return b.createTime - a.createTime
                    });
                }
                else{
                    this.dataSource = [];
                }
            })
        },
    },
    created() {
        this.init();
    }
}
</script>

<style scoped>

</style>
