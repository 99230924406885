<!--
* @program: TYHOA2 
* @author: ly
* @component:CalendarDiary 
* @description: 
* @create: 2024-08-14 16:14
workContent
taskTitle
processTitle
remarks
-->
<template>
    <div class="column-flex">
        <a-calendar mode="month" v-model:value="value">
            <template #dateFullCellRender="{ current }">
                <div style="height:120px;cursor: default">
                    <CalendarCellHead @click="add(current)" style="height:32px;cursor: pointer" :day="current"/>
                    <div style="height:88px;overflow: auto">
                        <div v-for="item in getList(current)" :key="item.id">
                            <div @click="edit(item)" :class="className(item)">{{diaryTitle(item)}}</div>
                        </div>
                    </div>
                </div>
            </template>
        </a-calendar>
    </div>
</template>

<script>
    import CalendarCellHead from "./CalendarCellHead";
    import dayjs from "dayjs"
    export default {
        name: "CalendarDiary",
        props:["diary"],
        emits:["edit"],
        components:{
            CalendarCellHead
        },
        data() {
            return {
                value:undefined,
            }
        },
        methods: {
            diaryTitle(diary){
                if(diary.taskTitle){
                    return diary.taskTitle
                }
                if(diary.itemName){
                    return diary.itemName
                }
                if(diary.processTitle){
                    return diary.processTitle
                }
                return "其他工作"
            },
            className(diary){
                if(diary.processEmployeeId === 'work'){
                    return "diary-title-3"
                }
                if(diary.taskTitle){
                    return "diary-title-1"
                }
                return "diary-title-2"
            },
            getList(current){
                if(this.diary && this.diary.length >0){
                    return this.diary.filter(item =>{
                        return dayjs(item.workDate).format('YYYY-MM-DD') === current.format('YYYY-MM-DD')
                    })
                }
                else{
                    return []
                }
            },
            getClass(current){
                //let name = this.value?.format('YYYY-MM-DD') === current.format('YYYY-MM-DD')?'item-selected':'';
                let name = "";
                if(dayjs().format('YYYY-MM-DD') === current.format('YYYY-MM-DD')){
                    name += " diary-today"
                }
                return name
            },
            edit(item){
                this.$emit("edit",item);
            },
            add(current){
                let item = {};
                item.workDate = current;
                this.$emit("edit",item);
            },
        }
    }
</script>

<style scoped>

</style>