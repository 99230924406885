/** 
* 自动生成文件，请勿修改 
* @name: 考核:员工年度考核维度得分表
* @description: 员工年度考核维度得分表（三期新增）
* @create: 2024/10/18 15:02:19
* @swagger: 2.0
* 合计:11条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const assessmentYear = { 
	/** 1 summary:同意/不同意-多条id,逗号分隔
	description:参数：多条id,逗号分隔
	method:post
	params:需要配合Column函数使用
	*/ 
	agree : new APIObject.postObject("/api/assessmentYear/agree.do"), 
	/** 2 summary:查询-（需要自己打分的）当前登录用户为评分人
	description:参数：无参数，后台根据session自己获取当前登录用户
	method:post
	params:需要配合Column函数使用
	*/ 
	queryByJudgeUser : new APIObject.postObject("/api/assessmentYear/queryByJudgeUser.do"), 
	/** 3 summary:同意/不同意-当年考核表的所有打分项都同意/不同意
	description:描述：全部同意/不同意（对于当月考核表的所有打分项，被考核人都同意，就只需传年就行）
	method:post
	params:需要配合Column函数使用
	*/ 
	agreeAll : new APIObject.postObject("/api/assessmentYear/agreeAll.do"), 
	/** 4 summary:删除-批量
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/assessmentYear/batchDelete.do"), 
	/** 5 summary:查询-单条(根据主键)
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/assessmentYear/queryOne.do"), 
	/** 6 summary:修改：多条
	description:参数：List<AssessmentYearVO>
	method:post
	params:需要配合Column函数使用
	*/ 
	batchUpd : new APIObject.postObject("/api/assessmentYear/batchUpd.do"), 
	/** 7 summary:删除-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/assessmentYear/delete.do"), 
	/** 8 summary:生成员工考核维度表
	description:参数：11：作业员年度考核：12：工勤岗年度考核；13：技术管理岗年度考核；14：工勤管理岗年度考核；
	method:post
	params:需要配合Column函数使用
	*/ 
	generate : new APIObject.postObject("/api/assessmentYear/generate.do"), 
	/** 9 summary:查询-当前登录用户（被考核人自己的）
	description:参数：无参数，后台根据session自己获取
	method:post
	params:
	*/ 
	queryAllCurrentUser : new APIObject.postObject("/api/assessmentYear/queryAllCurrentUser.do"), 
	/** 10 summary:查询-全部不分页
	description:参数：queryVO对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/assessmentYear/queryAll.do"), 
	/** 11 summary:修改：单条
	description:参数：单条数据对象AssessmentYearVO
	method:post
	params:需要配合Column函数使用
	*/ 
	update : new APIObject.postObject("/api/assessmentYear/update.do"), 
};
export default assessmentYear;