/**
 * @program: 前台

 * @author: ly
 *
 * @create: 2020-08-20 15:10
 **/


/**
 * 随机生成UUID
 * @param {int}len   生成的UUID的长度
 * @param {int}radix 生成的UUID的进制
 * @returns {string}
 */
import dayjs from "dayjs"
import {MyEnum} from "@/assets/tables/column";

function uuid(len, radix) {
    let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    let uuid = [], i;
    radix = radix || chars.length;

    if (len) {
        // Compact form
        for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random()*radix];
    } else {
        // rfc4122, version 4 form
        let r;

        // rfc4122 requires these characters
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
        uuid[14] = '4';

        // Fill in random data.  At i==19 set the high bits of clock sequence as
        // per rfc4122, sec. 4.1.5
        for (i = 0; i < 36; i++) {
            if (!uuid[i]) {
                r = 0 | Math.random()*16;
                uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r];
            }
        }
    }

    return uuid.join('');
}
/**
 * 返回object中的属性个数
 * @param {Object} obj
 * @returns {int}
 */
function countProperties (obj) {
    let count = 0;
    for (let property in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, property)) {
            count++;
        }
    }
    return count;
}


class EmitData {
    constructor(type,arg) {
        this.type = type;
        this.arg = arg;
    }
}

function checkAuth(auth,type) {
    if(auth && auth.length>0){
        return auth.indexOf(type) !== -1;
    }
    else{
        return false;
    }
}

function GetEmitData(type,arg) {
    return new EmitData(type,arg)
}


/**
 * @return {string}
 */
function GetCurrentFileUrl(url) {

    let reg = new RegExp("C:/apache-tomcat-8.5.38-2020/webapps/tyhoa_file/","g");
    if(url){
        //return url.replace(reg,"http://192.168.10.190:2020/tyhoa_file/");
        return url.replace(reg,"../tyhoa_file/");
    }
    else{
        return "";
    }
}

function dateFormat(fmt, date) {
    let ret;
    const opt = {
        "Y+": date.getFullYear().toString(),        // 年
        "M+": (date.getMonth() + 1).toString(),     // 月
        "d+": date.getDate().toString(),            // 日
        "H+": date.getHours().toString(),           // 时
        "m+": date.getMinutes().toString(),         // 分
        "S+": date.getSeconds().toString()          // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        }
    }
    return fmt;
}

function arrayDeleteItem(array,item) {
    let index = array.indexOf(item);
    array.splice(index,1);
    return array
}

// 手机号校验
function isPhoneNumber(phoneNum) {
    // let reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
    /*
      * 移动号码包括的号段：134/135/136/137,138,139；
    *                     147/148(物联卡号)；
    *                     150/151/152/157/158/159；
    *                     165（虚拟运营商）；
    *                     1703/1705/1706（虚拟运营商）、178；
    *                     182/183/184/187/188
    *                     198

    * 联通号段包括：130/131
    *               145
    *               155/156
    *               166/167(虚拟运营商)
    *               1704/1707/1708/1709、171
    *               186/186
    *
    * 电信号段包括： 133
    *                153
    *                162(虚拟运营商)
    *                1700/1701/1702(虚拟运营商)
    *                180/181/189
    *                191/199
    * */
    let reg = /^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,7,8]|8[0-9]|9[1,8,9])\d{8}$/;
    return reg.test(phoneNum);
}


// 两个时间内的所有yearMonth

function monthBetween(start,end) {
    if(start && end){
        let sM = new dayjs(start);
        let eM = new dayjs(end);
        let bMonth = [];
        while (eM >sM || sM.format("M") === eM.format("M")){
            bMonth.push(sM.format("YYYY-MM"));
            sM.add(1,"month");
        }
        return bMonth
    }
    else if(start && !end){
        let sM = new dayjs(start);
        return [sM.format("YYYY-MM")]
    }
    else{
        return []
    }
}

/**
 * time是否在时间段内
 * @param time 时间
 * @param period  时间段
 * @returns {boolean}
 */
function timeIsBetween(time,period) {
    if(period && period.length === 2){
        let current = dayjs(time);
        let sDate = dayjs(period[0]);
        let eDate = dayjs(period[1]);
        return current.isAfter(sDate) && current.isBefore(eDate)
    }
    return true
}

/**
 * 是否允许编辑的月份
 * eg:time  2022-4 2022-5  month  2022-5  return true
 * @param time 需要判断的时间
 * @param month 允许的月份
 * @returns {boolean}
 * 待修改
 */
function allowMonth(time,month) {
    //let mo = dayjs(time);
    console.log(time,month)
    //return  mo.isBetween(new dayjs(month).subtract(1, 'month').format("YYYY-MM"), new dayjs(month), null, "[]")
    return  true
}

/**
 * 通过身份证号得到生日 输出格式为:1990-01-01
 * @param idCard
 * @returns {string}
 */
function getBirth(idCard) {
    let birthday = "";
    if(idCard !== null && idCard !== ""){
        if(idCard.length === 15){
            birthday = "19"+idCard.slice(6,12);
        } else if(idCard.length === 18){
            birthday = idCard.slice(6,14);
        }
        birthday = birthday.replace(/(.{4})(.{2})/,"$1/$2/");
        //通过正则表达式来指定输出格式为:1990-01-01
    }
    return birthday;
}
/**
 * 通过身份证号得到性别
 * @param idCard
 * @returns {string}
 */
function getSex(idCard) {
    let sexStr = '';
    if (parseInt(idCard.slice(-2, -1)) % 2 === 1) {
        sexStr = '男';
    }
    else {
        sexStr = '女';
    }
    return sexStr;
}
/**
 * 通过身份证号得到年龄
 * @param idCard
 * @returns {string}
 */
function getAge(idCard) {
    let birthday = "";
    if(idCard !== null && idCard !== ""){
        if(idCard.length === 15){
            birthday = "19"+idCard.slice(6,12);
        } else if(idCard.length === 18){
            birthday = idCard.slice(6,14);
        }
        birthday = birthday.replace(/(.{4})(.{2})/,"$1/$2/");
        //通过正则表达式来指定输出格式为:1990-01-01
    }
    let date = new Date().toLocaleDateString();
    let year = parseInt(date.split("/")[0]);
    let mouth = parseInt(date.split("/")[1]);
    let day = parseInt(date.split("/")[2]);
    if(!birthday){
        return 0
    }
    let birthYear = parseInt(birthday.split("/")[0]);
    let birthMouth = parseInt(birthday.split("/")[1]);
    let birthDay = parseInt(birthday.split("/")[2]);
    let a = mouth*30 + day;
    let b = birthMouth*30 + birthDay;
    if(a - b >0){
        return year - birthYear;
    }
    else{
        return year - birthYear - 1;
    }
}
//计算startDate到当前日期满足的周年数
function workingYear(startDate) { //1900/01/01
    if (!startDate) {
        return 0
    }
    let date = dayjs();
    let year = date.year();
    let mouth = date.month()+1;
    let day = date.date();
    let nDate = dayjs(startDate);
    let birthYear = nDate.year();
    let birthMouth = nDate.month()+1;
    let birthDay = nDate.date();
    let a = mouth * 30 + day;
    let b = birthMouth * 30 + birthDay;
    if (a - b > 0) {
        return year - birthYear;
    } else {
        return year - birthYear - 1;
    }
}

function fixedNumber(number) {
    return  parseFloat(number.toFixed(2))
}

function getNode(key,treeData){ //递归函数
    for(let i in treeData){
        if(treeData[i]["id"] === key){
            return  treeData[i];
        }
        else {
            if(treeData[i].children.length>0){
                let res = getNode(key,treeData[i].children);
                if(res){
                    return res
                }
            }
        }
    }
}

function getHaveOpEnum(vue) {
    return vue.$route.meta.haveOpEnum;
}

function getDictText(dictCode,value) {
    let allDict = JSON.parse(localStorage.getItem("dict"));
    let dict = allDict.find(item=>{
        return  item.dictCode === dictCode;
    });
    if(dict && dict.dictItems.length>0){
        let item = dict.dictItems.find(obj=>obj.itemValue === value);
        if(item){
            return item.itemText;
        }
    }
    return ""
}
function getMyUserId() {
    let userInfo =  JSON.parse(localStorage.getItem("userInfo"));
    return userInfo.userInfo.id;
}
function getMyDeparts() {
    let userInfo =  JSON.parse(localStorage.getItem("userInfo"));
    return userInfo.departs;
}
function isFunction(fn) {
    return Object.prototype.toString.call(fn) === '[object Function]';
}
//将树状结构展开成数组
function getAllNodes (nodes, treeData) {
    for (let item of treeData) {
        nodes.push(item);
        if (item.children) {
            nodes = getAllNodes(nodes, item.children)
        }
    }
    return nodes
}

function GetDict(dictCode) {
    let allDict = JSON.parse(localStorage.getItem("dict"));
    let dict = allDict.find(item=>{
        return  item.dictCode === dictCode;
    });
    if(dict && dict.dictItemDTOList.length>0){
        let enums = [];
        for(let item of dict.dictItemDTOList){
            let {itemLabel,itemValue,remarks} = item;
            let myEnum = new MyEnum(itemValue,itemLabel,remarks);
            enums.push(myEnum);
        }
        return enums
    }
}

/**
 * 通过filter 找到满足条件的 array 中的元素
 * @param array 中的元素为object 例如：[{title:"中色",content:"刘"},{title:"lan",content:"王"}]
 * @param filer 为object 例如{title:"中色",content:"刘"}
 * @returns {*}
 */
function ArrayFilter(array,filer) {
    let keys = Object.keys(filer);
    let tempArray = array;
    for(let key of keys){
        if(filer[key] !== null && filer[key]!== undefined && filer[key] !==""){
            tempArray = tempArray.filter(item=>{
                switch (typeof item[key]) {
                    case "string":{
                        return item[key].includes(filer[key])
                    }
                    case "number":{
                        return item[key] === filer[key]
                    }
                    case "boolean":{
                        return item[key] === filer[key]
                    }
                    default:{
                        return false
                    }
                }
            })
        }
    }
    return tempArray
}

function objectsAreEqual(obj1, obj2) {
    if(!obj1 || !obj2){
        return false
    }
    // 获取 obj1 和 obj2 的属性名数组
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // 如果属性数量不同，则两个对象不相等
    if (keys1.length !== keys2.length) {
        return false;
    }

    // 检查每个属性是否相同
    for (let key of keys1) {
        const val1 = obj1[key];
        const val2 = obj2[key];

        // 如果属性值是对象，则递归调用 objectsAreEqual 函数
        if (val1 && val2 && typeof val1 === 'object' && typeof val2 === 'object') {
            if (!objectsAreEqual(val1, val2)) {
                return false;
            }
        } else if (val1 !== val2) {
            return false;
        }
    }
    return true;
}

let chnNumChar = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
let chnUnitSection = ["", "万", "亿", "万亿", "亿亿"];
let chnUnitChar = ["", "拾", "佰", "仟"];

function numToChn(num) {
    let index = num.toString().indexOf(".");
    if (index !== -1) {
        let str = num.toString().slice(index);
        let a = "点";
        for (let i = 1; i < str.length; i++) {
            a += chnNumChar[parseInt(str[i])];
        }
        return a;
    } else {
        return '';
    }
}

//定义在每个小节的内部进行转化的方法，其他部分则与小节内部转化方法相同
function sectionToChinese(section) {
    let str = '', chnstr = '', zero = false, count = 0;   //zero为是否进行补零， 第一次进行取余由于为个位数，默认不补零
    while (section > 0) {
        let v = section % 10;  //对数字取余10，得到的数即为个位数
        if (v === 0) {                    //如果数字为零，则对字符串进行补零
            if (zero) {
                zero = false;        //如果遇到连续多次取余都是0，那么只需补一个零即可
                chnstr = chnNumChar[v] + chnstr;
            }
        } else {
            zero = true;           //第一次取余之后，如果再次取余为零，则需要补零
            str = chnNumChar[v];
            str += chnUnitChar[count];
            chnstr = str + chnstr;
        }
        count++;
        section = Math.floor(section / 10);
    }
    return chnstr;
}

//定义整个数字全部转换的方法，需要依次对数字进行10000为单位的取余，然后分成小节，按小节计算，当每个小节的数不足1000时，则需要进行补零
/**
 * @return {string}
 */
function TransformToChinese(num) {
    let a = numToChn(num);
    num = Math.floor(num);
    let unitPos = 0;
    let strIns = '', chnStr = '';
    let needZero = false;

    if (num === 0) {
        return chnNumChar[0];
    }
    while (num > 0) {
        let section = num % 10000;
        if (needZero) {
            chnStr = chnNumChar[0] + chnStr;
        }
        strIns = sectionToChinese(section);
        strIns += (section !== 0) ? chnUnitSection[unitPos] : chnUnitSection[0];
        chnStr = strIns + chnStr;
        needZero = (section < 1000) && (section > 0);
        num = Math.floor(num / 10000);
        unitPos++;
    }

    return chnStr + a;
}
/**
 * @return {string}
 */
function NumberToChineseCurrency(num) {
    // 定义大写数字
    const cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
    // 定义单位
    const cnIntRadice = ['', '拾', '佰', '仟'];
    const cnIntUnits = ['', '万', '亿', '兆'];
    const cnDecUnits = ['角', '分'];
    const cnInteger = '整';
    const cnIntLast = '元';

    let integerNum;  // 整数部分
    let decimalNum;  // 小数部分
    let chineseStr = '';  // 返回的中文字符串
    let parts;  // 分离整数与小数部分

    // 确定数字是否为正数
    if (num === '') {
        return '';
    }
    num = parseFloat(num);
    if (num >= 999999999999999.99) {
        return ''; // 超出可处理范围
    }

    // 将数字分为整数和小数部分
    if (num === 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr;
    }

    num = num.toString();
    if (num.indexOf('.') === -1) {
        integerNum = num;
        decimalNum = '';
    } else {
        parts = num.split('.');
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 2);  // 只取小数部分前两位
    }

    // 处理整数部分
    if (parseInt(integerNum, 10) > 0) {
        let zeroCount = 0;
        let IntLen = integerNum.length;
        for (let i = 0; i < IntLen; i++) {
            let n = integerNum.substr(i, 1);
            let p = IntLen - i - 1;
            let q = p / 4;
            let m = p % 4;
            if (n === '0') {
                zeroCount++;
            } else {
                if (zeroCount > 0) {
                    chineseStr += cnNums[0];
                }
                zeroCount = 0; // 重置零计数
                chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
            }
            if (m === 0 && zeroCount < 4) {
                chineseStr += cnIntUnits[q];
            }
        }
        chineseStr += cnIntLast;
    }

    // 处理小数部分
    if (decimalNum !== '') {
        for (let i = 0; i < decimalNum.length; i++) {
            let n = decimalNum.substr(i, 1);
            if (n !== '0') {
                chineseStr += cnNums[parseInt(n)] + cnDecUnits[i];
            }
        }
    }

    if (chineseStr === '') {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
    } else if (decimalNum === '' || (/^0+$/.test(decimalNum))) {
        chineseStr += cnInteger;
    }

    return chineseStr;
}

/**
 * 将合同的实际情况统计并赋予项目JSON
 * item 项目附带合同信息
 * 检测项目任务是否完成，赋予itemState
 * @constructor
 */
function ApplyContract(item) {
    item.sumAmount = 0;
    item.contractTypes = []; //合同类型
    item.partyContract = !!item.contractInfoDTOs;
    let contractInfoDTOs = item.contractInfoDTOs;
    if(item.contractState !== '2'){//合同状态不是不需要的状态
        item.contractState = "0";
    }
    if (contractInfoDTOs && contractInfoDTOs.length > 0) {
        for (let item_c of contractInfoDTOs) {
            item.contractTypes.push(item_c.type);
            if (item_c.type === '1' || item_c.type === '4' || item_c.type === '7') {
                if(item.contractState !== '2'){//合同状态不是不需要的状态 且已录入收费合同时候
                    item.contractState = "1";
                }
                item.partyInfoId = item_c.associatedKey;
                item.partyInfoName = item_c.associatedName;
                item.sumAmount += item_c.amount; //合同总金额
                item.sigCompany = item_c.sigCompany;
                let company = GetDict("companies").find(item=>item.value === item_c.sigCompany);
                if(company){
                    item.sigCompanyName = company.label;
                }
                else{
                    console.log("找不到对应的签署公司，id:"+item.id+",sigCompany:"+item_c.sigCompany)
                }
            }
        }
    }
    item.sumCollect = item.sumCollect?item.sumCollect:0;
    item.sumCollectNo = item.sumAmount; //项目未收款总额 = 合同总额
    if (item.sumCollectNo > item.sumCollect) { //sumCollect 已收款总额
        item.sumCollectNo = item.sumCollectNo - item.sumCollect //未收款 = 合同额 - 已收款总额
    } else {
        item.sumCollectNo = 0;
    }
    item.collecState = item.sumCollect ? "1" : "0";
    //项目收款
    if(item.collectionState === "0"){
        item.collectionState = (item.sumAmount === item.sumCollect)?"1":"0";
    }
    //项目任务
    item.departmentName = item.itemTaskDTO?.departmentName;
    item.itemState = item.itemTaskDTO?.state;
    return item
}

export {uuid,countProperties,EmitData,timeIsBetween,allowMonth,fixedNumber,isFunction,
    checkAuth,GetEmitData,GetCurrentFileUrl,dateFormat,arrayDeleteItem,isPhoneNumber,monthBetween,
    getNode,getHaveOpEnum,getDictText,getMyUserId,getAllNodes,getMyDeparts,GetDict,ArrayFilter,
    getBirth,getSex,getAge,workingYear,objectsAreEqual,TransformToChinese,NumberToChineseCurrency,ApplyContract}
