<!--
* @program: TYHOA2 
* @author: ly
* @component:ProjectRegister 
* @description: 项目创建
* @create: 2024-08-28 15:49
-->
<template>
    <div class="column-flex" style="position: relative;overflow: hidden">
        <div class="search-bar">
            <a-button @click="init"   type="dashed">
                <template #icon><SyncOutlined title="重置"/></template>
            </a-button>
            <a-button @click="addData" style="margin: 0 10px"   type="dashed">
                新建项目
            </a-button>
            <a-radio-group v-model:value="auditState">
                <a-radio v-for="item in auditOptions" :key="item.value" :value="item.value">{{item.label}}</a-radio>
            </a-radio-group>
        </div>
        <div style="width: 100%;height: 100%;">
            <LTable :fixed="true" :data-source="dataSource" :download="true" :loading="loading" :columns="tableColumns">
                <template #action="{record}">
                    <div  class="action-column">
                        <a-button class="action-button" v-if="record.auditState !== '1'" @click="editDetail(record)" type="link">
                            <EditOutlined class="pointer" title="查看"/>
                        </a-button>
                        <a-popconfirm
                                v-if="record.auditState === '2'"
                                @confirm="reUpdate(record)"
                                cancel-text="否"
                                ok-text="是"
                                title="重新提交审核?">
                            <a-button class="action-button" type="link">
                                <UploadOutlined class="pointer" title="提交"/>
                            </a-button>
                        </a-popconfirm>
                        <a-popconfirm
                                v-if="record.auditState !== '1'"
                                @confirm="cancel(record)"
                                cancel-text="否"
                                ok-text="是"
                                title="确定删除吗?">
                            <a-button class="action-button" type="link">
                                <DeleteOutlined class="pointer" style="color: red" title="删除"/>
                            </a-button>
                        </a-popconfirm>
                    </div>
                </template>
            </LTable>
        </div>
    </div>
    <a-modal v-model:open="visible"
             :maskClosable="false"
             width="800px"
             destroyOnClose
             :footer="null"
             title="项目详情">
        <LForm :form-columns="tableColumns" :form-data="currentRecord" :view-model="false">
            <template #action="{record}">
                <a-button @click="saveData(record)"  type="primary">
                    提交
                </a-button>
            </template>
        </LForm>
    </a-modal>
</template>

<script>
    import itemInfo from "../../../assets/api/itemInfo";
    import {DeleteOutlined, SyncOutlined,EditOutlined,UploadOutlined} from "@ant-design/icons-vue";
    import LTable from "../../../components/table/LTable";
    import LForm from "../../../components/form/LForm";
    import {GetDict} from "../../../assets/utils/general";
    import Column from "../../../assets/tables/column";
    import ColumnType from "../../../assets/tables/column_type";
    import partyInfo from "../../../assets/api/partyInfo";
    import item_info from "../../TableStruct/item_info";
    export default {
        name: "ProjectRegister",
        components:{
            DeleteOutlined,
            LTable,  SyncOutlined,EditOutlined,UploadOutlined,
            LForm
        },
        data() {
            return {
                auditState:"0",
                auditOptions:GetDict("auditState"),
                loading:false,
                tableColumns:[
                    new Column("项目类型","itemType",ColumnType.Enum,true).setDefaultValue("1").setTableView(100)
                        .setDictEnum("itemType").setRequired(),
                    new Column("任务大类","itemCategory",ColumnType.Enum,true).setRequired()
                        .setDictEnum("itemCategory"),//0：其他；1：系统研发；2：工程测量；3：规划编制；4：数据处理
                    new Column("年度","year",ColumnType.Number,true).setTableView(80).setRequired(),
                    new Column("项目名称","name",ColumnType.String,true).setTableView(300).setRequired(),
                    new Column("甲方名称","partyInfoId",ColumnType.IdReplace,true).setTableView(200).setRequired()
                        .setIdReplaceObject("partyInfoName",{api:partyInfo.queryAll,tag:"queryText"}),
                    new Column("预计金额","itemBudget",ColumnType.Number,true).setDefaultValue(0).setTableView(100),//项目金额
                    new Column("合同状态","contractState",ColumnType.String,true).setGroup(2).setRequired()
                        .setTableView(100).setDictEnum("contractState").setDefaultValue("0"),
                    new Column("项目内容","content",ColumnType.String,true).setRequired(),
                    new Column("创建时间","createTime",ColumnType.Date,false).setTableView(100),
                    new Column("启动时间","startTime",ColumnType.Date,true).setTableView(100).setRequired(),
                    new Column("完成期限","endDate",ColumnType.Date,true).setTableView(100).setRequired(),
                    new Column("审核状态","auditState",ColumnType.Enum).setDefaultValue("0").setTableView(100)
                        .setDictEnum("auditState"),
                    new Column("备注","remarks",ColumnType.String,true),
                    new Column("操作","actions","actions").setTableView(100),
                ],
                projectList:[],
                visible:false,
                currentRecord:{}
            }
        },
        computed:{
            dataSource(){
                return this.projectList.filter(item=>item.auditState === this.auditState);
            }
        },
        methods: {
            init() {
                this.loading = true;
                itemInfo.queryAllByUser.post({},res=>{
                    if(res.data.flag){
                        this.projectList = res.data.data;
                    }
                    else{
                        this.projectList = [];
                        this.$message.error(res.data.msg);
                    }
                    this.loading = false;
                })
            },
            cancel(record){
                let data = {id:record.id};
                itemInfo.delete.urlPost(data,res=>{
                    if(res.data.flag){
                        this.projectList = this.projectList.filter(item => item.id !== record.id)
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                });
            },
            editDetail(record){
                this.currentRecord = record;
                this.visible = true;
            },
            addData(){
                this.currentRecord = new item_info().getFormData();
                this.visible = true;
            },
            reUpdate(record){
                record.auditState = "0";
                itemInfo.saveOrUpd.post(record, res => {
                    if (res.data.flag) {
                        this.init();
                        this.$message.success(res.data.msg);
                    } else {
                        this.$message.error(res.data.msg)
                    }
                });
            },
            saveData(record) {
                itemInfo.saveOrUpd.post(record, res => {
                    if (res.data.flag) {
                        this.visible = false;
                        this.init();
                        this.$message.success(res.data.msg);
                    } else {
                        this.$message.error(res.data.msg)
                    }
                });
            },
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>