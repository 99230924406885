<!--
* @program: TYHOA2 
* @author: ly
* @component:SelectContract
* @description: 
* @create: 2024-07-25 16:40
-->
<template>
    <div class="form-title" v-if="source.length > 0" >按合同确定收付款方
        <a-button v-if="value"  type="link"  @click="clear">
            清除
        </a-button>
    </div>
    <div v-else class="form-title">
        暂无可以收款的合同
    </div>
    <a-radio-group v-if="source.length > 0" @change="handleChange" v-model:value="value">
        <a-radio v-for="item in source" :key="item.id" :value="item.id">{{item.name}}</a-radio>
    </a-radio-group>
</template>

<script>
    export default {
        name: "SelectContract",
        emits:["change"],
        props:{
            contract: {
                required: true,
                default: []
            },
        },
        computed:{
            source(){
                return this.contract.filter(it=>it.type === '1' || it.type ==='4')
            }
        },
        data() {
            return {
                value:null
            }
        },
        methods: {
            handleChange() {
                this.$emit("change",this.contract.find(item=>item.id === this.value))
            },
            clear(){
                this.value = null;
                this.$emit("change",null)
            }
        }
    }
</script>

<style scoped>

</style>