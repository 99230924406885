/**
 * @program: tyh-oa
 * @author: ssr
 * @description: item_qc_apply 质检申请管理 审核用
 * @create: 2021-04-25 11:19
 **/
import Table from "@/assets/tables/table";
import Column from "@/assets/tables/column";
import ColumnType from "@/assets/tables/column_type"
export default class item_qc_apply extends Table{
    constructor() {
        super();
        this.name = "item_qc_apply";
        this.CNName = "质检申请管理";
        this.columnArray = [
            new Column("项目","itemName",ColumnType.String,false).setTableView(300),
            // new Column("质检类型","type",ColumnType.Enum,true).setTableView(100)
            //     .setEnum(["1","2","3"],["项目质检","任务质检","工序质检"],["blue","green","cyan"]),//质检类型（1：项目质检；2：任务质检；3：工序质检）
            new Column("质检标题","title",ColumnType.String,true),
            new Column("申请说明","content",ColumnType.String,true).setTableView(150),
            new Column("质检内容","proContent",ColumnType.String,true).setTableView(150),
            new Column("质检完成期限","planCommitDate",ColumnType.Date,true).setTableView(100),
            new Column("申请时间","applyTime",ColumnType.Date,false),
            new Column("申请部门","departmentName",ColumnType.String,false).setTableView(100),
            new Column("申请人","applyUserName",ColumnType.String,false).setTableView(80),
            new Column("审核状态","auditState",ColumnType.String,false)
                .setDictEnum("auditState"),
            new Column("审核意见","auditNote",ColumnType.String,false),
            new Column("审核时间","auditTime",ColumnType.Date,false),
            new Column("审核人","auditUserName",ColumnType.String,false),
            new Column("质检负责人","qcLeaderName",ColumnType.String,false),
            new Column("质检情况","state",ColumnType.Enum,false).setDefaultValue("0")
                .setEnum(
                    {label:"未完成质检",value:"0",color:"purple"},
                    {label:"已完成质检",value:"1",color:"blue"}),
            new Column("备注","remarks",ColumnType.String),
        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(100)];
    }
}