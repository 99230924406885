<!--
* @program: office_automation
* @author: ly
* @component:OtherOutcome
* @description: 资金账户支出
* @create: 2022-02-17 09:58
-->
<template>
    <div class="column-flex">
        <div class="search-bar">
            <a-button @click="init"  type="dashed">
                <template #icon><SyncOutlined/></template>
            </a-button>
            <a-button @click="addData"  type="dashed">
                <template #icon><PlusOutlined /></template>
            </a-button>
            <span style="margin-left: 10px">审核状态：</span>
            <a-radio-group @change="radioChange"  v-model:value="defaultQuery.approvalState">
                <a-radio value="0">未完成审核</a-radio>
                <a-radio value="1">已完成审核</a-radio>
            </a-radio-group>
        </div>
        <LTable :loading="loading" :data-source="dataSource" :columns="tableColumns">
            <template #action="{index,record}">
                <div class = "action-column">
                    <a-button @click="showDetail(record)"
                              class="action-button" type="link">
                        <SearchOutlined class="pointer"/>
                    </a-button>
                    <a-button @click="editDetail(record)"
                              v-if="record.approvalState === '0'"
                              class="action-button" type="link">
                        <EditOutlined class="pointer"/>
                    </a-button>
                    <a-popconfirm
                            v-if="record.approvalState === '0'"
                            @confirm="deleteData(index,record)"
                            cancel-text="否"
                            class="action-button"
                            ok-text="是"
                            title="删除？">
                        <DeleteOutlined class="pointer" style="color: red"/>
                    </a-popconfirm>
                </div>
            </template>
        </LTable>
    </div>
    <a-modal :footer="null"
             title="资金账户支付申请"
             width="700px"
             destroyOnClose
             v-model:open="visible">
        <a-radio-group v-if="!vModel" v-model:value="currentType" @change="selectType">
            <a-radio value="1">银行账户间转账</a-radio>
            <a-radio value="2">由银行卡取出到现金账户</a-radio>
            <a-radio value="3">由现金账户存入银行卡</a-radio>
            <a-radio value="4">支出</a-radio>
        </a-radio-group>
        <a-divider />
        <LForm :form-columns="formColumns" v-model:form-data="formData" :view-model="vModel">
            <template #action="{record}">
                <a-button @click="saveData(record)" type="primary">
                    提交
                </a-button>
            </template>
        </LForm>
    </a-modal>
</template>

<script>
    ////支出类型（1：卡间相互间转账；2：取现；3：存入，4：对外支出）
    //"银行账户间转账","由银行卡取出到现金账户","由现金账户存入银行卡","支出"
    import LTable from "@/components/table/LTable";
    import LForm from "@/components/form/LForm";
    import otherPay from "@/assets/api/otherPay";
    import bankBalance from "@/assets/api/bankBalance";
    import Column from "@/assets/tables/column";
    import ColumnType from "@/assets/tables/column_type";
    import dayjs from "dayjs";
    import api_item_info from "@/assets/api/itemInfo";
    import item_other_pay from "../../../TableStruct/item_other_pay";
    import {SyncOutlined,PlusOutlined,SearchOutlined,EditOutlined,DeleteOutlined} from "@ant-design/icons-vue"
    export default {
        name: "OtherPayApply",
        components: {
            SyncOutlined,PlusOutlined,SearchOutlined,EditOutlined,DeleteOutlined,
            LTable, LForm
        },
        computed:{

        },
        data() {
            return {
                tableColumns:new item_other_pay().getTableColumns(),
                dataSource: [],
                loading:false,
                formColumns:new item_other_pay().getFormColumns(),
                formData:{},
                vModel:true,
                visible:false,
                defaultQuery: {
                    approvalState: "0"
                },
                currentType:"1",
                bankCards:[],
                treeData:[],
                fieldNames :{
                    label: 'categoryName',
                    key:"id",
                    value:"id",
                },
                categoryId:null,
            }
        },
        methods: {
            init() {
                this.formData = {};
                this.loading = true;
                otherPay.queryAll.post(this.defaultQuery, res => {
                    this.loading = false;
                    if (res.data.flag) {
                        this.dataSource = res.data.data;
                        this.dataSource = this.dataSource.sort((a,b)=>{
                            return b.payDate - a.payDate;
                        });
                        this.dataSource.map(item=>{
                            let pCard = this.bankCards.find(card=>card.id === item.payBankId);
                            let rCard = this.bankCards.find(card=>card.id === item.receiveBankId);
                            if(pCard){
                                item.payBankName = pCard.remarks
                            }
                            if(rCard){
                                item.receiveBankName = rCard.remarks;
                            }
                        });
                    } else {
                        this.dataSource = [];
                    }
                });
            },
            showDetail(record) {
                this.vModel = true;
                this.formData = record;
                this.currentType = record.type;
                this.selectType();
                this.visible = true;
            },
            editDetail(record){
                this.currentType = record.type;
                this.vModel = false;
                this.formData = record;
                this.selectType();
                this.visible = true;
            },
            checkAction(action, record) {
                switch (action) {
                    case "edit": {
                        return record.approvalState === "1";
                    }
                    case "delete": {
                        return record.approvalState === "0";
                    }
                }
            },
            deleteData(index, record) {
                let data = {id: record.id};
                otherPay.delete.urlPost( data, res => {
                    if (res.data.flag) {
                        this.dataSource.splice(record.index, 1);
                        this.$message.success(res.data.msg);
                    } else {
                        this.$message.error(res.data.msg)
                    }
                });
            },
            addData() {
                this.currentType = "1";
                this.formData = {};
                this.visible = true;
                this.vModel = false;
                this.selectType();
            },
            radioChange() {
                this.init();
            },
            saveData(record) {
                record.type = this.currentType;
                if(this.currentType === "1"){
                    record.categoryId = "5dd9e0f4-dafe-41fc-a795-338ea9a1ee3d"
                }
                if(this.currentType === "2"){
                    record.categoryId = "2f650b0e-696e-4a90-940e-3e1a76f7b81d"
                }
                if(this.currentType === "3"){
                    record.categoryId = "ed04edf5-5e9e-4fb6-9bf5-1ead8288df51"
                }
                if(record.id){
                    otherPay.forceUpd.post(record, res => {
                        if (res.data.flag) {
                            this.visible = false;
                            this.init();
                            this.$message.success(res.data.msg);
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    });
                }
                else{
                    otherPay.saveOrUpd.post( record, res => {
                        if (res.data.flag) {
                            this.$message.success(res.data.msg);
                            this.visible = false;
                            this.init();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    });
                }

            },
            selectType(){
                switch (this.currentType) {//（1：卡间相互间转账；2：取现；3：存入，4：对外支出）
                    case "1":{
                        this.formColumns = [
                            new Column("审核状态","approvalState",ColumnType.Enum).setEnum(
                                {value:"0",label: "待审核",color:"red"},
                                {value:"1",label: "已审核",color:"green"},
                            ),
                            new Column("支付类型","type",ColumnType.Enum)
                                .setDictEnum("item_other_pay.type"),
                            new Column("费用类别","categoryId",ColumnType.Category),
                            new Column("金额","payAmount",ColumnType.Number,true),
                            new Column("日期","payDate",ColumnType.Date,true),
                            new Column("支付账户","payBankId",ColumnType.Selector,true)
                                .setSelectorObject("payBankName",this.bankCards.filter(item=>item.accountType ==="1")),
                            new Column("接收账户","receiveBankId",ColumnType.Selector,true)
                                .setSelectorObject("receiveBankName",this.bankCards.filter(item=>item.accountType ==="1")),
                            new Column("说明","remarks",ColumnType.String,true),
                        ];
                        break
                    }
                    case "2":{
                        this.formColumns = [
                            new Column("审核状态","approvalState",ColumnType.Enum).setEnum(
                                {value:"0",label: "待审核",color:"red"},
                                {value:"1",label: "已审核",color:"green"},
                            ),
                            new Column("支付类型","type",ColumnType.Enum).setTableView(100)
                                .setDictEnum("item_other_pay.type"),
                            new Column("费用类别","categoryId",ColumnType.Category),
                            new Column("金额","payAmount",ColumnType.Number,true),
                            new Column("日期","payDate",ColumnType.Date,true),
                            new Column("支付账户","payBankId",ColumnType.Selector,true)
                                .setSelectorObject("payBankName",this.bankCards.filter(item=>item.accountType ==="1")),
                            new Column("接收账户","receiveBankId",ColumnType.Selector,true)
                                .setSelectorObject("remarks",this.bankCards.filter(item=>item.accountType ==="2")),
                            new Column("说明","remarks",ColumnType.String,true),
                        ];
                        break
                    }
                    case "3":{
                        this.formColumns = [
                            new Column("审核状态","approvalState",ColumnType.Enum).setEnum(
                                {value:"0",label: "待审核",color:"red"},
                                {value:"1",label: "已审核",color:"green"},
                            ),
                            new Column("支付类型","type",ColumnType.Enum).setTableView(100)
                                .setDictEnum("item_other_pay.type"),
                            new Column("费用类别","categoryId",ColumnType.Category),
                            new Column("金额","payAmount",ColumnType.Number,true).setDefaultValue(0).setTableView(100),
                            new Column("日期","payDate",ColumnType.Date,true),
                            new Column("支付账户","payBankId",ColumnType.Selector,true)
                                .setSelectorObject("payBankName",this.bankCards.filter(item=>item.accountType ==="2")),
                            new Column("接收账户","receiveBankId",ColumnType.Selector,true)
                                .setSelectorObject("receiveBankName",this.bankCards.filter(item=>item.accountType ==="1")),
                            new Column("说明","remarks",ColumnType.String,true),
                        ];
                        break
                    }
                    case "4":{
                        this.formColumns = [
                            new Column("审核状态","approvalState",ColumnType.Enum).setEnum(
                                {value:"0",label: "待审核",color:"red"},
                                {value:"1",label: "已审核",color:"green"},
                            ),
                            new Column("支付类型","type",ColumnType.Enum).setTableView(100)
                                .setDictEnum("item_other_pay.type"),
                            new Column("费用类别","categoryId",ColumnType.Category,true).setTableView(100),
                            new Column("所属项目","itemId",ColumnType.IdReplace2,true)
                                .setIdReplace2Object("itemName",{api:api_item_info.queryAll,tag:"name",options:{
                                        "archiveState": "", "auditState": "1", "collectionState": "", "contractState": "",
                                        "itemLeaderName": "", "itemState": "", "name": "", "partyInfoName": "", "qualityState": "",
                                        "startTime": "", "tenderWay": ""
                                    }}),
                            new Column("金额","payAmount",ColumnType.Number,true).setDefaultValue(0).setTableView(100),
                            new Column("日期","payDate",ColumnType.Date,true).setTableView(100),
                            new Column("支付账户","payBankId",ColumnType.Selector,true).setTableView(100)
                                .setSelectorObject("payBankName",this.bankCards),
                            new Column("说明","remarks",ColumnType.String,true),
                        ];
                        break
                    }

                }
            },
            getSumMoney(){
                this.sumMoney = [];
                for(let i in this.dataSource){
                    let month = dayjs(this.dataSource[i].payDate).format("YYYY-MM");
                    let arr = this.sumMoney.filter(item=>item.month === month);
                    if(arr.length >0){
                        arr[0].value += this.dataSource[i].payAmount;
                    }
                    else{
                        this.sumMoney.push({month:month,value:this.dataSource[i].payAmount})
                    }
                }

            },
        },
        created() {
            this.$getItem("__myID").then(str=>{
                this.defaultQuery.createUser = str;
                bankBalance.queryAll.post({},res=>{
                    if(res.data.flag){
                        this.bankCards = res.data.data.filter(item=>item.compId !=="abandoned");
                        this.init()
                    }
                    else{
                        this.bankCards = [];
                    }
                });
            });
            this.$getItem("__myCategory").then(str=>{
                this.treeData = JSON.parse(str).filter(item=>item.categoryName === "转账类");
            });

        },
    }
</script>

<style scoped>

</style>
