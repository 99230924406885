/** 
* 自动生成文件，请勿修改 
* @name: 财务:资产、负债、权益具体科目期初余额
* @description: 产、负债、权益具体科目期初余额======资产负债表使用
* @create: 2024/10/18 15:02:19
* @swagger: 2.0
* 合计:6条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const assetsBalance = { 
	/** 1 summary:试算平衡
	description:参数：year:年度
	method:post
	params:需要配合Column函数使用
	*/ 
	calBalance : new APIObject.postObject("/api/assetsBalance/calBalance.do"), 
	/** 2 summary:删除-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/assetsBalance/delete.do"), 
	/** 3 summary:查询-单条
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/assetsBalance/queryOne.do"), 
	/** 4 summary:查询-全部不分页
	description:参数：year：年度；subjectId：科目id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/assetsBalance/queryAll.do"), 
	/** 5 summary:删除-批量
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/assetsBalance/batchDelete.do"), 
	/** 6 summary:保存/修改-单条
	description:参数：多条数据对象AssetsBalanceVO
	method:post
	params:需要配合Column函数使用
	*/ 
	batchSave : new APIObject.postObject("/api/assetsBalance/batchSave.do"), 
};
export default assetsBalance;