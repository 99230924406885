/** 
* 自动生成文件，请勿修改 
* @name: 资产：设备申领申请审核流水
* @description: 资产：设备申领申请审核流水
* @create: 2024/10/18 15:02:19
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const assetApplyApproval = { 
	/** 1 summary:报账单审核记录流水-分页查询
	description:参数：pageBean分页对象(传page、limit即可)、recordId(报账单id,可有可无);approvalUser(审核人id,可有可无);state(审核状态（0,1,2,3）,可有可无)
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/assetApplyApproval/queryPage.do"), 
	/** 2 summary:报账单审核记录流水-批量删除
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/assetApplyApproval/batchDelete.do"), 
	/** 3 summary:查询--单条数据
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/assetApplyApproval/queryOne.do"), 
	/** 4 summary:查询所有
	description:参数：applyId(申领申请id);approvalUser(审核人id);state(审核状态（0,1,2,3）)
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/assetApplyApproval/queryAll.do"), 
	/** 5 summary:报账单审核记录流水-单条删除
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/assetApplyApproval/delete.do"), 
	/** 6 summary:申领申请-审核
	description:参数：applyId:申领申请id;userId:用户id;state:(2:通过,3:不通过)
	method:post
	params:需要配合Column函数使用
	*/ 
	audit : new APIObject.postObject("/api/assetApplyApproval/audit.do"), 
	/** 7 summary:保存/修改
	description:参数：单条数据对象AssetApplyApprovalVO
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/assetApplyApproval/saveOrUpd.do"), 
};
export default assetApplyApproval;