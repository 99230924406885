/** 
* 自动生成文件，请勿修改 
* @name: 项目:任务管理模块============新增了一检附件上传
* @description: 项目-任务管理模块(增加接口：得到当前用户有参与工序的任务)
* @create: 2024/10/18 15:02:19
* @swagger: 2.0
* 合计:9条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const itemTask = { 
	/** 1 summary:查询-单条数据
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/itemTask/queryOne.do"), 
	/** 2 summary:查询-分页(得到当前用户参与的任务)
	description:参数：(pageBean分页参数)
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPageMy : new APIObject.postObject("/api/itemTask/queryPageMy.do"), 
	/** 3 summary:查询-不分页(得到当前用户参与的任务)
	description:参数：queryVO
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAllMy : new APIObject.postObject("/api/itemTask/queryAllMy.do"), 
	/** 4 summary:查询-所有数据(不查询子项目的任务)
	description:参数：如下
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/itemTask/queryAll.do"), 
	/** 5 summary:查询-分页(不查询子项目的任务)
	description:参数：(pageBean分页参数)、departmentId，itemId,queryText
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/itemTask/queryPage.do"), 
	/** 6 summary:删除-批量
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/itemTask/batchDelete.do"), 
	/** 7 summary:保存/修改-单个任务
	description:参数：单条数据对象ItemTaskVO
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/itemTask/saveOrUpd.do"), 
	/** 8 summary:上传一检附件===========新增接口
	description:参数：对主任务的一检信息，一件人员、日期、得分需同时存在
	method:post
	params:需要配合Column函数使用
	*/ 
	qcFileUpload : new APIObject.postObject("/api/itemTask/qcFileUpload.do"), 
	/** 9 summary:删除-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/itemTask/delete.do"), 
};
export default itemTask;