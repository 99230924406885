<!--
* @program: TYHOA2 
* @author: ly
* @component:ProjectIncome 
* @description: 
* @create: 2024-07-25 11:17
-->
<template>
    <div style="width: 100%;height: 100% ;display: flex">
        <div class="main-content50">
            <ProjectInvoice :record="record" :bank-cards="bankCards" :viewModel="viewModel"/>
        </div>
        <div class="main-content50">
            <ProjectIncomeRecord :record="record" :bank-cards="bankCards" :viewModel="viewModel"/>
        </div>
    </div>

</template>

<script>
    import ProjectInvoice from "./ProjectInvoice";
    import ProjectIncomeRecord from "./ProjectIncomeRecord";
    import bankBalance from "../../../assets/api/bankBalance";
    export default {
        name: "ProjectIncome",
        props:["record","viewModel"],
        components:{
            ProjectInvoice,ProjectIncomeRecord
        },
        data(){
            return{
                bankCards:[]
            }
        },
        created() {
            bankBalance.queryAll.post({},res=>{
                if(res.data.flag){
                    this.bankCards = res.data.data.filter(item=>item.compId !=="abandoned");
                }
                else{
                    this.bankCards = [];
                }
            });
        }

    }
</script>

<style scoped>

</style>