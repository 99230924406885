/** 
* 自动生成文件，请勿修改 
* @name: 支出:其他支出申请审批流程设置
* @description: 其他支出申请审批流程设置（四期新增（董灿提出））
* @create: 2024/10/18 15:02:19
* @swagger: 2.0
* 合计:6条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const otherPayAudit = { 
	/** 1 summary:批量删除
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/otherPayAudit/batchDelete.do"), 
	/** 2 summary:单条删除
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/otherPayAudit/delete.do"), 
	/** 3 summary:分页查询
	description:参数：OtherPayAuditQueryVO
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/otherPayAudit/queryPage.do"), 
	/** 4 summary:所有数据查询
	description:参数：OtherPayAuditQueryVO
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/otherPayAudit/queryAll.do"), 
	/** 5 summary:单条数据查询
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/otherPayAudit/queryOne.do"), 
	/** 6 summary:保存/修改
	description:参数：单条数据对象OtherPayAuditVO
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/otherPayAudit/saveOrUpd.do"), 
};
export default otherPayAudit;