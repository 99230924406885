<!--
* @program: office_automation
* @author: ly
* @component:UseSealSetting
* @description: 印章用途设定
* @create: 2022-06-07 14:41
-->
<template>
    <div class="column-flex">
        <div class="search-bar">
            <a-button @click="refresh"  shape="circle" type="dashed">
                <template #icon>
                    <SyncOutlined/>
                </template>
            </a-button>
            <a-button @click="addData"  shape="circle" type="dashed">
                <template #icon>
                    <PlusOutlined/>
                </template>
            </a-button>
        </div>
        <div style="width: 100%;height: 100%;display: flex">
            <div class="main-content50">
                <div class="full-fill">
                    <LTable :columns="argument.tableColumns" :dataSource="argument.dataSource" :page-size="pageSize" :view-model="true" ref="arTable">
                        <template #action="{index,record}">
                            <div class = "action-column">
                                <a-button @click="editDetail(record)"
                                          class="action-button" type="link">
                                    <EditFilled class="pointer"/>
                                </a-button>
                                <a-popconfirm
                                        @confirm="deleteData(index,record)"
                                        cancel-text="否"
                                        class="action-button"
                                        ok-text="是"
                                        title="删除？">
                                    <DeleteOutlined class="pointer" style="color: red"/>
                                </a-popconfirm>
                            </div>
                        </template>
                    </LTable>
                </div>
            </div>
            <div class="main-content50">
                <div class="full-fill">
                    <Welcome />
                </div>
            </div>
        </div>
    </div>
    <a-modal :footer="null"
             :maskClosable="true"
             title=""
             v-model:open="visible">
        <LForm :formColumns="formColumns" :formData="formData" :view-model="false">
            <template #action="{record}">
                <a-button @click="saveData(record)" type="primary">
                    提交
                </a-button>
            </template>
        </LForm>
    </a-modal>
</template>
<script>
    import Welcome from "../../Other/Welcome";
    import LTable from "../../../components/table/LTable";
    import LForm from "../../../components/form/LForm";
    import {SyncOutlined,PlusOutlined,EditFilled,DeleteOutlined} from "@ant-design/icons-vue"
    import Column from "../../../assets/tables/column";
    import ColumnType from "../../../assets/tables/column_type";
    import sealPurpose from "../../../assets/api/sealPurpose";
    export default {
        name: "UseSealSetting",
        components: {
            Welcome,LTable,LForm,
            SyncOutlined,PlusOutlined,EditFilled,DeleteOutlined
        },
        data() {
            return {
                pageSize:15,
                visible:false,
                argument:{
                    dataSource:[],
                    tableColumns:[
                        new Column("序号","_index",ColumnType.Index).setTableView(100),
                        new Column("用途","name",ColumnType.String,true).setTableView(150),
                        new Column("备注","remarks",ColumnType.String,true).setTableView(150),
                        new Column("操作","actions","actions").setTableView(80)
                    ],
                    loading:false,
                },
                formColumns:[
                    new Column("用途","name",ColumnType.String,true),
                    new Column("备注","remarks",ColumnType.String,true),
                ], //提供Form的字段集 this.table.getFormColumns()
                formData:null,//提供Form的formData
            }
        },
        methods: {
            init() {
                sealPurpose.queryAll.post({},res=>{
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = [];
                    }
                })
            },
            refresh(){
                this.init()
            },
            addData(){
                this.formData = {
                    name:null,
                    remarks:null
                };
                this.visible = true;
            },
            editDetail(record){
                this.visible = true;
                this.formData = record;
            },
            deleteData(index,record){
                let data = {
                    id:record.id
                };
                sealPurpose.delete.urlPost(data,res=>{
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        this.argument.dataSource.splice(index, 1);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },
            saveData(record){
                sealPurpose.saveOrUpd.post(record,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.init();
                        this.visible = false
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>
