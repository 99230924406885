<!--
* @program: TYHOA2 
* @author: ly
* @component:CooperationUnitsPicker
* @description: 
* @create: 2024-09-18 14:46
-->
<template>
    <a-select
            show-search
            v-model:value="selectId"
            placeholder="输入关键字查询(最大显示8条信息)"
            style="width: 100%"
            :filter-option="false"
            :not-found-content="loading ? undefined : null"
            :options="searchData"
            :fieldNames="fieldNames"
            @change="selectConform"
            @search="searching">
        <template v-if="loading" #notFoundContent>
            <a-spin size="small" />
        </template>
    </a-select>
</template>

<script>

    import partyInfo from "../../assets/api/partyInfo";

    export default {
        name: "CooperationUnitsPicker",
        props:["value","option"],
        emits:["update:value","change"],
        data(){
            return{
                loading:false,
                searchData:[],
                selectId:null,
                selectedData:[],
                fieldNames:{ label: "name", value: "id"}
            }
        },
        watch:{
            value(){
                this.selectId = this.value;
            }
        },
        methods:{
            init(){
                this.selectId = this.value;
                if(this.option){
                    this.searchData = [...this.option]
                }
            },
            searching(value){
                if(value){
                    this.loading = true;
                    let formParam = {
                        page:1,
                        limit:8,
                    };
                    let urlParam = {
                        queryText:value
                    };
                    partyInfo.queryPage.bothPost({formParam,urlParam},res=>{
                        this.loading = false;
                        if(res.data.flag){
                            this.searchData = res.data.data;
                        }
                        else{
                            this.searchData = [];
                            this.$message.error(res.data.msg);
                        }
                    })
                }
            },
            selectConform(){
                this.selectedData = this.searchData.find(item=>{return this.selectId === item.id});
                this.$emit("update:value",this.selectId);
                this.$emit("change",this.selectedData);
            }
        },
        created() {
            this.init();
        }
    }
</script>

<style scoped>

</style>