/** 
* 自动生成文件，请勿修改 
* @name: 报销:人员报账审批流程模块
* @description: 报销-人员报账审批流程-增删改查
* @create: 2024/10/18 15:02:19
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const processAudit = { 
	/** 1 summary:人员报账审批流程-批量保存/修改
	description:参数：List<ProcessAuditVO>,审核步骤数totalstepcount必须大于0
	method:post
	params:需要配合Column函数使用
	*/ 
	batchSaveOrUpd : new APIObject.postObject("/api/processAudit/batchSaveOrUpd.do"), 
	/** 2 summary:人员报账审批流程-分页查询
	description:参数：分页对象(传page、limit即可)、用户名（可有可无,用于模糊查询）
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/processAudit/queryPage.do"), 
	/** 3 summary:人员报账审批流程-批量删除
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/processAudit/batchDelete.do"), 
	/** 4 summary:人员报账审批流程-单条删除
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/processAudit/delete.do"), 
	/** 5 summary:人员报账审批流程-所有数据查询
	description:参数：用户名（可有可无,用于模糊查询）
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/processAudit/queryAll.do"), 
	/** 6 summary:人员报账审批流程-保存/修改
	description:参数：单条数据对象ProcessAuditVO
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/processAudit/saveOrUpd.do"), 
	/** 7 summary:人员报账审批流程-单条数据查询
	description:参数：数据主键id或者userId（一个用户有且只有一条审批流程）
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/processAudit/queryOne.do"), 
};
export default processAudit;