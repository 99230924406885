<!--
* @program: TYHOA2 
* @author: ly
* @component:InvoiceOutcome 
* @description: 
* @create: 2024-09-30 09:54
-->
<template>
    <div class="column-flex" style="position: relative;overflow: hidden">
        <div class="search-bar">
            <a-button @click="init"   type="dashed">
                <template #icon><SyncOutlined/></template>
            </a-button>
            <a-input v-model:value="filter.itemName" style="width:200px" placeholder="项目名称" />
            <a-input v-model:value="filter.partyName" style="width:200px" placeholder="付款方" />
            <a-input v-model:value="filter.companyName" style="width:200px" placeholder="收款方" />
            <a-input-number v-model:value="filter.invoiceAmount" style="width:150px" placeholder="开票金额" />
        </div>
        <div class="row-flex">
            <LTable :loading="loading" :data-source="source" :columns="inCols" >
                <template #action="{record}">
                    <div class = "action-column">
                        <a-button :loading="record.loading" @click="showDetail(record)"
                                  class="action-button" type="link">
                            <SearchOutlined class="pointer"/>
                        </a-button>
                        <a-button @click="editDetail(record)"
                                  class="action-button" type="link">
                            <EditFilled class="pointer"/>
                        </a-button>
                    </div>
                </template>
            </LTable>
        </div>
        <a-drawer v-model:open="visible2"
                  placement="bottom"
                  height="90%"
                  :closable="false"
                  :get-container="false"
                  :style="{ position: 'absolute' }"
                  destroy-on-close>
            <div class="search-bar">
                <a-button @click="refresh"  type="dashed">
                    <template #icon><SyncOutlined/></template>
                </a-button>
                <a-button @click="addPayed"  type="dashed">
                    <template #icon><PlusOutlined/></template>
                    添加支付申请
                </a-button>
            </div>
            <LTable  :data-source="source2" :columns="flowCols" >
                <template #action="{record}">
                    <a-button  type="link"  @click="showAudit(record)">
                        <template #icon><LinkOutlined /></template>
                    </a-button>
                    <a-button  v-if="checkAction('edit',record)" type="link"  @click="editFlow(record)">
                        <template #icon><EditFilled /></template>
                    </a-button>
                    <a-popconfirm
                            @confirm="cancelSubmit(record)"
                            cancel-text="否"
                            class="action-button"
                            ok-text="是"
                            title="是否撤销审核？"
                            v-if="checkAction('cancel',record)">
                        <a-tooltip title="撤销审核">
                            <a-button class="action-button" style="color: orange" type="link">
                                <RollbackOutlined class="pointer"/>
                            </a-button>
                        </a-tooltip>
                    </a-popconfirm>
                    <a-popconfirm
                            @confirm="submit(record)"
                            cancel-text="否"
                            class="action-button"
                            ok-text="提交"
                            title="提交审核？"
                            v-if="checkAction('submit',record)">
                        <a-tooltip title="提交审核">
                            <a-button class="action-button" style="color: green" type="link">
                                <ArrowUpOutlined class="pointer"/>
                            </a-button>
                        </a-tooltip>
                    </a-popconfirm>
                    <a-popconfirm
                            v-if="checkAction('delete',record)"
                            ok-text="是"
                            cancel-text="否"
                            class="action-button"
                            title="确定删除吗?"
                            @confirm="deleteFlow(record)">
                        <DeleteOutlined  style="color: red" class="pointer"/>
                    </a-popconfirm>
                </template>
            </LTable>
        </a-drawer>
    </div>
    <a-modal v-model:open="visible"
             destroy-on-close
             title="发票信息"
             :footer="null">
        <a-row>
            <a-col :span="6">
                <span class="form-value">合同选择</span>
            </a-col>
            <a-col :span="18">
                <ContractPicker v-model:value="contractId" :item-id="currentRecord.itemId" />
            </a-col>
        </a-row>
        <LForm :view-model="false"  :form-columns="inCols" :form-data="currentRecord">
            <template #action="{record}">
                <a-button @click="saveData(record)"  type="primary">
                    提交
                </a-button>
            </template>
        </LForm>
    </a-modal>
    <a-modal v-model:open="visible3"
             destroy-on-close
             title="外协支付申请"
             :footer="null">
        <a-row>
            <a-col :span="6">
                <span class="form-value">合同选择</span>
            </a-col>
            <a-col :span="18">
                <ContractPicker v-model:value="contractId" :item-id="currentRecord.itemId" />
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="6">
                <span class="form-value">选择付款账户</span>
            </a-col>
            <a-col :span="18">
                <a-select :fieldNames="fieldNames" v-model:value="bankId" style="width: 100%" :options="bankCards"/>
            </a-col>
        </a-row>
        <LForm :view-model="false" :form-columns="flowCols" :form-data="formData">
            <template #action="{record}">
                <a-button @click="saveFlow(record)"  type="primary">
                    提交
                </a-button>
            </template>
        </LForm>
    </a-modal>
    <a-modal v-model:open="visible4"
             destroy-on-close
             width="1000px"
             title="审核进度"
             maskClosable
             :footer="null">
        <OutSourcePayApplyAuditStatus :record="formData" />
    </a-modal>
</template>

<script>
    import LTable from "../../../../components/table/LTable";
    import LForm from "../../../../components/form/LForm";
    import {DeleteOutlined, EditFilled, PlusOutlined, SearchOutlined, SyncOutlined,LinkOutlined,
        RollbackOutlined,ArrowUpOutlined} from "@ant-design/icons-vue";
    import {ArrayFilter} from "../../../../assets/utils/general";
    import Column from "../../../../assets/tables/column";
    import ColumnType from "../../../../assets/tables/column_type";
    import invoiceInto from "../../../../assets/api/invoiceInto";
    import payApply from "../../../../assets/api/payApply";
    import OutSourcePayApplyAuditStatus from "../../../WorkAdministration/ProjectOutcome/OutSourcePayApplyAuditStatus";
    import ContractPicker from "../../../common/ContractPicker";
    export default {
        name: "InvoiceOutcome",
        props: ["bankCards"],
        components:{
            LTable,LForm,OutSourcePayApplyAuditStatus,ContractPicker,
            EditFilled,DeleteOutlined,PlusOutlined,SearchOutlined,SyncOutlined,RollbackOutlined,
            ArrowUpOutlined,LinkOutlined
        },
        computed: {
            source() {
                return ArrayFilter(this.source1, this.filter);
            }
        },
        data() {
            return {
                departmentId:undefined,
                filter:{},
                inCols:[
                    new Column("项目", "itemName", ColumnType.String, false).setTableView(200),
                    new Column("开票金额", "invoiceAmount", ColumnType.Number, true).setTableView(80),
                    new Column("已付款项", "payAmount", ColumnType.Number, false).setTableView(80),
                    new Column("付款状态", "payState", ColumnType.Enum, false).setGroup(1).setTableView(100)
                        .setDictEnum("payState"),
                    new Column("开票日期", "invoiceTime", ColumnType.Date, true).setTableView(100),
                    new Column("发票代码", "invoiceCode", ColumnType.String, true),
                    new Column("发票号码", "invoiceNumber", ColumnType.String, true),
                    new Column("发票类型", "invoiceType", ColumnType.Enum, true).setDefaultValue("1").setDictEnum("invoiceType"),
                    new Column("检验码", "checkCode", ColumnType.String, true),
                    new Column("付款方", "partyName", ColumnType.String).setTableView(150),
                    new Column("收款方", "companyName", ColumnType.String).setTableView(150),
                    new Column("绑定合同","remarks",ColumnType.Boolean,false).setBoolTags(["未绑定","已绑定"]).setTableView(80),
                    new Column("冲红", "flushRed", ColumnType.Boolean, true)
                        .setBoolTags(["未冲红", "已冲红"]).setDefaultValue(false),
                    new Column("操作", "actions", "actions", false).setTableView(90)
                ],
                visible:false,
                visible2:false,
                visible3:false,
                visible4:false,
                flowCols:[
                    new Column("付款合同","contractName",ColumnType.String,false).setTableView(200),
                    new Column("付款合同编号","contractNum",ColumnType.String,false),
                    new Column("付款发票","invoiceId",ColumnType.Boolean,false).setBoolTags(["未绑定付款发票","已绑定付款发票"]),
                    new Column("申请支付金额","payAmount",ColumnType.Number,true).setDefaultValue("0").setTableView(130),
                    new Column("申请部门", "applyDepartName", ColumnType.String, false).setTableView(100),
                    new Column("付款公司", "payCompanyId", ColumnType.Enum).setTableView(150)
                        .setDictEnum("companies"),
                    new Column("收款单位/个人", "outSourceName", ColumnType.String, false).setTableView(150),
                    new Column("支付状态","payState",ColumnType.Enum,false).setTableView(90)
                        .setDictEnum("payState"),
                    new Column("支付时间","payTime",ColumnType.Date).setTableView(100),
                    new Column("支付描述","payReason",ColumnType.String,true).setTableView(200),
                    new Column("操作", "actions", "actions", false).setTableView(120)
                ],
                loading:false,
                loading2:false,
                source1:[],
                source2:[],
                currentRecord:{},
                bankId:undefined,
                fieldNames :{key:'id', value: 'id',label:'remarks'},
                formData:{},
                contractId:undefined,
            }
        },
        methods: {
            init() {
                this.loading = true;
                invoiceInto.queryAll.post({},res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.source1 = res.data.data;
                        this.source1.sort((a, b) => {
                            return b.createTime - a.createTime;
                        });
                        this.source1.map(item=>{
                            item.itemName = item.itemInfo?.name;
                            item.partyName = item.partyInfo?.name;
                            item.companyName = item.outSourceInfoDTO?.name;
                            return item;
                        })
                    }
                    else{
                        this.source1 = [];
                    }
                })
            },
            showDetail(record){
                this.source2 = [];
                this.visible2 = true;
                this.currentRecord = record;
                this.contractId = this.currentRecord.remarks;
                payApply.queryAll.post({invoiceId:this.currentRecord.id},res=>{
                    if(res.data.flag){
                        this.source2 = res.data.data;
                    }
                })
            },
            refresh(){
                payApply.queryAll.post({invoiceId:this.currentRecord.id},res=>{
                    if(res.data.flag){
                        this.source2 = res.data.data;
                    }
                })
            },
            editDetail(record){
                this.currentRecord = record;
                this.visible = true;
                this.contractId = this.currentRecord.remarks;
            },
            saveData(record){
                record.remarks = this.contractId;
                invoiceInto.saveOrUpdate.post(record,res=>{
                    if(res.data.flag){
                        let index = this.source1.findIndex(item=>item.id === record.id)
                        this.source1[index] = record;
                        this.visible = false;
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            addPayed(){
                this.visible3 = true;
                this.formData = {};
                this.contractId = this.currentRecord.remarks;
            },
            editFlow(record){
                this.visible3 = true;
                this.formData = record;
                this.bankId = record.bankId;
                this.contractId = this.currentRecord.remarks;
            },
            saveFlow(record){
                record.itemId = this.currentRecord.itemId;
                record.outSourceId = this.currentRecord.companyId;
                record.payCompanyId = this.currentRecord.partyId;
                record.contractId = this.contractId;
                record.applyDepart = this.departmentId;
                record.invoiceId = this.currentRecord.id;
                record.bankId = this.bankId;
                payApply.saveOrUpd.post(record,res=>{
                    if(res.data.flag){
                        this.visible3 = false;
                        this.refresh();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            deleteFlow(record){
                let data = {id:record.id};
                payApply.delete.urlPost(data,res=>{
                    if(res.data.flag){
                        this.refresh()
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            checkAction(action, record) {
                /**
                 * submitState 提交状态 0：未提交；1：已提交，未提交的可删改
                 * approvalState 是否全部审核通过：0：未完成；1：已完成
                 * payState 支付状态：0：未支付；1：已支付
                 */
                switch (action) {
                    case "edit": {
                        return record.submitState === "0" && record.payState === "0" ;
                    }
                    case "submit": {
                        return record.submitState === "0" && record.payState === "0" ;
                    }
                    case "delete": {
                        return record.submitState === "0" && record.payState === "0" ;
                    }
                    case "cancel": {
                        return record.submitState === "1" && record.approvalState === "0";
                    }
                    case "download": {
                        return record.submitState === "1" && record.approvalState === "1";
                    }
                    case "auditDone":{
                        return record.approvalState === "1" && record.payState === "0" ;
                    }
                }
            },
            submit(record){
                let data = {
                    id: record.id
                };
                payApply.submit.urlPost(data,res=>{
                    if(res.data.flag){
                        this.refresh();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            cancelSubmit(record){
                let data = {
                    id: record.id
                };
                payApply.cancelSubmit.urlPost(data,res=>{
                    if(res.data.flag){
                        this.refresh();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            showAudit(record){
                this.visible4 = true;
                this.formData = record;
            }
        },
        async created() {
            this.init()
            let myDepartments = JSON.parse(await this.$getItem("__myDepartments"));
            this.departmentId = myDepartments[0].id
        }
    }
</script>

<style scoped>

</style>