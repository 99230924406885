<!--
* @program: office_automation
* @author: ly
* @component:StaffPromotionAudit
* @description: 员工晋升审核
* @create: 2022-03-08 15:52
-->
<template>
    <div class="column-flex">
        <div class="search-bar">
            <a-button @click="refresh"  shape="circle" type="dashed">
                <template #icon><SyncOutlined/></template>
            </a-button>
            <span>审核状态：</span>
            <a-radio-group  v-model:value="auditSuccess" @change="init">
                <a-radio value="0">未审核</a-radio>
                <a-radio value="1">审核未通过</a-radio>
                <a-radio value="2">审核通过</a-radio>
            </a-radio-group>
        </div>
        <div style="width: 100%;height: 100%;display: flex">
            <div class="main-content50">
                <div class="full-fill">
                        <LTable :columns="argument.tableColumns" :dataSource="argument.dataSource" :loading="argument.loading"
                                :view-model="true" :page-size="pageSize">
                            <template #action="{record}">
                                <div class="action-column">
                                    <a-button v-if="record.success !=='0'"  class="action-button"  type="link" @click="showData(record)">
                                        <SearchOutlined  class="pointer"/>
                                    </a-button>
                                    <a-button v-else  class="action-button"  type="link" @click="editData(record)">
                                        审核
                                    </a-button>
                                    <a-tooltip title="下载晋升评价表">
                                        <a-button v-if="record.success ==='2'"  class="action-button"  type="link" @click="downLoad(record)">
                                            <ArrowDownOutlined  class="pointer"/>
                                        </a-button>
                                    </a-tooltip>
                                </div>
                            </template>
                        </LTable>
                </div>
            </div>
            <div class="main-content50">
                <div class="full-fill">
                    <div v-if="currentRecord">
                        <a-page-header style="border: 1px solid rgb(235, 237, 240)">
                            <template #title>
                                <a-button @click="showHistory">
                                    晋升历史
                                </a-button>
                                <span style="margin-left: 20px">晋升数据：</span>
                                <a-radio-group button-style="solid" v-model:value="comp" >
                                    <a-radio-button value="PromotionInfo">评价表</a-radio-button>
                                    <a-radio-button value="PromotionPoints">分项得分表</a-radio-button>
                                </a-radio-group>
                                <span style="margin-left: 20px" v-if="comp === 'PromotionPoints'">若未指定【同事评分】的人则需要手动指定评分人</span>
                            </template>
                        </a-page-header>
                        <component :is="comp" :view-model="viewModel" v-model:value="currentRecord" :userType="'judgeView'"/>
                    </div>
                    <Welcome v-else/>

                </div>
            </div>
        </div>
    </div>
    <a-modal v-model:open="visible"
             destroyOnClose
             :width="800"
             :footer="null"
             title="晋升历史">
        <span>晋升详情请在【审核通过】的记录里面进行查看</span>
        <LTable :columns="argument2.tableColumns" :dataSource="argument2.dataSource" :loading="argument2.loading"
                :view-model="true" :page-size="pageSize" />
    </a-modal>
</template>

<script>
    import Welcome from "../../../Other/Welcome";
    import LTable from "../../../../components/table/LTable";
    import {
        EditOutlined,
        SearchOutlined,
        SyncOutlined,ArrowDownOutlined
    } from "@ant-design/icons-vue";
    import promotion_audit from "../../../TableStruct/promotion_audit";
    import promotionApply from "../../../../assets/api/promotionApply";
    import PromotionInfo from "./PromotionInfo";
    import Column from "../../../../assets/tables/column";
    import ColumnType from "../../../../assets/tables/column_type";
    import PromotionPoints from "./PromotionPoints";
    import UserPicker from "../../../common/UserPicker";

    export default {
        name: "StaffPromotionAudit",
        components: {
            Welcome, LTable,SearchOutlined,SyncOutlined,ArrowDownOutlined,
            EditOutlined,PromotionInfo,PromotionPoints,
            UserPicker
        },
        data(){
            return{
                table:new promotion_audit(),
                viewModel:true,
                pageSize:15,
                argument:{
                    dataSource:null,
                    tableColumns:new promotion_audit().getTableColumns(),
                    loading:false,
                },
                argument2:{
                    dataSource:null,
                    tableColumns:[
                        new Column("申请人","userName",ColumnType.String).setTableView(80),
                        new Column("现岗位","currentLevel",ColumnType.PositionLevel).setTableView(100),
                        new Column("晋升岗位","applyLevel",ColumnType.PositionLevel).setTableView(100),
                        new Column("申请时间","applyTime",ColumnType.Date).setTableView(80),
                        new Column("是否通过审核","success",ColumnType.Enum,true).setTableView(80)
                            .setDictEnum("success").setTableView(80),
                        new Column("生效日期","effectDate",ColumnType.Date,true).setTableView(80),
                    ],
                    loading:false,
                },
                currentRecord:null,
                visible:false,
                auditSuccess:"0",
                comp:"PromotionPoints",
            }
        },
        methods:{
            init(){
                let data = {
                    submitState:true,
                    success:this.auditSuccess
                };
                this.argument.loading = true;
                this.currentRecord = null;
                promotionApply.queryAll.post(data,res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = []
                    }
                })
            },
            refresh(){
                this.init();
            },
            showData(record){
                this.currentRecord = record;
                this.viewModel = true;
            },
            editData(record){
                this.viewModel = false;
                this.currentRecord = record;
            },
            handleChange(pos) {
                if (pos === "transform") {
                    this.argument.tableColumns = [
                        new Column("申请人","userName",ColumnType.String).setTableView(80),
                        new Column("晋升岗位","applyLevel",ColumnType.PositionLevel).setTableView(100),
                        new Column("操作","actions","actions").setTableView(60)
                    ]
                } else {
                    this.argument.tableColumns = this.table.getTableColumns();
                }
            },
            showHistory(){
                this.visible = true;
                let data = {
                    submitState:true,
                    success: "2", // 0"未审核",1"未通过审核",2"审核通过"
                    userId: this.currentRecord.userId,
                };
                this.argument2.loading = true;
                promotionApply.queryAll.post(data,res=>{
                    this.argument2.loading = false;
                    if(res.data.flag){
                        this.argument2.dataSource = res.data.data;
                    }
                    else{
                        this.argument2.dataSource = [];
                    }
                })
            },
            downLoad(record){
                let fileName = record.userName + "【" +record.applyLevel+ "晋升】.xls";
                let data = {
                    id:record.id
                };
                promotionApply.exportById.fileDownload(data,fileName);
            }
        },
        created() {
            this.argument.tableColumns = this.table.getTableColumns();
            this.init();
        }
    }
</script>

<style scoped>

</style>
