<!--
* @program: TYHOA2 
* @author: ly
* @component:DepartmentProjectFlows 
* @description: 部门项目收付款
* @create: 2024-09-18 20:49
-->
<template>
    <div class="column-flex">
        <div class="search-bar">
            <a-button @click="init" type="dashed">
                <template #icon><SyncOutlined/></template>
            </a-button>
            <a-select v-model:value="departmentId"
                      style="width: 200px"
                      @change="init"
            >
                <a-select-option v-for="department in myDepartments" :key="department.id">
                    {{ department.name }}
                </a-select-option>
            </a-select>
            <a-radio-group style="margin-left:10px" v-model:value="state" @change="init">
                <a-radio value="0">项目外协支付一览</a-radio>
                <a-radio value="1">项目收入一览</a-radio>
            </a-radio-group>
            <a-input class="title-divider" placeholder="项目名称" style="width: 200px" v-model:value="filter.itemName" />
            <a-range-picker v-model:value="rangeMonth" picker="month" />
        </div>
        <div style="width: 100%;height: 100%" v-if="state === '0'">
            <LTable  :columns="tableColumns" :data-source="source" :loading="loading">
                <template #action="{record}">
                    <div class = "action-column">
                        <a-tooltip title="查看审核流程">
                            <a-button @click="showStep(record)" type="link">
                                <template #icon>
                                    <SearchOutlined/>
                                </template>
                            </a-button>
                        </a-tooltip>
                    </div>
                </template>
            </LTable>
        </div>
        <div style="width: 100%;height: 100%" v-if="state === '1'">
            <LTable  :columns="tableColumns" :data-source="source" :loading="loading">
                <template #action="{record}">
                    <div class = "action-column">
                        <a-tooltip title="查看审核流程">
                            <a-button @click="showStep(record)" type="link">
                                <template #icon>
                                    <SearchOutlined/>
                                </template>
                            </a-button>
                        </a-tooltip>
                    </div>
                </template>
            </LTable>
        </div>
    </div>
    <a-modal :footer="null"
             :width="modalWidth"
             destroyOnClose
             title="审核状态"
             v-model:open="visible2">
        <AuditSteps :steps="steps" v-if="visible2"/>
    </a-modal>
</template>

<script>
    import LTable from "../../../../components/table/LTable";
    import AuditSteps from "../../../Equipment/AssetsAuditCenter/AuditSteps";
    import {SearchOutlined, SyncOutlined} from "@ant-design/icons-vue";
    import {ArrayFilter} from "../../../../assets/utils/general";
    import payApply from "../../../../assets/api/payApply";
    import payApproval from "../../../../assets/api/payApproval";
    import Column from "@/assets/tables/column";
    import ColumnType from "@/assets/tables/column_type"
    import collectionInfo from "../../../../assets/api/collectionInfo";
    import dayjs from "dayjs";
    export default {
        name: "DepartmentProjectFlows",
        components:{
            LTable,AuditSteps,
            SearchOutlined,SyncOutlined
        },
        data() {
            return {
                state:"0",
                myDepartments:[],
                departmentId:undefined,
                tableColumns:[],
                loading:false,
                visible:false,
                visible2:false,
                visible3:false,
                currentRecord:null,
                dataSource:[],
                steps:[],
                modalWidth:300,
                /**
                 * submitState 提交状态 0：未提交；1：已提交，未提交的可删改
                 * approvalState 是否全部审核通过：0：未完成；1：已完成
                 * payState 支付状态：0：未支付；1：已支付
                 */
                defaultQuery:{
                    submitState: "1",
                    // approvalState: "1",
                    // payState: "1"
                },
                filter:{},
                serviceAmount:0,
                rangeMonth:undefined
            }
        },
        computed:{
            source(){
                let source = [];
                if(this.rangeMonth){
                    switch (this.state) {
                        case "0":{
                            source = this.dataSource.filter(item=>{
                                let payDate = dayjs(item.payTime);
                                const startOfMonth = this.rangeMonth[0].startOf('month');
                                const endOfMonth = this.rangeMonth[1].endOf('month');
                                return payDate.isAfter(startOfMonth) && payDate.isBefore(endOfMonth);
                            });
                            break
                        }
                        case "1":{
                            source = this.dataSource.filter(item=>{
                                let payDate = dayjs(item.collectionTime);
                                const startOfMonth = this.rangeMonth[0].startOf('month');
                                const endOfMonth = this.rangeMonth[1].endOf('month');
                                return payDate.isAfter(startOfMonth) && payDate.isBefore(endOfMonth);
                            });
                            break
                        }
                    }
                    return ArrayFilter(source,this.filter);
                }
                else{
                    return ArrayFilter(this.dataSource,this.filter);
                }
            }
        },
        methods: {
            init(){
                switch (this.state) {
                    case "0":{
                        this.init0();
                        break
                    }
                    case "1":{
                        this.init1();
                        break
                    }
                }
            },
            init0() {
                this.tableColumns = [
                    new Column("项目名称","itemName",ColumnType.String).setTableView(200),
                    new Column("付款合同名称","contractName",ColumnType.String).setTableView(100),
                    new Column("付款合同编号","contractNum",ColumnType.String).setTableView(100),
                    new Column("收款单位/个人","outSourceName",ColumnType.String).setTableView(100),
                    new Column("申请支付金额","payAmount",ColumnType.Number).setTableView(100),
                    new Column("付款公司", "payCompanyId", ColumnType.Enum).setTableView(150)
                        .setDictEnum("companies"),
                    new Column("支付时间", "payTime", ColumnType.Date).setTableView(100),
                    new Column("支付描述","payReason",ColumnType.String).setTableView(150),
                    new Column("审核状态","approvalState",ColumnType.String).setTableView(80)
                        .setDictEnum("approvalState"),
                    new Column("支付状态","payState",ColumnType.String).setTableView(80)
                        .setDictEnum("payState"),
                    new Column("操作","actions","actions").setTableView(60)
                ];
                this.loading = true;
                this.dataSource = [];
                let data = {
                    applyDepart : this.departmentId,
                    submitState: "1",
                };
                payApply.queryAll.post(data, res => {
                    this.loading = false;
                    if (res.data.flag) {
                        this.dataSource = res.data.data.sort((a,b)=>{
                            return b.createTime - a.createTime
                        });
                    }
                });
            },
            init1(){
                this.tableColumns = [
                    new Column("项目名称","itemName",ColumnType.String).setTableView(200),
                    new Column("收款时间","collectionTime",ColumnType.Date).setTableView(80),
                    new Column("收款金额(元)","collectionAmount",ColumnType.Number).setTableView(100),
                    new Column("所属发票","invoiceNumber",ColumnType.String).setTableView(100),
                    new Column("付款方", "payerName", ColumnType.String).setTableView(150),
                    new Column("收款方", "receiverId", ColumnType.Enum).setTableView(150)
                        .setDictEnum("companies"),
                    new Column("收款备注","remarks",ColumnType.String).setTableView(150),
                ];
                this.loading = true;
                this.dataSource = [];
                let data = {
                    deptId:this.departmentId
                }
                collectionInfo.queryAll.urlPost(data,res=>{
                    this.loading = false;
                    if (res.data.flag) {
                        this.dataSource = res.data.data.sort((a,b)=>{
                            return b.createTime - a.createTime
                        });
                    }
                })
            },
            showStep(record) {
                this.steps = [];
                let data = {
                    "applyId": record.id,
                };
                payApproval.queryAll.post(data, res => {
                    if (res.data.flag) {
                        this.steps = res.data.data;
                        this.modalWidth = (this.steps.length+1) * 270;
                        this.visible2 = true;
                    }
                    else{
                        this.$message.error("无审核流程，本条支付为无需审核类型")
                    }
                })
            },
        },
        created() {
            this.$getItem("__myDepartments").then(str=>{
                this.myDepartments = JSON.parse(str);
                this.departmentId = this.myDepartments[0].id;
                this.init()
            })
        }
    }
</script>

<style scoped>

</style>