<!--
* @program: TYHOA2 
* @author: ly
* @component:ContractPicker 
* @description: 
* @create: 2024-09-30 15:04
-->
<template>
    <a-select
            v-model:value="selectId"
            placeholder="合同选择"
            style="width: 100%;margin: 0;padding: 0"
            :options="searchData"
            :fieldNames="fieldNames"
            @change="selectConform">
        <template #option="{type, name}">
            <div style="text-align:left"><a-tag>{{getType(type)}}</a-tag>{{name}}</div>
        </template>
    </a-select>
</template>

<script>

    import contractInfo from "../../assets/api/contractInfo";
    import {GetDict} from "../../assets/utils/general";

    export default {
        name: "ContractPicker",
        props:["value","itemId"],
        emits:["update:value","change"],
        data(){
            return{
                loading:false,
                searchData:[],
                selectId:null,
                selectedData:[],
                fieldNames:{ label: "name", value: "id"},
                contractType:GetDict("contractType"),
            }
        },
        watch:{
            value(){
                this.selectId = this.value;
            }
        },
        methods:{
            init(){
                this.selectId = this.value;
                contractInfo.queryAll.post({itemId:this.itemId},res=>{
                    if(res.data.flag){
                        this.searchData = res.data.data;
                    }
                    else{
                        this.searchData = [];
                    }
                })
            },
            selectConform(){
                this.selectedData = this.searchData.find(item=>{return this.selectId === item.id});
                this.$emit("update:value",this.selectId);
                this.$emit("change",this.selectedData);
            },
            getType(value){
                let type = this.contractType.find(item=>item.value === value);
                if(type){
                    return type.label
                }
                else{
                    return ""
                }
            }
        },
        created() {
            this.init();

        }
    }
</script>

<style scoped>

</style>