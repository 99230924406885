<!--
* @program: TYHOA2 
* @author: ly
* @component:OtherPayAudit
* @description: 其他支付审核
* @create: 2024-09-14 17:26
-->
<template>
    <div class="column-flex">
        <div class="search-bar">
            <a-button @click="init"  type="dashed">
                <template #icon>
                    <SyncOutlined/>
                </template>
            </a-button>
            <a-radio-group style="margin-left:15px;border-left: #022d5c solid;padding-left:10px" @change="init" v-model:value="queryOptions.state">
                <a-radio v-for="item in stateDict" :key="item.value" :value="item.value">{{item.label}}</a-radio>
            </a-radio-group>
        </div>
        <div class="row-flex">
            <LTable :columns="tableColumns" :dataSource="source" :loading="loading">
                <template #action="{record}">
                    <div class="action-column">
                        <a-tooltip title="查看审核流程">
                            <a-button @click="showStep(record)" type="link">
                                <template #icon>
                                    <SearchOutlined/>
                                </template>
                            </a-button>
                        </a-tooltip>
                        <a-tooltip title="通过审核" v-if="record.approvalState ==='0' && queryOptions.state === '1'">
                            <a-button @click="enableRow(record)" class="action-button" type="link">
                                <CheckOutlined class="pointer" style="color: green"/>
                            </a-button>
                        </a-tooltip>
                        <a-tooltip title="拒绝通过" v-if="record.approvalState ==='0' && queryOptions.state === '1'">
                            <a-button @click="disableRow(record)" class="action-button" type="link">
                                <CloseOutlined class="pointer" style="color: red"/>
                            </a-button>
                        </a-tooltip>
                    </div>
                </template>
            </LTable>
        </div>
    </div>
    <a-modal :footer="null"
             :title="title"
             destroyOnClose
             v-model:open="visible">
        <a-textarea :auto-size="{ minRows: 2, maxRows: 6 }" v-model:value="reason"/>
        <a-button @click="saveAudit" size="small" style="margin: 20px" type="primary">
            提交
        </a-button>
    </a-modal>
    <a-modal :footer="null"
              destroyOnClose
             :width="modalWidth"
              title="审核状态"
              v-model:open="visible2">
        <AuditSteps :steps="steps" v-if="visible2"/>
    </a-modal>
</template>

<script>

    import otherPay from "../../../assets/api/otherPay";
    import AuditSteps from "../../Equipment/AssetsAuditCenter/AuditSteps";
    import LTable from "../../../components/table/LTable";
    import {CheckOutlined, CloseOutlined, SearchOutlined, SyncOutlined} from "@ant-design/icons-vue";
    import {ArrayFilter, GetDict} from "../../../assets/utils/general";
    import otherPayApproval from "../../../assets/api/otherPayApproval";
    import item_other_pay from "../../TableStruct/item_other_pay";
    export default {
        name: "OtherPayAudit",
        components: {
            AuditSteps,
            LTable,
            CheckOutlined,
            CloseOutlined,
            SearchOutlined,
            SyncOutlined,
        },
        computed:{
            source(){
                return ArrayFilter(this.dataSource,this.filter);
            }
        },
        data() {
            return {
                stateDict:GetDict("approval.state"),
                searchData: null,
                dataSource: [],
                tableColumns:new item_other_pay().getTableColumns(),
                loading: false,
                queryOptions: {
                    state: "1"
                },
                filter:{},
                viewModel: true,
                title: "详情",
                visible: false,
                currentRecord: null,
                auditState: null,
                reason: null,
                steps: [],
                visible2: false,
                modalWidth:300
            }
        },
        methods: {
            init() {
                this.loading = true;
                otherPay.queryMyApproval.urlPost(this.queryOptions, res => {
                    this.loading = false;
                    if (res.data.flag) {
                        this.dataSource = res.data.data.sort((a, b) => {
                            return b.createTime - a.createTime;
                        });
                    } else {
                        this.dataSource = [];
                    }
                });
            },
            enableRow(record) {
                this.visible = true;
                this.title = "通过审核";
                this.auditState = "2";
                this.reason = "满足申请条件";
                this.currentRecord = record;
            },
            disableRow(record) {
                this.visible = true;
                this.reason = "不满足申请条件，详情请咨询设备管理人";
                this.auditState = "3";
                this.title = "拒绝通过理由";
                this.currentRecord = record;
            },
            saveAudit() {
                let data = {
                    payId: this.currentRecord.id,
                    state: this.auditState,
                    remarks: this.reason
                };
                otherPayApproval.approvalApply.urlPost(data, res => {
                    if (res.data.flag) {
                        this.init();
                        this.visible = false;
                        this.$message.success(res.data.msg);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },
            showStep(record) {
                this.steps = [];
                let data = {
                    "payId": record.id,
                };
                otherPayApproval.queryAll.post(data, res => {
                    if (res.data.flag) {
                        this.steps = res.data.data;
                        this.modalWidth = (this.steps.length+1) * 270;
                        this.visible2 = true;
                    }
                })
            }
        },
        created() {
            this.init()
            // this.$getItem("__myID").then(str=>{
            //     this.queryOptions.approvalUser = str;
            //     this.init()
            // })

        }
    }
</script>

<style scoped>

</style>