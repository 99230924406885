/** 
* 自动生成文件，请勿修改 
* @name: 报销:费用类别模块
* @description: 报销-费用类别-增删改查
* @create: 2024/10/18 15:02:19
* @swagger: 2.0
* 合计:8条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const category = { 
	/** 1 summary:费用类别-批量删除
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/category/batchDelete.do"), 
	/** 2 summary:费用类别-批量保存/修改
	description:参数：List<CategoryVO>
	method:post
	params:需要配合Column函数使用
	*/ 
	batchSaveOrUpd : new APIObject.postObject("/api/category/batchSaveOrUpd.do"), 
	/** 3 summary:费用类别-保存/修改
	description:参数：单条数据对象CategoryVO
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/category/saveOrUpd.do"), 
	/** 4 summary:查询所有未绑定会计科目的类别-树形结构
	description:参数：无
	method:post
	params:
	*/ 
	noBindSuject : new APIObject.postObject("/api/category/noBindSuject.do"), 
	/** 5 summary:费用类别-多条数据查询
	description:参数：类别名称（大类名称），模糊查询，可传可不传
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOneLevel : new APIObject.postObject("/api/category/queryOneLevel.do"), 
	/** 6 summary:费用类别-单条删除
	description:参数：单条数据的主键id,若选择的是大类，其下的小类也会被删除，前端应给与二次提示
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/category/delete.do"), 
	/** 7 summary:费用类别-根据一个大类id查询其下的所有小类
	description:参数：大类id，必传
	method:post
	params:需要配合Column函数使用
	*/ 
	findSmallCategory : new APIObject.postObject("/api/category/findSmallCategory.do"), 
	/** 8 summary:费用类别-单条数据查询
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/category/queryOne.do"), 
};
export default category;