/**
 * @program: tyh-oa
 * @author: ly
 * @description: APIObject
 * @create: 2021-03-19 14:17
 **/
import {message } from 'ant-design-vue';
import {router} from "../../main"
import {axiosInstance} from "../../main";

//const baseUrl = "http://192.168.10.190:8086/zslt-boot/";
//const baseUrl = "http://192.168.10.45:8086/zslt-boot/";
// const baseUrl = "http://localhost:8086/zslt-boot/";


const baseUrl = "../tyhoa_ht";// 190 开发测试
const geoserver_url = '/hxzf_geoserver';// 开发测试


class apiClass {
    constructor(url) {
        this.url = apiClass.baseUrl()  + url;
    }
    static baseUrl(){
        return baseUrl;
    }
    static geoserverUrl(){
        return geoserver_url;
    }
    fileDownload(url,fileName){//下载文件使用
        axiosInstance({
            url:this.url + url,
            responseType: 'blob',
            method: 'get',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                //"X-Access-Token": localStorage.getItem("token")
            }
        }).then(res =>{
            if(res.data.code === 401){
                message.error(res.data.msg);
                return router.push({path:"/"})
            }
            if (res.data.type === 'application/json') {
                const reader = new FileReader();
                reader.readAsText(res.data, 'utf-8');
                reader.onload = () => {
                    let msg = JSON.parse(reader.result);
                    message.error(msg.msg);
                };
            }
            else{
                // 非IE下载
                if ('download' in document.createElement('a')) {
                    let tagA = document.createElement('a');
                    tagA.href = window.URL.createObjectURL(res.data) ;// 获取当前文件的一个内存URL
                    tagA.download = fileName ;// 下载后文件名
                    tagA.style.display = 'none';
                    document.body.appendChild(tagA);
                    tagA.click();
                    window.URL.revokeObjectURL(tagA.href);
                    document.body.removeChild(tagA);
                }
                else{
                    window.navigator.msSaveBlob(res.data,fileName);
                }
            }
        }).catch(error=>{
            console.log(error.response)
            message.error("axios错误")
        });
    }
    preFileDownload(data,fileName){
        let url = this.url+"?";
        for(let i in data){
            let str = i+"="+data[i];
            url += str + "&";
        }
        url = url.substr(0,url.length-1);
        axiosInstance({
            url,
            responseType: 'blob',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        }).then(res =>{
            if(res.data.code === 401){
                message.error(res.data.msg);
                return router.push({path:"/"})
            }
            if (res.data.type === 'application/json') {
                const reader = new FileReader();
                reader.readAsText(res.data, 'utf-8');
                reader.onload = () => {
                    let msg = JSON.parse(reader.result);
                    message.error(msg.msg);
                };
            }
            else{
                // 非IE下载
                if ('download' in document.createElement('a')) {
                    let tagA = document.createElement('a');
                    tagA.href = window.URL.createObjectURL(res.data) ;// 获取当前文件的一个内存URL
                    tagA.download = fileName ;// 下载后文件名
                    tagA.style.display = 'none';
                    document.body.appendChild(tagA);
                    tagA.click();
                    window.URL.revokeObjectURL(tagA.href);
                    document.body.removeChild(tagA);
                }
                else{
                    window.navigator.msSaveBlob(res.data,fileName);
                }
            }
        }).catch(error=>{
            console.log(error.response)
            message.error("axios错误");
        })
    }
    fileDownloadGet(data,fileName){//下载文件使用
        let url = this.url+"?";
        for(let i in data){
            let str = i+"="+data[i];
            url += str + "&";
        }
        url = url.substr(0,url.length-1);
        axiosInstance({
            url,
            responseType: 'blob',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        }).then(res =>{
            if(res.data.code === 401){
                message.error(res.data.msg);
                return router.push({path:"/"})
            }
            if (res.data.type === 'application/json') {
                const reader = new FileReader();
                reader.readAsText(res.data, 'utf-8');
                reader.onload = () => {
                    let msg = JSON.parse(reader.result);
                    message.error(msg.msg);
                };
            }
            else{
                // 非IE下载
                if ('download' in document.createElement('a')) {
                    let tagA = document.createElement('a');
                    tagA.href = window.URL.createObjectURL(res.data) ;// 获取当前文件的一个内存URL
                    tagA.download = fileName ;// 下载后文件名
                    tagA.style.display = 'none';
                    document.body.appendChild(tagA);
                    tagA.click();
                    window.URL.revokeObjectURL(tagA.href);
                    document.body.removeChild(tagA);
                }
                else{
                    window.navigator.msSaveBlob(res.data,fileName);
                }
            }
        }).catch(error=>{
            console.log(error.response)
            message.error("axios错误");
        })
    }
    fileDownloadPost(data,fileName){//下载文件使用
        axiosInstance({
            url:this.url,
            data: data,
            responseType: 'blob',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                //"X-Access-Token": localStorage.getItem("token")
            }
        }).then(res =>{
            if(res.data.code === 401){
                message.error(res.data.msg);
                return router.push({path:"/"})
            }
            if (res.data.type === 'application/json') {
                const reader = new FileReader();
                reader.readAsText(res.data, 'utf-8');
                reader.onload = () => {
                    let msg = JSON.parse(reader.result);
                    message.error(msg.msg);
                };
            }
            else{
                // 非IE下载
                if ('download' in document.createElement('a')) {
                    let tagA = document.createElement('a');
                    tagA.href = window.URL.createObjectURL(res.data) ;// 获取当前文件的一个内存URL
                    tagA.download = fileName ;// 下载后文件名
                    tagA.style.display = 'none';
                    document.body.appendChild(tagA);
                    tagA.click();
                    window.URL.revokeObjectURL(tagA.href);
                    document.body.removeChild(tagA);
                }
                else{
                    window.navigator.msSaveBlob(res.data,fileName);
                }
            }
        }).catch(()=>{
            message.error("下载出错！")
        });
    }
    promiseHandling(promise,callback){
        promise.then(res=> {
            if(res.data.code === 401){
                return router.push({path:"/"})
            }
        }).catch(error=>{
            console.log(error.response);
            message.error("axios错误");
        });
        if(callback){
            promise.then(res=>{
                callback(res)
            })
        }
        return promise;
    }
}
class getObject extends apiClass{
    constructor(url) {
        super(url);
    }
    /**
     *
     * @param data 传入参数
     * @param callback 回调函数
     */
    get(data,callback){
        let promise = axiosInstance.get(this.url,{params:data});
        return this.promiseHandling(promise,callback);
    }
    getByteImg(data,callback){
        let promise =  axiosInstance.get(
            this.url,
            {params:data,responseType:'blob'},
        );
        return this.promiseHandling(promise,callback);
    }
    async asyncGet(data){
        let res = await axiosInstance.get(this.url,{params:data});
        return res.data;
    }
}
class postObject extends apiClass{
    constructor(url) {
        super(url);
    }
    post(data,callback){
        let promise = axiosInstance.post(this.url,data);
        return this.promiseHandling(promise,callback);
    }
    async asyncPost(data){
        let res = await axiosInstance.post(this.url,data);
        if(res.data.code === 401){
            return router.push({path:"/"})
        }
        else{
            return res.data;
        }
    }
    async urlAsyncPost(data){
        let formData = new FormData();
        let keys = Object.keys(data);
        for(let key of keys){
            formData.append(key, data[key]);
        }
        let res = await axiosInstance.post(this.url,formData);
        if(res.data.code === 401){
            return router.push({path:"/"})
        }
        else{
            return res.data;
        }
    }
    /**
     * POST情况下用url传递参数
     * @param data 传入参数
     * @param callback 回调函数
     */
    urlPost(data,callback){
        let formData = new FormData();
        let keys = Object.keys(data);
        for(let key of keys){
            formData.append(key, data[key]);
        }
        let promise = axiosInstance.post(this.url,formData);
        return this.promiseHandling(promise,callback);
    }
    bothPost({urlParam,formParam},callback){
        let url = this.url+"?";
        for(let i in urlParam){
            if(urlParam[i] !== null && urlParam[i] !==""){
                let str = i+"="+urlParam[i];
                url += str + "&";
            }
        }
        url = url.substr(0,url.length-1);
        let promise = axiosInstance.post(url,formParam);
        return this.promiseHandling(promise,callback);
    }

}
class deleteObject extends apiClass{
    constructor(url) {
        super(url);
    }
    /**
     * DELETE情况下用url传递参数
     * @param data 传入参数
     * @param callback 回调函数
     */
    urlDELETE(data,callback){
        let promise = axiosInstance.delete(this.url,  {
            params: data
        });
        return this.promiseHandling(promise,callback);
    }
}

class mixObject extends Classes([deleteObject,getObject,postObject]){
    constructor(url) {
        super(url);
        this.url = apiClass.baseUrl()  + url;
    }
}
function Classes(bases) {
    class Bases {
        constructor() {
            bases.forEach(base => Object.assign(this, new base()));
        }
    }
    bases.forEach(base => {
        Object.getOwnPropertyNames(base.prototype)
            .filter(prop => prop !== 'constructor')
            .forEach(prop => Bases.prototype[prop] = base.prototype[prop])
    });
    return Bases;
}

function readToken() {
    axiosInstance.interceptors.request.use(
        config =>{
            config.headers["X-Access-Token"] = localStorage.getItem("token");
            return config}, error => {return Promise.reject(error)});
}

export default {getObject,deleteObject,postObject,mixObject};
export {readToken,apiClass,baseUrl,geoserver_url};
