<!--
* @program: TYHOA2 
* @author: ly
* @component:MonthReportItem 
* @description: 
* @create: 2024-08-12 20:27
  "achieveAmount": 0,
  "descri": "string",
  "id": "string",
  "month": "string",
  "ratio": 0,
  "remarks": "string",
  "taskId": "string"
-->
<template>
    <a-card hoverable class="card-info" >
        <template #title>
            <span class="card-title">
                {{title}}
            </span>
        </template>
        <template #extra>
            <div v-if="!viewModel" class="action-column">
                <a-button type="link"  @click="workRatio">
                    工作量比例分配
                </a-button>
                <a-button v-if="vModel" type="link"  @click="editData">
                    编辑
                </a-button>
                <a-button v-if="!vModel" type="link"  @click="saveData">
                    保存
                </a-button>
                <a-button v-if="!vModel" type="link"  @click="cancelEdit">
                    取消
                </a-button>
                <a-popconfirm
                        ok-text="是"
                        cancel-text="否"
                        class="action-button"
                        title="确定删除吗?"
                        v-if="vModel"
                        @confirm="deleteData">
                    <a-button style="color: red" type="link">
                        <template #icon>
                            <DeleteOutlined   title="删除" class="pointer"/>
                        </template>
                        删除
                    </a-button>
                </a-popconfirm>
            </div>
        </template>
        <a-row v-if="!vModel">
            <a-col class="form-label" style="padding-right: 15px;" :span="6">
                <div>月报时间</div>
            </a-col>
            <a-col class="form-value" :span="6">
                <a-date-picker picker="month" style="width: 100%;" @change="selectHandle" v-model:value="selectedDate" :disabledDate="disabledDate" />
            </a-col>
            <a-col class="form-label" style="padding-right: 15px;" :span="6">
                <div >完成总进度(%)</div>
            </a-col>
            <a-col class="form-value" :span="4">
                <a-input-number style="width: 100%;" :max="100" :min="0" v-model:value="formData.ratio"  />
            </a-col>
        </a-row>
        <a-row v-if="vModel">
            <a-col class="form-label" style="text-align: right;padding-right: 15px;" :span="6">
                <div>完成总进度(%)</div>
            </a-col>
            <a-col class="form-value"   :span="18">
                <a-progress style="width: 70%" strokeColor="#0DBB04" :format="(percent,success)=>{return  '(增量'+(percent - success)+'%)'+percent+'%'}" :percent="formData.ratio" :success="{ percent: formData.formerRatio,strokeColor:'#B8B8B8' }"  />
            </a-col>
        </a-row>
        <a-row>
            <a-col class="form-label" style="text-align: right;padding-right: 15px;" :span="6">
                <div>本月工作情况</div>
            </a-col>
            <a-col class="form-value"  :span="16">
                <a-textarea v-if="!vModel" v-model:value="formData.descri"  placeholder="本月工作情况" :rows="4" />
                <pre v-else class="report-item" >{{formData.descri}}</pre>
            </a-col>
        </a-row>
    </a-card>
</template>

<script>
    import {DeleteOutlined} from "@ant-design/icons-vue";
    import dayjs from "dayjs";
    import taskMonthRatio from "../../../../assets/api/taskMonthRatio";

    export default {
        name: "MonthReportItem",
        props:["report","viewModel"],
        emits:["delete","workRatio","update"],
        components:{
            DeleteOutlined
        },
        computed:{
            // editable() {
            //     let data1 =  dayjs(this.formData.createTime).format("YYYY-MM-DD")
            //     let data2 =  dayjs().format("YYYY-MM-DD");
            //     return data1 === data2
            // },
            title(){
                if(this.formData.month){
                    return dayjs(this.formData.month).format("YYYY年度MM月月报");
                }
                else{
                    return ""
                }
            }
        },
        data() {
            return {
                selectedDate:null,
                vModel:true,
                formData:{},
            }
        },
        methods: {
            init() {
                this.formData = {...this.report};
                this.selectedDate = dayjs(this.formData.month)
            },
            disabledDate(current){ //
                let thisMonth = dayjs().month();
                let currentMonth = dayjs(current).month();
                return currentMonth !== thisMonth && currentMonth !== thisMonth -1
            },
            saveData(){
                this.$getItem("__myName").then(str => {
                    this.formData.remarks = str;
                    this.vModel = true;
                    taskMonthRatio.saveOrUpd.post(this.formData,res=>{
                        if(res.data.flag){
                            this.$message.success(res.data.msg);
                            this.$emit("update");
                        }
                        else{
                            this.$message.error(res.data.msg);
                        }
                    })
                })
            },
            selectHandle(){
                if(this.selectedDate){
                    this.formData.month = this.selectedDate.format('YYYY-MM');
                }
            },
            editData(){
                this.vModel = false;
            },
            cancelEdit(){
                this.vModel = true;
                this.init()
            },
            deleteData(){
                let data = {id:this.formData.id};
                taskMonthRatio.delete.urlPost(data,res=>{
                    if(res.data.flag){
                        this.$emit("delete",this.formData.id);
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            workRatio(){
                this.$emit("workRatio",this.formData.month)
            },
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>