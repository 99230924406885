<!--
* @program: TYHOA2 
* @author: ly
* @component:QCManager 
* @description: 质检管理
* @create: 2024-09-27 09:21
-->
<template>
    <div class="column-flex">
        <div class="row-flex">
            <div style="height: 100%;width:200px; overflow: auto;background: #fafafa;">
                <MenuCardItem v-for="(child,index) in childrenRoutes.filter(item=>item.path)" :key="index"
                              :menu="child" :enable="$route.name === child.name"
                              @click="change(child)"/>
            </div>
            <div class="route-content">
                <transition  name="fade"   mode="out-in">
                    <router-view />
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
    import CardRoute from "../../Other/OAMenuDefaultPage/CardRoute";
    export default {
        name: "QCManager",
        mixins:[CardRoute],
    }
</script>

<style scoped>

</style>