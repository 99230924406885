/** 
* 自动生成文件，请勿修改 
* @name: 资产:设备类别模块
* @description: 设备类别-增删改查
* @create: 2024/10/18 15:02:19
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const deviceCategory = { 
	/** 1 summary:保存/修改-单条
	description:参数：单条数据对象DeviceCategoryVO
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/deviceCategory/saveOrUpd.do"), 
	/** 2 summary:删除-单条
	description:参数：单条数据的主键id,若选择的是大类，其下的小类也会被删除，前端应给与二次提示
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/deviceCategory/delete.do"), 
	/** 3 summary:保存/修改-批量
	description:参数：List<DeviceCategoryVO>
	method:post
	params:需要配合Column函数使用
	*/ 
	batchSaveOrUpd : new APIObject.postObject("/api/deviceCategory/batchSaveOrUpd.do"), 
	/** 4 summary:查询-单条数据
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/deviceCategory/queryOne.do"), 
	/** 5 summary:删除-批量
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/deviceCategory/batchDelete.do"), 
	/** 6 summary:查询-根据一个大类id查询其下的所有小类
	description:参数：大类id，必传
	method:post
	params:需要配合Column函数使用
	*/ 
	findSmallCategory : new APIObject.postObject("/api/deviceCategory/findSmallCategory.do"), 
	/** 7 summary:查询-大类小类树形结构
	description:参数：类别名称（大类名称），模糊查询，可传可不传
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOneLevel : new APIObject.postObject("/api/deviceCategory/queryOneLevel.do"), 
};
export default deviceCategory;