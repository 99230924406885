<!--
* @program: office_automation
* @author: ly
* @component:AttendanceRuleSetting
* @description: 考勤扣分规则设定
* @create: 2022-01-14 19:23
-->
<template>
    <div class="column-flex">
        <div class="search-bar">
            <a-button @click="refresh"  shape="circle" type="dashed">
                <template #icon><SyncOutlined/></template>
            </a-button>
            <a-button @click="addData"  shape="circle" type="dashed">
                <template #icon>
                    <PlusOutlined/>
                </template>
            </a-button>
        </div>
        <div style="width: 100%;height: 100%;display: flex">
            <div class="main-content50">
                <div class="full-fill">
                    <LTable :columns="argument.tableColumns" :dataSource="argument.dataSource" :view-model="true" :page-size="pageSize">
                        <template #action="{index,record}">
                            <div class="action-column">
                                <a-button @click="showDetail(record)" class="action-button" type="link">
                                    <SearchOutlined class="pointer"/>
                                </a-button>
                                <a-popconfirm
                                        ok-text="是"
                                        cancel-text="否"
                                        class="action-button"
                                        title="确定删除吗?"
                                        @confirm="deleteData(index,record)">
                                    <a-button   type="link">
                                        <DeleteOutlined  style="color: red" class="pointer"/>
                                    </a-button>
                                </a-popconfirm>
                            </div>
                        </template>
                    </LTable>
                </div>
            </div>
            <div class="main-content50">
                <div class="full-fill">
                    <div v-if="currentRecord" >
                        <a-page-header style="border: 1px solid rgb(235, 237, 240);">
                            <template #title>
                                    <div >
                                        {{currentRecord.title}}
                                        <a-button @click="addData2"  shape="circle" type="dashed" style="margin-left: 16px;">
                                            <template #icon>
                                                <PlusOutlined/>
                                            </template>
                                        </a-button>
                                    </div>
                            </template>
                        </a-page-header>
                        <LTable :columns="argument2.tableColumns" :dataSource="argument2.dataSource" :view-model="false" :page-size="pageSize" @save="saveData2" @delete="deleteData2">
                            <template #action="{index,record}">
                                <div class="action-column">
                                    <a-popconfirm
                                            ok-text="是"
                                            cancel-text="否"
                                            class="action-button"
                                            title="确定删除吗?"
                                            @confirm="deleteData2(index,record)">
                                        <a-button   type="link">
                                            <DeleteOutlined  style="color: red" class="pointer"/>
                                        </a-button>
                                    </a-popconfirm>
                                </div>
                            </template>
                        </LTable>
                    </div>
                    <div  style="font-size: 40px;" class="non-select" v-else>
                        欢迎使用贵州中色蓝图办公系统
                    </div>
                </div>
            </div>
        </div>
    </div>
    <a-modal v-model:open="visible"
             destroyOnClose
             :footer="null"
             title="添加新规则">
        <span>规则标题：</span><a-textarea v-model:value="title" auto-size/>
        <span>备注：</span><a-textarea v-model:value="remarks" auto-size/>
        <a-button size="small" type="primary" style="margin: 20px" @click="dataSave">
            提交
        </a-button>
    </a-modal>
</template>

<script>
    import LTable from "../../../../components/table/LTable";
    import {SyncOutlined,PlusOutlined,SearchOutlined,DeleteOutlined} from "@ant-design/icons-vue"
    import Column from "../../../../assets/tables/column";
    import ColumnType from "../../../../assets/tables/column_type";
    import attendanceRule from "../../../../assets/api/attendanceRule";
    import ruleDetail from "../../../../assets/api/ruleDetail";
    export default {
        name: "AttendanceRuleSetting",
        components: {
            LTable,SyncOutlined,PlusOutlined,
            SearchOutlined,DeleteOutlined
        },
        data(){
            return{
                viewModel:true,
                pageSize:15,
                argument:{
                    dataSource:null,
                    tableColumns:[
                        // new Column("序号","_index",ColumnType.Index,false).setTableView(60),
                        new Column("规则标题","title",ColumnType.String,true).setTableView(200),
                        new Column("备注","remarks",ColumnType.Date,true).setTableView(100),
                        new Column("操作","actions","actions").setTableView(80)
                    ],
                    loading:false,
                },
                argument2:{
                    dataSource:null,
                    tableColumns:[//1：迟到；2：早退；3：缺卡；4：旷工；5：地点异常；6：设备异常
                        // new Column("序号","_index",ColumnType.Index,false).setTableView(60),
                        new Column("违规类型","type",ColumnType.Enum,true).setTableView(100)
                        .setEnum(
                            {value:"1",label:"迟到",color:"pink"},
                            {value:"2",label:"早退",color:"pink"},
                            {value:"3",label:"缺卡",color:"pink"},
                            {value:"4",label:"旷工",color:"pink"},
                            {value:"5",label:"地点异常",color:"pink"},
                            {value:"6",label:"设备异常",color:"pink"}),
                        new Column("不予扣分次数","allowCount",ColumnType.Number,true).setTableView(100),
                        new Column("不扣分的时间范围","timeTerm",ColumnType.Number,true).setTableView(150),
                        new Column("扣分/次","onceScore",ColumnType.Number,true).setTableView(100),
                        new Column("操作","actions","actions").setTableView(80)
                    ],
                    loading:false,
                },
                visible:false,
                title:null,
                remarks:null,
                currentRecord:null,
            }
        },
        methods:{
            init(){
                this.visible = false;
                this.argument2.dataSource = [];
                this.argument.loading = true;
                attendanceRule.queryAll.urlPost({},res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = []
                    }
                })
            },
            refresh(){
                this.init()
            },
            init2(){
                let data = {
                    "ruleId": this.currentRecord.id,
                    "type": ""
                };
                this.argument2.loading = true;
                ruleDetail.queryAll.post(data,res=>{
                    this.argument2.loading = false;
                    if(res.data.flag){
                        this.argument2.dataSource = res.data.data;
                    }
                    else{
                        this.argument2.dataSource = [];
                    }
                })
            },
            showDetail(record){
                this.currentRecord = record;
                this.init2();
            },
            deleteData(index,record){
                let data = {
                    id:record.id
                };
                attendanceRule.delete.urlPost(data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.argument.dataSource.splice(index,1);
                        this.argument2.dataSource = [];
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            dataSave(){
                let data ={
                    title:this.title,
                    remarks:this.remarks
                };
                attendanceRule.saveOrUpdate.post(data,res=>{
                    if(res.data.flag){
                        this.init();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            addData(){
                this.title = "";
                this.remarks = "";
                this.visible = true;
            },
            addData2(){
                let data = {
                    type:null,
                    allowCount:null,
                    onceScore:null,
                    remarks:""
                };
                this.argument2.dataSource.push(data);
            },
            saveData2(record){
                record.ruleId = this.currentRecord.id;
                ruleDetail.saveOrUpdate.post(record,res=>{
                    if(res.data.flag){
                        this.init2();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            deleteData2(record){
                let data = {
                    id:record.id
                };
                ruleDetail.delete.urlPost(data);
            }
        },
        created() {
            this.init()
        }
    }
</script>
<style scoped>

</style>
