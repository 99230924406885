<!--
* @program: TYHOA2 
* @author: ly
* @component:OtherIncome 
* @description: 
* @create: 2024-09-24 14:17
-->
<template>
    <div class="column-flex">
        <div class="search-bar">
            <a-button @click="init" type="dashed">
                <template #icon>
                    <SyncOutlined/>
                </template>
            </a-button>
            <a-button @click="addData" type="dashed">
                <template #icon>
                    <PlusOutlined/>
                </template>
            </a-button>
            <a-input placeholder="收入类别" style="width: 200px" v-model:value="filter.categoryName"/>
            <a-input placeholder="收款账户" style="width: 200px" v-model:value="filter.bankName"/>
            <a-range-picker v-model:value="rangeMonth" picker="month" />
        </div>
        <LTable :columns="tableColumns" :data-source="source" :loading="loading">
            <template #action="{record}">
                <div class="action-column">
                    <a-tooltip title="编辑绩效">
                        <a-button @click="editData(record)" type="link">
                            <template #icon>
                                <EditOutlined/>
                            </template>
                        </a-button>
                    </a-tooltip>
                    <a-popconfirm
                            @confirm="deleteData(record)"
                            cancel-text="否"
                            ok-text="是"
                            title="确定删除吗？">
                        <a-button type="link">
                            <template #icon>
                                <DeleteOutlined style="color: red"/>
                            </template>
                        </a-button>
                    </a-popconfirm>
                </div>
            </template>
        </LTable>
    </div>
    <a-modal :footer="null"
             :width="600"
             destroy-on-close
             title="确认支付完成"
             v-model:visible="visible">
        <a-row>
            <a-col :span="6">
                <div class="form-label" style="margin-right: 10px">所属项目</div>
            </a-col>
            <a-col :span="18">
                <ProjectPicker  v-model:value="itemId" :option="option" />
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="6">
                <div class="form-label" style="margin-right: 10px">收入类别</div>
            </a-col>
            <a-col :span="18">
                <a-tree-select
                        treeDefaultExpandAll
                        v-model:value="categoryId"
                        style="width: 100%"
                        placeholder="输入查询/选择子分类"
                        :field-names="fieldNames"
                        :treeData="treeData"
                        :treeNodeFilterProp="'categoryName'"/>
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="6">
                <div class="form-label" style="margin-right: 10px">支出账户</div>
            </a-col>
            <a-col :span="18">
                <a-select :disabled="vModel" :fieldNames="{key:'id', value: 'id',label:'remarks'}"
                          :options="bankCards" style="width: 100%" v-model:value="bankId">
                </a-select>
            </a-col>
        </a-row>
        <LForm :labelSpan="6" :view-model="vModel" :form-data="currentRecord" :form-columns="formColumns">
            <template #action="{record}">
                <a-button @click="saveData(record)"  type="primary">
                    提交
                </a-button>
            </template>
        </LForm>
    </a-modal>
</template>

<script>
    import LTable from "../../../../components/table/LTable";
    import {DeleteOutlined, PlusOutlined, SearchOutlined, SyncOutlined, EditOutlined} from "@ant-design/icons-vue";
    import {ArrayFilter} from "../../../../assets/utils/general";
    import income from "../../../../assets/api/income";
    import item_finance_income from "../../../TableStruct/item_finance_income";
    import ProjectPicker from "../../../common/ProjectPicker";
    import LForm from "../../../../components/form/LForm";
    import dayjs from "dayjs";
    export default {
        name: "OtherIncome",
        props: ["bankCards"],
        components: {
            LTable,ProjectPicker,LForm,
            SearchOutlined, SyncOutlined, PlusOutlined,EditOutlined,
            DeleteOutlined
        },
        computed: {
            source(){
                let source = [];
                if(this.rangeMonth){
                    source = this.dataSource.filter(item=>{
                        let payDate = dayjs(item.collectDate);
                        const startOfMonth = this.rangeMonth[0].startOf('month');
                        const endOfMonth = this.rangeMonth[1].endOf('month');
                        return payDate.isAfter(startOfMonth) && payDate.isBefore(endOfMonth);
                    });
                    return ArrayFilter(source,this.filter);
                }
                else{
                    return ArrayFilter(this.dataSource,this.filter);
                }
            }
        },
        data() {
            return {
                visible: false,
                loading: false,
                currentRecord: null,
                dataSource: [],
                tableColumns: new item_finance_income().getTableColumns(),
                formColumns: new item_finance_income().getFormColumns(),
                filter:{},
                rangeMonth:undefined,
                itemId:undefined,
                bankId:undefined,
                categoryId:undefined,
                option:[],
                vModel:true,
                fieldNames :{
                    label: 'categoryName',
                    key:"id",
                    value:"id",
                },
                treeData:[]
            }
        },
        methods: {
            init() {
                this.loading = true;
                income.queryAll.post({},res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data.sort((a,b)=>{
                            return b.createTime - a.createTime
                        });
                        this.dataSource.map(item=>{
                            let pCard = this.bankCards.find(card=>card.id === item.bankId);
                            if(pCard){
                                item.bankName = pCard.remarks
                            }
                            return item
                        });
                    }
                    else{
                        this.dataSource = [];
                    }
                })
            },
            addData(){
                this.itemId = undefined;
                this.bankId = undefined;
                this.categoryId = undefined;
                this.visible = true;
                this.currentRecord = {};
                this.option = [];
                this.vModel = false;
            },
            editData(record){
                this.itemId = record.itemId;
                this.bankId = record.bankId;
                this.categoryId = record.categoryId;
                this.vModel = false;
                this.visible = true;
                this.currentRecord = record;
                this.option = [{id:record.itemId,name:record.itemName}]
            },
            saveData(record){
                record.itemId = this.itemId;
                record.bankId = this.bankId;
                record.categoryId = this.categoryId;
                income.saveOrUpdate.post(record,res=>{
                    if(res.data.flag){
                        this.visible = false;
                        this.init();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
        },
        created() {
            this.init();
            this.$getItem("__myCategory").then(str=>{
                this.treeData = JSON.parse(str).filter(item=>item.categoryName === '收入类')
            })
        },
    }
</script>

<style scoped>

</style>